import { React, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Stack, Chip, Modal, Typography } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MuiAlert from "@mui/material/Alert";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Snackbar from "@mui/material/Snackbar";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import {
  getPanelMembers,
  getSurveyNames,
  getPersonDisplaySettings,
  setPersonDisplaySettings,
  deletePanelMember,
  deleteSurveyHelpers,
  updateSubpanels,
  surveyPanel,
} from "../Survey/Survey.service";
import { formatDate, getTextFromHTML } from "../../Utility/constants";
import {
  setFilterFormData,
  setSelectedPanelMemberData,
} from "../Survey/Survey.slice";
import Tooltip from "../../components/Common/Tooltip";
import Button from "../../components/Common/Button";
import FilterForm from "./FilterForm";
import { initialFormData, filterEmptyFormData } from "./FilterForm";
import PanelMember from "./PanelMember";
import theme from "../../theme";
import LoadingText from "../../components/Common/LoadingText";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import { USER_ROLES } from "../../Utility/constants";

const PanelManagement = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const surveyId = location?.state?.surveyId;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );

  const isSuperUser =
    roleName === USER_ROLES.BUILDER_SUPER_USER_ROLENAME ? true : false;

  const isBuilderAdminUser =
    roleName === USER_ROLES.BUILDER_ADMIN_ROLENAME ? true : false;

  const deletePanelTooltip =
    isSuperUser || isBuilderAdminUser
      ? "Delete a panel member"
      : "Please contact AHA support to have a panel member removed";
  const [sorting, setSorting] = useState([]);
  const [sortAscending, setSortAscending] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [surveyNames, setSurveyNames] = useState([]);
  const [panelMembers, setPanelMembers] = useState([]);
  const [columnVisibility, setColumnVisibility] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openPanelModal, setOpenPanelModal] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState(false);
  const [selectedMemberId, setSelectedMemberId] = useState(null);

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmId, setConfirmId] = useState(0);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);

  const [confirmHelperTitle, setConfirmHelperTitle] = useState(null);
  const [confirmHelperOpen, setConfirmHelperOpen] = useState(false);

  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showLoading, setShowLoading] = useState(true);

  const initialColumns = {
    sr_custom_id: true,
    name: true,
    title: true,
    username: true,
    custom_id: true,
    orgName: true,
    date_modified: true,
    remove: true,
  };

  const selectedPanelId = useSelector(
    (state) => state?.survey?.selectedPanelId
  );
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const requestedUserEmail = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.username
  );

  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const surveyPanels = useSelector((state) => state?.survey?.surveyPanels);
  const filterFormData = useSelector((state) => state?.survey?.filterFormData);

  const selectedPanel = Array.isArray(surveyPanels)
    ? surveyPanels.find((panel) => panel.id === selectedPanelId)
    : [];

  const showButtons =
    roleName === USER_ROLES.BUILDER_SUPER_USER_ROLENAME ||
    roleName === USER_ROLES.BUILDER_ADMIN_ROLENAME
      ? true
      : false;

  useEffect(() => {
    const panelMember = panelMembers.find(
      (member) => member?.id === selectedMemberId
    );
    if (panelMember) {
      dispatch(setSelectedPanelMemberData(panelMember));
      setOpenPanelModal(true);
    }
  }, [selectedMemberId]);

  const processFilters = () => {
    const selectedFormData = {};

    Object.keys(filterFormData).forEach((key) => {
      if (Array.isArray(filterFormData[key])) {
        if (filterFormData[key].length > 0) {
          selectedFormData[key] = filterFormData[key].join(",");
        }
      } else if (filterFormData[key] !== "" && filterFormData[key] !== false) {
        selectedFormData[key] = filterFormData[key];
      }
    });

    return selectedFormData;
  };
  const deletePost = async () => {
    try {
      const response = await deletePanelMember(confirmId);
      if (response?.status === 200) {
        handleSuccess();
      }
    } catch (error) {
      console.log("Error deleting panel member", error);
      return null;
    }
  };
  const handleSuccess = () => {
    setSuccessSnackbarOpen(true);
    setAlertMsg("Panel Member is deleted successfully.");
    setAlertSeverity("success");
    setConfirmTitle(null);
    setConfirmId(0);
    setConfirmOpen(false);
    fetchPanelAssignmentInfo();
  };
  const openDeletePanel = async (srId, name) => {
    if (isSuperUser || isBuilderAdminUser) {
      setConfirmTitle(
        "Are you sure you want to delete panel member <b>" + name + "</b> ?"
      );
      setConfirmId(srId);
      setConfirmOpen(true);
    } else return;
  };
  const openDeleteSurveyHelpersPanel = async () => {
    if (isSuperUser || isBuilderAdminUser) {
      setConfirmHelperTitle(
        "Are you sure you want to delete all the survey takers in the panel? This action cannot be undone."
      );
      setConfirmHelperOpen(true);
    } else return;
  };
  const deleteAllHelpers = async () => {
    try {
      const res = await deleteSurveyHelpers(selectedPanelId);
      if (res?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey Helpers deleted successfully.");
        setAlertSeverity("success");
        fetchPanelAssignmentInfo();
        setConfirmHelperOpen(false);
      }
    } catch (error) {
      console.error("Error deleting survey helpers:", error);
    }
  };
  const fetchPanelAssignmentInfo = async () => {
    try {
      const res = await getPanelMembers({
        surveyPanelId: selectedPanelId,
        pageNumber: pagination.pageIndex + 1,
        numberPerPage: pagination.pageSize,
        sortBy: sortBy,
        sortAscending: sortAscending,
        filterCriteria: processFilters(),
        surveyId: surveyId,
      });
      if (res?.data) {
        setPanelMembers(res?.data?.panelMemberAssignments);
        setRowCount(res?.data?.totalNumber);
        setShowLoading(false);
      } else setPanelMembers([]);
    } catch (error) {
      console.error("Error fetching panel members:", error);
    }
  };

  const fetchSurveyNames = async () => {
    try {
      const res = await getSurveyNames(selectedPanelId);
      setSurveyNames(res.data);
    } catch (error) {
      console.error("Error fetching survey names:", error);
    }
  };

  useEffect(() => {
    const getDisplaySettings = async () => {
      try {
        const res = await getPersonDisplaySettings({
          personId: personId,
          page: "PanelManagement",
        });
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const fetchedColumnObj = res.data
            .map((item) => {
              if (item?.type === "column") {
                return item?.name;
              } else return null;
            })
            .reduce((obj, columnName) => {
              if (columnName) {
                obj[columnName] = true;
              }
              return obj;
            }, {});

          for (const key in initialColumns) {
            if (!fetchedColumnObj.hasOwnProperty(key)) {
              fetchedColumnObj[key] = false;
            }
          }
          const fetchedFilterObj = await res.data.reduce((obj, item) => {
            if (item.type === "filter") {
              if (
                [
                  "survey_filing.status_id",
                  "survey_respondent.role_id",
                  "survey_organization.state_id",
                  "survey_respondent.state_id",
                ].includes(item.name)
              ) {
                obj[item.name] = item.value.split(",").map((v) => parseInt(v));
              } else {
                obj[item.name] = item.value;
              }
            }
            return obj;
          }, {});

          setColumnVisibility(fetchedColumnObj);
          if (
            JSON.stringify(fetchedFilterObj) ===
              JSON.stringify(initialFormData) ||
            JSON.stringify(fetchedFilterObj) === JSON.stringify({})
          ) {
            dispatch(setFilterFormData(filterEmptyFormData(initialFormData)));
          } else dispatch(setFilterFormData(fetchedFilterObj));
        } else setColumnVisibility(initialColumns);
      } catch (error) {
        console.error("Error fetching person display settings:", error);
      }
    };
    if (personId) {
      getDisplaySettings();
    }
  }, [personId]);

  useEffect(() => {
    const setColumns = Object.entries(columnVisibility)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    const setDisplaySettings = async () => {
      try {
        await setPersonDisplaySettings({
          personId: personId,
          page: "PanelManagement",
          columns: setColumns,
          filterCriteria: processFilters(),
          createdBy: userName,
        });
      } catch (error) {
        console.error("Error setting person column settings:", error);
      }
    };
    if (Object.keys(columnVisibility).length !== 0) {
      setDisplaySettings();
    }
  }, [columnVisibility, filterFormData]);
  const data = useMemo(() => {
    return panelMembers?.map((member) => {
      return {
        id: member?.id,
        sr_custom_id: member?.srCustomId,
        name: member?.surveyRespondent?.name,
        title: member?.surveyRespondent?.title,
        username: member?.surveyRespondent?.username,
        custom_id: member?.surveyRespondent?.surveyOrganization?.customId,
        orgName: member?.surveyRespondent?.surveyOrganization?.name,
        date_modified:
          formatDate(
            member?.surveyRespondent?.surveyOrganization?.dateModified
          ) +
          " (" +
          member?.surveyRespondent?.status?.name +
          ")",
      };
    });
  }, [panelMembers]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sr_custom_id",
        header: "Id",
        size: 30,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 200,
        Cell: ({ row }) => (
          <Typography
            variant="h6"
            sx={{
              cursor: "pointer",
              color: theme.palette.primary.main,
              textDecoration: "underline",
            }}
            onClick={() => setSelectedMemberId(row?.original?.id)}
          >
            {row?.original?.name}
          </Typography>
        ),
      },
      {
        accessorKey: "title",
        header: "Title",
        size: 200,
      },
      {
        accessorKey: "username",
        header: "Username",
        size: 50,
      },
      {
        accessorKey: "custom_id",
        header: "Custom Id",
        size: 50,
      },
      {
        accessorKey: "orgName",
        header: "Org. Name",
        size: 220,
      },
      {
        accessorKey: "date_modified",
        header: "Modify Date",
        size: 150,
      },
      {
        accessorKey: "remove",
        header: "Remove",
        size: 50,
        Cell: ({ row }) => (
          <>
            <Tooltip title={deletePanelTooltip} arrow>
              <DeleteOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.gray.main,
                  background: theme.palette.gray.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "10px",
                }}
                onClick={() =>
                  openDeletePanel(row?.original?.id, row?.original?.name)
                }
              />
            </Tooltip>
          </>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    manualPagination: true,
    manualSorting: true,
    columns,
    data,
    onPaginationChange: setPagination,
    state: { pagination, sorting, columnVisibility },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    rowCount,
    initialState: { density: "compact" },
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
  });
  useEffect(() => {
    if (selectedPanelId) {
      fetchSurveyNames();
    }
  }, [selectedPanelId]);
  useEffect(() => {
    if (selectedPanelId) {
      fetchPanelAssignmentInfo();
    }
  }, [
    selectedPanelId,
    filterFormData,
    pagination.pageIndex,
    pagination.pageSize,
  ]);
  useEffect(() => {
    if (sortBy !== null && sortAscending !== null) {
      fetchPanelAssignmentInfo();
    }
  }, [sortBy, sortAscending]);
  useEffect(() => {
    if (sorting[0]) {
      if (sorting[0]?.id === "orgName") {
        setSortBy(`survey_organization.name`);
      } else if (sorting[0]?.id === "custom_id") {
        setSortBy(`survey_organization.custom_id`);
      } else {
        setSortBy(`survey_respondent.${sorting[0]?.id}`);
      }
      setSortAscending(!sorting[0]?.desc);
    }
  }, [sorting]);

  const handleOpenModal = async () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const handleOpenPanelModal = async () => {
    dispatch(setSelectedPanelMemberData({}));
    setOpenPanelModal(true);
  };

  const handleDownloadPanel = async () => {
    setSuccessSnackbarOpen(true);
    setAlertSeverity("success");
    setAlertMsg("Generating export file…");
    try {
      const res = await surveyPanel({
        surveyPanelId: selectedPanelId,
        requestedUserEmail: requestedUserEmail,
        filterCriteria: processFilters(),
      });
      if (res?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertSeverity("success");
        setAlertMsg(
          "Export complete. Please check your email for a link to the file. You may need to check your spam folder if you do not see the email in your inbox."
        );
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg(
          "An error occurred. Please contact support for assistance."
        );
        setAlertSeverity("error");
      }
    } catch (error) {
      console.error("Error downloading panel");
    }
  };

  const handleClosePanelModal = () => {
    setOpenPanelModal(false);
    setSelectedMemberId(null);

    fetchPanelAssignmentInfo();
  };

  const createStateSubPanel = async () => {
    setLoadingMessage(true);
    try {
      const res = await updateSubpanels(selectedPanelId);
      if (res?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg(res?.data);
        setAlertSeverity("success");
        setLoadingMessage(false);
      }
    } catch (error) {
      console.error("Error creating state sub panel");
      setLoadingMessage(false);
    }
  };
  return showLoading ? (
    <LoadingText />
  ) : (
    <div style={{ padding: "30px" }}>
      <div style={{ marginBottom: "20px" }}>
        <Typography color="primary">
          <strong> Panel:</strong> {selectedPanel?.name} <br />
          <strong> Panel used in: </strong>
          {surveyNames?.map((name) => getTextFromHTML(name))?.join(", ")}
          <br />
          <strong> Modify Date:</strong>
          {formatDate(selectedPanel?.dateModified)}
        </Typography>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Stack direction="row" spacing={1}>
          {showButtons && (
            <Tooltip title="This feature is coming soon" arrow>
              <Chip label={"Upload Panel"} className="chipStyle" />
            </Tooltip>
          )}
          {!isSuperUser && !isBuilderAdminUser && (
            <Tooltip
              title="Please contact AHA support to add a panel member"
              arrow
            >
              <Chip label={"Add Panel Member"} className="chipStyle" />{" "}
            </Tooltip>
          )}
          {(isSuperUser || isBuilderAdminUser) && (
            <Chip
              onClick={handleOpenPanelModal}
              label={"Add Panel Member"}
              className="chipStyle"
            />
          )}
          <Tooltip arrow>
            <Chip
              label={"Download Panel"}
              onClick={handleDownloadPanel}
              className="chipStyle"
            />
          </Tooltip>

          {showButtons && (
            <>
              <Tooltip title="This feature is coming soon" arrow>
                <Chip label={"Edit Attribute Label"} className="chipStyle" />{" "}
              </Tooltip>

              <Tooltip arrow>
                <Chip
                  label={"Create State Sub-Panels"}
                  onClick={() => createStateSubPanel()}
                  className="chipStyle"
                />
              </Tooltip>
              <Tooltip arrow>
                <Chip
                  label={"Delete all survey helpers"}
                  onClick={openDeleteSurveyHelpersPanel}
                  className="chipStyle"
                />
              </Tooltip>
              {loadingMessage && (
                <span style={{ paddingLeft: "10px" }}>Please wait...</span>
              )}
            </>
          )}
        </Stack>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button startIcon={<TuneOutlinedIcon />} onClick={handleOpenModal}>
          Set Filter (find) Criteria
        </Button>
      </div>
      <div style={{ marginBottom: "20px" }}></div>
      <MaterialReactTable table={table} />
      <Modal open={openModal} onClose={handleCloseModal}>
        <FilterForm handleClose={handleCloseModal} />
      </Modal>
      <Modal open={openPanelModal} onClose={handleClosePanelModal}>
        <div className="panelModal">
          <div className="panelDiv">
            <Typography variant="label">Panel Member</Typography>
          </div>
          <PanelMember handleClosePanel={handleClosePanelModal} />
        </div>
      </Modal>

      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={deletePost}
        pageName="Panel"
      ></ConfirmDialog>

      <ConfirmDialog
        title={confirmHelperTitle}
        open={confirmHelperOpen}
        setOpen={setConfirmHelperOpen}
        onConfirm={deleteAllHelpers}
        pageName="Helper"
      ></ConfirmDialog>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={400000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </div>
  );
};

export default PanelManagement;
