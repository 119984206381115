import React from "react";
import {
  Modal,
  Box,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";

const StateSelectModal = ({
  open,
  handleClose,
  selectedState,
  setSelectedState,
  handleContinue,
  isButtonEnabled,
  setIsButtonEnabled,
}) => {
  const states = useSelector((state) => state?.survey?.states);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#fff",
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Please select a state to see the survey as a user from that state
          would see it.
        </Typography>
        <FormControl fullWidth sx={{ my: 2 }}>
          <Select
            variant="outlined"
            value={selectedState}
            onChange={(e) => {
              setIsButtonEnabled(false);
              setSelectedState(e.target.value);
            }}
            disabled={!isButtonEnabled}
          >
            {states.map((state) => (
              <MenuItem key={state.id} value={state.id}>
                {state.longName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          onClick={handleContinue}
          variant="contained"
          color="primary"
          sx={{ mr: 2 }}
          disabled={!isButtonEnabled}
        >
          Continue
        </Button>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Box>
    </Modal>
  );
};

export default StateSelectModal;
