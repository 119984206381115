export const initialQuesFormData = {
  label: "",
  name: "",
  helpText: "",
};

export const initialAnsList = {
  noAnswer: true,
  "Combobox Field": false,
  "State Field": false,
  "CheckBox Field": false,
  "Numeric Field": false,
  radiogrp: false,
  checkboxgrp: false,
  commongrp: false,
  textAnswer: false,
  singleResponseAnswer: false,
};

export const initialFieldFormData = {
  userLabel: "",
  name: "",
  namePosition: "Above",
  fieldFlow: "NL",
  horizontalAlign: "left",
  historyFlag: true,
  fillHistory: false,
  minNumeric: "",
  maxNumeric: "",
  size: 10,
  maxDigits: 10,
  numPrecision: "",
  isFloat: false,
  autoCommas: true,
  minDate: "",
  maxDate: "",
  maxLength: 1000,
  numLines: 3,
  isMultiselect: false,
  isRequired: false,
};

export const initialresData = [
  {
    id: 1,
    sequence: 0,
    name: "VALUE1",
    titlePosition: "Right",
    allowableValue: "VALUE1",
    exclusiveAnswer: false,
    includeCom: false,
    comFieldHeading: "",
    comFieldPlace: "Left",
    comFieldLabel: "",
  },
  {
    id: 2,
    sequence: 1,
    name: "VALUE2",
    titlePosition: "Right",
    allowableValue: "VALUE2",
    exclusiveAnswer: false,
    includeCom: false,
    comFieldHeading: "",
    comFieldPlace: "Left",
    comFieldLabel: "",
  },
  {
    id: 3,
    sequence: 2,
    name: "VALUE3",
    titlePosition: "Right",
    allowableValue: "VALUE3",
    exclusiveAnswer: false,
    includeCom: false,
    comFieldHeading: "",
    comFieldPlace: "Left",
    comFieldLabel: "",
  },
];

export const commonKeys = [
  "userLabel",
  "name",
  "namePosition",
  "fieldFlow",
  "horizontalAlign",
  "historyFlag",
  "fillHistory",
  "isRequired",
];

export const includedKeys = {
  "Numeric Field": [
    "maxNumeric",
    "minNumeric",
    "size",
    "maxDigits",
    "numPrecision",
    "isFloat",
    "autoCommas",
  ],
  "State Field": ["isMultiselect"],
  "Date Field": ["minDate", "maxDate"],
  "Simpletext Field": ["maxLength", "size"],
  "Textarea Field": ["maxLength", "size", "numLines"],
  "Combobox Field": ["isMultiselect"],
};

export const fieldTypeOptions = [
  { id: 1, name: "CheckBox Field" },
  { id: 2, name: "Combobox Field" },
  { id: 3, name: "Date Field" },
  { id: 4, name: "Numeric Field" },
  { id: 11, name: "Phone Field" },
  { id: 5, name: "Radio Field" },
  { id: 7, name: "Radioyn Field" },
  { id: 8, name: "Simpletext Field" },
  { id: 10, name: "State Field" },
];

export const namePositionOptions = [
  { name: "Do not display", value: "No Title" },
  { name: "Above", value: "Above" },
  { name: "Below", value: "Below" },
  { name: "to the Left of", value: "Left" },
  { name: "to the Right of", value: "Right" },
];

export const initMatrixFormData = {
  id: null,
  dateCreated: null,
  createdBy: null,
  groupLabel: "",
  name: "",
  description: "",
  descriptionPosition: "",
  helpText: "",
  shortName: "",
  tableWidthPct: "",
  hideGroup: false,
  sequence: null,
  headings: [
    {
      id: null,
      dateCreated: null,
      status: {},
      name: "",
      value: "",
      sequence: 0,
      widthPct: 40,
      exclusiveAnswer: false,
    },
    {
      id: null,
      dateCreated: null,
      status: {},
      name: "",
      value: 1,
      sequence: 1,
      widthPct: 15,
      exclusiveAnswer: false,
      field: {
        id: null,
        dateCreated: null,
        dateModified: null,
        createdBy: null,
        modifiedBy: null,
        fieldType: {
          name: "Numeric Field",
        },
        sequence: 1,
        columnNumber: 1,
        minNumeric: null,
        maxNumeric: null,
        maxDigits: null,
        size: null,
        minDate: null,
        maxDate: null,
        userLabel: null,
        name: null,
        namePosition: "",
        horizontalAlign: "",
        fieldFlow: "NL",
        numLines: null,
        numPrecision: null,
        fieldValues: [],
        historyFlag: true,
        disabledField: false,
        readOnlyField: false,
        hideField: false,
        autoCommas: false,
        fillHistory: false,
        isRequired: false,
        maxLength: null,
        isMultiselect: false,
        isFloat: false,
      },
    },
    {
      id: null,
      dateCreated: null,
      status: {},
      name: "",
      value: 2,
      sequence: 2,
      widthPct: 15,
      exclusiveAnswer: false,
      field: {
        id: null,
        dateCreated: null,
        dateModified: null,
        createdBy: null,
        modifiedBy: null,
        fieldType: {
          name: "Numeric Field",
        },
        sequence: 2,
        columnNumber: 2,
        minNumeric: null,
        maxNumeric: null,
        maxDigits: null,
        size: null,
        minDate: null,
        maxDate: null,
        userLabel: null,
        name: null,
        namePosition: "",
        horizontalAlign: "",
        fieldFlow: "NL",
        numLines: null,
        numPrecision: null,
        fieldValues: [],
        historyFlag: true,
        disabledField: false,
        readOnlyField: false,
        hideField: false,
        autoCommas: false,
        fillHistory: false,
        isRequired: false,
        maxLength: null,
        isMultiselect: false,
        isFloat: false,
      },
    },
    {
      id: null,
      dateCreated: null,
      status: {},
      name: "",
      value: 3,
      sequence: 3,
      widthPct: 15,
      exclusiveAnswer: false,
      field: {
        id: null,
        dateCreated: null,
        dateModified: null,
        createdBy: null,
        modifiedBy: null,
        fieldType: {
          name: "Numeric Field",
        },
        sequence: 3,
        columnNumber: 3,
        minNumeric: null,
        maxNumeric: null,
        maxDigits: null,
        size: null,
        minDate: null,
        maxDate: null,
        userLabel: null,
        name: null,
        namePosition: "",
        horizontalAlign: "",
        fieldFlow: "NL",
        numLines: null,
        numPrecision: null,
        fieldValues: [],
        historyFlag: true,
        disabledField: false,
        readOnlyField: false,
        hideField: false,
        autoCommas: false,
        fillHistory: false,
        isRequired: false,
        maxLength: null,
        isMultiselect: false,
        isFloat: false,
      },
    },
    {
      id: null,
      dateCreated: null,
      status: {},
      name: "",
      value: 4,
      sequence: 4,
      widthPct: 15,
      exclusiveAnswer: false,
      field: {
        id: null,
        dateCreated: null,
        dateModified: null,
        createdBy: null,
        modifiedBy: null,
        fieldType: {
          name: "Numeric Field",
        },
        sequence: 4,
        columnNumber: 4,
        minNumeric: null,
        maxNumeric: null,
        maxDigits: null,
        size: null,
        minDate: null,
        maxDate: null,
        userLabel: null,
        name: null,
        namePosition: "",
        horizontalAlign: "",
        fieldFlow: "NL",
        numLines: null,
        numPrecision: null,
        fieldValues: [],
        historyFlag: true,
        disabledField: false,
        readOnlyField: false,
        hideField: false,
        autoCommas: false,
        fillHistory: false,
        isRequired: false,
        maxLength: null,
        isMultiselect: false,
        isFloat: false,
      },
    },
  ],
  questions: [],
};

export const mapResponseData = (data) => {
  const resFields = data.map((item, index) => ({
    name: item.name,
    allowableValue: item.allowableValue,
    titlePosition: item.titlePosition,
    exclusiveAnswer: item.exclusiveAnswer,
    sequence: item.sequence,
  }));

  return { fieldValues: resFields };
};

export const emptyFieldRow = () => {
  return {
    id: null,
    dateCreated: null,
    dateModified: null,
    createdBy: null,
    modifiedBy: null,
    fieldType: {
      name: "",
    },
    sequence: 0,
    columnNumber: 0,
    minNumeric: null,
    maxNumeric: null,
    maxDigits: null,
    size: null,
    minDate: null,
    maxDate: null,
    userLabel: null,
    name: null,
    namePosition: "",
    horizontalAlign: "",
    fieldFlow: "NL",
    numLines: null,
    numPrecision: null,
    status: {},
    fieldValues: [],
    historyFlag: true,
    disabledField: false,
    readOnlyField: false,
    hideField: false,
    autoCommas: false,
    fillHistory: false,
    isRequired: false,
    maxLength: null,
    isMultiselect: false,
    isFloat: false,
  };
};
