import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EditSurvey from "./EditSurvey";
import EditSection from "./EditSection";
import EditQues from "./EditQues";
import EditPage from "./EditPage";
import SectionForm from "./SectionForm";
import PageForm from "./PageForm";
import NewSurvey from "./NewSurvey";
import SurveyVrs from "../ValidationRules/SurveyVrs";
import SectionVrs from "../ValidationRules/SectionVrs";
import PageVrs from "../ValidationRules/PageVrs";
import ManageRules from "../ValidationRules/ManageRules";

import {
  setSurveyBuilderCurrentView,
  setSelectedQues,
} from "../../Survey/Survey.slice";

const renderView = (currentView) => {
  switch (currentView) {
    case 1:
      return <EditSurvey />;
    case 2:
      return <EditSection />;
    case 3:
      return <EditPage />;
    case 4:
      return <EditQues />;
    case 5:
      return <SectionForm />;
    case 6:
      return <PageForm />;
    case 7:
      return <NewSurvey />;
    case 8:
      return <SurveyVrs />;
    case 9:
      return <SectionVrs />;
    case 10:
      return <PageVrs />;
    case 11:
      return <ManageRules />;
    default:
      return null;
  }
};
const SurveyBuilder = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const showDataFilterRule = location?.state?.showDataFilterRule;
  const datafilterRecord = location?.state?.datafilterRecord;

  const currentView = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData?.surveyBuilderCurrentView?.viewIndex
  );
  useEffect(() => {
    const newSurveyVR = (level) => {
      dispatch(setSelectedQues({}));

      dispatch(
        setSurveyBuilderCurrentView({
          viewIndex: 11,
          source: level,
          showDataFilterRule: true,
          datafilterRecord: datafilterRecord,
        })
      );
    };

    if (showDataFilterRule) {
      newSurveyVR("SurveyLevel");
    }
  }, [showDataFilterRule]);

  return <>{renderView(currentView)}</>;
};

export default SurveyBuilder;
