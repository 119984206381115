import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import DatePicker from "../../../components/Common/DatePicker";
import { activateSurvey } from "../../Survey/Survey.service";
import { formatShortDateDash } from "../../../Utility/constants";
import { triggerSidebarRefresh } from "../../Survey/Survey.slice";
const ActivateSurvey = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const surveyDto = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey
  );
  const [activeDate, setActiveDate] = useState(null);
  const [deactiveDate, setDeactiveDate] = useState(null);
  const [checked, setChecked] = useState(false);
  const handleActionActive = async (e) => {
    try {
      const payload = {
        survey: surveyDto,
        activeDate: activeDate ? formatShortDateDash(activeDate) : null,
        deactiveDate: deactiveDate ? formatShortDateDash(deactiveDate) : null,
        eraseAllFilings: checked,
      };
      const response = await activateSurvey(payload);

      if (response?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey activated successfully");
        setAlertSeverity("success");
        setTimeout(() => {
          handleClose();
          dispatch(triggerSidebarRefresh());
        }, 2000);
      }
    } catch (error) {
      console.log("Error captured for deactivating survey", error);
      return null;
    }
  };

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleBeginingDate = (date) => {
    setActiveDate(date);
  };
  const handleEndingDate = (date) => {
    setDeactiveDate(date);
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">
            Activate {surveyDto.name} Survey
          </Typography>
        </div>
        <div className="panelForm">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <Typography variant="paragraph" color="primary">
              Please enter an activate date (default is now) and deactivate date
              (optional) to activate (open) this survey.
            </Typography>

            <Grid item xs={10} container>
              <Grid item xs={2}>
                <Typography variant="body1">Activate Date</Typography>
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  value={activeDate}
                  onChange={handleBeginingDate}
                  height="small"
                  sx={{ width: "200px" }}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Deactivate Date</Typography>
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  value={deactiveDate}
                  onChange={handleEndingDate}
                  height="small"
                  sx={{ width: "200px" }}
                />
              </Grid>
            </Grid>
            <Grid item xs={10} container>
              <Grid item xs={8}>
                {" "}
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      checked={checked}
                      name="deletedata"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Important: Click the checkbox to delete ALL data for this survey"
                />
              </Grid>
            </Grid>
            <Grid item xs={10} container>
              <Grid item xs={5}></Grid>
              <Grid item xs={5}>
                <Button variant="outlined" onClick={handleClose}>
                  Cancel
                </Button>{" "}
                <Button variant="contained" onClick={handleActionActive}>
                  Activate
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default ActivateSurvey;
