import React, { useEffect, useRef, useState } from "react";
import { Grid, Typography, Button, Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createField,
  createStandardQuestionGroup,
  deleteField,
  deleteFieldValue,
  fullQuestionGroupById,
  importFieldValues,
  updateField,
  updateQuestion,
  createQuestion,
} from "../../../Survey/Survey.service";
import {
  setEditFieldId,
  setEditQuesGrpId,
  setEditQuesId,
  setSavedComField,
  setSavedField,
  setSavedQues,
  setSavedQuesGrp,
  setSelectedField,
  setAddQuesToGrp,
  setTriggerMatrixGrp,
} from "../../../Survey/Survey.slice";
import { formatShortDateDash, htmlParser } from "../../../../Utility/constants";
import InputValidation from "./InputValidation";
import AnswerDisplayFormat from "./AnswerDisplayFormat";
import AdditionalAnswerOptions from "./AdditionalAnswerOptions";
import ResponseOptions from "./ResponseOptions";
import AnswerLabelHeading from "./AnswerLabelHeading";
import SelectAnswerField from "./SelectAnswerField";
import QuestionCard from "./QuestionCard";
import QuesPreview from "./QuesPreview";
import {
  initialQuesFormData,
  initialFieldFormData,
  initialresData,
  commonKeys,
  includedKeys,
  initialAnsList,
} from "./AddQuesConstants";
import useCustomTable from "./useCustomTable";
import MultipleResponseGroupInfo from "./MultipleResponseGroupInfo";
import CommonResponseGroupInfo from "./CommonResponseGroupInfo";
import FieldsTable from "./FieldsTable";
const AddQues = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );
  const selectedPageId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.id
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const savedQuesGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQuesGrp
  );

  const savedField = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedField
  );

  const selectedField = useSelector(
    (state) => state?.survey?.surveyBuilderData.selectedField
  );

  const addQuesToGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.addQuesToGrp
  );

  const selectedSecName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec?.section?.name
  );

  const selectedPageName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.name
  );

  const savedQues = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQues
  );

  const savedComField = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedComField
  );

  const EditFieldId = useSelector(
    (state) => state?.survey?.surveyBuilderData.EditFieldId
  );

  const EditQuesGrpId = useSelector(
    (state) => state?.survey?.surveyBuilderData.EditQuesGrpId
  );

  const EditQuesId = useSelector(
    (state) => state?.survey?.surveyBuilderData.EditQuesId
  );

  const quesEditSource = useSelector(
    (state) => state?.survey?.surveyBuilderData.quesEditSource
  );

  const [ansList, setAnsList] = useState(initialAnsList);
  const [textAns, setTextAns] = useState({
    "Simpletext Field": false,
    "Textarea Field": false,
    "Phone Field": false,
    "Date Field": false,
  });
  const [singleResAns, setSingleResAns] = useState({
    "Radio Field": false,
    "Radioyn Field": false,
  });
  const [currentQues, setCurrentQues] = useState({});
  const [quesFormData, setQuesFormData] = useState({});
  const [fieldFormData, setFieldFormData] = useState(initialFieldFormData);
  const [showFieldOptions, setShowFieldOptions] = useState(true);
  const [textAnsExpanded, setTextAnsExpanded] = useState(false);
  const [singleResAnsExpanded, setSingleResAnsExpanded] = useState(false);
  const [importClicked, setImportClicked] = useState(false);
  const sortedData = initialresData.sort((a, b) => a.sequence - b.sequence);
  const [data, setData] = useState(sortedData);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [refreshFieldVals, setRefreshFieldVals] = useState(false);
  const [file, setFile] = useState(null);
  const isFieldCreating = useRef(false);
  const isFieldUpdating = useRef(false);
  const isComFieldCreating = useRef(false);
  const isComFieldUpdating = useRef(false);
  const isComFieldDeleting = useRef(false);
  const [triggerQuesGrpRefresh, setTriggerQuesGrpRefresh] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const handleFieldSelect = (event) => {
    const { name, checked } = event.target;

    if (name === "textAnswer") {
      setTextAnsExpanded(checked);
      if (checked) {
        setTextAns({
          "Simpletext Field": true,
          "Textarea Field": false,
          "Phone Field": false,
          "Date Field": false,
        });
      }
    }
    if (name === "singleResponseAnswer") {
      setSingleResAnsExpanded(checked);
      if (checked) {
        setSingleResAns({
          "Radio Field": true,
          "Radioyn Field": false,
        });
      }
    }

    if (["Radio Field", "Radioyn Field"].includes(name)) {
      if (checked) {
        setSingleResAns({
          "Radio Field": name === "Radio Field",
          "Radioyn Field": name === "Radioyn Field",
        });
      } else {
        setSingleResAns((prevTextAns) => ({ ...prevTextAns, [name]: false }));
      }
    }
    if (
      [
        "Simpletext Field",
        "Textarea Field",
        "Phone Field",
        "Date Field",
      ].includes(name)
    ) {
      if (checked) {
        setTextAns({
          "Simpletext Field": name === "Simpletext Field",
          "Textarea Field": name === "Textarea Field",
          "Phone Field": name === "Phone Field",
          "Date Field": name === "Date Field",
        });
      } else {
        setTextAns((prevTextAns) => ({ ...prevTextAns, [name]: false }));
      }
    }

    const newState = {
      noAnswer: false,
      "Combobox Field": false,
      "State Field": false,
      "CheckBox Field": false,
      "Numeric Field": false,
      textAnswer: [
        "Simpletext Field",
        "Textarea Field",
        "Phone Field",
        "Date Field",
      ].includes(name)
        ? ansList.textAnswer
        : false,
      radiogrp: false,
      checkboxgrp: false,
      commongrp: false,
      singleResponseAnswer: ["Radio Field", "Radioyn Field"].includes(name)
        ? ansList.singleResponseAnswer
        : false,
    };

    newState[name] = checked;

    setAnsList(newState);
    if (!["Radio Field", "Radioyn Field"].includes(name)) {
      setSingleResAnsExpanded(
        name === "singleResponseAnswer" ? checked : false
      );
    }
    if (
      ![
        "Simpletext Field",
        "Textarea Field",
        "Phone Field",
        "Date Field",
      ].includes(name)
    ) {
      setTextAnsExpanded(name === "textAnswer" ? checked : false);
    }
  };

  const isMatrixField = () => {
    return (
      selectedField === "commongrp" ||
      selectedField === "checkboxgrp" ||
      selectedField === "radiogrp"
    );
  };

  useEffect(() => {
    const findFirstTrueVal = () => {
      if (ansList.singleResponseAnswer) {
        return Object.entries(singleResAns).find(
          ([key, value]) => value === true
        )?.[0];
      } else if (ansList.textAnswer) {
        return Object.entries(textAns).find(
          ([key, value]) => value === true
        )?.[0];
      } else {
        return Object.entries(ansList).find(
          ([key, value]) => value === true
        )?.[0];
      }
    };
    if (!isMatrixField()) {
      dispatch(setSelectedField(findFirstTrueVal()));
    }
  }, [ansList, textAns, singleResAns]);

  const handleQuesChange = (e) => {
    const { name, value } = e.target;
    setQuesFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFieldChangeWrapper = (name) => (val) => {
    handleFieldChange(name, val);
  };

  const handleFieldChange = (name, value) => {
    if (name === "autoCommas" || name === "isFloat") {
      value = value === "true";
    }
    if (name === "minDate" || name === "maxDate") {
      value = value ? formatShortDateDash(value) : "";
    }

    setFieldFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const lastQues =
      savedQuesGrp?.questions?.[savedQuesGrp?.questions?.length - 1];
    setCurrentQues(savedQues ? savedQues : lastQues);
  }, [savedQuesGrp, savedQues]);

  useEffect(() => {
    const setQues = async () => {
      try {
        let response;
        if (EditQuesGrpId || savedQuesGrp?.id) {
          response = await fullQuestionGroupById(
            EditQuesGrpId || savedQuesGrp?.id
          );
        }
        const resData = response?.data;
        if (resData && Object.keys(resData).length > 0 && resData.id) {
          dispatch(setSavedQuesGrp(resData));
          const quesToEdit = EditQuesId
            ? resData.questions.find((question) => question.id === EditQuesId)
            : resData.questions?.[resData?.questions?.length - 1];
          if (quesToEdit && !addQuesToGrp) {
            dispatch(setSavedQues(quesToEdit));
          }
        }
      } catch (error) {
        console.error("Error setting quesGrp data:", error);
      }
    };
    setQues();
  }, [EditQuesGrpId, EditQuesId, triggerQuesGrpRefresh, showFieldOptions]);

  useEffect(() => {
    if (savedQues) {
      const savedFormData = {
        label: savedQues?.label || "",
        name: savedQues?.name || "",
        helpText: savedQues?.helpText || "",
      };
      if (
        savedFormData.label !== quesFormData.label ||
        savedFormData.name !== quesFormData.name ||
        savedFormData.helpText !== quesFormData.helpText
      ) {
        setQuesFormData(savedFormData);
      }
    } else {
      setQuesFormData(initialQuesFormData);
    }
  }, [savedQues]);

  useEffect(() => {
    if (showFieldOptions === false) {
      if (savedQues?.fields?.length > 0) {
        const selectedField = savedQues.fields.find(
          (obj) => obj.id === EditFieldId
        );
        if (selectedField) {
          dispatch(setSavedField(selectedField));
        }
      }
    } else {
      setIsEditing(false);
      dispatch(setSavedField(null));
      dispatch(setEditFieldId(null));
      if (!isMatrixField()) {
        dispatch(setSelectedField("noAnswer"));
        setAnsList(initialAnsList);
        setTextAnsExpanded(false);
        setSingleResAnsExpanded(false);
      }
    }
  }, [showFieldOptions]);

  useEffect(() => {
    const createOrUpdateQuesGroup = async () => {
      quesFormData.createdBy = userName;

      let payload = {
        page: selectedPageId,
        createdBy: userName,
        questions: [quesFormData],
      };
      try {
        if (!savedQuesGrp) {
          if (quesFormData.name) {
            const response = await createStandardQuestionGroup(payload);
            const quesGrp = response?.data;
            if (quesGrp && Object.keys(quesGrp).length > 0 && quesGrp.id) {
              dispatch(setSavedQuesGrp(quesGrp));
            }
          }
        } else {
          if (addQuesToGrp) {
            let payload = {
              ...quesFormData,
              questionGroup: {
                ...savedQuesGrp,
              },
            };
            if (payload.name) {
              const response = await createQuestion(payload);
              dispatch(setSavedQues(response?.data));
              dispatch(setAddQuesToGrp(false));
              setTriggerQuesGrpRefresh((prev) => !prev);
            }
          } else {
            let payload = { ...currentQues, ...quesFormData };
            if (payload?.name) {
              const response = await updateQuestion(payload);

              const updatedQues = response?.data;
              if (
                updatedQues &&
                Object.keys(updatedQues).length > 0 &&
                updatedQues.id
              ) {
                dispatch(setSavedQues(updatedQues));
              }
            }
          }
        }
      } catch (error) {
        console.log("Error creating/updating StandardQuestionGroup", error);
        return null;
      }
    };

    createOrUpdateQuesGroup();
  }, [quesFormData]);

  function compareData(data1, data2) {
    const data1Dict = {};
    data1?.forEach((item) => (data1Dict[item.sequence] = item));

    const data2Dict = {};
    data2?.forEach((item) => (data2Dict[item.sequence] = item));

    const sequences1 = Object.keys(data1Dict).map(Number);
    const sequences2 = Object.keys(data2Dict).map(Number);

    if (
      sequences1.length !== sequences2.length ||
      !sequences1.every((seq) => sequences2.includes(seq))
    ) {
      return true;
    }

    for (let seq of sequences1) {
      const item1 = data1Dict[seq];
      const item2 = data2Dict[seq];

      if (
        item1.name !== item2.name ||
        item1.id !== item2.id ||
        item1.titlePosition !== item2.titlePosition ||
        item1.exclusiveAnswer !== item2.exclusiveAnswer ||
        item1.allowableValue !== item2.allowableValue
      ) {
        return true;
      }
    }

    return false;
  }

  function isFieldFormUpdated(obj1, obj2) {
    if (!obj1 || !obj2) {
      return true;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    const commonKeys = keys1.filter((key) => keys2.includes(key));

    for (const key of commonKeys) {
      if (obj1[key] != obj2[key]) {
        return true;
      }
    }
    return false;
  }

  useEffect(() => {
    const filterFieldFormData = () => {
      const keysToInclude = includedKeys[selectedField] || [];
      const allKeys = [...commonKeys, ...keysToInclude];
      return Object.keys(fieldFormData)
        .filter((key) => allKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = fieldFormData[key];
          return obj;
        }, {});
    };

    const mapResData = (data) => {
      const resFields = data.map((item, index) => ({
        name: item.name,
        allowableValue: item.allowableValue,
        titlePosition: item.titlePosition,
        exclusiveAnswer: item.exclusiveAnswer,
        sequence: item.sequence,
      }));

      return { fieldValues: resFields };
    };

    const createOrUpdateField = async () => {
      let filteredFieldFormData = filterFieldFormData();

      let payload = {
        question: { id: currentQues?.id },
        fieldType: {
          name: selectedField,
        },
        columnNumber: 0,
        createdBy: userName,
        ...filteredFieldFormData,
      };

      let mappedResData = mapResData(data);
      if (
        selectedField === "Radio Field" ||
        selectedField === "Combobox Field" ||
        selectedField === "CheckBox Field"
      ) {
        payload = {
          ...payload,
          ...mappedResData,
        };
      }

      try {
        if (!savedField) {
          if (currentQues?.id && !isEditing) {
            if (isFieldCreating.current) {
              return;
            }
            isFieldCreating.current = true;
            try {
              const response = await createField(payload);
              const newQues = response?.data;
              if (newQues && Object.keys(newQues).length > 0 && newQues.id) {
                dispatch(setSavedField(newQues));
              }
            } catch (error) {
              console.log("Error creating field", error);
            } finally {
              isFieldCreating.current = false;
            }
          }
        } else {
          let payload = {
            ...savedField,
            ...filteredFieldFormData,
            ...(selectedField === "Radio Field" ||
            selectedField === "Combobox Field" ||
            selectedField === "CheckBox Field"
              ? {
                  fieldValues: data,
                }
              : {}),
          };
          if (currentQues?.id) {
            if (isFieldCreating.current || isFieldUpdating.current) {
              return;
            }
            isFieldUpdating.current = true;
            try {
              const response = await updateField(payload);
              const updatedQues = response?.data;
              if (
                updatedQues &&
                Object.keys(updatedQues).length > 0 &&
                updatedQues.id
              ) {
                dispatch(setSavedField(updatedQues));
              }
            } catch (error) {
              console.log("Error updating field", error);
            } finally {
              isFieldUpdating.current = false;
            }
          }
        }
      } catch (error) {
        console.log("Error creating/updating Field", error);
        return null;
      }
    };
    if (showFieldOptions === false) {
      if (savedField === null) {
        createOrUpdateField();
      } else {
        let shouldUpdate = false;
        if (isFieldFormUpdated(fieldFormData, savedField)) {
          shouldUpdate = true;
        }
        if (
          selectedField === "Radio Field" ||
          selectedField === "CheckBox Field" ||
          selectedField === "Combobox Field"
        ) {
          if (compareData(data, savedField?.["fieldValues"])) {
            shouldUpdate = true;
          }
        }
        if (shouldUpdate) {
          createOrUpdateField();
        }
      }
    }
  }, [fieldFormData, data, showFieldOptions]);

  useEffect(() => {
    if (savedField) {
      const updatedFieldFormData = () => {
        const updatedData = { ...fieldFormData };
        for (const key in fieldFormData) {
          if (key in savedField) {
            updatedData[key] = savedField[key];
          }
        }
        return updatedData;
      };

      const updatedFormData = updatedFieldFormData();

      const isUpdated =
        JSON.stringify(fieldFormData) !== JSON.stringify(updatedFormData);

      if (isUpdated) {
        setFieldFormData(updatedFormData);
      }

      //update res data with savedField for matching keys using sequence

      let updatedResData = data
        .map((item1) => {
          let item2 = savedField?.["fieldValues"]?.find(
            (item2) => item2.sequence === item1.sequence
          );
          return item2 ? { ...item1, ...item2 } : null;
        })
        .filter((item) => item !== null);

      savedField?.["fieldValues"]?.forEach((item2) => {
        if (
          !updatedResData.some((item1) => item1.sequence === item2.sequence)
        ) {
          updatedResData.push(item2);
        }
      });

      let uniqueData = [];
      let dupIds = new Set();

      updatedResData.forEach((item) => {
        if (!dupIds.has(item.id)) {
          uniqueData.push({ ...item });
          dupIds.add(item.id);
        }
      });

      uniqueData.forEach((item, index) => {
        item.sequence = index;
        // item.comFieldHeading = "";
        // item.comFieldLabel = "";
        // item.comFieldPlace = "Left";
        // item.includeCom = false;
      });

      updatedResData = uniqueData;

      //if original data and updated data is different, update data with fieldvalues of savedfield

      const isResDataDiff = compareData(data, updatedResData);
      if (isResDataDiff) {
        setData(updatedResData);
      }
    }
  }, [savedField]);

  useEffect(() => {
    const createOrUpdateComField = async (item) => {
      const payload = {
        question: { id: currentQues?.id },
        createdBy: userName,
        fieldType: {
          name: "Simpletext Field",
        },
        columnNumber: item.sequence,
        name: item.comFieldHeading,
        namePosition: item.comFieldPlace,
        userLabel: item.comFieldLabel,
        historyFlag: fieldFormData?.historyFlag,
        fillHistory: fieldFormData?.fillHistory,
      };

      try {
        const existingCom = savedComField[item.id];

        if (!existingCom) {
          if (currentQues?.id) {
            if (savedField?.id) {
              if (isComFieldCreating.current) {
                return;
              }
              isComFieldCreating.current = true;
              try {
                const response = await createField(payload);
                const updatedComRes = response?.data;
                if (
                  updatedComRes &&
                  Object.keys(updatedComRes).length > 0 &&
                  updatedComRes.id
                ) {
                  const updatedSavedComField = {
                    ...savedComField,
                    [item.id]: updatedComRes,
                  };
                  dispatch(setSavedComField(updatedSavedComField));
                }
              } catch (error) {
                console.log("Error creating comment field", error);
              } finally {
                isComFieldCreating.current = false;
              }
            }
          }
        } else {
          console.log("update comment field", item.id, existingCom);

          const relatedData = data.find((d) => d.id === item.id);

          if (relatedData) {
            const isComUpdated =
              relatedData.comFieldHeading !== existingCom.name ||
              relatedData.comFieldLabel !== existingCom.userLabel ||
              relatedData.comFieldPlace !== existingCom.namePosition;

            if (isComUpdated) {
              if (isComFieldCreating.current || isComFieldUpdating.current) {
                return;
              }
              isComFieldUpdating.current = true;
              try {
                const updatedComField = {
                  ...existingCom,
                  name: relatedData.comFieldHeading,
                  userLabel: relatedData.comFieldLabel,
                  namePosition: relatedData.comFieldPlace,
                };
                const response = await updateField(updatedComField);
                const updatedComRes = response?.data;

                if (
                  updatedComRes &&
                  Object.keys(updatedComRes).length > 0 &&
                  updatedComRes.id
                ) {
                  const updatedSavedComField = {
                    ...savedComField,
                    [item.id]: updatedComRes,
                  };
                  dispatch(setSavedComField(updatedSavedComField));
                }
              } catch (error) {
                console.log("Error updating comment field", error);
              } finally {
                isComFieldUpdating.current = false;
              }
            }
          }
        }
      } catch (error) {
        console.log("Error creating/updating comment Field", error);
        return null;
      }
    };

    const deleteComField = async (item) => {
      try {
        const existingCom = savedComField[item.id];
        if (existingCom) {
          if (isComFieldDeleting.current) {
            return;
          }
          isComFieldDeleting.current = true;
          const response = await deleteField(existingCom?.id);
          const resData = response?.data;
          if (resData) {
            const { [item.id]: _, ...updatedComs } = savedComField;

            dispatch(setSavedComField(updatedComs));
            isComFieldDeleting.current = false;
          }
        }
      } catch (error) {
        console.log("Error deleting comment Field", error);
        return null;
      }
    };

    data.forEach((item) => {
      if (Object.keys(item).length > 0 && item.hasOwnProperty("dateCreated")) {
        if (item.includeCom) {
          createOrUpdateComField(item);
        } else {
          deleteComField(item);
        }
      }
    });
  }, [data]);

  useEffect(() => {
    // if (Object.keys(savedComField).length > 0) {
    setData((prevData) => {
      const updatedData = prevData.map((data) => {
        const key = data.id.toString();
        if (savedComField[key]) {
          data.comFieldHeading = savedComField[key].name;
          data.comFieldPlace = savedComField[key].namePosition;
          data.comFieldLabel = savedComField[key].userLabel;
        } else {
          data.comFieldHeading = "";
          data.comFieldLabel = "";
          data.comFieldPlace = "Left";
        }
        return data;
      });
      return updatedData;
    });
    // }
  }, [savedComField]);

  useEffect(() => {
    setData(initialresData);
    if (isMatrixField()) {
      setShowFieldOptions(false);
    }
  }, [selectedField]);

  useEffect(() => {
    if (selectedField === "noAnswer" || !quesFormData.name) {
      setNextDisabled(true);
    } else if (selectedField !== "noAnswer" && quesFormData.name) {
      setNextDisabled(false);
    }
  }, [selectedField, savedQuesGrp]);

  const handleImportClick = () => {
    setImportClicked(!importClicked);
  };

  const handleNext = () => {
    setFieldFormData(initialFieldFormData);
    setData(initialresData);
    setShowFieldOptions(false);
    dispatch(setTriggerMatrixGrp(false));
  };

  const handleAddQues = () => {
    dispatch(setAddQuesToGrp(true));
    dispatch(setSavedQues(null));
    dispatch(setSavedField(null));
    dispatch(setSavedComField({}));
    dispatch(setEditQuesGrpId(null));
    dispatch(setEditQuesId(null));
    dispatch(setEditFieldId(null));
    setQuesFormData(initialQuesFormData);
    setFieldFormData(initialFieldFormData);
    setData(initialresData);
    setShowFieldOptions(true);
  };

  const handleAddField = () => {
    dispatch(setSavedField(null));
    dispatch(setSavedComField({}));
    setFieldFormData(initialFieldFormData);
    setData(initialresData);
    setShowFieldOptions(true);
  };

  const handleDone = () => {
    dispatch(setSelectedField(null));
    dispatch(setSavedQuesGrp(null));
    dispatch(setSavedQues(null));
    dispatch(setSavedField(null));
    dispatch(setSavedComField({}));
    dispatch(setEditQuesGrpId(null));
    dispatch(setEditQuesId(null));
    dispatch(setEditFieldId(null));
    dispatch(setTriggerMatrixGrp(false));
    navigate("/survey-builder");
  };

  const secondViewButtons = (
    <>
      {!isMatrixField() && (
        <Button variant="outlined" sx={{ mr: 1 }} onClick={handleAddField}>
          Back
        </Button>
      )}
      {!isMatrixField() && quesEditSource !== "editPage" && (
        <Button variant="contained" sx={{ mr: 1 }} onClick={handleAddQues}>
          Add Question
        </Button>
      )}
      {!isMatrixField() && (
        <Button variant="contained" sx={{ mr: 1 }} onClick={handleAddField}>
          Add Field
        </Button>
      )}

      <Button variant="contained" onClick={handleDone}>
        Done
      </Button>
    </>
  );

  const renderTopButtons = () => {
    if (showFieldOptions) {
      return (
        <>
          <Button variant="outlined" sx={{ mr: 1 }} onClick={handleDone}>
            Cancel
          </Button>
          {quesEditSource !== "editPage" && (
            <Button sx={{ mr: 1 }} variant="contained" onClick={handleAddQues}>
              Add Question
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={nextDisabled}
            sx={{ mr: 1 }}
          >
            Next
          </Button>
          <Button variant="contained" sx={{ mr: 1 }} onClick={handleDone}>
            Done
          </Button>
        </>
      );
    }
    return secondViewButtons;
  };

  const handleResChange = (id, field, value) => {
    setData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleResDelete = async (id) => {
    if (savedComField?.[id]) {
      try {
        const response = await deleteField(savedComField[id]?.id);
        const resData = response?.data;
        if (resData) {
          const { [id]: _, ...updatedComs } = savedComField;
          dispatch(setSavedComField(updatedComs));
        }
      } catch (error) {
        console.error("Error deleting field:", error);
      }
    }
    setData((prevData) => {
      const newData = prevData.filter((row) => row.id !== id);
      const updatedData = newData.map((item, index) => ({
        ...item,
        sequence: index,
      }));

      return updatedData;
    });

    try {
      const response = await deleteFieldValue(id);
      const updatedQues = response?.data;
      if (
        updatedQues &&
        Object.keys(updatedQues).length > 0 &&
        updatedQues.id
      ) {
        dispatch(setSavedField(updatedQues));
      }
    } catch (error) {
      console.error("Error deleting field value:", error);
    }
  };

  const handleAddRes = () => {
    const newSeq =
      data?.length > 0 ? Math.max(...data.map((row) => row.sequence)) + 1 : 0;
    const newRow = {
      // id: newSeq + 1,
      sequence: newSeq,
      name: `VALUE${newSeq + 1}`,
      titlePosition: "Right",
      allowableValue: `VALUE${newSeq + 1}`,
      exclusiveAnswer: false,
      includeCom: false,
      comFieldHeading: "",
      comFieldPlace: "Left",
      comFieldLabel: "",
    };

    setData((prevData) => [...prevData, newRow]);
  };

  const table = useCustomTable(
    data,
    setData,
    handleResChange,
    handleResDelete,
    selectedField
  );

  const handleCheckboxChange = (event) => {
    setRefreshFieldVals(event.target.checked);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleFileUpload = async () => {
    if (file.type === "text/plain" && savedField?.id) {
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await importFieldValues(
          formData,
          savedField?.id,
          refreshFieldVals
        );
        if (response?.status === 200) {
          const responseData = response?.data;

          const deleteAllComs = async () => {
            const ids = Object.values(savedComField).map((field) => field.id);
            if (ids.length > 0) {
              for (const id of ids) {
                await deleteField(id);
              }
            }
          };

          await deleteAllComs();
          dispatch(setSavedComField({}));
          if (
            responseData &&
            Object.keys(responseData).length > 0 &&
            responseData.id
          ) {
            dispatch(setSavedField(responseData));
            handleImportClick();
          }
        }
      } catch (error) {
        console.error("Error importing FieldValues:", error);
      }
    }
  };
  return (
    <>
      <Grid item xs={12} container alignItems="flex-start">
        <Grid item xs={6}>
          <Typography color="primary" variant="label">
            Please enter the question text and select an answer type to continue
          </Typography>
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end">
          {renderTopButtons()}
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={1} alignItems="flex-start">
        <Grid item xs={12}>
          <Typography variant="body1" paragraph>
            <b>Survey-</b> {htmlParser(surveyName)}{" "}
            <b style={{ paddingLeft: "2%" }}>Section-</b>{" "}
            {htmlParser(selectedSecName)}{" "}
            <b style={{ paddingLeft: "2%" }}>Page-</b>{" "}
            {htmlParser(selectedPageName)}
          </Typography>
        </Grid>
      </Grid>
      <Box justifyContent="space-between" display="flex" sx={{ mt: 1 }}>
        {showFieldOptions && (
          <Box style={{ width: !showFieldOptions ? "100%" : "49.5%" }}>
            <QuestionCard
              quesFormData={quesFormData}
              handleQuesChange={handleQuesChange}
              showFieldOptions={showFieldOptions}
            />
            <FieldsTable
              setFieldFormData={setFieldFormData}
              setShowFieldOptions={setShowFieldOptions}
              selectedField={selectedField}
              setTriggerQuesGrpRefresh={setTriggerQuesGrpRefresh}
              setIsEditing={setIsEditing}
            />
          </Box>
        )}
        <Box style={{ width: !showFieldOptions ? "100%" : "49.5%" }}>
          {showFieldOptions ? (
            <SelectAnswerField
              ansList={ansList}
              handleFieldSelect={handleFieldSelect}
              singleResAnsExpanded={singleResAnsExpanded}
              singleResAns={singleResAns}
              textAnsExpanded={textAnsExpanded}
              textAns={textAns}
            />
          ) : (
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              {selectedField === "checkboxgrp" ||
              selectedField === "radiogrp" ? (
                <Grid item xs={12}>
                  <MultipleResponseGroupInfo />
                </Grid>
              ) : selectedField === "commongrp" ? (
                <Grid item xs={12}>
                  <CommonResponseGroupInfo />
                </Grid>
              ) : (
                <>
                  <ResponseOptions
                    table={table}
                    selectedField={selectedField}
                    importClicked={importClicked}
                    refreshFieldVals={refreshFieldVals}
                    handleCheckboxChange={handleCheckboxChange}
                    handleFileChange={handleFileChange}
                    handleFileUpload={handleFileUpload}
                    handleAddRes={handleAddRes}
                    handleImportClick={handleImportClick}
                  />

                  <AnswerLabelHeading
                    fieldFormData={fieldFormData}
                    handleFieldChange={handleFieldChange}
                    handleFieldChangeWrapper={handleFieldChangeWrapper}
                  />
                  <InputValidation
                    selectedField={selectedField}
                    fieldFormData={fieldFormData}
                    handleFieldChangeWrapper={handleFieldChangeWrapper}
                    handleFieldChange={handleFieldChange}
                  />
                  <AnswerDisplayFormat
                    selectedField={selectedField}
                    fieldFormData={fieldFormData}
                    handleFieldChangeWrapper={handleFieldChangeWrapper}
                    handleFieldChange={handleFieldChange}
                  />
                  <AdditionalAnswerOptions
                    fieldFormData={fieldFormData}
                    handleFieldChange={handleFieldChange}
                  />
                </>
              )}
            </Grid>
          )}
        </Box>
      </Box>
      {!showFieldOptions && (
        <QuesPreview secondViewButtons={secondViewButtons} />
      )}
    </>
  );
};

export default AddQues;
