import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Typography,
  Grid,
  List,
  Button,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Card,
  CardHeader,
  ListItem,
  Divider,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import theme from "../../theme";
import {
  saveDataFilterFields,
  fieldUserLabels,
  dataFilterFields,
} from "../Survey/Survey.service";

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const AddDataQuery = ({ handleCloseDataQuery, dataFilterId }) => {
  const location = useLocation();
  const surveyId = location?.state?.surveyId;
  const [errorMsg, setErrorMsg] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  useEffect(() => {
    const getDataFiltersFields = async () => {
      setRight([]);
      try {
        const response = await dataFilterFields(dataFilterId);
        let arr2 = [];
        if (response.data.length > 0) {
          response.data.forEach((element) => {
            arr2.push({
              id: element.field["id"],
              userLabel: element.field["userLabel"],
            });
          });
          setRight(arr2);
        }

        fetchDataFiltersFields(arr2);
      } catch (error) {
        console.error("Error fetching data filter fields:", error);
      }
    };

    if (dataFilterId) {
      getDataFiltersFields();
    }
  }, [dataFilterId]);

  const fetchDataFiltersFields = async (selectedfieldsArr) => {
    setLeft([]);
    try {
      const response = await fieldUserLabels(surveyId);
      if (response.data.length > 0) {
        if (selectedfieldsArr.length === 0) {
          setLeft(response.data);
        } else {
          let arr1 = [];
          response?.data.forEach((element) => {
            let res = selectedfieldsArr.find((item) => item.id === element.id);
            if (res === undefined) {
              arr1.push({
                id: element.id,
                userLabel: element.userLabel,
              });
            }
          });
          setLeft(arr1);
        }
      }
    } catch (error) {
      console.error("Error fetching Data Filters:", error);
    }
  };

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked));
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ padding: "10px" }}
        title={title}
        subheader={`${items.length} record/s`}
      />
      <Divider />
      <List
        sx={{ width: 330, height: 370, overflow: "auto" }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value.id}-label`;

          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.userLabel} />
            </ListItemButton>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  const handleSave = () => {
    let fieldIds = [];
    if (right.length > 0) {
      for (let cnt = 0; cnt < right.length; cnt++) {
        fieldIds.push(right[cnt].id);
      }
    }
    saveRecord(fieldIds);
  };
  const saveRecord = async (fieldIds) => {
    try {
      const payload = {
        dataFilterId: dataFilterId,
        fieldIds: fieldIds,
      };
      const response = await saveDataFilterFields(payload);
      if (response?.status === 200 && !response?.data?.detail) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("fields saved successfully.");
        setAlertSeverity("success");

        setTimeout(() => {
          handleCloseDataQuery();
        }, 2000);
      } else if (response?.data?.status === 400) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error- save data fields", error);
      setErrorMsg("Error- save data fields");
    }
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Edit Data Fields</Typography>
        </div>
        <div className="panelForm">
          <Typography variant="body2" paragraph>
            Select a field on the left and click the {">>"} button to add it to
            the data query. To remove elements from the data query, select a
            field on the right and click the {"<<"} button. Click on the Done
            button to save changes.
          </Typography>
          {errorMsg !== "" && (
            <Typography variant="body2" color="error" paragraph>
              {errorMsg}
            </Typography>
          )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>{customList("Possible Fields", left)}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ margin: "10px" }}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;&gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  sx={{ margin: "10px" }}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;&lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{customList("Selected Fields", right)}</Grid>
          </Grid>

          <Divider
            color={theme.palette.blue.main}
            sx={{ marginTop: "10px", marginBottom: "10px" }}
          />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <Button variant="outlined" onClick={handleCloseDataQuery}>
                Cancel
              </Button>
            </div>
            <div>
              <Button variant="outlined" onClick={() => handleSave()}>
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};
export default AddDataQuery;
