import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  FormGroup,
} from "@mui/material";

const AdditionalAnswerOptions = ({ fieldFormData, handleFieldChange }) => (
  <Grid item xs={6}>
    <Card>
      <CardContent>
        <Box>
          <Typography variant="label" sx={{ fontWeight: "bold" }}>
            ADDITIONAL ANSWER OPTIONS
          </Typography>
        </Box>
        <FormGroup>
          <Grid container xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={fieldFormData["historyFlag"] === true}
                  name="historyFlag"
                  onChange={(e) =>
                    handleFieldChange(e?.target?.name, e?.target?.checked)
                  }
                />
              }
              label="Show History"
            />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={fieldFormData["fillHistory"] === true}
                  name="fillHistory"
                  onChange={(e) =>
                    handleFieldChange(e?.target?.name, e?.target?.checked)
                  }
                />
              }
              label="Allow Fill With Last Year's Data"
            />
          </Grid>
        </FormGroup>
      </CardContent>
    </Card>
  </Grid>
);

export default AdditionalAnswerOptions;
