import { React } from "react";
import { Box } from "@mui/material";

const LoadingText = () => {
  return (
    <>
      <Box
        display="flex"
        color="primary"
        sx={{ height: "100vh", width: "100%", fontSize: "24px" }}
        alignItems="center"
        justifyContent="center"
      >
        One moment please...
      </Box>
    </>
  );
};
export default LoadingText;
