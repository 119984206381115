import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Radio,
  FormGroup,
  RadioGroup,
} from "@mui/material";

const SelectAnswerField = ({
  ansList,
  handleFieldSelect,
  singleResAnsExpanded,
  singleResAns,
  textAnsExpanded,
  textAns,
}) => {
  const cardBorder = {
    border: "1px solid #808080",
    borderRadius: "12px",
    boxShadow: "4px 4px 20px 0px #3e495429",
    padding: "12px",
    marginLeft: "10px",
  };

  return (
    <Card>
      <CardContent>
        <Typography paragraph>
          <b>SELECT AN ANSWER (FIELD) TYPE</b>
        </Typography>
        <FormGroup>
          <Grid container>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList.noAnswer}
                    onChange={handleFieldSelect}
                    name="noAnswer"
                  />
                }
                label="No Answer (question text used as heading or instructions)"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="center" color="primary">
                <b>SINGLE RESPONSE FORMAT</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList.singleResponseAnswer}
                    onChange={handleFieldSelect}
                    name="singleResponseAnswer"
                  />
                }
                label="Single Response Answer [ Radio Buttons : Custom (default), True/False, Yes/No ]"
              />
            </Grid>
            {singleResAnsExpanded && (
              <Grid item xs={12} style={cardBorder}>
                <RadioGroup
                  row
                  aria-labelledby="singleResAns"
                  name="row-radio-singleResAns"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={singleResAns["Radio Field"]}
                        onChange={handleFieldSelect}
                        name="Radio Field"
                      />
                    }
                    label="Custom Radio"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={singleResAns["Radioyn Field"]}
                        onChange={handleFieldSelect}
                        name="Radioyn Field"
                      />
                    }
                    label="Yes/No"
                  />
                </RadioGroup>
              </Grid>
            )}
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList["Combobox Field"]}
                    onChange={handleFieldSelect}
                    name="Combobox Field"
                  />
                }
                label="Dropdown list question"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList["State Field"]}
                    onChange={handleFieldSelect}
                    name="State Field"
                  />
                }
                label="State list question"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList["CheckBox Field"]}
                    onChange={handleFieldSelect}
                    name="CheckBox Field"
                  />
                }
                label="Multiple responses answer (checkbox)"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList["Numeric Field"]}
                    onChange={handleFieldSelect}
                    name="Numeric Field"
                  />
                }
                label="Numeric answer"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Radio
                    checked={ansList.textAnswer}
                    onChange={handleFieldSelect}
                    name="textAnswer"
                  />
                }
                label="Text answer [ including Single line text (default), Multiple line text, Phone, Date ]"
              />
            </Grid>
            {textAnsExpanded && (
              <Grid item xs={12} style={cardBorder}>
                <RadioGroup
                  row
                  aria-labelledby="textAns"
                  name="row-radio-textAns"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={textAns["Simpletext Field"]}
                        onChange={handleFieldSelect}
                        name="Simpletext Field"
                      />
                    }
                    label="Single line text"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={textAns["Textarea Field"]}
                        onChange={handleFieldSelect}
                        name="Textarea Field"
                      />
                    }
                    label="Multiple line text"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={textAns["Phone Field"]}
                        onChange={handleFieldSelect}
                        name="Phone Field"
                      />
                    }
                    label="Phone"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={textAns["Date Field"]}
                        onChange={handleFieldSelect}
                        name="Date Field"
                      />
                    }
                    label="Date"
                  />
                </RadioGroup>
              </Grid>
            )}
          </Grid>
        </FormGroup>
      </CardContent>
    </Card>
  );
};

export default SelectAnswerField;
