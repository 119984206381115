import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useTreeItem } from "@mui/x-tree-view/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import { getPopulatedSurvey } from "../../pages/Survey/Survey.service";
import {
  getTextFromHTML,
  htmlParser,
  truncateText,
} from "../../Utility/constants";
import { Box, CircularProgress } from "@mui/material";
import {
  setSelectedPage,
  setSelectedQues,
  setSelectedSec,
  setSidebarLoading,
  setSurveyBuilderCurrentView,
  setSurveyBuilderSurvey,
} from "../../pages/Survey/Survey.slice";
import { useDispatch, useSelector } from "react-redux";

const RootTreeView = styled(TreeView)(({ theme }) => ({
  borderRadius: "14px",
  backgroundColor: "rgba(0, 48, 135, 0.15)",
  [`& .${treeItemClasses.group}`]: {
    paddingLeft: 18,
  },
}));

function SectionTreeItem(props) {
  const { sec, currentView, ...otherProps } = props;

  return (
    <TreeItem
      {...otherProps}
      ContentComponent={React.forwardRef((contentProps, ref) => (
        <SectionContent
          ref={ref}
          sec={sec}
          currentView={currentView}
          {...contentProps}
        />
      ))}
    />
  );
}

const SectionContent = React.forwardRef(function SectionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    sec,
    currentView,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const dispatch = useDispatch();
  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    if (currentView !== 11) {
      dispatch(setSelectedSec(sec));
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 2, source: "" }));
    }
    handleExpansion(event);
    handleSelection(event);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        border: "1px solid rgba(0, 48, 135, 1)",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function PageTreeItem(props) {
  const { page, currentView, ...otherProps } = props;
  return (
    <TreeItem
      ContentComponent={(contentProps) => (
        <PageContent {...contentProps} currentView={currentView} page={page} />
      )}
      {...otherProps}
    />
  );
}

const PageContent = React.forwardRef(function PageContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
    page,
    currentView,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const dispatch = useDispatch();
  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const selectedSurveyData = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData
  );

  const handleClick = (event) => {
    if (currentView !== 11) {
      const section = selectedSurveyData?.sections.find((section) =>
        section.pages.some((pageObj) => pageObj.page.id === page?.page?.id)
      );

      dispatch(setSelectedSec(section));
      dispatch(setSelectedPage(page));
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 3, source: "" }));
    }
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && {
          backgroundColor: "rgba(0, 48, 135, 0.15)",
          borderBottom: "1px solid rgba(0, 48, 135, 1)",
        }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function QuestionTreeItem(props) {
  const { ques, currentView, ...otherProps } = props;
  return (
    <TreeItem
      ContentComponent={(contentProps) => (
        <QuestionContent
          {...contentProps}
          ques={ques}
          currentView={currentView}
        />
      )}
      {...otherProps}
    />
  );
}

const QuestionContent = React.forwardRef(function QuestionContent(props, ref) {
  const { className, classes, label, nodeId, onClick, ques, currentView } =
    props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const dispatch = useDispatch();
  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    dispatch(setSelectedQues(ques));
    if (currentView !== 11) {
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 4, source: "" }));
    }
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        padding: "8px 0",
        ...(selected && {
          color: "#1b1ba7",
          backgroundColor: "#9dafd1",
          borderRadius: "7px",
        }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography variant="label" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

function PageTreeItems({ pages, currentView }) {
  return (
    <>
      {pages.map((page, index) => (
        <PageTreeItem
          page={page}
          key={page?.page?.id}
          selecticon={<CropSquareIcon />}
          nodeId={page?.page?.id}
          label={`Page-${index + 1} : ${truncateText(
            getTextFromHTML(page?.page?.name),
            16
          )}`}
          currentView={currentView}
          sx={{
            backgroundColor: "rgba(0, 48, 135, 0.15)",
            border: "1px solid rgba(0, 48, 135, 1)",
            borderRadius: "14px",
            [`& .${treeItemClasses.group}`]: {
              maxHeight: 200,
              overflowY: "auto",
              overflowX: "hidden",
              margin: "12px 12px 12px -5px",
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 48, 135, 1) white",
              "&::-webkit-scrollbar": {
                width: 3,
                backgroundColor: "white",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 48, 135, 1)",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            margin: "0px 10px 10px -18px",
          }}
        >
          <div>
            <QuestionTreeItems
              index={index}
              questions={page?.questionGroups}
              currentView={currentView}
            />
          </div>
        </PageTreeItem>
      ))}
    </>
  );
}

function QuestionTreeItems({ questions, currentView }) {
  let charCount = 25;
  if (currentView === 11) charCount = 30;
  return (
    <>
      {questions
        .reduce((accumulator, currentGroup) => {
          return accumulator.concat(currentGroup.questions);
        }, [])
        .map((question, index) => (
          <a
            key={question.id}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <QuestionTreeItem
              ques={question}
              currentView={currentView}
              selectIcon={<CropSquareIcon />}
              nodeId={question.id}
              label={truncateText(
                `${getTextFromHTML(question.label) || ""} ${
                  getTextFromHTML(question.name) || ""
                }`,
                charCount
              )}
            />
          </a>
        ))}
    </>
  );
}

function SurveyBuilderSidebar() {
  const [expanded, setExpanded] = useState([]);
  const [sections, setSections] = useState([]);
  const [survey, setSurvey] = useState({});
  const [loading, setLoading] = useState(true);
  const [ruleEditorLevel, setRuleEditorLevel] = useState(null);

  const dispatch = useDispatch();
  const surveyId = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyId
  );
  const sidebarRefresh = useSelector(
    (state) => state?.survey?.surveyBuilderData?.sidebarRefreshFlag
  );

  const selectedSurveyData = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData
  );

  const selectedPage = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage
  );

  const selectedSection = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec
  );

  const currentView = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData?.surveyBuilderCurrentView?.viewIndex
  );
  const currentSource = useSelector(
    (state) => state?.survey?.surveyBuilderData.surveyBuilderCurrentView.source
  );
  const selectedPageId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage?.page?.id
  );
  useEffect(() => {
    const fetchPopulatedSurvey = async () => {
      setLoading(true);
      dispatch(setSidebarLoading(true));
      try {
        const response = await getPopulatedSurvey(surveyId);
        setSections(response.data.sections);
        setSurvey(response.data.survey);
        dispatch(setSurveyBuilderSurvey(response.data));
      } catch (error) {
        console.log("Error fetching Populated Survey", error);
      } finally {
        setLoading(false);
        dispatch(setSidebarLoading(false));
      }
    };
    fetchPopulatedSurvey();
  }, [surveyId, sidebarRefresh]);

  useEffect(() => {
    if (selectedPage?.page?.id) {
      const section = selectedSurveyData?.sections.find((section) =>
        section.pages.some(
          (pageObj) => pageObj?.page?.id === selectedPage?.page?.id
        )
      );
      const page = section?.pages.find(
        (page) => page?.page?.id === selectedPage?.page?.id
      );

      dispatch(setSelectedPage(page));
    }
    if (selectedSection?.section?.id) {
      const section = selectedSurveyData?.sections.find(
        (section) => section?.section?.id === selectedSection?.section?.id
      );
      dispatch(setSelectedSec(section));
    }
  }, [selectedSurveyData]);

  useEffect(() => {
    if (currentSource) {
      if (currentSource.includes("__")) {
        const rowSoure = currentSource.split("__");
        const rowName = rowSoure[0];
        setRuleEditorLevel(rowName);
      } else {
        setRuleEditorLevel(currentSource);
      }
    }
  }, [currentSource]);

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  return loading ? (
    <>
      <Box
        display="flex"
        sx={{ height: "100vh", width: "100%" }}
        bgcolor="#d9e0ed"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </>
  ) : (
    <div>
      <div style={{ display: "flex", textWrap: "wrap" }}>
        <Typography
          variant="headerMenu"
          color="primary"
          sx={{ cursor: "pointer" }}
          onClick={() =>
            dispatch(setSurveyBuilderCurrentView({ viewIndex: 1, source: "" }))
          }
        >
          {htmlParser(survey?.name)}
        </Typography>
      </div>
      {(currentView !== 11 ||
        (currentView === 11 &&
          (ruleEditorLevel === "SurveyLevel" ||
            ruleEditorLevel === "addPageLevelDR" ||
            ruleEditorLevel === "addQuesGrpLevelDR"))) && (
        // entire survey tree --------------------- //
        <>
          {sections.map((sec) => (
            <RootTreeView
              aria-label="customized"
              expanded={expanded}
              onNodeToggle={handleNodeToggle}
              defaultCollapseIcon={<ExpandMoreIcon />}
              defaultExpandIcon={<ChevronRightIcon />}
              sx={{
                margin: "10px 0",
              }}
              key={sec.id}
            >
              <SectionTreeItem
                sec={sec}
                key={sec?.section?.id}
                selecticon={<SelectAllIcon />}
                nodeId={sec?.section?.id}
                label={`Section ${truncateText(
                  getTextFromHTML(sec?.section?.name),
                  22
                )}`}
                currentView={currentView}
                sx={{
                  border: "1px solid transparent",
                  borderRadius: "14px",
                  [`& .${treeItemClasses.group}`]: {
                    maxHeight: 250,
                    overflowY: "auto",
                    overflowX: "hidden",
                    margin: "12px 12px",
                    scrollbarWidth: "thin",
                    scrollbarColor: "rgba(0, 48, 135, 1) white",
                    "&::-webkit-scrollbar": {
                      width: 3,
                      backgroundColor: "white",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0, 48, 135, 1)",
                      borderRadius: 4,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                  },
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                <PageTreeItems
                  pages={
                    ruleEditorLevel === "addPageLevelDR"
                      ? sec.pages.filter(
                          (page) => page?.page?.id !== selectedPageId
                        )
                      : sec.pages
                  }
                  currentView={currentView}
                />
              </SectionTreeItem>
            </RootTreeView>
          ))}
        </>
      )}
      {currentView === 11 && ruleEditorLevel === "SectionLevel" && (
        // section tree  --------------------- //
        <>
          <RootTreeView
            aria-label="customized"
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              margin: "10px 0",
            }}
            key={selectedSection.section?.id}
          >
            <SectionTreeItem
              sec={selectedSection}
              key={selectedSection.section?.id}
              selecticon={<SelectAllIcon />}
              nodeId={selectedSection.section?.id}
              label={`Section ${truncateText(
                getTextFromHTML(selectedSection.section?.name),
                25
              )}`}
              currentView={currentView}
              sx={{
                border: "1px solid transparent",
                borderRadius: "14px",
                [`& .${treeItemClasses.group}`]: {
                  maxHeight: 590,
                  overflowY: "auto",
                  overflowX: "hidden",
                  margin: "10px 10px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "rgba(0, 48, 135, 1) white",
                  "&::-webkit-scrollbar": {
                    width: 3,
                    backgroundColor: "white",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0, 48, 135, 1)",
                    borderRadius: 4,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <PageTreeItems
                pages={selectedSection.pages}
                currentView={currentView}
              />
            </SectionTreeItem>
          </RootTreeView>
        </>
      )}

      {currentView === 11 && ruleEditorLevel === "PageLevel" && (
        // page tree  --------------------- //
        <>
          <b>
            {truncateText(`${getTextFromHTML(selectedPage.page?.name)}`, 35)}
          </b>
          <RootTreeView
            aria-label="customized"
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            key={selectedPage.page?.id}
          >
            <QuestionTreeItems
              index={1}
              questions={selectedPage?.questionGroups}
              currentView={currentView}
            />
          </RootTreeView>
        </>
      )}
    </div>
  );
}

export default SurveyBuilderSidebar;
