import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "../../../components/Common/Button";
import theme from "../../../theme";
import { createPage, updatePage } from "../../Survey/Survey.service";
import {
  fetchPanelsByOrgId,
  setSelectedSurveyId,
  setSelectedSurveyName,
  setSurveyBuilderCurrentView,
  triggerSidebarRefresh,
} from "../../Survey/Survey.slice";
import RichEditor from "../../../components/Common/RichEditor";
import { CLEAN_FORM_DATA } from "../../../Utility/constants";

export const initNewSurveyData = {
  name: "",
  description: "",
  helpText: "",
};

const PageForm = () => {
  const dispatch = useDispatch();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [seq, setSeq] = useState(null); //check usage and when no page
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [nameError, setNameError] = useState(null);
  const selectedPage = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedPage
  );

  const selectedSection = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec
  );

  const selectedSurveyData = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData
  );

  const currentViewSource = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData?.surveyBuilderCurrentView?.source
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const [formData, setFormData] = useState(initNewSurveyData);
  const [updateFormData, setUpdateFormData] = useState({});

  useEffect(() => {
    const calculateSequence = (pages) => {
      if (pages?.length > 0) {
        const largestSequencePage = pages.reduce(
          (max, current) =>
            current.page.sequence > max.page.sequence ? current : max,
          pages[0]
        );
        return largestSequencePage?.page?.sequence + 1;
      }
      return 0;
    };

    if (Object.keys(selectedPage)?.length > 0) {
      const section = selectedSurveyData?.sections.find((section) =>
        section.pages.some(
          (pageObj) => pageObj.page.id === selectedPage?.page?.id
        )
      );
      if (section) {
        const allPages = section.pages;
        setSeq(calculateSequence(allPages));
      }
    } else if (Object.keys(selectedSection)?.length > 0) {
      const allPages = selectedSection?.pages;
      setSeq(calculateSequence(allPages));
    }
  }, [selectedPage, selectedSection]);

  useEffect(() => {
    setUpdateFormData(selectedPage?.page);
  }, [selectedPage]);

  useEffect(() => {
    if (Object.keys(selectedPage)?.length > 0) {
      const updatedFormData = {
        name: selectedPage?.page?.name,
        description: selectedPage?.page?.description,
        helpText: selectedPage?.page?.helpText,
      };
      setFormData(updatedFormData);
    }
  }, [selectedPage]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (Object.keys(selectedPage).length > 0) {
      setUpdateFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = () => {
    setNameError(null);
    const name = formData["name"];
    if (!name || name?.replace(/(&nbsp;|\s|<br\s*\/?>)/g, "").trim() === "") {
      setNameError("Please enter name");
    } else {
      if (selectedPage && selectedPage?.page?.id) {
        updateSectionRecord(selectedPage?.page?.id);
      } else {
        createSectionRecord();
      }
    }
  };

  const updateSectionRecord = async (id) => {
    const cleanData = CLEAN_FORM_DATA(updateFormData);
    try {
      const payload = {
        ...cleanData,
        modifiedBy: userName,
      };
      const response = await updatePage(payload);
      if (response?.data) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Page updated successfully.");
        setAlertSeverity("success");
        //   dispatch(setSelectedSurveyId(id));
        //   dispatch(setSelectedSurveyName(selectedPage?.survey?.name));

        setTimeout(() => {
          handleCancel();
          dispatch(triggerSidebarRefresh());
        }, 2000);
      }
    } catch (error) {
      console.log("Error updating page", error);
    }
  };

  const createSectionRecord = async () => {
    const cleanData = CLEAN_FORM_DATA(formData);
    try {
      const payload = {
        ...cleanData,
        createdBy: userName,
        sequence: seq,
        section: selectedSection?.section,
      };
      const response = await createPage(payload);
      if (response?.data) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Page created successfully.");
        setAlertSeverity("success");

        //   dispatch(setSelectedSurveyId(response.data.survey.id));
        //   dispatch(setSelectedSurveyName(response.data.survey.name));
        setTimeout(() => {
          handleCancel();
          dispatch(triggerSidebarRefresh());
        }, 2000);
      }
    } catch (error) {
      console.log("Error creating page", error);
    }
  };

  const handleCancel = () => {
    if (
      currentViewSource === "addPageButton" ||
      currentViewSource === "editPageIcon"
    ) {
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 2, source: "" }));
    } else if (currentViewSource === "editPageButton") {
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 3, source: "" }));
    }
  };

  return (
    <Box component="fieldset">
      <legend sx={{ color: theme.palette.pink.main }}>
        <b>PAGE TITLE & ATTRIBUTES</b>
      </legend>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <Typography variant="body1">Name *</Typography>
        </Grid>
        <Grid item xs={5}>
          <RichEditor
            name="name"
            value={formData["name"]}
            onChange={handleChange}
          />
          {nameError && (
            <Typography variant="body1" color="error">
              {nameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle1" color="error">
            * Required
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">Description</Typography>
        </Grid>
        <Grid item xs={10}>
          <RichEditor
            name="description"
            value={formData["description"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">Help</Typography>
        </Grid>
        <Grid item xs={10}>
          <RichEditor
            name="helpText"
            value={formData["helpText"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Button onClick={handleCancel} sx={{ marginRight: "10px" }}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default PageForm;
