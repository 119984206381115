import React, { useEffect, useState } from "react";
import {
  displayBatchUpdatePage,
  getQuesGroupsForSelectList,
  getSurveyFilingBySurveyFilingId,
  updateGroupShortName,
  updateSurveyFilingStatus,
} from "../Survey/Survey.service";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import {
  Box,
  CircularProgress,
  Grid,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setBatchUpdateCurrentView,
  setSelectedSurveyFilingIds,
  setUpdatedSurveyFilingIds,
} from "../Survey/Survey.slice";
import UpdateGrpNameModal from "./UpdateGrpNameModal";
import RenderRadioGrp from "../../components/QuestionTypes/RenderRadioGrp";
import RenderCheckboxGrp from "../../components/QuestionTypes/RenderCheckboxGrp";
import RenderCommonGrp from "../../components/QuestionTypes/RenderCommonGrp";
import RenderQuestion from "../../components/QuestionTypes/RenderQuestion";
import { htmlParser, getTextFromHTML } from "../../Utility/constants";
import HelpTextPopper from "../../components/Common/Popper";

const SaveBatchUpdate = () => {
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(false);
  const [selectedQuesGroupId, setSelectedQuesGroupId] = useState(null);
  const [selectedQuesGroupName, setSelectedQuesGroupName] = useState(null);
  const [selectedFilingStatus, setSelectedFilingStatus] = useState(null);
  const [questionGroupsForSelectList, setQuestionGroupsForSelectList] =
    useState({});
  const [quesData, setQuesData] = useState([]);
  const [isGrpNameModalOpen, setGrpNameModalOpen] = useState(false);

  const surveyId = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyId
  );
  const selectedSurveyFilingIds = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedSurveyFilingIds
  );
  const updatedSurveyFilingIds = useSelector(
    (state) => state?.survey?.batchUpdateData?.updatedSurveyFilingIds
  );
  const selectedQuesGroups = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedQuesGroups
  );
  const selectedFields = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedFields
  );
  const updateFilingStatus = useSelector(
    (state) => state?.survey?.batchUpdateData?.updateFilingStatus
  );

  const { customId: currentCustomId, surveyFilingId: currentSurveyFilingId } =
    selectedSurveyFilingIds?.[0] || {};

  const handleBack = () => {
    if (updatedSurveyFilingIds.length > 0) {
      const lastUpdatedSurveyFilingId =
        updatedSurveyFilingIds[updatedSurveyFilingIds.length - 1];
      const newSelectedSurveyFilingIds = [
        lastUpdatedSurveyFilingId,
        ...selectedSurveyFilingIds,
      ];
      const newUpdatedSurveyFilingIds = updatedSurveyFilingIds.slice(0, -1);

      dispatch(setUpdatedSurveyFilingIds(newUpdatedSurveyFilingIds));
      dispatch(setSelectedSurveyFilingIds(newSelectedSurveyFilingIds));
    } else {
      dispatch(setBatchUpdateCurrentView(2));
    }
  };

  const handleCloseModal = () => {
    setGrpNameModalOpen(false);
  };

  useEffect(() => {
    const fetchSurveyFiling = async (currentSurveyFilingId) => {
      try {
        const res = await getSurveyFilingBySurveyFilingId(
          currentSurveyFilingId
        );
        if (res.data) {
          setSelectedFilingStatus(res.data?.status?.name);
        }
      } catch (error) {
        console.error("Error fetching SurveyFiling:", error);
      }
    };
    if (currentSurveyFilingId) {
      fetchSurveyFiling(currentSurveyFilingId);
    }
  }, [selectedSurveyFilingIds]);

  const getQuesGroupName = async () => {
    const quesGroupsRes = await getQuesGroupsForSelectList(surveyId);
    try {
      if (quesGroupsRes.data) {
        setQuestionGroupsForSelectList(quesGroupsRes.data);
      }
    } catch (error) {
      console.error("Error fetching QuesGroup:", error);
    }
  };

  useEffect(() => {
    getQuesGroupName();
  }, []);

  const handleGrpNameUpdate = (quesGroupId) => {
    setSelectedQuesGroupId(quesGroupId);
    setSelectedQuesGroupName(questionGroupsForSelectList?.[quesGroupId]);
    setGrpNameModalOpen(true);
  };

  const handleGrpNameModalSubmit = async (grpName) => {
    setGrpNameModalOpen(false);
    const res = await updateGroupShortName({
      groupId: selectedQuesGroupId,
      shortName: grpName,
    });

    if (res.data) {
      await getQuesGroupName();
    }

    setGrpNameModalOpen(false);
  };

  const handleSubmit = async () => {
    try {
      if (currentSurveyFilingId && updateFilingStatus === "yes") {
        const res = await getSurveyFilingBySurveyFilingId(
          currentSurveyFilingId
        );
        if (res.data) {
          await updateSurveyFilingStatus({
            surveyFiling: res.data,
            statusName: selectedFilingStatus,
          });
        } else {
          console.error("Error getting SurveyFiling");
        }
      }

      const newSurveyFilingIds = [...selectedSurveyFilingIds];
      const updatedSurveyFilingId = newSurveyFilingIds.shift();

      dispatch(
        setUpdatedSurveyFilingIds([
          ...updatedSurveyFilingIds,
          updatedSurveyFilingId,
        ])
      );
      dispatch(setSelectedSurveyFilingIds(newSurveyFilingIds));
    } catch (error) {
      console.error("Error updating survey filing status:", error);
    }
  };

  const fetchBatchUpdatePage = async () => {
    try {
      setPageLoading(true);
      const res = await displayBatchUpdatePage({
        surveyFilingId: currentSurveyFilingId,
        groupIds: selectedQuesGroups,
        fieldIds: selectedFields,
      });

      const questionGroupsArray = Object.values(res.data.questionGroupsMap).map(
        (item) => ({
          ...item.questionGroup,
          pageFilingId: item.pageFilingId,
        })
      );

      const fieldsGroupsArray = Object.entries(
        res.data.fieldQuestionGroupsMap
      ).map(([fieldId, item]) => {
        const filteredQuestions = item.questionGroup.questions.filter(
          (question) => question.fields.some((field) => field.id == fieldId)
        );

        const questionGroup = {
          ...item.questionGroup,
          pageFilingId: item.pageFilingId,
          questions: filteredQuestions,
          renderFields: true,
        };

        return { ...questionGroup, fieldId: parseInt(fieldId) };
      });

      setPageLoading(false);
      setQuesData([...questionGroupsArray, ...fieldsGroupsArray]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setPageLoading(false);
    }
  };

  useEffect(() => {
    if (currentSurveyFilingId) {
      fetchBatchUpdatePage();
    } else {
      dispatch(setBatchUpdateCurrentView(4));
    }
  }, [selectedSurveyFilingIds]);

  return pageLoading ? (
    <>
      <Box
        display="flex"
        sx={{ height: "100vh", width: "100%" }}
        bgcolor="#d9e0ed"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress />
      </Box>
    </>
  ) : (
    <>
      <div style={{ marginBottom: "15px" }}>
        <Typography color="primary" variant="label">
          Batch Update for Custom ID: {currentCustomId}
        </Typography>
      </div>
      <div>
        <form>
          {quesData &&
            quesData.map((qes) => (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Typography
                    color="primary"
                    variant="label"
                    style={{ marginRight: "15px" }}
                  >
                    GROUP:{" "}
                    {getTextFromHTML(questionGroupsForSelectList[qes.id])}
                  </Typography>
                  <Button
                    variant="outlined"
                    onClick={() => handleGrpNameUpdate(qes.id)}
                  >
                    Update Group Name
                  </Button>
                </div>
                {qes.groupType.name === "radiogrp" && (
                  <RenderRadioGrp
                    questionsGrp={qes}
                    pageFilingId={qes.pageFilingId}
                    renderFields={qes.renderFields}
                    fieldId={qes.fieldId}
                    fetchBatchUpdatePage={fetchBatchUpdatePage}
                  />
                )}
                {qes.groupType.name === "checkboxgrp" && (
                  <RenderCheckboxGrp
                    questionsGrp={qes}
                    pageFilingId={qes.pageFilingId}
                    renderFields={qes.renderFields}
                    fieldId={qes.fieldId}
                    fetchBatchUpdatePage={fetchBatchUpdatePage}
                  />
                )}
                {qes.groupType.name === "commongrp" && (
                  <RenderCommonGrp
                    questionsGrp={qes}
                    pageFilingId={qes.pageFilingId}
                    renderFields={qes.renderFields}
                    fieldId={qes.fieldId}
                    fetchBatchUpdatePage={fetchBatchUpdatePage}
                  />
                )}
                {qes.groupType.name !== "radiogrp" &&
                  qes.groupType.name !== "checkboxgrp" &&
                  qes.groupType.name !== "commongrp" &&
                  qes.questions &&
                  qes.questions.length > 0 &&
                  qes.questions.length === 1 &&
                  qes.questions.map((question) => {
                    return (
                      <RenderQuestion
                        question={question}
                        multiple={false}
                        pageFilingId={qes.pageFilingId}
                        renderFields={qes.renderFields}
                        fieldId={qes.fieldId}
                        fetchBatchUpdatePage={fetchBatchUpdatePage}
                      />
                    );
                  })}
                {qes.groupType.name !== "radiogrp" &&
                  qes.groupType.name !== "checkboxgrp" &&
                  qes.groupType.name !== "commongrp" &&
                  qes.questions?.length > 1 && (
                    <>
                      <StyledEngineProvider injectFirst>
                        <Card className="card">
                          <CardContent className="card-header">
                            <Grid container direction={"column"}>
                              <Grid
                                item
                                alignSelf="start"
                                className="question-container"
                              >
                                <Typography variant="question">
                                  {htmlParser(
                                    qes.descriptionPosition !== null ||
                                      qes.descriptionPosition !== "No Title"
                                      ? (qes.label || "") +
                                          " " +
                                          (qes.name || "")
                                      : (qes.label || "") +
                                          " " +
                                          (qes.description || "")
                                  )}
                                  {qes?.helpText && (
                                    <HelpTextPopper
                                      question={qes}
                                    ></HelpTextPopper>
                                  )}
                                </Typography>
                              </Grid>
                            </Grid>
                            <div>
                              <Typography variant="h6">
                                {htmlParser(
                                  qes.descriptionPosition !== null ||
                                    qes.descriptionPosition !== "No Title"
                                    ? qes.description || ""
                                    : ""
                                )}
                              </Typography>
                            </div>
                          </CardContent>
                          <CardContent
                            className="card-content"
                            sx={{ backgroundColor: "#fff !important" }}
                          >
                            {qes.questions.map((question) => {
                              return (
                                <RenderQuestion
                                  question={question}
                                  multiple={true}
                                  pageFilingId={qes.pageFilingId}
                                  renderFields={qes.renderFields}
                                  fieldId={qes.fieldId}
                                  fetchBatchUpdatePage={fetchBatchUpdatePage}
                                />
                              );
                            })}
                          </CardContent>
                        </Card>
                      </StyledEngineProvider>
                    </>
                  )}
              </>
            ))}
        </form>
      </div>
      <Button variant="contained" onClick={handleSubmit}>
        Submit
      </Button>
      <Button
        variant="outlined"
        sx={{ marginLeft: "15px" }}
        onClick={handleBack}
      >
        Back
      </Button>
      <UpdateGrpNameModal
        open={isGrpNameModalOpen}
        onClose={handleCloseModal}
        onSubmit={handleGrpNameModalSubmit}
        grpName={selectedQuesGroupName}
      />
    </>
  );
};

export default SaveBatchUpdate;
