import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import Button from "../../../components/Common/Button";
import { useLocation } from "react-router";
import {
  setImportCurrentView,
  setImportPastBatchResult,
} from "../../Survey/Survey.slice";
import { useDispatch, useSelector } from "react-redux";
import { FormControl } from "@mui/material";
import { formatShortDate, htmlParser } from "../../../Utility/constants";
import { completedBatches } from "../../Survey/Survey.service";

const PastBatchResults = () => {
  const [selectedBatch, setSelectedBatch] = useState(null);
  const location = useLocation();
  const surveyName = location?.state?.surveyName;
  const surveyId = location?.state?.surveyId;
  const [loadingBatch, setLoadingBatch] = useState(false);
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const selectedBatchId = useSelector((state) => state.survey.selectedBatchId);
  const dispatch = useDispatch();
  const [batchResult, setbatchResult] = useState([]);

  useEffect(() => {
    const findCompletedBatches = async () => {
      setLoadingBatch(true);
      try {
        const res = await completedBatches(surveyId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const responseData = res?.data;
          setbatchResult(responseData);
          setSelectedBatch(
            selectedBatchId ? selectedBatchId : responseData[0].id
          );
          setLoadingBatch(false);
        }
      } catch (error) {
        console.error("Error fetching batch Information:", error);
      }
    };
    if (personId) {
      findCompletedBatches();
    }
  }, [personId, selectedBatchId]);

  useEffect(() => {
    const selectedBatchRecord = async () => {
      try {
        const currentBatch = await batchResult.find(
          (batch) => batch?.id === (selectedBatchId || selectedBatch)
        );

        await selectedBatchDetails(currentBatch);

        if (selectedBatchId) {
          dispatch(setImportCurrentView(6));
        }
      } catch (error) {
        console.error("Error fetching batch Information:", error);
      }
    };
    if (selectedBatch) {
      selectedBatchRecord();
    }
  }, [selectedBatch, selectedBatchId]);

  const selectedBatchDetails = (currentBatch) => {
    const batchData = {
      batchId: currentBatch["id"],
      fileName: currentBatch["uploadFileName"],
      startDate: currentBatch["dateCreated"],
      recordsUploaded: currentBatch["recordCount"],
      recordExceptions: currentBatch["exceptionsRecordCount"],
      recordValidationErrors: currentBatch["validationRecordCount"],
      uploadedBy: currentBatch["createdBy"],
      recordExceptionDetails: [],
    };
    dispatch(setImportPastBatchResult(batchData));
  };
  return (
    <div>
      <Typography variant="label" color="primary" sx={{ marginBottom: "20px" }}>
        Select Batch
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        Functions: select a batch for viewing
      </Typography>
      <Typography variant="label" color="primary" sx={{ marginBottom: "30px" }}>
        Survey: {htmlParser(surveyName)}
      </Typography>
      <div>
        {loadingBatch && " Loading past batch results..."}
        {batchResult?.length > 0 && (
          <>
            <FormControl
              sx={{ minWidth: 200, marginTop: "10px", marginBottom: "20px" }}
            >
              <Select
                value={selectedBatch}
                onChange={(e) => setSelectedBatch(e.target.value)}
              >
                {batchResult.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    Batch ID:{data.id} {formatShortDate(data.dateCreated)} (
                    {data.recordCount} records)
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <Button
                variant="contained"
                component="label"
                style={{ marginRight: "20px" }}
                onClick={() => dispatch(setImportCurrentView(6))}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => dispatch(setImportCurrentView(1))}
              >
                Back
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PastBatchResults;
