import React, { useEffect, useState, useRef } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  StyledEngineProvider,
} from "@mui/material";
import { htmlParser } from "../../Utility/constants";
import Checkbox from "../Common/Checkbox";
import HelpTextPopper from "../Common/Popper";
import NumericInputQuestion from "./NumericInputQuestion";
import TextInputQuestion from "./TextInputQuestion";
import DatePicker from "../Common/DatePicker";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import TextAreaQuestion from "./TextAreaQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import DropdownQuestion from "./DropdownQuestion";
import PhoneField from "../Common/PhoneField";
import StatesQuestion from "./StatesQuestion";
import { saveAnswerOptions } from "../../pages/Survey/Survey.slice";
import CopyrightTwoToneIcon from "@mui/icons-material/CopyrightTwoTone";
// import withCommentModal from "../BuilderViewEditRouter/withCommentModal";

import { useDispatch, useSelector } from "react-redux";
import StateDropdown from "../Common/StateDropdown";
import TotalCalcQes from "./TotalCalcQes";

const TextFieldConfig = {
  questionNo: "Question-1",
  questionType: "Text",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  optionDirection: "row",
};

const TextAreaConfig = {
  questionNo: "Question-2",
  questionType: "Text Area",
  value: "text area input",
  size: 500,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  row: 10,
};

const NumericFieldConfig = {
  questionNo: "Question-3",
  questionType: "Numeric",
  value: "",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  allowDecimal: false,
  precision: 4,
  maxDigits: 5,
  min: 20,
  max: 1000000,
};

const DateFieldConfig = {
  questionNo: "Question-4",
  questionType: "Date",
  size: 250,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RadioConfig = {
  questionNo: "Question-5",
  questionType: "Radio",
  optionDirection: "row",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const MultiChoiceConfig = {
  questionNo: "Question-6",
  questionType: "Checkbox",
  optionDirection: "column",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const DropdownConfig = {
  questionNo: "Question-7",
  questionType: "Dropdown",
  size: 10,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RenderCommonGrp = ({
  questionsGrp,
  pageFilingId,
  getCorrectedData,
  pageId,
  secId,
  openCommentModal,
  reloadSec,
  hideComment = false,
  disableSave = false,
  fetchBatchUpdatePage = () => {},
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const [totals, setTotals] = useState({});
  const [numVals, setNumVals] = useState({});
  const [changedCol, setChangedCol] = useState(null);
  const headings = questionsGrp.headings.map((heading) => heading.name);
  const questions = questionsGrp.questions.map((question) => question);
  const pagePayload = useSelector((state) => state.survey.pagePayload);
  const [selectedOptions, setSelectedOptions] = useState({});
  const tableRef = useRef(null);
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const selectedSurveyFilingIds = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedSurveyFilingIds
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const updateFilingStatus = useSelector(
    (state) => state?.survey?.batchUpdateData?.updateFilingStatus
  );

  const [tableHeader, setTableHeader] = useState([]);

  const {
    customId: currentCustomId,
    surveyFilingId: currentSurveyFilingId,
    surveyRespondentId: currentRespondentId,
  } = selectedSurveyFilingIds?.[0] || {};

  const saveFieldResponse = async (value, field) => {
    if (otherProps?.correctionObj?.page === "correction") {
      getCorrectedData(value, field);
    } else {
      const payload = {
        answers: [
          {
            id: field?.answer?.id ? field?.answer?.id : null,
            value: value,
            loopIndex: 1,
            fieldId: field.id,
            surveyFilingId: currentSurveyFilingId,
            surveyRespondentId: currentRespondentId,
            createdBy: "string",
          },
        ],
        surveyFilingId: currentSurveyFilingId,
        pageFilingId: pageFilingId,
        runValidations: false,
        modifiedBy: userName,
        modifiedBySurveyRespondentId: currentRespondentId,
        updateStatuses: updateFilingStatus === "yes" ? false : true,
      };

      if (!disableSave) {
        await dispatch(saveAnswerOptions(payload));
      }

      if (field?.isInSamePageDispRule) {
        fetchBatchUpdatePage();
      }
    }
  };

  const numericFieldChange = (value, field) => {
    setNumVals((prevVals) => ({
      ...prevVals,
      [field.id]: value,
    }));
    setChangedCol(field?.sequence);
    saveFieldResponse(value, field);
  };

  const autoCalcChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const dateChanged = (value, field) => {
    // console.log('date field changed: ', value, field);
    saveFieldResponse(value, field);
  };

  const phoneNumChange = (value, field) => {
    // console.log('date phone changed: ', value, field);
    saveFieldResponse(value, field);
  };

  const textFieldChange = (value, field) => {
    // console.log('text field changed: ', value, field);
    saveFieldResponse(value, field);
  };

  const textAreaChange = (value, field) => {
    // console.log('text area changed: ', value, field);
    saveFieldResponse(value, field);
  };

  const radioFieldChange = (value, field) => {
    // console.log('radioFieldChange changed: ', value);
    saveFieldResponse(value, field);
  };

  const dropdownChange = (value, field) => {
    // console.log('dropdown changed: ', value);
    saveFieldResponse(value, field);
  };

  const stateFieldChange = (value, field) => {
    // console.log('stateFieldChange changed: ', value);
    saveFieldResponse(value, field);
  };

  const checkboxOptionChange = (value, field) => {
    console.log("checkboxOptionChange changed: ", value);
    saveFieldResponse(value, field);
  };

  useEffect(() => {
    if (questionsGrp?.questions) {
      let options = {};
      questionsGrp.questions?.forEach((question) => {
        const questionId = question.id;
        let checkboxGrp = question?.fields?.filter(
          (field) => field.fieldType.name === "CheckBox Field"
        );
        if (checkboxGrp.length) {
          let ansVal = checkboxGrp
            .map((field) => {
              const value = field?.answer?.value;
              // Remove trailing "|" characters using regex
              return value ? value.replace(/\|+$/g, "") : undefined;
            })
            .filter(
              (value) => value !== undefined && value !== null && value !== ""
            );
          options[questionId] = ansVal || [];
        }
      });
      setSelectedOptions(options);
    }
  }, [questionsGrp]);

  const handleCheckboxChange = (question, fieldValue, field, event) => {
    setSelectedOptions((prevSelectedOptions) => {
      const updatedSelectedOptions = { ...prevSelectedOptions };
      if (!updatedSelectedOptions[question.id]) {
        updatedSelectedOptions[question.id] = [];
      }

      if (fieldValue?.exclusiveAnswer) {
        if (
          updatedSelectedOptions[question.id].some(
            (item) => item === fieldValue.allowableValue
          )
        ) {
          updatedSelectedOptions[question.id] = [];
          let ans = event?.target?.checked ? event?.target?.value : null;
          saveFieldResponse(ans + "|", field);
        } else {
          let notExclusiveArray = [...updatedSelectedOptions[question.id]];
          updatedSelectedOptions[question.id] = [fieldValue.allowableValue];
          saveResponse(notExclusiveArray, question, field);
        }
      } else {
        if (
          updatedSelectedOptions[question.id].some(
            (item) => item === fieldValue.allowableValue
          )
        ) {
          updatedSelectedOptions[question.id] = updatedSelectedOptions[
            question.id
          ].filter((item) => item !== fieldValue.allowableValue);
          let ans = event?.target?.checked ? event?.target?.value : null;
          saveFieldResponse(ans + "|", field);
        } else {
          let exOpts = question?.fields?.filter((item) =>
            updatedSelectedOptions[question.id]?.includes(
              item?.fieldValues[0]?.allowableValue
            )
          );
          let exclusiveField = exOpts?.find(
            (item) => item?.fieldValues[0]?.exclusiveAnswer
          );
          if (exOpts?.some((item) => item?.fieldValues[0]?.exclusiveAnswer)) {
            updatedSelectedOptions[question.id] = [fieldValue.allowableValue];
            uncheckExclusiveWhenOther(exclusiveField, question, field);
          } else {
            updatedSelectedOptions[question.id].push(fieldValue.allowableValue);
            let ans = event?.target?.checked ? event?.target?.value : null;
            saveFieldResponse(ans + "|", field);
          }
        }
      }
      return updatedSelectedOptions;
    });
  };

  const saveResponse = (options, question, field) => {
    if (options.length) {
      let ansArr = [];
      options.forEach((opt) => {
        let checkField = question.fields.find(
          (item) => item?.fieldValues[0]?.allowableValue === opt
        );
        let ansObj = {
          id: checkField?.answer?.id ? checkField?.answer?.id : null,
          value: null,
          loopIndex: 1,
          fieldId: checkField.id,
          surveyFilingId: currentSurveyFilingId,
          surveyRespondentId: currentRespondentId,
          createdBy: "string",
        };
        ansArr.push(ansObj);
      });
      let exclusiveAns = {
        id: field?.answer?.id ? field?.answer?.id : null,
        value: field?.fieldValues[0]?.allowableValue
          ? field?.fieldValues[0]?.allowableValue + "|"
          : null,
        loopIndex: 1,
        fieldId: field.id,
        surveyFilingId: currentSurveyFilingId,
        surveyRespondentId: currentRespondentId,
        createdBy: "string",
      };
      ansArr.push(exclusiveAns);
      saveExclusiveOpt(ansArr, field);
    }
  };

  const uncheckExclusiveWhenOther = (exclusiveField, question, field) => {
    let ansArr = [
      {
        id: field?.answer?.id ? field?.answer?.id : null,
        value: field?.fieldValues[0]?.allowableValue
          ? field?.fieldValues[0]?.allowableValue + "|"
          : null,
        loopIndex: 1,
        fieldId: field.id,
        surveyFilingId: currentSurveyFilingId,
        surveyRespondentId: currentRespondentId,
        createdBy: "string",
      },
      {
        id: exclusiveField?.answer?.id ? exclusiveField?.answer?.id : null,
        value: null,
        loopIndex: 1,
        fieldId: exclusiveField.id,
        surveyFilingId: currentSurveyFilingId,
        surveyRespondentId: currentRespondentId,
        createdBy: "string",
      },
    ];
    saveExclusiveOpt(ansArr, field);
  };

  const saveExclusiveOpt = (ansArr, field) => {
    const payload = {
      answers: ansArr,
      surveyFilingId: currentSurveyFilingId,
      pageFilingId: pageFilingId,
      runValidations: false,
      modifiedBy: userName,
      surveyRespondentId: currentRespondentId,
      updateStatuses: updateFilingStatus === "yes" ? false : true,
    };

    if (!disableSave) {
      dispatch(saveAnswerOptions(payload));
    }

    if (field?.isInSamePageDispRule) {
      fetchBatchUpdatePage();
    }
  };

  const getErrorRule = (field) => {
    if (
      otherProps.correctionObj?.page === "correction" &&
      otherProps.correctionObj?.fieldIds.includes(field?.id)
    ) {
      return { displayIndicator: true };
    }
    return null;
  };

  const getTotalVal = (question, field) => {
    const calculateTotalSum = () => {
      const filteredQuestions = questionsGrp.questions.filter(
        (ques) => ques.sequence < question.sequence
      );
      const totalSum = filteredQuestions.reduce((sum, question) => {
        const matchingField = question.fields.find(
          (f) => f.sequence === field.sequence
        );

        const fieldValue =
          numVals[matchingField?.id] !== undefined
            ? numVals[matchingField.id]
            : matchingField?.answer?.value;
        if (
          fieldValue !== undefined &&
          fieldValue !== null &&
          fieldValue !== ""
        ) {
          return sum + parseFloat(fieldValue);
        }
        return sum;
      }, 0);

      const hasValidAnswers = filteredQuestions.some((question) => {
        const matchingField = question.fields.find(
          (f) => f.sequence === field.sequence
        );

        const fieldValue =
          numVals[matchingField?.id] !== undefined
            ? numVals[matchingField.id]
            : matchingField?.answer?.value;
        return (
          fieldValue !== undefined && fieldValue !== null && fieldValue !== ""
        );
      });

      return totalSum === 0 && !hasValidAnswers ? "" : totalSum;
    };

    return calculateTotalSum();
  };

  useEffect(() => {
    const newTotals = { ...totals };

    questionsGrp.questions.forEach((question) => {
      question.fields.forEach((field) => {
        if (field?.fieldType?.name === "Auto Calc Total Field") {
          const total = getTotalVal(question, field);
          if (!changedCol) {
            newTotals[`${question.sequence}-${field.sequence}`] = field?.answer
              ? field?.answer.value
              : "";
          }
          if (field.sequence == changedCol) {
            saveFieldResponse(total, field);
            newTotals[`${question.sequence}-${field.sequence}`] = total;
          }
        }
      });
    });

    setTotals(newTotals);
  }, [numVals, questionsGrp]);

  const fieldComponents = (question, field, index) => {
    const fieldType = field?.fieldType?.name;
    let fieldComponent;

    switch (fieldType) {
      case "Simpletext Field":
        let textConfig = Object.assign({}, TextFieldConfig, field);
        textConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <TextInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={textConfig}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => textFieldChange(value, field)}
          />
        );
        break;
      case "Textarea Field":
        let config = Object.assign({}, TextAreaConfig, field);
        config.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <TextAreaQuestion
            disabledField={field?.disabledField}
            fieldConfig={config}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => textAreaChange(value, field)}
          />
        );
        break;
      case "Numeric Field":
        let numConfig = Object.assign({}, NumericFieldConfig, field);
        numConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <NumericInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={numConfig}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => numericFieldChange(value, field)}
            triggerOnChange={true}
          />
        );
        break;
      case "Date Field":
        let dateConfig = Object.assign({}, DateFieldConfig, field);
        dateConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <DatePicker
            disabledField={field?.disabledField}
            value={dateConfig.value}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => dateChanged(value, field)}
          />
        );
        break;
      case "Radioyn Field":
        let radioConfig = Object.assign({}, RadioConfig, field);
        radioConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={radioConfig}
            type={otherProps.type}
            qesType="y/n"
            value={radioConfig.value}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => radioFieldChange(value, field)}
          />
        );
        break;
      case "Radio Field":
        let radioFieldConfig = Object.assign({}, RadioConfig, field);
        radioFieldConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={radioFieldConfig}
            type={otherProps.type}
            value={radioFieldConfig.value}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => radioFieldChange(value, field)}
          />
        );
        break;
      case "CheckBox Field":
        fieldComponent = () => (
          <MultipleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={MultiChoiceConfig}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => checkboxOptionChange(value, field)}
          />
        );
        break;
      case "State Select Field":
        let stateMultiConfig = Object.assign({}, DropdownConfig, field);
        stateMultiConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <StatesQuestion
            disabledField={field?.disabledField}
            fieldConfig={stateMultiConfig}
            type={otherProps.type}
            value={stateMultiConfig.value}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => stateFieldChange(value, field)}
          />
        );
        break;
      case "State Field":
        let stateConfig = Object.assign({}, DropdownConfig, field);
        stateConfig.value = field?.answer ? field?.answer?.value : "";
        fieldComponent = () => (
          <StateDropdown
            question={field}
            disabledField={field?.disabledField}
            size={stateConfig?.size}
            type={otherProps.type}
            value={stateConfig?.value}
            label={field}
            errorIndicator={getErrorRule(field)}
            options={field?.fieldValues || []}
            onChange={(value) => dropdownChange(value, field)}
          />
        );
        break;
      case "Phone Field":
        fieldComponent = () => (
          <PhoneField
            question={field}
            disabledField={field?.disabledField}
            placeholder={"(XXX) XXX-XXXX"}
            type={otherProps.type}
            value={field?.answer ? field?.answer.value : null}
            size={field.size}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => phoneNumChange(value, field)}
          />
        );
        break;
      case "Combobox Field":
        let comboConfig = Object.assign({}, DropdownConfig, field);
        comboConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <DropdownQuestion
            disabledField={field?.disabledField}
            fieldConfig={comboConfig}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => dropdownChange(value, field)}
          />
        );
        break;
      case "Auto Calc Total Field":
        let CalcConfig = Object.assign(NumericFieldConfig, field);
        // CalcConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <TotalCalcQes
            disabledField={
              otherProps.correctionObj?.page === "correction" ? false : true
            }
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            fieldConfig={CalcConfig}
            question={field}
            total={
              otherProps.correctionObj?.page === "correction"
                ? field?.answer?.value || ""
                : totals?.[`${question.sequence}-${field.sequence}`]
            }
            onChange={(value) => {
              if (otherProps.correctionObj?.page === "correction") {
                autoCalcChange(value, field);
              } else {
                numericFieldChange(value, field);
              }
            }}
          />
        );
        break;
      default:
        // throw new Error(`Unknown field type: ${fieldType}`);
        fieldComponent = () => (
          <Typography variant="h4">Under Development</Typography>
        );
        break;
    }

    const alignmentConfig = {
      "right-left": {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
      },
      "right-center": {
        flexDirection: "row-reverse",
        justifyContent: "center",
      },
      "right-right": {
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      },
      "left-left": { flexDirection: "row", justifyContent: "flex-start" },
      "left-center": { flexDirection: "row", justifyContent: "center" },
      "left-right": { flexDirection: "row", justifyContent: "flex-end" },
      "Above-left": { flexDirection: "column", alignContent: "flex-start" },
      "Above-center": { flexDirection: "column", alignContent: "center" },
      "Above-right": { flexDirection: "column", alignContent: "flex-end" },
      "Below-left": {
        flexDirection: "column-reverse",
        alignContent: "flex-start",
      },
      "Below-center": {
        flexDirection: "column-reverse",
        alignContent: "center",
      },
      "Below-right": {
        flexDirection: "column-reverse",
        alignContent: "flex-end",
      },
    };

    const getNamePosition = (namePos) => {
      if ((namePos && namePos === "No Title") || !namePos) return "left";
      else return namePos;
    };

    let alignmentKey = `${getNamePosition(field.namePosition)}-${
      field.horizontalAlign || "left"
    }`;

    let { flexDirection, justifyContent, alignContent } =
      alignmentConfig[alignmentKey] || {};

    if (field.fieldFlow === "NL") {
      flexDirection = "column";
    }
    return (
      <Grid
        item
        container
        justifyContent={justifyContent}
        flexDirection={flexDirection}
        alignContent={alignContent}
        key={index}
      >
        {field.name &&
          field.namePosition !== "No Title" &&
          field.namePosition && (
            <Grid item className="heading-container">
              <Typography variant="label">{htmlParser(field.name)}</Typography>
            </Grid>
          )}

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          item
        >
          {fieldComponent(field)}
        </Grid>
      </Grid>
    );
  };

  const tableData = questions.map((question) => {
    const row = [];

    const firstRow = [];
    firstRow.push(
      <div style={{ display: "flex", alignItems: "center" }}>
        {isOpde && !hideComment && (
          <CopyrightTwoToneIcon
            style={{
              margin: "0 5px 3px 0",
              color: "#003087",
              cursor: "pointer",
            }}
            onClick={() => openCommentModal(secId, pageId, question.id)}
          />
        )}
        <Typography>
          {htmlParser(
            question.descriptionPosition !== null ||
              question.descriptionPosition !== "No Title"
              ? (question.label || "") + " " + (question.name || "")
              : (question.label || "") + " " + (question.description || "")
          )}
          {question?.helpText && (
            <HelpTextPopper question={question}></HelpTextPopper>
          )}
        </Typography>
      </div>
    );
    row.push(firstRow);
    question.fields.forEach((field, index) => {
      if (field.hasOwnProperty("columnNumber") && field.columnNumber !== null) {
        if (row[field.columnNumber]) {
          row[field.columnNumber].push(
            <>{fieldComponents(question, field, index)}</>
          );
        } else {
          row[field.columnNumber] = [];
          row[field.columnNumber].push(
            <>{fieldComponents(question, field, index)}</>
          );
        }
      }
    });
    row.questionId = question.id;
    return row;
  });

  useEffect(() => {
    const tableHeader = (
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr
            style={{
              borderBottom: "1px solid #ccc",
            }}
          >
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{
                  textAlign: index === 0 ? "left" : "center",
                  width: index === 0 ? "40%" : `${60 / (headings.length - 1)}%`,
                }}
              >
                {htmlParser(heading)}
              </th>
            ))}
          </tr>
        </thead>
      </table>
    );
    setTableHeader(tableHeader);
  }, [headings.length, questionsGrp]);

  return (
    <StyledEngineProvider injectFirst>
      <Card className="card">
        <CardContent className="card-header">
          <Grid container direction={"column"}>
            <Grid
              item
              // alignSelf={TextFieldConfig.alignQuestion}
              className="question-container"
            >
              <Typography variant="question">
                {htmlParser(
                  questionsGrp.descriptionPosition !== null ||
                    questionsGrp.descriptionPosition !== "No Title"
                    ? (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.name || "")
                    : (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.description || "")
                )}
                {questionsGrp?.helpText && (
                  <HelpTextPopper question={questionsGrp}></HelpTextPopper>
                )}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Typography variant="h6">
              {htmlParser(
                questionsGrp.descriptionPosition !== null ||
                  questionsGrp.descriptionPosition !== "No Title"
                  ? questionsGrp.description || ""
                  : ""
              )}
            </Typography>
          </div>
        </CardContent>
        <CardContent
          className="card-content"
          sx={{ backgroundColor: "#fff !important" }}
        >
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <FormControl component="fieldset">
                {tableHeader}
                <Box
                  sx={{
                    // marginX: " -10px",
                    // minHeight: "350px",
                    overflowY: "scroll",
                    height:
                      tableRef?.current?.offsetHeight <= 600 ? "100%" : "600px",
                    scrollbarWidth: "thin",
                    scrollbarColor: "rgba(0, 48, 135, 1) white",
                    "&::-webkit-scrollbar": {
                      width: 3,
                      backgroundColor: "white",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0, 48, 135, 1)",
                      borderRadius: 4,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "@media print": {
                      height: "100%",
                    },
                  }}
                >
                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody ref={tableRef}>
                      {tableData.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            borderBottom: "1px solid #ccc",
                          }}
                          id={row.questionId}
                        >
                          {row.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              style={{
                                textAlign: cellIndex === 0 ? "left" : "center",
                                padding: "8px 0px",
                                padding: "8px 0px",
                                width:
                                  cellIndex === 0
                                    ? "40%"
                                    : `${60 / (headings.length - 1)}%`,
                              }}
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </StyledEngineProvider>
  );
};

export default RenderCommonGrp;
