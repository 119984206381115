import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useTreeItem } from "@mui/x-tree-view/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import Button from "../Common/Button";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
}));

const surveys = [
  { id: 1001, name: "Survey 1" },
  { id: 1002, name: "Survey 2" },
  { id: 1003, name: "Survey 3" },
];

const RootTreeView = styled(TreeView)(({ theme }) => ({
  borderRadius: "14px",
  backgroundColor: "rgba(0, 48, 135, 0.15)",
  [`& .${treeItemClasses.group}`]: {
    paddingLeft: 18,
  },
}));

function SectionTreeItem(props) {
  const { setSubfolderDialogOpen, ...otherProps } = props;

  return (
    <TreeItem
      {...otherProps}
      ContentComponent={React.forwardRef((contentProps, ref) => (
        <SectionContent
          ref={ref}
          setSubfolderDialogOpen={setSubfolderDialogOpen}
          {...contentProps}
        />
      ))}
    />
  );
}

const SectionContent = React.forwardRef(function SectionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    setSubfolderDialogOpen,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const selecticon = <CropSquareIcon />;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    const isArrowClick = event.target.classList.contains("MuiSvgIcon-root");
    const isPlusIconClick = event.target.classList.contains("plusIcon");

    if (isPlusIconClick) {
      setSubfolderDialogOpen(true);
    } else if (isArrowClick) {
      handleExpansion(event);
      handleSelection(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        border: "1px solid rgba(0, 48, 135, 1)",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <AddCircleOutlineIcon
        className="plusIcon"
        fontSize="small"
        sx={{ marginRight: "15px" }}
      />
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function PageTreeItem(props) {
  return <TreeItem ContentComponent={PageContent} {...props} />;
}

const PageContent = React.forwardRef(function PageContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && {
          backgroundColor: "rgba(0, 48, 135, 0.15)",
          borderBottom: "1px solid rgba(0, 48, 135, 1)",
        }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function QuestionTreeItem(props) {
  return <TreeItem ContentComponent={QuestionContent} {...props} />;
}

const QuestionContent = React.forwardRef(function QuestionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const selecticon = <CropSquareIcon />;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        padding: "8px 0",
        ...(selected && {
          color: "#1b1ba7",
          backgroundColor: "#9dafd1",
          borderRadius: "7px",
        }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography variant="label" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

// -------------------------------------------------------
function PageTreeItems({ years }) {
  return (
    <>
      {years.map((year, index) => (
        <PageTreeItem
          // onClick={() => {
          //   // dispatch(setDataIds({ secId: section.id, pgId: year.id }));
          //   // dispatch(setShowLanding(false));
          // }}
          key={year.id}
          selecticon={<CropSquareIcon />}
          nodeId={year.id.toString()}
          label={`Year- ${year.name}`}
          sx={{
            backgroundColor: "rgba(0, 48, 135, 0.15)",
            border: "1px solid rgba(0, 48, 135, 1)",
            borderRadius: "14px",
            [`& .${treeItemClasses.group}`]: {
              maxHeight: 200,
              overflowY: "auto",
              overflowX: "hidden",
              margin: "12px 12px 12px -5px",
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 48, 135, 1) white",
              "&::-webkit-scrollbar": {
                width: 3,
                backgroundColor: "white",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 48, 135, 1)",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            margin: "0px 10px 10px -18px",
          }}
        >
          <div>
            <QuestionTreeItems index={index} />
          </div>
        </PageTreeItem>
      ))}
    </>
  );
}

function QuestionTreeItems() {
  return (
    <>
      {surveys.map((survey, index) => (
        <a
          // href={`#${survey.id}`}
          style={{ textDecoration: "none", color: "inherit" }}
        >
          <QuestionTreeItem
            key={survey.id}
            selectIcon={<CropSquareIcon />}
            nodeId={`${index + 1}`}
            label={`AHA Survey- ${survey.name}`}
          />
        </a>
      ))}
    </>
  );
}

function ExplorerTree() {
  const [expanded, setExpanded] = useState([]);
  const [newFolderName, setNewFolderName] = useState("");
  const [folders, setFolders] = useState([
    { id: 101, name: "A" },
    { id: 102, name: "B" },
    { id: 103, name: "C" },
  ]);
  const [subfolders, setSubFolders] = useState([
    { id: 100, name: "2010/14" },
    { id: 200, name: "2014/18" },
    { id: 300, name: "2018/21" },
  ]);
  const [isFolderDialogOpen, setFolderDialogOpen] = useState(false);
  const [isSubfolderDialogOpen, setSubfolderDialogOpen] = useState(false);

  const handleCreateFolder = () => {
    if (newFolderName.trim() === "") {
      return;
    }

    const newFolderId = Date.now();

    const newFolder = { id: newFolderId, name: newFolderName };
    setFolders((prevFolders) => [...prevFolders, newFolder]);

    setFolderDialogOpen(false);

    setNewFolderName("");
  };

  const handleCreateSubfolder = () => {
    if (newFolderName.trim() === "") {
      return;
    }

    const newFolderId = Date.now();

    const newFolder = { id: newFolderId, name: newFolderName };
    setSubFolders((prevFolders) => [...prevFolders, newFolder]);

    setSubfolderDialogOpen(false);

    setNewFolderName("");
  };

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <Button
          startIcon={<CreateNewFolderIcon />}
          onClick={() => setFolderDialogOpen(true)}
        >
          Create Folder
        </Button>
        <BootstrapDialog
          open={isFolderDialogOpen}
          onClose={() => setFolderDialogOpen(false)}
        >
          <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
            <Typography variant="body1" fontWeight="bold">
              Create New Folder
            </Typography>
            <IconButton
              onClick={() => setFolderDialogOpen(false)}
              aria-label="close"
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <TextField
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value)}
              fullWidth
            />
            <div style={{ paddingTop: "20px" }}>
              <Button onClick={handleCreateFolder}>Create</Button>
            </div>
          </DialogContent>
        </BootstrapDialog>
      </div>
      {folders.map((folder, index) => (
        <RootTreeView
          aria-label="customized"
          expanded={expanded}
          onNodeToggle={handleNodeToggle}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          sx={{
            margin: "20px 0",
          }}
          key={folder.id}
        >
          <SectionTreeItem
            key={folder.id}
            setSubfolderDialogOpen={setSubfolderDialogOpen}
            selecticon={<SelectAllIcon />}
            nodeId={folder.id.toString()}
            label={`Folder- ${folder.name}`}
            sx={{
              border: "1px solid transparent",
              borderRadius: "14px",
              [`& .${treeItemClasses.group}`]: {
                maxHeight: 250,
                overflowY: "auto",
                overflowX: "hidden",
                margin: "12px 12px",
                scrollbarWidth: "thin",
                scrollbarColor: "rgba(0, 48, 135, 1) white",
                "&::-webkit-scrollbar": {
                  width: 3,
                  backgroundColor: "white",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "rgba(0, 48, 135, 1)",
                  borderRadius: 4,
                },
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "transparent",
                },
              },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            <PageTreeItems index={index} years={subfolders} />
          </SectionTreeItem>
        </RootTreeView>
      ))}
      <BootstrapDialog
        open={isSubfolderDialogOpen}
        onClose={() => setSubfolderDialogOpen(false)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography variant="body1" fontWeight="bold">
            Create Subfolder
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setSubfolderDialogOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
            fullWidth
          />
          <div style={{ paddingTop: "20px" }}>
            <Button onClick={handleCreateSubfolder}>Create</Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default ExplorerTree;
