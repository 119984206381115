import { React, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormHelperText,
  MenuItem,
  Button,
} from "@mui/material";

import { updateField } from "../../../Survey/Survey.service";
import { namePositionOptions } from "./AddQuesConstants";
import { setTriggerMatrixGrp } from "../../../Survey/Survey.slice";
const CheckboxAttributes = ({
  attrRow,
  handleCloseAttrView,
  handleFieldChangeWrapper,
  fieldTypeName,
}) => {
  const dispatch = useDispatch();
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const [errMsg, setErrMsg] = useState("");
  const [attrInfo, setAttrInfo] = useState({
    id: attrRow.row.id,
    historyFlag: attrRow.row.historyFlag,
    fillHistory: attrRow.row.fillHistory,
    name: attrRow.row.name,
    namePosition: attrRow.row.namePosition,
    fieldFlow: attrRow.row.fieldFlow,
    horizontalAlign: attrRow.row.horizontalAlign,
    userLabelnew: attrRow.row.userLabel,
    sequence: attrRow.row.sequence,
    index: attrRow.index,
    columnNumber: attrRow.row.columnNumber,
    isRequired: attrRow.row.isRequired,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setAttrInfo((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setAttrInfo((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async () => {
    setErrMsg("");
    if (attrInfo.userLabelnew) {
      dispatch(setTriggerMatrixGrp(false));
      const quesPayload = {
        question: { id: attrRow.qid },
        modifiedBy: userName,
        id: attrInfo.id,
        fieldType: {
          name: fieldTypeName,
        },
        columnNumber: attrInfo.columnNumber,
        userLabel: attrInfo.userLabelnew,
        name: attrInfo.name,
        namePosition: attrInfo.namePosition,
        historyFlag: attrInfo.historyFlag,
        fillHistory: attrInfo.fillHistory,
        fieldFlow: attrInfo.fieldFlow,
        horizontalAlign: attrInfo.horizontalAlign,
        sequence: attrInfo.sequence,
        isRequired: attrInfo.isRequired,
      };

      try {
        const response = await updateField(quesPayload);
        dispatch(setTriggerMatrixGrp(true));
        handleFieldChangeWrapper(response.data, attrRow.index);
      } catch (error) {
        console.log("Error updating field", error);
        return null;
      }
    } else {
      setErrMsg("Please enter user label");
    }
  };
  return (
    <div className="panelModal">
      <div className="panelDiv">
        <Typography variant="label">Field attributes information</Typography>
      </div>
      <div className="panelForm">
        <Typography variant="label" paragraph>
          <b>ANSWER LABEL & HEADING</b>
        </Typography>

        <Grid
          item
          xs={12}
          container
          sx={{ mb: 1 }}
          spacing={1}
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="userLabelnew"
                value={attrInfo["userLabelnew"]}
                onChange={handleChange}
                label="User Label *"
              />
            </FormControl>
            {errMsg && (
              <Typography color="error" variant="body1">
                {errMsg}
              </Typography>
            )}
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="name"
                variant="outlined"
                value={attrInfo["name"]}
                label="Heading"
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel className="inputLabel">Position</InputLabel>
              <Select
                size="small"
                name="namePosition"
                fullWidth
                value={attrInfo["namePosition"]}
                onChange={handleChange}
                label="Position"
              >
                {namePositionOptions.map((option, index) => (
                  <MenuItem key={`ftype${index}`} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Typography variant="label" sx={{ fontWeight: "bold" }}>
          ANSWER DISPLAY FORMAT
        </Typography>

        <Grid item xs={12} container spacing={0} alignItems="flex-start">
          <Grid item xs={4}>
            <RadioGroup
              row
              aria-label="field-flow"
              name="fieldFlow"
              value={attrInfo["fieldFlow"]}
              onChange={handleChange}
            >
              <FormHelperText
                sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
              >
                Insert New Line
              </FormHelperText>

              <FormControlLabel
                value="NL"
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value="SL"
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-label="horizontal-align"
              name="horizontalAlign"
              value={attrInfo["horizontalAlign"]}
              onChange={handleChange}
            >
              <FormHelperText
                sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
              >
                Alignment
              </FormHelperText>
              <FormControlLabel
                value="left"
                control={<Radio size="small" />}
                label="Left"
              />
              <FormControlLabel
                value="center"
                control={<Radio size="small" />}
                label="Center"
              />
              <FormControlLabel
                value="right"
                control={<Radio size="small" />}
                label="Right"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Typography variant="label" sx={{ fontWeight: "bold" }}>
          ADDITIONAL ANSWER OPTIONS
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={attrInfo["historyFlag"] === true}
                name="historyFlag"
                onChange={handleChange}
              />
            }
            label="Show History"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={attrInfo["fillHistory"]}
                name="fillHistory"
                onChange={handleChange}
              />
            }
            label="Allow Fill With Last Year's Data"
          />
        </Grid>
        <Typography variant="label" sx={{ fontWeight: "bold" }}>
          INPUT VALIDATION
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={attrInfo["isRequired"] === true}
                name="isRequired"
                onChange={handleChange}
              />
            }
            label="Is Required"
          />
        </Grid>
      </div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          marginLeft: "44%",
          marginBottom: "1%",
        }}
      >
        <Button variant="outlined" onClick={handleCloseAttrView}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleSave()}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default CheckboxAttributes;
