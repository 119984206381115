import React, { useState, useEffect } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  InputLabel,
  Box,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { orgAndChildOrgsByParentOrgId } from "../../pages/Survey/Survey.service";
import { setSelectedOrgs } from "../../pages/Survey/Survey.slice";

const AdminSidebar = () => {
  const dispatch = useDispatch();

  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );

  const [parentChildOrgList, setParentChildOrgList] = useState([]);
  const [selOrgId, setSelOrgId] = useState("");
  const [selOrgName, setSelOrgName] = useState("");

  useEffect(() => {
    const getOrgAndChildOrgsByParentOrgId = async () => {
      setParentChildOrgList([]);
      try {
        const res = await orgAndChildOrgsByParentOrgId(orgId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          setParentChildOrgList(res.data);
          setSelOrgId(res.data[0].id);
          setSelOrgName(res.data[0].name);
        }
      } catch (error) {
        console.error("Error fetching child Org:", error);
      }
    };
    if (orgId) {
      getOrgAndChildOrgsByParentOrgId();
    }
  }, [orgId]);

  useEffect(() => {
    if (selOrgId && selOrgName) {
      const orgEntry = { id: selOrgId, name: selOrgName };
      dispatch(setSelectedOrgs(orgEntry));
    }
  }, [dispatch, selOrgId, selOrgName]);

  const handleChange = (e) => {
    const { value } = e.target;
    const result = parentChildOrgList?.find((item) => item.id === value);
    setSelOrgId(value);
    setSelOrgName(result.name);
  };
  return (
    <>
      <div style={{ whiteSpace: "normal" }}>
        <Typography color="primary" variant="h5">
          Current list of organizations
        </Typography>
        <Box sx={{ margin: "20px 0" }}>
          {parentChildOrgList.length > 0 && (
            <>
              <FormControl fullWidth>
                <Select
                  value={selOrgId}
                  name="child_org_id"
                  onChange={handleChange}
                >
                  {parentChildOrgList.map((row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </Box>
        <Typography color="primary">
          To work with another organization, please select from the list above.
        </Typography>
      </div>
    </>
  );
};

export default AdminSidebar;
