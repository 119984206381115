import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import MenuItem from "@mui/material/MenuItem";
import Button from "../../../components/Common/Button";
import { formatShortDate, htmlParser } from "../../../Utility/constants";
import { Grid, Typography, FormControl } from "@mui/material";
import { inProgressBatches } from "../../Survey/Survey.service";
import {
  setImportCurrentView,
  setSelectedBatchId,
} from "../../Survey/Survey.slice";

const ImportProgress = () => {
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const location = useLocation();
  const surveyId = location?.state?.surveyId;
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchResult, setbatchResult] = useState([]);
  const dispatch = useDispatch();
  const surveyName = location?.state?.surveyName;
  const [loadingFile, setLoadingFile] = useState(true);

  const getInProgressBatches = async () => {
    try {
      const res = await inProgressBatches(surveyId);
      if (Array.isArray(res?.data) && res?.data?.length > 0) {
        const responseData = res?.data;
        setbatchResult(responseData);
        setSelectedBatch(responseData[0].id);
        setLoadingFile(false);
      }
    } catch (error) {
      console.error("Error fetching batch Information:", error);
    }
  };

  useEffect(() => {
    //Implementing the setInterval method
    const intervals = setInterval(() => {
      setLoadingFile(true);
      setbatchResult([]);
      setSelectedBatch(null);
      getInProgressBatches();
    }, 9000);

    //Clearing the interval
    return () => clearInterval(intervals);
  }, [personId]);

  useEffect(() => {
    const selectedBatchRecord = async () => {
      try {
        dispatch(setSelectedBatchId(selectedBatch));
      } catch (error) {
        console.error("Error fetching batch Information:", error);
      }
    };
    if (selectedBatch) {
      selectedBatchRecord();
    }
  }, [selectedBatch]);

  return (
    <>
      <Typography variant="label" color="primary" sx={{ marginBottom: "20px" }}>
        Select Batch
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        Functions: select a batch for viewing
      </Typography>
      <Typography variant="label" color="primary">
        Survey: {htmlParser(surveyName)}
      </Typography>
      <div>
        {loadingFile && (
          <Typography variant="subtitle2" color="info">
            Refreshing Batch Summary...
          </Typography>
        )}
        {!loadingFile && batchResult?.length > 0 && (
          <>
            <Grid
              container
              spacing={2}
              sx={{ marginTop: "2px", marginBottom: "10px" }}
            >
              <Grid item xs={8}>
                <FormControl sx={{ minWidth: 200 }}>
                  <Select
                    value={selectedBatch}
                    onChange={(e) => setSelectedBatch(e.target.value)}
                  >
                    {batchResult.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        Batch ID:{data.id} {formatShortDate(data.dateCreated)} (
                        {data.recordCount} records)
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "30px",
              }}
            >
              <Button
                variant="contained"
                component="label"
                style={{ marginRight: "20px" }}
                onClick={() => dispatch(setImportCurrentView(8))}
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => dispatch(setImportCurrentView(1))}
              >
                Back
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ImportProgress;
