import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";

const ResponseOptions = ({
  table,
  selectedField,
  importClicked,
  refreshFieldVals,
  handleCheckboxChange,
  handleFileChange,
  handleFileUpload,
  handleAddRes,
  handleImportClick,
}) => (
  <Grid item xs={12}>
    {(selectedField === "Radio Field" ||
      selectedField === "CheckBox Field" ||
      selectedField === "Combobox Field") && (
      <Card>
        <CardContent>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography variant="label" sx={{ fontWeight: "bold" }}>
              {importClicked ? "IMPORT" : "RESPONSES"}
            </Typography>
          </Box>
          {importClicked ? (
            <>
              <FormControlLabel
                control={<Checkbox />}
                checked={refreshFieldVals}
                onChange={handleCheckboxChange}
                label="Check to refresh the current field values. Otherwise the values will be appended."
              />
              <Button variant="outlined" component="label">
                Choose File
                <input
                  type="file"
                  hidden
                  accept=".txt"
                  onChange={(e) => handleFileChange(e)}
                />
              </Button>
            </>
          ) : (
            <MaterialReactTable table={table} />
          )}
          {importClicked ? (
            <Box sx={{ marginTop: "15px" }}>
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                onClick={handleImportClick}
              >
                Back
              </Button>
              <Button variant="contained" onClick={handleFileUpload}>
                Submit
              </Button>
            </Box>
          ) : (
            <Box sx={{ marginTop: "15px" }}>
              <Button
                sx={{ marginRight: "15px" }}
                variant="contained"
                onClick={handleAddRes}
              >
                Add Response
              </Button>
              <Button variant="contained" onClick={handleImportClick}>
                Import Responses
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    )}
  </Grid>
);

export default ResponseOptions;
