import { Button, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Tooltip from "../../../components/Common/Tooltip";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import theme from "../../../theme";
import { deletePage, updatePageOrder } from "../../Survey/Survey.service";
import {
  setSelectedPage,
  setSurveyBuilderCurrentView,
  triggerSidebarRefresh,
} from "../../Survey/Survey.slice";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";
import MuiAlert from "@mui/material/Alert";
import SurveyActionButtons from "./SurveyActionButtons";
import { getTextFromHTML, htmlParser } from "../../../Utility/constants";

const EditSection = () => {
  const [data, setData] = useState([]);
  const [secName, setSecName] = useState(null);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 0 });
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmPageId, setConfirmPageId] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const dispatch = useDispatch();
  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );
  const selectedSecName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec?.section?.name
  );

  const selectedSecPages = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec?.pages
  );
  const surveyData = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData
  );

  useEffect(() => {
    setSecName(selectedSecName);
  }, [selectedSecName, surveyData]);

  useEffect(() => {
    if (selectedSecPages?.length > 0) {
      const pagesInSec = selectedSecPages?.map((page) => ({
        id: page?.page?.id,
        pageName: getTextFromHTML(page?.page?.name),
      }));
      setData(pagesInSec);
      setPagination({ pageIndex: 0, pageSize: pagesInSec?.length });
    } else setData([]);
  }, [selectedSecPages]);

  const handlePageDelete = async () => {
    try {
      const response = await deletePage(confirmPageId);
      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Page deleted successfully");
        setAlertSeverity("success");
        setConfirmTitle("");
        setConfirmPageId("");
        setConfirmOpen(false);
        dispatch(triggerSidebarRefresh());
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting page");
        setAlertSeverity("error");
        setConfirmTitle("");
        setConfirmPageId("");
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error deleting page", error);
      return null;
    }
  };

  const openDeleteSurveyData = async (secId, name) => {
    setConfirmTitle("Deleting " + name + " - Press OK to proceed.");
    setConfirmPageId(secId);
    setConfirmOpen(true);
  };

  const handleOperation = (source, pageId, secPages) => {
    const selectedPage = secPages?.find((obj) => obj.page.id === pageId);
    dispatch(setSelectedPage(selectedPage));
    dispatch(
      setSurveyBuilderCurrentView({
        viewIndex: 6,
        source: source,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "operations",
        header: "Operations",
        size: 50,
        Cell: ({ row }) => (
          <>
            <Tooltip title="Page info edit" arrow>
              <NoteAltOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.green.main,
                  background: theme.palette.green.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "10px",
                }}
                onClick={() =>
                  handleOperation(
                    "editPageIcon",
                    row?.original?.id,
                    selectedSecPages
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Copy this page" arrow>
              <ContentCopyOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.blue.main,
                  background: theme.palette.blue.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "10px",
                }}
              />
            </Tooltip>
            <Tooltip title="Delete this page" arrow>
              <CloseOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.error.main,
                  background: theme.palette.error.light,
                  borderRadius: "10px",
                  padding: "10px",
                }}
                onClick={() => {
                  openDeleteSurveyData(
                    row?.original?.id,
                    row?.original?.pageName
                  );
                }}
              />
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "pageName",
        header: "Page",
      },
    ],
    [selectedSecPages]
  );

  const updatePgOrder = async (newPgOrder) => {
    try {
      await updatePageOrder(newPgOrder);
    } catch (error) {
      console.error("Error updating page order", error);
    } finally {
      dispatch(triggerSidebarRefresh());
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    state: { pagination },
    enableColumnActions: false,
    initialState: { density: "compact" },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );

          if (draggingRow.index !== hoveredRow.index) {
            setData([...data]);
            const newPgOrder = {
              idSequenceList: data.map((item, index) => ({
                id: item.id,
                sequence: index,
              })),
            };

            updatePgOrder(newPgOrder);
          }
        }
      },
    }),
  });
  const buttonStyle = {
    marginRight: "20px",
    marginBottom: "15px",
  };

  return (
    <>
      <div style={{ marginBottom: "15px" }}>
        <div>
          <Typography variant="label" color="primary">
            {htmlParser(surveyName)}
          </Typography>
        </div>
        <div>
          <Typography variant="label" color="primary">
            {htmlParser(secName)}
          </Typography>
        </div>
      </div>
      <SurveyActionButtons page="Section" />
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label">
          Pages in Section. Available operations: reorder, edit, copy, and
          delete page
        </Typography>
      </div>
      <MaterialReactTable table={table} />
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handlePageDelete}
        pageName="ModifySurvey"
      ></ConfirmDialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default EditSection;
