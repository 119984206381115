import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import { TextField, Typography, Grid, InputLabel } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "../../components/Common/Button";
import { useSelector } from "react-redux";
import theme from "../../theme";
import { formatDate } from "../../Utility/constants";
import { forceUpdateSurveyFilingStatus } from "../Survey/Survey.service";

const ErrorList = ({ errorMsg, color }) => {
  const listItems = errorMsg.map((error, index) => (
    <li style={{ color: color }} key={index}>
      {error}
    </li>
  ));
  return <ul>{listItems}</ul>;
};
const UpdateSurveyStatus = ({ handleCloseSurveyAdmin }) => {
  const selectedPanelMemberData = useSelector(
    (state) => state?.survey?.selectedPanelMemberData
  );
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const [formData, setFormData] = useState();
  const [errorMsg, setErrorMsg] = useState([]);

  // Modified date and created by may be blank -
  // When you force update the status, it sets two different fields on the
  // survey filing: ownerOrgModifiedBy and ownerOrgModifiedDate.
  // Those are different fields that modifiedBy and modifiedDate.

  useEffect(() => {
    if (Object.keys(selectedPanelMemberData).length > 0) {
      const surveyAdminData = {
        surveyFilingId: selectedPanelMemberData.surveyFilingId,
        name: selectedPanelMemberData.surveyRespondent.surveyOrganization.name,
        surveyFilingStatus: selectedPanelMemberData.surveyFilingStatus,
        createdBy: selectedPanelMemberData?.surveyFilingCreatedBy || null,
        dateCreated: selectedPanelMemberData?.surveyFilingDateCreated || null,
        dateModified: selectedPanelMemberData?.surveyFilingDateModified || null,
        modifiedBy: selectedPanelMemberData?.surveyFilingModifiedBy || null,
        reason: null,
        statusName: null,
      };
      setFormData(surveyAdminData);
    }
  }, [selectedPanelMemberData]);

  const handleSave = async (e) => {
    if (!formData.reason || !formData.statusName) {
      let errorMessage = [];
      if (!formData.statusName)
        errorMessage.push("Please select a new survey status");
      if (!formData.reason)
        errorMessage.push("Please enter a reason for the status change");

      setErrorMsg(errorMessage);
    } else {
      setErrorMsg([]);
      const payload = {
        surveyFilingId: formData.surveyFilingId,
        statusName: formData.statusName,
        reason: formData.reason,
        modifiedBy: userName,
      };
      forceUpdateSurveyFilingStatus(payload)
        .then(() => {
          handleCloseSurveyAdmin();
        })
        .catch((error) => {
          console.error("Error Updating Survey Status", error);
          handleCloseSurveyAdmin();
        });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      {!!formData && (
        <div className="panelModal">
          <div className="panelDiv">
            <Typography variant="label">
              Survey Administration for {formData.name}
            </Typography>
          </div>
          <div className="panelForm">
            <form
              style={{ display: "flex", flexDirection: "column", gap: "15px" }}
            >
              <Typography
                variant="h6"
                color="primary"
                style={{ fontWeight: "bold" }}
              >
                Please use the dropdown below to change the status of this
                survey.
              </Typography>

              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">CURRENT SURVEY STATUS:</Typography>
                </Grid>
                <Grid item xs={8}>
                  {formData.surveyFilingStatus}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">NEW SURVEY STATUS *:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl variant="outlined" sx={{ minWidth: "210px" }}>
                    <InputLabel className="inputLabel">
                      NEW SURVEY STATUS
                    </InputLabel>
                    <Select
                      defaultValue=""
                      size="small"
                      label="NEW SURVEY STATUS"
                      name="statusName"
                      onChange={handleChange}
                    >
                      <MenuItem key="1" value="Completed">
                        Completed
                      </MenuItem>
                      <MenuItem key="2" value="Submitted">
                        Submitted
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">
                    REASON FOR STATUS CHANGE *:
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    name="reason"
                    multiline
                    rows={5}
                    onChange={handleChange}
                  />
                </Grid>

                <Grid item xs={true}>
                  {errorMsg.length > 0 && (
                    <ErrorList
                      errorMsg={errorMsg}
                      color={theme.palette.error.main}
                    />
                  )}
                </Grid>
              </Grid>

              <Divider color={theme.palette.blue.main} />
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">DATE SURVEY STARTED:</Typography>
                </Grid>
                <Grid item xs={8}>
                  {formData.dateCreated ? formatDate(formData.dateCreated) : ""}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">STARTED BY:</Typography>
                </Grid>
                <Grid item xs={8}>
                  {formData.createdBy ? formData.createdBy : ""}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">DATE LAST MODIFIED :</Typography>
                </Grid>
                <Grid item xs={8}>
                  {formData.dateModified
                    ? formatDate(formData.dateModified)
                    : ""}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  <Typography variant="h6">LAST MODIFIED BY:</Typography>
                </Grid>
                <Grid item xs={8}>
                  {formData.modifiedBy ? formData.modifiedBy : ""}
                </Grid>
              </Grid>
              <Divider color={theme.palette.blue.main} />

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: "15px" }}>
                  <Button onClick={handleSave}>Save</Button>
                </div>
                <div
                  style={{
                    marginLeft: "15px",
                  }}
                >
                  <Button variant="outlined" onClick={handleCloseSurveyAdmin}>
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateSurveyStatus;
