import { React, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  Grid,
  Box,
  Modal,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
} from "@mui/material";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import {
  getPanelMembers,
  getPersonDisplaySettings,
  setPersonDisplaySettings,
  responseReportRecords,
  responseReportPDF,
  getDataFilters,
} from "../Survey/Survey.service";
import {
  replaceCommaChar,
  htmlParser,
  formatDate,
} from "../../Utility/constants";
import { setFilterFormData } from "../Survey/Survey.slice";
import Button from "../../components/Common/Button";
import FilterForm from "./FilterForm";
import { initialFormData, filterEmptyFormData } from "./FilterForm";
import LoadingText from "../../components/Common/LoadingText";

const ResponseReport = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState("none");
  const [filterList, setFilterList] = useState([]);
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;

  const selectedPanelId = useSelector(
    (state) => state?.survey?.selectedPanelId
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState([]);
  const [sortAscending, setSortAscending] = useState(null);
  const [sortBy, setSortBy] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const [panelMembers, setPanelMembers] = useState([]);

  const [columnVisibility, setColumnVisibility] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [showLoading, setShowLoading] = useState(true);
  const [loadingExport, setLoadingExport] = useState(false);
  const [loadingPdfExport, setLoadingPdfExport] = useState(false);
  const [loadingDataExport, setLoadingDataExport] = useState(false);

  const initialColumns = {
    custom_id: true,
    cname: false,
    title: false,
    username: false,
    address1: false,
    address2: false,
    city: false,
    county: false,
    stateCode: false,
    zip_code: false,
    zip_code_ext: false,
    phone_number: false,
    fax_number: false,
    email: false,
    surveyFilingStatus: false,
    attribute1: false,
    attribute2: false,
    attribute3: false,
    attribute4: false,
    attribute5: false,
    attribute6: false,
    attribute7: false,
    attribute8: false,
    attribute9: false,
    attribute10: false,
    attribute11: false,
    attribute12: false,
    attribute13: false,
    attribute14: false,
    attribute15: false,
    attribute16: false,
    attribute17: false,
    attribute18: false,
    attribute19: false,
    attribute20: false,

    sr_custom_id: false,
    orgName: false,
    oaddress1: false,
    oaddress2: false,
    ocity: false,
    ocounty: false,
    ostateCode: false,
    ozip_code: false,
    ozip_code_ext: false,
    ophone_number: false,
    ofax_number: false,
    ourl: false,
    oregion: false,
    ourban_flag: false,
    oattribute1: false,
    oattribute2: false,
    oattribute3: false,
    oattribute4: false,
    oattribute5: false,
    oattribute6: false,
    oattribute7: false,
    oattribute8: false,
    oattribute9: false,
    oattribute10: false,
    oattribute11: false,
    oattribute12: false,
    oattribute13: false,
    oattribute14: false,
    oattribute15: false,
    oattribute16: false,
    oattribute17: false,
    oattribute18: false,
    oattribute19: false,
    oattribute20: false,
    surveyFilingDateCreated: false,
    surveyFilingDateModified: false,
  };
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const filterFormData = useSelector((state) => state?.survey?.filterFormData);

  const handleFilterChange = (event) => {
    setSelectedFilters(event.target.value);
  };

  const processFilters = () => {
    const selectedFormData = {};

    Object.keys(filterFormData).forEach((key) => {
      if (Array.isArray(filterFormData[key])) {
        if (filterFormData[key].length > 0) {
          selectedFormData[key] = filterFormData[key].join(",");
        }
      } else if (filterFormData[key] !== "" && filterFormData[key] !== false) {
        selectedFormData[key] = filterFormData[key];
      }
    });

    return selectedFormData;
  };
  const fetchPanelAssignmentInfo = async () => {
    setLoadingDataExport(false);
    setPanelMembers([]);
    let payload = {
      surveyPanelId: selectedPanelId,
      pageNumber: pagination.pageIndex + 1,
      numberPerPage: pagination.pageSize,
      sortBy: sortBy,
      sortAscending: sortAscending,
      filterCriteria: processFilters(),
      surveyId: surveyId,
    };
    const dataFilters =
      selectedFilters === "none" ? {} : { dataFilterId: selectedFilters };
    payload = { ...payload, ...dataFilters };
    try {
      setLoadingDataExport(true);
      const res = await getPanelMembers(payload);
      if (res?.data) {
        setPanelMembers(res?.data?.panelMemberAssignments);
        setRowCount(res?.data?.totalNumber);
        setShowLoading(false);
      }
      setLoadingDataExport(false);
    } catch (error) {
      setLoadingDataExport(false);

      console.error("Error fetching panel members:", error);
    }
  };

  useEffect(() => {
    const getDisplaySettings = async () => {
      try {
        const res = await getPersonDisplaySettings({
          personId: personId,
          page: "ResponseReport",
        });
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const fetchedColumnObj = res.data
            .map((item) => {
              if (item?.type === "column") {
                return item?.name;
              } else return null;
            })
            .reduce((obj, columnName) => {
              if (columnName) {
                obj[columnName] = true;
              }
              return obj;
            }, {});

          for (const key in initialColumns) {
            if (!fetchedColumnObj.hasOwnProperty(key)) {
              fetchedColumnObj[key] = false;
            }
          }
          fetchedColumnObj["custom_id"] = true;
          const fetchedFilterObj = await res.data.reduce((obj, item) => {
            if (item.type === "filter") {
              if (
                [
                  "survey_filing.status_id",
                  "survey_respondent.role_id",
                  "survey_organization.state_id",
                  "survey_respondent.state_id",
                ].includes(item.name)
              ) {
                obj[item.name] = item.value.split(",").map((v) => parseInt(v));
              } else {
                obj[item.name] = item.value;
              }
            }
            return obj;
          }, {});

          setColumnVisibility(fetchedColumnObj);
          if (
            JSON.stringify(fetchedFilterObj) ===
              JSON.stringify(initialFormData) ||
            JSON.stringify(fetchedFilterObj) === JSON.stringify({})
          ) {
            dispatch(setFilterFormData(filterEmptyFormData(initialFormData)));
          } else dispatch(setFilterFormData(fetchedFilterObj));
        } else setColumnVisibility(initialColumns);
      } catch (error) {
        console.error("Error fetching person display settings:", error);
      }
    };
    if (personId) {
      getDisplaySettings();
    }
  }, [personId]);

  useEffect(() => {
    const fetchDataFilters = async () => {
      setFilterList([]);
      try {
        const response = await getDataFilters(surveyId);
        setFilterList(response?.data || []);
      } catch (error) {
        console.error("Error fetching Data Filters:", error);
      }
    };

    if (surveyId) {
      fetchDataFilters();
    }
  }, [surveyId]);

  useEffect(() => {
    const setColumns = Object.entries(columnVisibility)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    const setDisplaySettings = async () => {
      try {
        await setPersonDisplaySettings({
          personId: personId,
          page: "ResponseReport",
          columns: setColumns,
          filterCriteria: processFilters(),
          createdBy: userName,
        });
      } catch (error) {
        console.error("Error setting person column settings:", error);
      }
    };
    if (Object.keys(columnVisibility).length !== 0) {
      setDisplaySettings();
    }
  }, [columnVisibility, filterFormData]);
  const data = useMemo(() => {
    return panelMembers?.map((member) => {
      return {
        custom_id: member?.srCustomId,
        cname: member?.surveyRespondent?.name,
        title: member?.surveyRespondent?.title,
        username: member?.surveyRespondent?.username,
        address1: member?.surveyRespondent?.address1,
        address2: member?.surveyRespondent?.address2,
        city: member?.surveyRespondent?.city,
        county: member?.surveyRespondent?.county,
        stateCode: member?.surveyRespondent?.stateCode?.shortName,
        zip_code: member?.surveyRespondent?.zip_code,
        zip_code_ext: member?.surveyRespondent?.zip_code_ext,
        phone_number: member?.surveyRespondent?.phone_number,
        fax_number: member?.surveyRespondent?.fax_number,
        email: member?.surveyRespondent?.email,
        surveyFilingStatus: member?.surveyFilingStatus,
        attribute1: member?.surveyRespondent?.attribute1,
        attribute2: member?.surveyRespondent?.attribute2,
        attribute3: member?.surveyRespondent?.attribute3,
        attribute4: member?.surveyRespondent?.attribute4,
        attribute5: member?.surveyRespondent?.attribute5,
        attribute6: member?.surveyRespondent?.attribute6,
        attribute7: member?.surveyRespondent?.attribute7,
        attribute8: member?.surveyRespondent?.attribute8,
        attribute9: member?.surveyRespondent?.attribute9,
        attribute10: member?.surveyRespondent?.attribute10,
        attribute11: member?.surveyRespondent?.attribute11,
        attribute12: member?.surveyRespondent?.attribute12,
        attribute13: member?.surveyRespondent?.attribute13,
        attribute14: member?.surveyRespondent?.attribute14,
        attribute15: member?.surveyRespondent?.attribute15,
        attribute16: member?.surveyRespondent?.attribute16,
        attribute17: member?.surveyRespondent?.attribute17,
        attribute18: member?.surveyRespondent?.attribute18,
        attribute19: member?.surveyRespondent?.attribute19,
        attribute20: member?.surveyRespondent?.attribute20,

        sr_custom_id: member?.surveyRespondent?.surveyOrganization?.customId,
        orgName: member?.surveyRespondent?.surveyOrganization?.name,
        oaddress1: member?.surveyRespondent?.surveyOrganization?.address1,
        oaddress2: member?.surveyRespondent?.surveyOrganization?.address2,
        ocity: member?.surveyRespondent?.surveyOrganization?.city,
        ocounty: member?.surveyRespondent?.surveyOrganization?.county,
        ostateCode:
          member?.surveyRespondent?.surveyOrganization?.stateCode?.shortName,
        ozip_code: member?.surveyRespondent?.surveyOrganization?.zip_code,
        ozip_code_ext:
          member?.surveyRespondent?.surveyOrganization?.zip_code_ext,
        ophone_number:
          member?.surveyRespondent?.surveyOrganization?.phone_number,
        ofax_number: member?.surveyRespondent?.surveyOrganization?.fax_number,
        ourl: member?.surveyRespondent?.surveyOrganization?.url,
        oregion: member?.surveyRespondent?.surveyOrganization?.region,
        ourban_flag: member?.surveyRespondent?.surveyOrganization?.urbanFlag
          ? "Urban"
          : "Rural",

        oattribute1: member?.surveyRespondent?.surveyOrganization?.attribute1,
        oattribute2: member?.surveyRespondent?.surveyOrganization?.attribute2,
        oattribute3: member?.surveyRespondent?.surveyOrganization?.attribute3,
        oattribute4: member?.surveyRespondent?.surveyOrganization?.attribute4,
        oattribute5: member?.surveyRespondent?.surveyOrganization?.attribute5,
        oattribute6: member?.surveyRespondent?.surveyOrganization?.attribute6,
        oattribute7: member?.surveyRespondent?.surveyOrganization?.attribute7,
        oattribute8: member?.surveyRespondent?.surveyOrganization?.attribute8,
        oattribute9: member?.surveyRespondent?.surveyOrganization?.attribute9,
        oattribute10: member?.surveyRespondent?.surveyOrganization?.attribute10,
        oattribute11: member?.surveyRespondent?.surveyOrganization?.attribute11,
        oattribute12: member?.surveyRespondent?.surveyOrganization?.attribute12,
        oattribute13: member?.surveyRespondent?.surveyOrganization?.attribute13,
        oattribute14: member?.surveyRespondent?.surveyOrganization?.attribute14,
        oattribute15: member?.surveyRespondent?.surveyOrganization?.attribute15,
        oattribute16: member?.surveyRespondent?.surveyOrganization?.attribute16,
        oattribute17: member?.surveyRespondent?.surveyOrganization?.attribute17,
        oattribute18: member?.surveyRespondent?.surveyOrganization?.attribute18,
        oattribute19: member?.surveyRespondent?.surveyOrganization?.attribute19,
        oattribute20: member?.surveyRespondent?.surveyOrganization?.attribute20,
        surveyFilingDateCreated: member?.surveyFilingDateCreated
          ? formatDate(member?.surveyFilingDateCreated)
          : "",
        surveyFilingDateModified: member?.surveyFilingDateModified
          ? formatDate(member?.surveyFilingDateModified)
          : "",
      };
    });
  }, [panelMembers]);

  const columns = useMemo(() => [
    {
      columns: [
        {
          accessorKey: "custom_id",
          header: "Custom Id",
          enableHiding: false,
        },
        {
          accessorKey: "cname",
          header: "Name",
        },
        {
          accessorKey: "title",
          header: "Title",
        },
        {
          accessorKey: "username",
          header: "Username",
        },
        {
          accessorKey: "address1",
          header: "Address1",
        },
        {
          accessorKey: "address2",
          header: "Address2",
        },
        {
          accessorKey: "city",
          header: "City",
        },
        {
          accessorKey: "county",
          header: "County",
        },
        {
          accessorKey: "stateCode",
          header: "State",
        },
        {
          accessorKey: "zip_code",
          header: "Zip",
        },
        {
          accessorKey: "zip_code_ext",
          header: "Zip Ext.",
        },
        {
          accessorKey: "phone_number",
          header: "Telephone",
        },
        {
          accessorKey: "fax_number",
          header: "Fax",
        },
        {
          accessorKey: "email",
          header: "Email",
        },
        {
          accessorKey: "surveyFilingStatus",
          header: "Survey Filing Status",
        },
        {
          accessorKey: "attribute1",
          header: "Attribute1",
        },
        {
          accessorKey: "attribute2",
          header: "Attribute2",
        },
        {
          accessorKey: "attribute3",
          header: "Attribute3",
        },
        {
          accessorKey: "attribute4",
          header: "Attribute4",
        },
        {
          accessorKey: "attribute5",
          header: "Attribute5",
        },
        {
          accessorKey: "attribute6",
          header: "Attribute6",
        },
        {
          accessorKey: "attribute7",
          header: "Attribute7",
        },
        {
          accessorKey: "attribute8",
          header: "Attribute8",
        },
        {
          accessorKey: "attribute9",
          header: "Attribute9",
        },
        {
          accessorKey: "attribute10",
          header: "Attribute10",
        },
        {
          accessorKey: "attribute11",
          header: "Attribute11",
        },
        {
          accessorKey: "attribute12",
          header: "Attribute12",
        },
        {
          accessorKey: "attribute13",
          header: "Attribute13",
        },
        {
          accessorKey: "attribute14",
          header: "Attribute14",
        },
        {
          accessorKey: "attribute15",
          header: "Attribute15",
        },
        {
          accessorKey: "attribute16",
          header: "Attribute16",
        },
        {
          accessorKey: "attribute17",
          header: "Attribute17",
        },
        {
          accessorKey: "attribute18",
          header: "Attribute18",
        },
        {
          accessorKey: "attribute19",
          header: "Attribute19",
        },
        {
          accessorKey: "attribute20",
          header: "Attribute20",
        },
      ],
      header: "Respondent Information",
    },
    {
      columns: [
        {
          accessorKey: "sr_custom_id",
          header: "Custom Id",
        },
        {
          accessorKey: "orgName",
          header: "Org. Name",
        },
        {
          accessorKey: "oaddress1",
          header: "Address1",
        },
        {
          accessorKey: "oaddress2",
          header: "Address2",
        },
        {
          accessorKey: "ocity",
          header: "City",
        },
        {
          accessorKey: "ocounty",
          header: "County",
        },
        {
          accessorKey: "ostateCode",
          header: "State",
        },
        {
          accessorKey: "ozip_code",
          header: "Zip",
        },
        {
          accessorKey: "ozip_code_ext",
          header: "Zip Ext.",
        },
        {
          accessorKey: "ophone_number",
          header: "Telephone",
        },
        {
          accessorKey: "ofax_number",
          header: "Fax",
        },
        {
          accessorKey: "ourl",
          header: "Website URL",
        },
        {
          accessorKey: "oregion",
          header: "Region",
        },
        {
          accessorKey: "ourban_flag",
          header: "Location",
        },
        {
          accessorKey: "oattribute1",
          header: "Attribute1",
        },
        {
          accessorKey: "oattribute2",
          header: "Attribute2",
        },
        {
          accessorKey: "oattribute3",
          header: "Attribute3",
        },
        {
          accessorKey: "oattribute4",
          header: "Attribute4",
        },
        {
          accessorKey: "oattribute5",
          header: "Attribute5",
        },
        {
          accessorKey: "oattribute6",
          header: "Attribute6",
        },
        {
          accessorKey: "oattribute7",
          header: "Attribute7",
        },
        {
          accessorKey: "oattribute8",
          header: "Attribute8",
        },
        {
          accessorKey: "oattribute9",
          header: "Attribute9",
        },
        {
          accessorKey: "oattribute10",
          header: "Attribute10",
        },
        {
          accessorKey: "oattribute11",
          header: "Attribute11",
        },
        {
          accessorKey: "oattribute12",
          header: "Attribute12",
        },
        {
          accessorKey: "oattribute13",
          header: "Attribute13",
        },
        {
          accessorKey: "oattribute14",
          header: "Attribute14",
        },
        {
          accessorKey: "oattribute15",
          header: "Attribute15",
        },
        {
          accessorKey: "oattribute16",
          header: "Attribute16",
        },
        {
          accessorKey: "oattribute17",
          header: "Attribute17",
        },
        {
          accessorKey: "oattribute18",
          header: "Attribute18",
        },
        {
          accessorKey: "oattribute19",
          header: "Attribute19",
        },
        {
          accessorKey: "oattribute20",
          header: "Attribute20",
        },
        {
          accessorKey: "surveyFilingDateCreated",
          header: "Survey Filing Created Date",
        },
        {
          accessorKey: "surveyFilingDateModified",
          header: "Survey Filing Modify Date",
        },
      ],
      header: "Organization Information",
    },
  ]);
  const handlePDFData = async () => {
    setLoadingPdfExport(true);
    let payload = {
      surveyId: surveyId,
      surveyPanelId: selectedPanelId,
      personId: personId,
      sortBy: sortBy,
      sortAscending: sortAscending,
      displaySettings: null,
    };
    const dataFilters =
      selectedFilters === "none" ? {} : { dataFilterId: selectedFilters };
    payload = { ...payload, ...dataFilters };

    try {
      const res = await responseReportPDF(payload);
      if (res?.status === 200) {
        const response = res?.data;
        var blob = new Blob([response], { type: "application/pdf" });
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "ResponseReport" + ".pdf";
        link.click();

        setLoadingPdfExport(false);
      }
    } catch (error) {
      console.error("Error fetching all panel members:", error);
    }

    setLoadingPdfExport(false);
  };
  const handleExportData = async () => {
    setLoadingExport(true);
    let payload = {
      surveyId: surveyId,
      surveyPanelId: selectedPanelId,
      personId: personId,
      sortBy: sortBy,
      sortAscending: sortAscending,
      displaySettings: null,
    };
    const dataFilters =
      selectedFilters === "none" ? {} : { dataFilterId: selectedFilters };
    payload = { ...payload, ...dataFilters };
    try {
      const res = await responseReportRecords(payload);
      if (res?.status === 200 && res?.data.length > 0) {
        const responseData = res?.data;
        let headerRow = "";
        headerRow = `${headerRow}Respondent Custom Id,`;
        if (columnVisibility.cname) {
          headerRow = `${headerRow}Respondent Name,`;
        }
        if (columnVisibility.title) headerRow = `${headerRow}Respondent Title,`;
        if (columnVisibility.username)
          headerRow = `${headerRow}Respondent Username,`;
        if (columnVisibility.address1)
          headerRow = `${headerRow}Respondent Address1,`;
        if (columnVisibility.address2)
          headerRow = `${headerRow}Respondent Address2,`;
        if (columnVisibility.city) headerRow = `${headerRow}Respondent City,`;
        if (columnVisibility.county)
          headerRow = `${headerRow}Respondent County,`;
        if (columnVisibility.stateCode) {
          headerRow = `${headerRow}Respondent State,`;
        }
        if (columnVisibility.zip_code)
          headerRow = `${headerRow}Respondent Zip,`;
        if (columnVisibility.zip_code_ext)
          headerRow = `${headerRow}Respondent Zip Ext.,`;
        if (columnVisibility.phone_number)
          headerRow = `${headerRow}Respondent Telephone,`;
        if (columnVisibility.fax_number)
          headerRow = `${headerRow}Respondent Fax,`;
        if (columnVisibility.email) headerRow = `${headerRow}Respondent Email,`;
        if (columnVisibility.surveyFilingStatus)
          headerRow = `${headerRow}Survey Filing Status,`;
        if (columnVisibility.attribute1)
          headerRow = `${headerRow}Respondent Attribute1,`;
        if (columnVisibility.attribute2)
          headerRow = `${headerRow}Respondent Attribute2,`;
        if (columnVisibility.attribute3)
          headerRow = `${headerRow}Respondent Attribute3,`;
        if (columnVisibility.attribute4)
          headerRow = `${headerRow}Respondent Attribute4,`;
        if (columnVisibility.attribute5)
          headerRow = `${headerRow}Respondent Attribute5,`;
        if (columnVisibility.attribute6)
          headerRow = `${headerRow}Respondent Attribute6,`;
        if (columnVisibility.attribute7)
          headerRow = `${headerRow}Respondent Attribute7,`;
        if (columnVisibility.attribute8)
          headerRow = `${headerRow}Respondent Attribute8,`;
        if (columnVisibility.attribute9)
          headerRow = `${headerRow}Respondent Attribute9,`;
        if (columnVisibility.attribute10)
          headerRow = `${headerRow}Respondent Attribute10,`;
        if (columnVisibility.attribute11)
          headerRow = `${headerRow}Respondent Attribute11,`;
        if (columnVisibility.attribute12)
          headerRow = `${headerRow}Respondent Attribute12,`;
        if (columnVisibility.attribute13)
          headerRow = `${headerRow}Respondent Attribute13,`;
        if (columnVisibility.attribute14)
          headerRow = `${headerRow}Respondent Attribute14,`;
        if (columnVisibility.attribute15)
          headerRow = `${headerRow}Respondent Attribute15,`;
        if (columnVisibility.attribute16)
          headerRow = `${headerRow}Respondent Attribute16,`;
        if (columnVisibility.attribute17)
          headerRow = `${headerRow}Respondent Attribute17,`;
        if (columnVisibility.attribute18)
          headerRow = `${headerRow}Respondent Attribute18,`;
        if (columnVisibility.attribute19)
          headerRow = `${headerRow}Respondent Attribute19,`;
        if (columnVisibility.attribute20)
          headerRow = `${headerRow}Respondent Attribute20,`;
        if (columnVisibility.sr_custom_id)
          headerRow = `${headerRow}Organization Custom Id,`;
        if (columnVisibility.orgName)
          headerRow = `${headerRow}Organization Org. Name,`;
        if (columnVisibility.oaddress1)
          headerRow = `${headerRow}Organization Address1,`;
        if (columnVisibility.oaddress2)
          headerRow = `${headerRow}Organization Address2,`;
        if (columnVisibility.ocity)
          headerRow = `${headerRow}Organization City,`;
        if (columnVisibility.ocounty)
          headerRow = `${headerRow}Organization County,`;
        if (columnVisibility.ostateCode)
          headerRow = `${headerRow}Organization State,`;
        if (columnVisibility.ozip_code)
          headerRow = `${headerRow}Organization Zip,`;
        if (columnVisibility.ozip_code_ext)
          headerRow = `${headerRow}Organization Zip Ext.,`;
        if (columnVisibility.ophone_number)
          headerRow = `${headerRow}Organization Telephone,`;
        if (columnVisibility.ofax_number)
          headerRow = `${headerRow}Organization Fax,`;
        if (columnVisibility.ourl)
          headerRow = `${headerRow}Organization Website URL,`;
        if (columnVisibility.oregion)
          headerRow = `${headerRow}Organization Region,`;
        if (columnVisibility.ourban_flag)
          headerRow = `${headerRow}Organization Location,`;
        if (columnVisibility.oattribute1)
          headerRow = `${headerRow}Organization Attribute1,`;
        if (columnVisibility.oattribute2)
          headerRow = `${headerRow}Organization Attribute2,`;
        if (columnVisibility.oattribute3)
          headerRow = `${headerRow}Organization Attribute3,`;
        if (columnVisibility.oattribute4)
          headerRow = `${headerRow}Organization Attribute4,`;
        if (columnVisibility.oattribute5)
          headerRow = `${headerRow}Organization Attribute5,`;
        if (columnVisibility.oattribute6)
          headerRow = `${headerRow}Organization Attribute6,`;
        if (columnVisibility.oattribute7)
          headerRow = `${headerRow}Organization Attribute7,`;
        if (columnVisibility.oattribute8)
          headerRow = `${headerRow}Organization Attribute8,`;
        if (columnVisibility.oattribute9)
          headerRow = `${headerRow}Organization Attribute9,`;
        if (columnVisibility.oattribute10)
          headerRow = `${headerRow}Organization Attribute10,`;
        if (columnVisibility.oattribute11)
          headerRow = `${headerRow}Organization Attribute11,`;
        if (columnVisibility.oattribute12)
          headerRow = `${headerRow}Organization Attribute12,`;
        if (columnVisibility.oattribute13)
          headerRow = `${headerRow}Organization Attribute13,`;
        if (columnVisibility.oattribute14)
          headerRow = `${headerRow}Organization Attribute14,`;
        if (columnVisibility.oattribute15)
          headerRow = `${headerRow}Organization Attribute15,`;
        if (columnVisibility.oattribute16)
          headerRow = `${headerRow}Organization Attribute16,`;
        if (columnVisibility.oattribute17)
          headerRow = `${headerRow}Organization Attribute17,`;
        if (columnVisibility.oattribute18)
          headerRow = `${headerRow}Organization Attribute18,`;
        if (columnVisibility.oattribute19)
          headerRow = `${headerRow}Organization Attribute19,`;
        if (columnVisibility.oattribute20)
          headerRow = `${headerRow}Organization Attribute20,`;
        if (columnVisibility.surveyFilingDateCreated)
          headerRow = `${headerRow}Survey Filing Created Date,`;
        if (columnVisibility.surveyFilingDateModified)
          headerRow = `${headerRow}Survey Filing Modify Date,`;
        const csvStr = responseData.map((member) => {
          let row = "";
          let newRowLine = [];
          row += (member?.srCustomId || "") + ",";
          if (columnVisibility.cname)
            row += replaceCommaChar(member?.surveyRespondent?.name) + ",";
          if (columnVisibility.title)
            row += replaceCommaChar(member?.surveyRespondent?.title) + ",";
          if (columnVisibility.username)
            row += (member?.surveyRespondent?.username || "") + ",";
          if (columnVisibility.address1)
            row += replaceCommaChar(member?.surveyRespondent?.address1) + ",";
          if (columnVisibility.address2)
            row += replaceCommaChar(member?.surveyRespondent?.address2) + ",";
          if (columnVisibility.city)
            row += (member?.surveyRespondent?.city || "") + ",";
          if (columnVisibility.county)
            row += (member?.surveyRespondent?.county || "") + ",";
          if (columnVisibility.stateCode)
            row += (member?.surveyRespondent?.stateCode?.shortName || "") + ",";
          if (columnVisibility.zip_code)
            row += (member?.surveyRespondent?.zip_code || "") + ",";
          if (columnVisibility.zip_code_ext)
            row += (member?.surveyRespondent?.zip_code_ext || "") + ",";
          if (columnVisibility.phone_number)
            row += (member?.surveyRespondent?.phone_number || "") + ",";
          if (columnVisibility.fax_number)
            row += (member?.surveyRespondent?.fax_number || "") + ",";
          if (columnVisibility.email)
            row += (member?.surveyRespondent?.email || "") + ",";
          if (columnVisibility.surveyFilingStatus)
            row += (member?.surveyFilingStatus || "") + ",";
          if (columnVisibility.attribute1)
            row += replaceCommaChar(member?.surveyRespondent?.attribute1) + ",";
          if (columnVisibility.attribute2)
            row += replaceCommaChar(member?.surveyRespondent?.attribute2) + ",";
          if (columnVisibility.attribute3)
            row += replaceCommaChar(member?.surveyRespondent?.attribute3) + ",";
          if (columnVisibility.attribute4)
            row += replaceCommaChar(member?.surveyRespondent?.attribute4) + ",";
          if (columnVisibility.attribute5)
            row += replaceCommaChar(member?.surveyRespondent?.attribute5) + ",";
          if (columnVisibility.attribute6)
            row += replaceCommaChar(member?.surveyRespondent?.attribute6) + ",";
          if (columnVisibility.attribute7)
            row += replaceCommaChar(member?.surveyRespondent?.attribute7) + ",";
          if (columnVisibility.attribute8)
            row += replaceCommaChar(member?.surveyRespondent?.attribute8) + ",";
          if (columnVisibility.attribute9)
            row += replaceCommaChar(member?.surveyRespondent?.attribute9) + ",";
          if (columnVisibility.attribute10)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute10) + ",";
          if (columnVisibility.attribute11)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute11) + ",";
          if (columnVisibility.attribute12)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute12) + ",";
          if (columnVisibility.attribute13)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute13) + ",";
          if (columnVisibility.attribute14)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute14) + ",";
          if (columnVisibility.attribute15)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute15) + ",";
          if (columnVisibility.attribute16)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute16) + ",";
          if (columnVisibility.attribute17)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute17) + ",";
          if (columnVisibility.attribute18)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute18) + ",";
          if (columnVisibility.attribute19)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute19) + ",";
          if (columnVisibility.attribute20)
            row +=
              replaceCommaChar(member?.surveyRespondent?.attribute20) + ",";

          if (columnVisibility.sr_custom_id)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.customId || "") +
              ",";
          if (columnVisibility.orgName)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.name
              ) + ",";

          if (columnVisibility.oaddress1)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.address1
              ) + ",";

          if (columnVisibility.oaddress2)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.address2
              ) + ",";
          if (columnVisibility.ocity)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.city || "") + ",";
          if (columnVisibility.ocounty)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.county || "") +
              ",";

          if (columnVisibility.ostateCode)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.stateCode
                ?.shortName || "") + ",";
          if (columnVisibility.ozip_code)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.zip_code || "") +
              ",";
          if (columnVisibility.ozip_code_ext)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.zip_code_ext ||
                "") + ",";
          if (columnVisibility.ophone_number)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.phone_number ||
                "") + ",";
          if (columnVisibility.ofax_number)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.fax_number || "") +
              ",";
          if (columnVisibility.ourl)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.url || "") + ",";
          if (columnVisibility.oregion)
            row +=
              (member?.surveyRespondent?.surveyOrganization?.region || "") +
              ",";
          if (columnVisibility.ourban_flag) {
            let locationText = "";
            let urbData =
              member?.surveyRespondent?.surveyOrganization?.urbanFlag;
            if (urbData !== undefined || urbData !== null) {
              locationText = urbData ? "Urban" : "Rural";
            }
            row += locationText + ",";
          }
          if (columnVisibility.oattribute1)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute1
              ) + ",";
          if (columnVisibility.oattribute2)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute2
              ) + ",";
          if (columnVisibility.oattribute3)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute3
              ) + ",";
          if (columnVisibility.oattribute4)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute4
              ) + ",";
          if (columnVisibility.oattribute5)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute5
              ) + ",";
          if (columnVisibility.oattribute6)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute6
              ) + ",";
          if (columnVisibility.oattribute7)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute7
              ) + ",";
          if (columnVisibility.oattribute8)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute8
              ) + ",";
          if (columnVisibility.oattribute9)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute9
              ) + ",";
          if (columnVisibility.oattribute10)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute10
              ) + ",";
          if (columnVisibility.oattribute11)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute11
              ) + ",";
          if (columnVisibility.oattribute12)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute12
              ) + ",";
          if (columnVisibility.oattribute13)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute13
              ) + ",";
          if (columnVisibility.oattribute14)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute14
              ) + ",";
          if (columnVisibility.oattribute15)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute15
              ) + ",";
          if (columnVisibility.oattribute16)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute16
              ) + ",";
          if (columnVisibility.oattribute17)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute17
              ) + ",";
          if (columnVisibility.oattribute18)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute18
              ) + ",";
          if (columnVisibility.oattribute19)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute19
              ) + ",";
          if (columnVisibility.oattribute20)
            row +=
              replaceCommaChar(
                member?.surveyRespondent?.surveyOrganization?.attribute20
              ) + ",";
          if (columnVisibility.surveyFilingDateCreated)
            row +=
              (member?.surveyFilingDateCreated
                ? formatDate(member?.surveyFilingDateCreated)
                : "") + ",";
          if (columnVisibility.surveyFilingDateModified)
            row +=
              (member?.surveyFilingDateModified
                ? formatDate(member?.surveyFilingDateModified)
                : "") + ",";
          newRowLine.push(row);
          return newRowLine;
        });
        let headerRowLine = [];
        headerRowLine.push(headerRow.slice(0, -1) + "\n");

        csvStr.forEach(function (row) {
          headerRowLine += row.join(",");
          headerRowLine += "\n";
        });

        var hiddenElement = document.createElement("a");
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(headerRowLine);

        hiddenElement.target = "_blank";
        hiddenElement.download = "ResponseReport.csv";
        hiddenElement.click();
        setLoadingExport(false);
      }
    } catch (error) {
      console.error("Error fetching all panel members:", error);
    }

    setLoadingExport(false);
  };
  const table = useMaterialReactTable({
    manualPagination: true,
    manualSorting: true,
    columns,
    data,
    onPaginationChange: setPagination,
    state: { pagination, sorting, columnVisibility },
    onColumnVisibilityChange: setColumnVisibility,
    onSortingChange: setSorting,
    rowCount,
    initialState: {
      density: "compact",
    },
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableStickyHeader: true,
    //optionally override the default column widths
    defaultColumn: {
      maxSize: 400,
      minSize: 100,
      size: 200, //default size is usually 180
    },
    enableColumnResizing: true,
    columnResizeMode: "onEnd", //default
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          padding: "8px",
          flexWrap: "wrap",
        }}
      >
        <Grid
          sx={{ display: "flex" }}
          direction="row"
          alignItems="center"
          spacing={2}
        >
          <Grid item xs={2}>
            <FormControl>
              <InputLabel className="inputLabel">Select data filter</InputLabel>
              <Select
                size="small"
                sx={{ width: "260px", marginRight: "20px" }}
                value={selectedFilters}
                onChange={handleFilterChange}
                label="Select data filter"
              >
                <MenuItem value="none">
                  <em>None</em>
                </MenuItem>
                {filterList.map((filter) => (
                  <MenuItem key={filter.id} value={filter.id}>
                    {filter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Button
              disabled={loadingPdfExport}
              onClick={() => handlePDFData()}
              startIcon={<FileDownloadIcon />}
            >
              {" "}
              Export to PDF
            </Button>{" "}
            <Button
              disabled={loadingExport}
              onClick={() => handleExportData()}
              startIcon={<FileDownloadIcon />}
            >
              Export to CSV
            </Button>
          </Grid>
          <Grid item xs={5} sx={{ ml: 2 }}>
            {loadingExport && "Generating CSV..."}
            {loadingPdfExport && "Generating PDF..."}
            {loadingDataExport && (
              <>
                <Typography style={{ fontWeight: "bold" }}>
                  Retrieving results...
                </Typography>{" "}
                <Box>
                  <LinearProgress color="primary" />
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    ),
  });
  useEffect(() => {
    if (selectedPanelId) {
      fetchPanelAssignmentInfo();
    }
  }, [
    filterFormData,
    pagination.pageIndex,
    pagination.pageSize,
    selectedPanelId,
  ]);

  useEffect(() => {
    fetchPanelAssignmentInfo();
  }, [selectedFilters]);
  useEffect(() => {
    if (sortBy !== null && sortAscending !== null) {
      fetchPanelAssignmentInfo();
    }
  }, [sortBy, sortAscending]);
  useEffect(() => {
    if (sorting[0]) {
      if (sorting[0]?.id === "orgName") setSortBy(`survey_organization.name`);
      else if (sorting[0]?.id === "sr_custom_id")
        setSortBy(`survey_organization.custom_id`);
      else if (sorting[0]?.id === "oaddress1")
        setSortBy(`survey_organization.address1`);
      else if (sorting[0]?.id === "oaddress2")
        setSortBy(`survey_organization.address2`);
      else if (sorting[0]?.id === "ocity")
        setSortBy(`survey_organization.city`);
      else if (sorting[0]?.id === "ocounty")
        setSortBy(`survey_organization.county`);
      else if (sorting[0]?.id === "ostateCode")
        setSortBy(`survey_organization_state.short_name`);
      else if (sorting[0]?.id === "ozip_code")
        setSortBy(`survey_organization.zip_code`);
      else if (sorting[0]?.id === "ozip_code_ext")
        setSortBy(`survey_organization.zip_code_ext`);
      else if (sorting[0]?.id === "ophone_number")
        setSortBy(`survey_organization.phone_number`);
      else if (sorting[0]?.id === "ofax_number")
        setSortBy(`survey_organization.fax_number`);
      else if (sorting[0]?.id === "ourl") setSortBy(`survey_organization.url`);
      else if (sorting[0]?.id === "oregion")
        setSortBy(`survey_organization.region`);
      else if (sorting[0]?.id === "ourban_flag")
        setSortBy(`survey_organization.urban_flag`);
      else if (sorting[0]?.id === "stateCode")
        setSortBy(`survey_respondent_state.short_name`);
      else if (sorting[0]?.id === "oattribute1")
        setSortBy(`survey_organization.attribute1`);
      else if (sorting[0]?.id === "oattribute2")
        setSortBy(`survey_organization.attribute2`);
      else if (sorting[0]?.id === "oattribute3")
        setSortBy(`survey_organization.attribute3`);
      else if (sorting[0]?.id === "oattribute4")
        setSortBy(`survey_organization.attribute4`);
      else if (sorting[0]?.id === "oattribute5")
        setSortBy(`survey_organization.attribute5`);
      else if (sorting[0]?.id === "oattribute6")
        setSortBy(`survey_organization.attribute6`);
      else if (sorting[0]?.id === "oattribute7")
        setSortBy(`survey_organization.attribute7`);
      else if (sorting[0]?.id === "oattribute8")
        setSortBy(`survey_organization.attribute8`);
      else if (sorting[0]?.id === "oattribute9")
        setSortBy(`survey_organization.attribute9`);
      else if (sorting[0]?.id === "oattribute10")
        setSortBy(`survey_organization.attribute10`);
      else if (sorting[0]?.id === "oattribute11")
        setSortBy(`survey_organization.attribute11`);
      else if (sorting[0]?.id === "oattribute12")
        setSortBy(`survey_organization.attribute12`);
      else if (sorting[0]?.id === "oattribute13")
        setSortBy(`survey_organization.attribute13`);
      else if (sorting[0]?.id === "oattribute14")
        setSortBy(`survey_organization.attribute14`);
      else if (sorting[0]?.id === "oattribute15")
        setSortBy(`survey_organization.attribute15`);
      else if (sorting[0]?.id === "oattribute16")
        setSortBy(`survey_organization.attribute16`);
      else if (sorting[0]?.id === "oattribute17")
        setSortBy(`survey_organization.attribute17`);
      else if (sorting[0]?.id === "oattribute18")
        setSortBy(`survey_organization.attribute18`);
      else if (sorting[0]?.id === "oattribute19")
        setSortBy(`survey_organization.attribute19`);
      else if (sorting[0]?.id === "oattribute20")
        setSortBy(`survey_organization.attribute20`);
      else if (sorting[0]?.id === "cname") setSortBy(`survey_respondent.name`);
      else if (sorting[0]?.id === "custom_id")
        setSortBy(`survey_respondent.sr_custom_id`);
      else if (sorting[0]?.id === "surveyFilingStatus")
        setSortBy(`surveyFilingStatus`);
      else if (sorting[0]?.id === "surveyFilingDateCreated")
        setSortBy(`survey_filing.date_created`);
      else if (sorting[0]?.id === "surveyFilingDateModified")
        setSortBy(`survey_filing.date_modified`);
      else setSortBy(`survey_respondent.${sorting[0]?.id}`);

      setSortAscending(!sorting[0]?.desc);
    }
  }, [sorting]);
  const handleOpenModal = async () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };
  return showLoading ? (
    <LoadingText />
  ) : (
    <div style={{ padding: "15px" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={1}>
          <Grid item xs={8}>
            <Typography variant="label" color="primary">
              Response Report for: {htmlParser(surveyName)}
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <Button
              startIcon={<TuneOutlinedIcon />}
              onClick={() => handleOpenModal()}
            >
              Set Filter (find) Criteria
            </Button>
          </Grid>
        </Grid>
      </Box>
      <div style={{ marginBottom: "20px" }}></div>
      <MaterialReactTable table={table} />
      <Modal open={openModal} onClose={handleCloseModal}>
        <FilterForm handleClose={handleCloseModal} />
      </Modal>
    </div>
  );
};

export default ResponseReport;
