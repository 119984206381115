import React, { useState, useEffect, useMemo, useRef } from "react";
import JoditEditor from "jodit-react";

const RichEditor = ({ name, value, onChange }) => {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const options = [
    "bold",
    "italic",
    "|",
    "ul",
    "ol",
    "|",
    "font",
    "fontsize",
    "|",
    "outdent",
    "indent",
    "align",
    "|",
    "hr",
    "|",
    "fullsize",
    "brush",
    "|",
    "table",
    "link",
    "|",
    "undo",
    "redo",
  ];
  const config = useMemo(
    () => ({
      addNewLine: false,
      paragraph: false,
      readonly: false,
      placeholder: "",
      defaultActionOnPaste: "insert_as_html",
      askBeforePasteHTML: false,
      pastePlainText: true,
      defaultLineHeight: 1.5,
      enter: "br",
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      statusbar: false,
      sizeLG: 900,
      sizeMD: 700,
      sizeSM: 400,
      toolbarAdaptive: false,
      toolbarButtonSize: "small",
      saveHeightInStorage: true,
      height: "auto",
      minHeight: 120,
      maxHeight: 1200,
    }),
    []
  );

  const handleChange = (newContent) => {
    setContent(newContent);
    onChange({ target: { name: name, value: newContent } });
  };

  useEffect(() => {
    setContent(value);
  }, [value]);

  return (
    <JoditEditor
      name={name}
      ref={editor}
      value={content}
      config={config}
      tabIndex={1}
      onBlur={handleChange}
    />
  );
};

export default RichEditor;
