import { useEffect, useMemo, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Tooltip from "../../../../components/Common/Tooltip";
import { Card, CardContent, Grid, Typography, Box } from "@mui/material";
import theme from "../../../../theme";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import {
  setEditFieldId,
  setSavedQues,
  setSelectedField,
} from "../../../Survey/Survey.slice";
import { initialFieldFormData } from "./AddQuesConstants";
import {
  deleteField,
  fullQuestionById,
  updateFieldOrder,
} from "../../../Survey/Survey.service";

const FieldsTable = ({
  setFieldFormData,
  setShowFieldOptions,
  selectedField,
  setTriggerQuesGrpRefresh,
  setIsEditing,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [editField, setEditField] = useState(null);

  const savedQuesFields = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQues?.fields
  );

  useEffect(() => {
    setFieldFormData(initialFieldFormData);
  }, [editField]);

  useEffect(() => {
    if (!savedQuesFields) {
      setData([]);
    } else {
      const updatedData = savedQuesFields.map((data) => ({
        id: data?.id,
        userLabel: data?.userLabel,
        fieldLabel: data?.fieldLabel,
        fieldType: data?.fieldType?.description,
        fieldName: data?.fieldType?.name,
      }));
      setData(updatedData);
    }
  }, [savedQuesFields]);

  const handleFieldDelete = async (id) => {
    if (id) {
      try {
        await deleteField(id);
      } catch (error) {
        console.error("Error deleting field:", error);
      } finally {
        setTriggerQuesGrpRefresh((prev) => !prev);
      }
    }
  };

  const handleFieldEdit = (row) => {
    setIsEditing(true);
    dispatch(setSelectedField(row.original?.fieldName));
    dispatch(setEditFieldId(row.original?.id));
    setEditField(row.original?.id);
    setShowFieldOptions(false);
  };

  const handleFieldReorder = async (newFieldOrder) => {
    try {
      await updateFieldOrder(newFieldOrder);
    } catch (error) {
      console.error("Error updating field order", error);
    } finally {
      setTriggerQuesGrpRefresh((prev) => !prev);
    }
  };

  const columns = useMemo(() => {
    let baseColumns = [
      {
        accessorKey: "edit",
        header: "Edit",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Edit field" arrow>
            <EditOutlinedIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.green.main,
                background: theme.palette.green.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => handleFieldEdit(row)}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "delete",
        header: "Delete",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Delete field" arrow>
            <CloseOutlinedIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.error.main,
                background: theme.palette.error.light,
                borderRadius: "10px",
                padding: "10px",
              }}
              onClick={() => handleFieldDelete(row.original.id)}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "userLabel",
        header: "User Label",
        size: 50,
        Cell: ({ row }) => <>{<label>{row.original?.userLabel}</label>}</>,
      },
      {
        accessorKey: "fieldLabel",
        header: "Field Label",
        size: 50,
        Cell: ({ row }) => <>{<label>{row.original?.fieldLabel}</label>}</>,
      },
      {
        accessorKey: "fieldType",
        header: "Field Type",
        size: 50,
        Cell: ({ row }) => <>{<label>{row.original?.fieldType}</label>}</>,
      },
    ];
    return baseColumns;
  }, [selectedField, editField]);

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    initialState: { density: "compact" },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );

          if (draggingRow.index !== hoveredRow.index) {
            setData([...data]);
            const newFieldOrder = {
              idSequenceList: data.map((item, index) => ({
                id: item.id,
                sequence: index,
              })),
            };

            handleFieldReorder(newFieldOrder);
          }
        }
      },
    }),
  });

  return (
    data?.length > 0 && (
      <Grid item xs={12} mt={1}>
        <Card>
          <CardContent>
            <Box mb={1}>
              <Typography variant="label" sx={{ fontWeight: "bold" }}>
                FIELDS
              </Typography>
            </Box>
            <MaterialReactTable table={table} />
          </CardContent>
        </Card>
      </Grid>
    )
  );
};

export default FieldsTable;
