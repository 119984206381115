import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useSelector } from "react-redux";

const StateDropdown = ({
  question,
  label,
  height,
  value,
  size,
  options,
  onChange,
  multiselect,
  disabledField,
  ...otherProps
}) => {
  const [selectedValues, setSelectedValues] = useState(multiselect ? [] : "");
  const countryCodes = useSelector((state) => state.survey.states);
  const countries = countryCodes.map((coun) => coun.longName);

  const handleSelectChange = (e, newSelectedValues) => {
    setSelectedValues(newSelectedValues);
    onChange(getShortName(countryCodes, newSelectedValues));
  };

  useEffect(() => {
    if (value && value !== "") {
      setSelectedValues(getLongName(countryCodes, value));
    }
  }, [value, question]);

  function getLongName(array, shortName) {
    const result = array?.find((item) => item.shortName === shortName);
    return result ? result.longName : null;
  }
  function getShortName(array, longName) {
    const result = array?.find((item) => item.longName === longName);
    return result ? result.shortName : null;
  }

  return (
    <Grid sx={{ display: "flex" }} direction="column">
      <Grid sx={{ display: "flex" }} direction="row" alignItems="center">
        <Autocomplete
          disabled={
            otherProps?.type === "preview" || disabledField ? true : false
          }
          multiple={false}
          value={selectedValues}
          onChange={handleSelectChange}
          options={countries}
          renderInput={(params) => <TextField {...params} />}
          // getOptionLabel={(option) => option.longName}
          sx={{
            width: size ? `${size}px` : "100%",
            minWidth: size ? `${size}px` : "250px",
          }}
        />
        {otherProps.errorIndicator?.displayIndicator && (
          <Grid pl={2} style={{ color: "maroon" }}>{`<< `}</Grid>
        )}
      </Grid>
      <Grid>
        {label?.historicalAnswer?.value && (
          <Grid alignSelf={"start"} style={{ color: "maroon" }}>
            {getLongName(countryCodes, label?.historicalAnswer?.value)}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default StateDropdown;
