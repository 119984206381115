import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { useTreeItem } from "@mui/x-tree-view/TreeItem";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CropSquareIcon from "@mui/icons-material/CropSquare";
import SelectAllIcon from "@mui/icons-material/SelectAll";
import "./SurveyTree.scss";
import {
  getTextFromHTML,
  htmlParser,
  truncateText,
} from "../../../Utility/constants";
import { getPopulatedSurvey } from "../../../pages/Survey/Survey.service";
import { selHistSelectedQues } from "../../../pages/Survey/Survey.slice";

const RootTreeView = styled(TreeView)(({ theme }) => ({
  borderRadius: "14px",
  backgroundColor: "rgba(0, 48, 135, 0.15)",
  [`& .${treeItemClasses.group}`]: {
    paddingLeft: 18,
  },
}));

function SectionTreeItem(props) {
  const { sec, ...otherProps } = props;

  return (
    <TreeItem
      {...otherProps}
      ContentComponent={React.forwardRef((contentProps, ref) => (
        <SectionContent ref={ref} sec={sec} {...contentProps} />
      ))}
    />
  );
}

const SectionContent = React.forwardRef(function SectionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        border: "1px solid rgba(0, 48, 135, 1)",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function PageTreeItem(props) {
  const { page, ...otherProps } = props;
  return (
    <TreeItem
      ContentComponent={(contentProps) => (
        <PageContent {...contentProps} page={page} />
      )}
      {...otherProps}
    />
  );
}

const PageContent = React.forwardRef(function PageContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
    onClick,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;
  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    handleExpansion(event);
    handleSelection(event);
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "rgba(0, 48, 135, 0.15)",
        padding: "10px",
        borderRadius: "14px",
        ...(selected && { backgroundColor: "rgba(0, 48, 135, 0.15)" }),
        ...(expanded && {
          backgroundColor: "rgba(0, 48, 135, 0.15)",
          borderBottom: "1px solid rgba(0, 48, 135, 1)",
        }),
        ...(focused && { backgroundColor: "rgba(0, 48, 135, 0.35)" }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography component="div" className={classes.label}>
        {label}
      </Typography>
      <div className={classes.iconContainer}>{icon}</div>
    </div>
  );
});

function QuestionTreeItem(props) {
  const { ques, handleClose, handleSurveyTreeClose, ...otherProps } = props;
  return (
    <TreeItem
      ContentComponent={(contentProps) => (
        <QuestionContent
          {...contentProps}
          ques={ques}
          handleClose={handleClose}
          handleSurveyTreeClose={handleSurveyTreeClose}
        />
      )}
      {...otherProps}
    />
  );
}

const QuestionContent = React.forwardRef(function QuestionContent(props, ref) {
  const {
    className,
    classes,
    label,
    nodeId,
    onClick,
    ques,
    handleClose,
    handleSurveyTreeClose,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const dispatch = useDispatch();
  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleClick = (event) => {
    dispatch(selHistSelectedQues(ques));
    handleExpansion(event);
    handleSelection(event);
    handleSurveyTreeClose();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <div
      style={{
        backgroundColor: "transparent",
        padding: "8px 0",
        ...(selected && {
          color: "#1b1ba7",
          backgroundColor: "#9dafd1",
          borderRadius: "7px",
        }),
      }}
      className={clsx(className, classes.label, {
        "Mui-expanded": expanded,
        "Mui-selected": selected,
        "Mui-focused": focused,
        "Mui-disabled": disabled,
      })}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      ref={ref}
    >
      <Typography variant="label" className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

function PageTreeItems({ pages, handleClose, handleSurveyTreeClose }) {
  return (
    <>
      {pages.map((page, index) => (
        <PageTreeItem
          page={page}
          key={page?.page?.id}
          selecticon={<CropSquareIcon />}
          nodeId={page?.page?.id}
          label={`Page-${index + 1} : ${truncateText(
            getTextFromHTML(page?.page?.name),
            16
          )}`}
          sx={{
            backgroundColor: "rgba(0, 48, 135, 0.15)",
            border: "1px solid rgba(0, 48, 135, 1)",
            borderRadius: "14px",
            [`& .${treeItemClasses.group}`]: {
              maxHeight: 200,
              overflowY: "auto",
              overflowX: "hidden",
              margin: "12px 12px 12px -5px",
              scrollbarWidth: "thin",
              scrollbarColor: "rgba(0, 48, 135, 1) white",
              "&::-webkit-scrollbar": {
                width: 3,
                backgroundColor: "white",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0, 48, 135, 1)",
                borderRadius: 4,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
              },
            },
            margin: "0px 10px 10px -18px",
          }}
        >
          <div>
            <QuestionTreeItems
              index={index}
              questions={page?.questionGroups}
              handleClose={handleClose}
              handleSurveyTreeClose={handleSurveyTreeClose}
            />
          </div>
        </PageTreeItem>
      ))}
    </>
  );
}

function QuestionTreeItems({ questions, handleClose, handleSurveyTreeClose }) {
  return (
    <>
      {questions
        .reduce((accumulator, currentGroup) => {
          return accumulator.concat(currentGroup.questions);
        }, [])
        .map((question, index) => (
          <a
            key={question.id}
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <QuestionTreeItem
              ques={question}
              selectIcon={<CropSquareIcon />}
              nodeId={question.id}
              label={truncateText(
                `${getTextFromHTML(question.label) || ""} ${
                  getTextFromHTML(question.name) || ""
                }`,
                23
              )}
              handleClose={handleClose}
              handleSurveyTreeClose={handleSurveyTreeClose}
            />
          </a>
        ))}
    </>
  );
}

const SurveyTree = ({
  selFieldSurveyId,
  handleClose,
  handleSurveyTreeClose,
}) => {
  const [expanded, setExpanded] = useState([]);
  const [sections, setSections] = useState([]);
  const [survey, setSurvey] = useState({});
  const [loading, setLoading] = useState(true);

  const handleNodeToggle = (event, nodes) => {
    setExpanded(nodes);
  };
  useEffect(() => {
    const fetchPopulatedSurvey = async () => {
      setLoading(true);
      try {
        const response = await getPopulatedSurvey(selFieldSurveyId);
        setSections(response.data.sections);
        setSurvey(response.data.survey);
      } catch (error) {
        console.log("Error fetching Populated Survey", error);
      } finally {
        setLoading(false);
      }
    };
    if (selFieldSurveyId) {
      fetchPopulatedSurvey();
    }
  }, [selFieldSurveyId]);

  return loading ? (
    <>
      <div className="treeModal">
        <div className="treeForm">
          <Typography
            variant="headerMenu"
            color="primary"
            sx={{ cursor: "pointer" }}
          >
            Loading Survey Questions...
          </Typography>
        </div>
      </div>
    </>
  ) : (
    <div className="treeModal">
      <div className="treeForm">
        <div style={{ display: "flex", textWrap: "wrap" }}>
          <Typography
            variant="headerMenu"
            color="primary"
            sx={{ cursor: "pointer" }}
          >
            {htmlParser(survey?.name)}
          </Typography>
        </div>
        <div style={{ textAlign: "right" }}>
          <Button variant="outlined" paragraph onClick={handleClose}>
            Close
          </Button>
        </div>
        {sections.map((sec) => (
          <RootTreeView
            aria-label="customized"
            expanded={expanded}
            onNodeToggle={handleNodeToggle}
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            sx={{
              margin: "10px 0",
            }}
            key={sec.id}
          >
            <SectionTreeItem
              sec={sec}
              key={sec?.section?.id}
              selecticon={<SelectAllIcon />}
              nodeId={sec?.section?.id}
              label={`Section ${truncateText(
                getTextFromHTML(sec?.section?.name),
                23
              )}`}
              sx={{
                border: "1px solid transparent",
                borderRadius: "14px",
                [`& .${treeItemClasses.group}`]: {
                  maxHeight: 250,
                  overflowY: "auto",
                  overflowX: "hidden",
                  margin: "12px 12px",
                  scrollbarWidth: "thin",
                  scrollbarColor: "rgba(0, 48, 135, 1) white",
                  "&::-webkit-scrollbar": {
                    width: 3,
                    backgroundColor: "white",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "rgba(0, 48, 135, 1)",
                    borderRadius: 4,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "transparent",
                  },
                },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              <PageTreeItems
                handleClose={handleClose}
                handleSurveyTreeClose={handleSurveyTreeClose}
                pages={sec.pages}
              />
            </SectionTreeItem>
          </RootTreeView>
        ))}
      </div>
    </div>
  );
};
export default SurveyTree;
