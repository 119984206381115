import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import PublicLayout from "./components/Layouts/PublicLayout/PublicLayout";
import NoSidebarLayout from "./components/Layouts/NoSidebarLayout/NoSidebarLayout";
import AppLayout from "./components/Layouts/AppLayout/AppLayout";
import FileMgr from "./pages/FileMgr/FileMgr";
import BatchUpdate from "./pages/BatchUpdate";
import NewSurvey from "./pages/ModifySurvey/SurveyBuilder/NewSurvey";
import AddQues from "./pages/ModifySurvey/SurveyBuilder/AddQues";
import Organization from "./pages/Admin/Organization";
import Homepage from "./pages/Homepage/Homepage";

const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route
              path="/file-mgr"
              element={
                <NoSidebarLayout>
                  <FileMgr />
                </NoSidebarLayout>
              }
            />
            <Route
              path="/batch-update"
              element={
                <NoSidebarLayout>
                  <BatchUpdate />
                </NoSidebarLayout>
              }
            />
            <Route
              path="/new-survey"
              element={
                <NoSidebarLayout>
                  <NewSurvey />
                </NoSidebarLayout>
              }
            />
            <Route
              path="/add-question"
              element={
                <NoSidebarLayout>
                  <AddQues />
                </NoSidebarLayout>
              }
            />
            <Route
              path="/organization"
              element={
                <NoSidebarLayout>
                  <Organization />
                </NoSidebarLayout>
              }
            />
            <Route
              path="/"
              element={
                <NoSidebarLayout>
                  <Homepage />
                </NoSidebarLayout>
              }
            />
            <Route
              path="/*"
              element={
                <PublicLayout>
                  <AppLayout />
                </PublicLayout>
              }
            />
          </Routes>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
