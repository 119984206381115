import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setImportCurrentView } from "../../Survey/Survey.slice";
import { useLocation, useNavigate } from "react-router";
import Button from "../../../components/Common/Button";
import Tooltip from "../../../components/Common/Tooltip";
import { htmlParser } from "../../../Utility/constants";
import {
  completedBatches,
  inProgressBatches,
} from "../../Survey/Survey.service";

const BatchHome = () => {
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;
  const [isCompleted, setIsCompleted] = useState(false);
  const [isProgress, setIsProgress] = useState(false);

  useEffect(() => {
    const findBatchesExists = async () => {
      try {
        const res = await inProgressBatches(surveyId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          setIsProgress(true);
        }
      } catch (error) {
        console.error("Error fetching batch details:", error);
      }
      try {
        const res = await completedBatches(surveyId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          setIsCompleted(true);
        }
      } catch (error) {
        console.error("Error fetching batch details:", error);
      }
    };
    if (personId) {
      findBatchesExists();
    }
  }, [personId]);

  const handleViewChange = (view) => {
    dispatch(setImportCurrentView(view));
  };

  return (
    <>
      <Typography variant="label" color="primary" sx={{ marginBottom: "50px" }}>
        {htmlParser(surveyName)}
      </Typography>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography variant="body1" sx={{ marginBottom: "20px" }}>
          You may begin a new data import or view past data import results.
        </Typography>
        <Tooltip title="Import Survey Data" arrow>
          <Typography
            variant="body1"
            color="primary"
            sx={{ marginBottom: "10px", cursor: "pointer" }}
            onClick={() => handleViewChange(2)}
          >
            Begin a new data import
          </Typography>
        </Tooltip>
        {isProgress && (
          <Tooltip title="View batch progress" arrow>
            <Typography
              variant="body1"
              color="primary"
              sx={{ marginBottom: "10px", cursor: "pointer" }}
              onClick={() => handleViewChange(7)}
            >
              View the progress of an in-progress batch
            </Typography>
          </Tooltip>
        )}
        {isCompleted && (
          <>
            <Tooltip title="Get all batches for this survey" arrow>
              <Typography
                variant="body1"
                color="primary"
                sx={{ marginBottom: "10px", cursor: "pointer" }}
                onClick={() => handleViewChange(4)}
              >
                View past import results
              </Typography>
            </Tooltip>
            <Tooltip title="Perform a search on past batches" arrow>
              <Typography
                variant="body1"
                color="primary"
                sx={{ marginBottom: "30px", cursor: "pointer" }}
                onClick={() => handleViewChange(5)}
              >
                Search for a filing
              </Typography>
            </Tooltip>
          </>
        )}
        <Button
          variant="outlined"
          component="label"
          style={{ marginRight: "20px" }}
          onClick={() => {
            navigate("/");
          }}
        >
          Back
        </Button>
      </Box>
    </>
  );
};

export default BatchHome;
