import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Typography, Grid, Divider, Button, TextField } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import theme from "../../theme";
import { createDataFilter, updateDataFilter } from "../Survey/Survey.service";
import { CLEAN_FORM_DATA } from "../../Utility/constants";

export const initNewData = {
  id: "",
  name: "",
  description: "",
};
const AddEditDataFilter = ({
  handleCloseDataFilter,
  selFilterData,
  handleRefreshDataFilter,
}) => {
  const location = useLocation();
  const [formData, setFormData] = useState(initNewData);
  const [errorMsg, setErrorMsg] = useState("");
  const [requiredErrorMsg, setRequiredErrorMsg] = useState("");
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const surveyId = location?.state?.surveyId;
  const createdBy = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  useEffect(() => {
    if (Object.keys(selFilterData).length > 0) {
      const updatedFormData = {
        id: selFilterData.id,
        name: selFilterData.name || "",
        description: selFilterData.description || "",
        rule: selFilterData.rule ? { id: selFilterData.rule.id } : "",
      };
      setFormData(updatedFormData);
    }
  }, [selFilterData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    setErrorMsg("");
    setRequiredErrorMsg("");
    const name = formData["name"];
    if (name.trim() === "") {
      setRequiredErrorMsg("Please enter required field");
    } else {
      const isUpdateCall = Object.keys(selFilterData).length > 0;
      if (isUpdateCall) {
        updateDataFilterSave(selFilterData.id);
      } else {
        createDataFilterSave();
      }
    }
  };
  const updateDataFilterSave = async (id) => {
    const cleanData = CLEAN_FORM_DATA(formData);
    const payload = {
      ...cleanData,
      modifiedBy: createdBy,
      survey: { id: surveyId },
      id: id,
    };
    try {
      const response = await updateDataFilter(payload);
      if (response?.data?.id) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Data filter updated successfully.");
        setAlertSeverity("success");

        setTimeout(() => {
          handleRefreshDataFilter();
        }, 2000);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving data filter", error);
    }
  };
  const createDataFilterSave = async () => {
    const cleanData = CLEAN_FORM_DATA(formData);
    const payload = {
      ...cleanData,
      createdBy: createdBy,
      survey: { id: surveyId },
    };
    try {
      const response = await createDataFilter(payload);
      if (response?.data?.id) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Data filter created successfully.");
        setAlertSeverity("success");
        setTimeout(() => {
          handleRefreshDataFilter();
        }, 2000);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving data filter", error);
    }
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Create/Update Data Filter</Typography>
        </div>
        <div className="panelForm">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <label>Please enter the filter name and description</label>
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              * Name required.
            </Typography>
            {requiredErrorMsg !== "" && (
              <div
                style={{ textAlign: "right", color: theme.palette.error.main }}
              >
                {requiredErrorMsg}
              </div>
            )}
            {errorMsg !== "" && (
              <div
                style={{ textAlign: "right", color: theme.palette.error.main }}
              >
                {errorMsg}
              </div>
            )}
            <Divider color={theme.palette.blue.main} />
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={2}>
                <Typography variant="body1">Name *</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  size="small"
                  name="name"
                  variant="outlined"
                  value={formData["name"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Description</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  name="description"
                  multiline
                  rows={5}
                  value={formData["description"]}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Divider color={theme.palette.blue.main} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Button variant="outlined" onClick={handleCloseDataFilter}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button variant="outlined" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AddEditDataFilter;
