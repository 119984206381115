import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { Button, Typography, TextField, Box } from "@mui/material";

const UpdateGrpNameModal = ({ open, onClose, onSubmit, grpName }) => {
  const [groupName, setGroupName] = useState("");
  const [error, setError] = useState("");
  
  useEffect(() => {
    if (open) {
      setGroupName(grpName);
      setError("");
    }
  }, [open]);

  const handleGroupNameChange = (event) => {
    const { value } = event.target;
    setGroupName(value);
    if (value.trim() && error) {
      setError("");
    }
  };

  const handleSave = () => {
    if (!groupName?.trim()) {
      setError("Group name is required");
      return;
    }

    onSubmit(groupName);
  };

  const handleClose = () => {
    setError("");
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "#fff",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography variant="label" color="primary">
          Group Name
        </Typography>
        <TextField
          fullWidth
          style={{ marginTop: "15px" }}
          error={!!error}
          helperText={error}
          value={groupName}
          onChange={handleGroupNameChange}
        />
        <Box mt={2} display="flex">
          <Button
            variant="outlined"
            style={{ marginRight: "15px" }}
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UpdateGrpNameModal;
