import React from "react";
import { useSelector } from "react-redux";
import ChoosePMA from "./ChoosePMA";
import ChooseFields from "./ChooseFields";
import SaveBatchUpdate from "./SaveBatchUpdate";
import RunBatchUpdateVals from "./RunBatchUpdateVals";

const renderView = (currentView) => {
  switch (currentView) {
    case 1:
      return <ChoosePMA />;
    case 2:
      return <ChooseFields />;
    case 3:
      return <SaveBatchUpdate />;
    case 4:
      return <RunBatchUpdateVals />;
    default:
      return null;
  }
};

const BatchUpdate = () => {
  const currentView = useSelector(
    (state) => state?.survey?.batchUpdateData?.batchUpdateCurrentView
  );

  return <>{renderView(currentView)}</>;
};

export default BatchUpdate;
