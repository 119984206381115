import React, { useEffect, useState } from "react";
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../../components/Common/Button";
import Divider from "@mui/material/Divider";
import { useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { USER_ROLES } from "../../Utility/constants";

import {
  savePanelMemberAssignment,
  updateSurveyOrganization,
  updateSurveyRespondent,
} from "../Survey/Survey.service";

import theme from "../../theme";
export const initialPanelData = {
  "survey_respondent.sr_Custom_Id": "",
  "survey_respondent.first_name": "",
  "survey_respondent.middle_initial": "",
  "survey_respondent.last_name": "",
  "survey_respondent.title": "",
  "survey_respondent.username": "",
  "survey_respondent.address1": "",
  "survey_respondent.address2": "",
  "survey_respondent.city": "",
  "survey_respondent.county": "",
  "survey_respondent.state_id": null,
  "survey_respondent.zip_code": "",
  "survey_respondent.zip_code_ext": "",
  "survey_respondent.phone_number": "",
  "survey_respondent.fax_number": "",
  "survey_respondent.email": "",

  "survey_respondent.attribute1": "",
  "survey_respondent.attribute2": "",
  "survey_respondent.attribute3": "",
  "survey_respondent.attribute4": "",
  "survey_respondent.attribute5": "",
  "survey_respondent.attribute6": "",
  "survey_respondent.attribute7": "",
  "survey_respondent.attribute8": "",
  "survey_respondent.attribute9": "",
  "survey_respondent.attribute10": "",
  "survey_respondent.attribute11": "",
  "survey_respondent.attribute12": "",
  "survey_respondent.attribute13": "",
  "survey_respondent.attribute14": "",
  "survey_respondent.attribute15": "",
  "survey_respondent.attribute16": "",
  "survey_respondent.attribute17": "",
  "survey_respondent.attribute18": "",
  "survey_respondent.attribute19": "",
  "survey_respondent.attribute20": "",

  "survey_organization.custom_id": "",
  "survey_organization.name": "",
  "survey_organization.address1": "",
  "survey_organization.address2": "",
  "survey_organization.city": "",
  "survey_organization.county": "",
  "survey_organization.state_id": null,
  "survey_organization.zip_code": "",
  "survey_organization.zip_code_ext": "",
  "survey_organization.phone_number": "",
  "survey_organization.fax_number": "",
  "survey_organization.urban_flag": null,
  "survey_organization.url": "",
  "survey_organization.region": "",

  "survey_organization.attribute1": "",
  "survey_organization.attribute2": "",
  "survey_organization.attribute3": "",
  "survey_organization.attribute4": "",
  "survey_organization.attribute5": "",
  "survey_organization.attribute6": "",
  "survey_organization.attribute7": "",
  "survey_organization.attribute8": "",
  "survey_organization.attribute9": "",
  "survey_organization.attribute10": "",
  "survey_organization.attribute11": "",
  "survey_organization.attribute12": "",
  "survey_organization.attribute13": "",
  "survey_organization.attribute14": "",
  "survey_organization.attribute15": "",
  "survey_organization.attribute16": "",
  "survey_organization.attribute17": "",
  "survey_organization.attribute18": "",
  "survey_organization.attribute19": "",
  "survey_organization.attribute20": "",
};

export const newPanelData = (formData) => {
  const filteredFormData = {};
  for (const key in formData) {
    if (
      (Array.isArray(formData[key]) &&
        formData[key].length !== 0 &&
        formData[key].some((item) => item !== "")) ||
      (!Array.isArray(formData[key]) && formData[key] !== "")
    ) {
      filteredFormData[key] = formData[key];
    }
  }
  return filteredFormData;
};

const PanelMember = ({ handleClosePanel }) => {
  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );

  const isSuperUser =
    roleName === USER_ROLES.BUILDER_SUPER_USER_ROLENAME ? true : false;

  const isBuilderAdminUser =
    roleName === USER_ROLES.BUILDER_ADMIN_ROLENAME ? true : false;

  const [showResProp, setSResProp] = useState(false);
  const [showOrgProp, setShowOrgProp] = useState(false);
  const selectedPanelMemberData = useSelector(
    (state) => state?.survey?.selectedPanelMemberData
  );

  const [formData, setFormData] = useState(initialPanelData);
  const [existingSrData, setExistingSrData] = useState(null);
  const [existingSoData, setExistingSoData] = useState(null);
  const [soDataUpdated, setSoDataUpdated] = useState(false);
  const [srDataUpdated, setSrDataUpdated] = useState(false);

  const states = useSelector((state) => state?.survey?.states);
  const selectedPanelId = useSelector(
    (state) => state?.survey?.selectedPanelId
  );

  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );

  const [errorMsg, setErrorMsg] = useState("");
  const toggleResProp = () => {
    setSResProp(!showResProp);
  };
  const toggleOrgProp = () => {
    setShowOrgProp(!showOrgProp);
  };

  useEffect(() => {
    if (Object.keys(selectedPanelMemberData).length > 0) {
      const srInfo = selectedPanelMemberData?.surveyRespondent;
      const soInfo =
        selectedPanelMemberData?.surveyRespondent?.surveyOrganization;

      const mapAttributes = (prefix, info) => {
        const result = {};
        for (let i = 1; i <= 20; i++) {
          result[`${prefix}.attribute${i}`] = info?.[`attribute${i}`] || "";
        }
        return result;
      };

      const surveyRespondentAttributes = mapAttributes(
        "survey_respondent",
        srInfo
      );
      const surveyOrganizationAttributes = mapAttributes(
        "survey_organization",
        soInfo
      );

      const updatedFormData = {
        "survey_respondent.sr_Custom_Id":
          selectedPanelMemberData?.srCustomId || "",
        "survey_respondent.first_name": srInfo?.firstName || "",
        "survey_respondent.middle_initial": srInfo?.middleInitial || "",
        "survey_respondent.last_name": srInfo?.lastName || "",
        "survey_respondent.title": srInfo?.title || "",
        "survey_respondent.username": srInfo?.username || "",
        "survey_respondent.address1": srInfo?.address1 || "",
        "survey_respondent.address2": srInfo?.address2 || "",
        "survey_respondent.city": srInfo?.city || "",
        "survey_respondent.county": srInfo?.county || "",
        "survey_respondent.state_id": !isNaN(srInfo?.stateCode?.id)
          ? srInfo?.stateCode?.id
          : null,
        "survey_respondent.zip_code": srInfo?.zipCode || "",
        "survey_respondent.zip_code_ext": srInfo?.zipCodeExt || "",
        "survey_respondent.phone_number": srInfo?.phoneNumber || "",
        "survey_respondent.fax_number": srInfo?.faxNumber || "",
        "survey_respondent.email": srInfo?.email || "",

        "survey_organization.custom_id": soInfo?.customId || "",
        "survey_organization.name": soInfo?.name || "",
        "survey_organization.address1": soInfo?.address1 || "",
        "survey_organization.address2": soInfo?.address2 || "",
        "survey_organization.city": soInfo?.city || "",
        "survey_organization.county": soInfo?.county || "",
        "survey_organization.state_id": !isNaN(soInfo?.stateCode?.id)
          ? soInfo?.stateCode?.id
          : null,
        "survey_organization.zip_code": soInfo?.zipCode || "",
        "survey_organization.zip_code_ext": soInfo?.zipCodeExt || "",
        "survey_organization.phone_number": soInfo?.phoneNumber || "",
        "survey_organization.fax_number": soInfo?.faxNumber || "",
        "survey_organization.urban_flag":
          soInfo?.urbanFlag === true || soInfo?.urbanFlag === false
            ? soInfo?.urbanFlag
            : null,
        "survey_organization.url": soInfo?.url || "",
        "survey_organization.region": soInfo?.region || "",
        ...surveyRespondentAttributes,
        ...surveyOrganizationAttributes,
      };

      // console.log(updatedFormData);

      const srData = Object.fromEntries(
        Object.entries(updatedFormData).filter(([key]) =>
          key.startsWith("survey_respondent")
        )
      );

      const soData = Object.fromEntries(
        Object.entries(updatedFormData).filter(([key]) =>
          key.startsWith("survey_organization")
        )
      );
      setExistingSrData(srData);
      setExistingSoData(soData);
      setFormData(updatedFormData);
    }
  }, [selectedPanelMemberData]);

  useEffect(() => {
    if ((formData, existingSrData, existingSoData)) {
      const srFormData = Object.fromEntries(
        Object.entries(formData).filter(([key]) =>
          key.startsWith("survey_respondent")
        )
      );

      const soFormData = Object.fromEntries(
        Object.entries(formData).filter(([key]) =>
          key.startsWith("survey_organization")
        )
      );

      if (JSON.stringify(srFormData) !== JSON.stringify(existingSrData)) {
        setSrDataUpdated(true);
      } else setSrDataUpdated(false);

      if (JSON.stringify(soFormData) !== JSON.stringify(existingSoData)) {
        setSoDataUpdated(true);
      } else setSoDataUpdated(false);
    }
  }, [formData, existingSrData, existingSoData]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? [1, 2] : [1],
      }));
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === "urban" ? true : false,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const isExistingPanelMember =
      Object.keys(selectedPanelMemberData).length > 0;

    const mapAttributes = (prefix, info) => {
      const result = {};
      for (let i = 1; i <= 20; i++) {
        result[`${prefix}.attribute${i}`] = `attribute${i}`;
      }
      return result;
    };

    const surveyRespondentAttributes = mapAttributes("survey_respondent");
    const surveyOrganizationAttributes = mapAttributes("survey_organization");
    const srMapping = {
      "survey_respondent.sr_Custom_Id": "srCustomId",
      "survey_respondent.first_name": "firstName",
      "survey_respondent.middle_initial": "middleInitial",
      "survey_respondent.last_name": "lastName",
      "survey_respondent.title": "title",
      "survey_respondent.username": "username",
      "survey_respondent.address1": "address1",
      "survey_respondent.address2": "address2",
      "survey_respondent.city": "city",
      "survey_respondent.county": "county",
      "survey_respondent.state_id": "stateCode",
      "survey_respondent.zip_code": "zipCode",
      "survey_respondent.zip_code_ext": "zipCodeExt",
      "survey_respondent.phone_number": "phoneNumber",
      "survey_respondent.fax_number": "faxNumber",
      "survey_respondent.email": "email",
      ...surveyRespondentAttributes,
    };
    const soMapping = {
      "survey_organization.custom_id": "customId",
      "survey_organization.name": "name",
      "survey_organization.address1": "address1",
      "survey_organization.address2": "address2",
      "survey_organization.city": "city",
      "survey_organization.county": "county",
      "survey_organization.state_id": "stateCode",
      "survey_organization.zip_code": "zipCode",
      "survey_organization.zip_code_ext": "zipCodeExt",
      "survey_organization.phone_number": "phoneNumber",
      "survey_organization.fax_number": "faxNumber",
      "survey_organization.urban_flag": "urbanFlag",
      "survey_organization.url": "url",
      "survey_organization.region": "region",
      ...surveyOrganizationAttributes,
    };

    if (isExistingPanelMember) {
      const surveyResPayload = JSON.parse(
        JSON.stringify(selectedPanelMemberData)
      );

      const surveyOrgPayload = JSON.parse(
        JSON.stringify(
          selectedPanelMemberData?.surveyRespondent?.surveyOrganization
        )
      );

      for (const key in formData) {
        if (srMapping.hasOwnProperty(key)) {
          const selectedKey = srMapping[key];
          if (selectedKey === "srCustomId") {
            surveyResPayload.surveyRespondent[selectedKey] = formData[key];
          } else if (selectedKey === "stateCode") {
            surveyResPayload.surveyRespondent[selectedKey] = {
              id: formData[key],
            };
          } else surveyResPayload.surveyRespondent[selectedKey] = formData[key];
        }
      }

      for (const key in formData) {
        if (soMapping.hasOwnProperty(key)) {
          const selectedKey = soMapping[key];
          if (selectedKey === "stateCode") {
            surveyOrgPayload[selectedKey] = { id: formData[key] };
          } else surveyOrgPayload[selectedKey] = formData[key];
        }
      }

      if (srDataUpdated) {
        updatePanelCall(surveyResPayload.surveyRespondent);
      }

      if (soDataUpdated) {
        updateSurveyOrganization(surveyOrgPayload)
          .then((response) => {
            console.log("Survey Org Updated Successfully", response);

            handleClosePanel();
          })
          .catch((error) => {
            console.error("Error Updating Survey Org", error);
          });
      }
      if (!srDataUpdated && !soDataUpdated) {
        handleClosePanel();
      }
    } else {
      const surveyResPayload = {};
      const surveyOrgPayload = {};

      for (const key in formData) {
        if (srMapping.hasOwnProperty(key)) {
          let selectedKey = srMapping[key];
          if (selectedKey === "stateCode") {
            surveyResPayload[selectedKey] = { id: formData[key] };
          } else {
            surveyResPayload[selectedKey] = formData[key];
          }
        }
      }

      for (const key in formData) {
        if (soMapping.hasOwnProperty(key)) {
          let selectedKey = soMapping[key];
          if (selectedKey === "stateCode") {
            surveyOrgPayload[selectedKey] = { id: formData[key] };
          } else {
            surveyOrgPayload[selectedKey] = formData[key];
          }
        }
      }

      const srPayload = {
        surveyOrganization: surveyOrgPayload,
        ...surveyResPayload,
      };
      createPanelSave(srPayload);
    }
  };

  const createPanelSave = async (srPayload) => {
    try {
      const response = await savePanelMemberAssignment({
        surveyPanelId: selectedPanelId,
        organizationId: orgId,
        surveyRespondent: srPayload,
      });
      if (response?.response?.status === 200 || response?.status === 200) {
        handleClosePanel();
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving create panel", error);
    }
  };
  const updatePanelCall = async (surveyRespondent) => {
    try {
      const response = await updateSurveyRespondent(surveyRespondent);
      if (response?.response?.status === 200 || response?.status === 200) {
        handleClosePanel();
      } else if (
        response?.response?.status === 400 ||
        response?.status === 400
      ) {
        setErrorMsg(response?.response?.data?.detail);
      }
    } catch (error) {
      console.log("Error Updating Survey Respondent", error);
    }
  };
  const columnStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  };

  return (
    <div className="panelForm">
      <form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
        <label>
          Please edit the survey respondent and/or the survey organization
          information
        </label>
        <Typography color="primary" style={{ fontWeight: "bold" }}>
          * ID and Username are required. Username must be an email address.
        </Typography>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button variant="outlined" onClick={handleClosePanel}>
              Cancel
            </Button>
          </div>
          {(isSuperUser || isBuilderAdminUser) && (
            <div style={{ display: "flex", gap: "15px" }}>
              <Button onClick={handleSave}>Save</Button>
            </div>
          )}
        </div>
        {errorMsg !== "" && (
          <div style={{ textAlign: "right", color: theme.palette.error.main }}>
            {errorMsg}
          </div>
        )}
        <Divider color={theme.palette.blue.main} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {/* First column */}

          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography
              color="primary"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              Survey Respondent Information
            </Typography>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Id</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.sr_Custom_Id"
                  variant="outlined"
                  value={formData["survey_respondent.sr_Custom_Id"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">First Name</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.first_name"
                  variant="outlined"
                  value={formData["survey_respondent.first_name"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Middle Name</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.middle_initial"
                  variant="outlined"
                  value={formData["survey_respondent.middle_initial"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Last Name</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.last_name"
                  variant="outlined"
                  value={formData["survey_respondent.last_name"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Title</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.title"
                  variant="outlined"
                  value={formData["survey_respondent.title"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Username (6 chars min.)</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.username"
                  variant="outlined"
                  value={formData["survey_respondent.username"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Address 1</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.address1"
                  variant="outlined"
                  value={formData["survey_respondent.address1"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Address 2</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.address2"
                  variant="outlined"
                  value={formData["survey_respondent.address2"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">City</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.city"
                  variant="outlined"
                  value={formData["survey_respondent.city"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">County</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.county"
                  variant="outlined"
                  value={formData["survey_respondent.county"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">State</Typography>
                <FormControl sx={{ width: "220px" }}>
                  <Select
                    size="small"
                    // multiple
                    variant="outlined"
                    value={formData["survey_respondent.state_id"]}
                    onChange={handleChange}
                    name="survey_respondent.state_id"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.longName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Zip</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.zip_code"
                  variant="outlined"
                  value={formData["survey_respondent.zip_code"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Zip Ext.</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.zip_code_ext"
                  variant="outlined"
                  value={formData["survey_respondent.zip_code_ext"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Telephone Number</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.phone_number"
                  variant="outlined"
                  value={formData["survey_respondent.phone_number"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Fax Number</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.fax_number"
                  variant="outlined"
                  value={formData["survey_respondent.fax_number"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Email Address</Typography>
                <TextField
                  size="small"
                  name="survey_respondent.email"
                  variant="outlined"
                  value={formData["survey_respondent.email"]}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          {/* Second column */}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography
              color="primary"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
            >
              Survey Organization Information
            </Typography>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Custom Id</Typography>
                <TextField
                  size="small"
                  name="survey_organization.custom_id"
                  variant="outlined"
                  value={formData["survey_organization.custom_id"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Name</Typography>
                <TextField
                  size="small"
                  name="survey_organization.name"
                  variant="outlined"
                  value={formData["survey_organization.name"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Address 1</Typography>
                <TextField
                  size="small"
                  name="survey_organization.address1"
                  variant="outlined"
                  value={formData["survey_organization.address1"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Address 2</Typography>
                <TextField
                  size="small"
                  name="survey_organization.address2"
                  variant="outlined"
                  value={formData["survey_organization.address2"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">City</Typography>
                <TextField
                  size="small"
                  name="survey_organization.city"
                  variant="outlined"
                  value={formData["survey_organization.city"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">County</Typography>
                <TextField
                  size="small"
                  name="survey_organization.county"
                  variant="outlined"
                  value={formData["survey_organization.county"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">State</Typography>
                <FormControl sx={{ width: "220px" }}>
                  <Select
                    size="small"
                    // multiple
                    variant="outlined"
                    value={formData["survey_organization.state_id"]}
                    onChange={handleChange}
                    name="survey_organization.state_id"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.longName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Zip</Typography>
                <TextField
                  size="small"
                  name="survey_organization.zip_code"
                  variant="outlined"
                  value={formData["survey_organization.zip_code"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Zip Ext.</Typography>

                <TextField
                  size="small"
                  name="survey_organization.zip_code_ext"
                  variant="outlined"
                  value={formData["survey_organization.zip_code_ext"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Telephone Number</Typography>
                <TextField
                  size="small"
                  name="survey_organization.phone_number"
                  variant="outlined"
                  value={formData["survey_organization.phone_number"]}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Fax Number</Typography>
                <TextField
                  size="small"
                  name="survey_organization.fax_number"
                  variant="outlined"
                  value={formData["survey_organization.fax_number"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Location</Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <FormControl component="fieldset" sx={{ width: "220px" }}>
                    <RadioGroup
                      row
                      name="survey_organization.urban_flag"
                      value={
                        formData["survey_organization.urban_flag"] === true
                          ? "urban"
                          : formData["survey_organization.urban_flag"] === false
                          ? "rural"
                          : null
                      }
                      onChange={handleChange}
                    >
                      <FormControlLabel
                        value="urban"
                        control={<Radio />}
                        label="Urban"
                      />
                      <FormControlLabel
                        value="rural"
                        control={<Radio />}
                        label="Rural"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            </div>
            <div style={rowStyle}>
              <div style={columnStyle}>
                <Typography variant="h6">Website URL</Typography>
                <TextField
                  size="small"
                  name="survey_organization.url"
                  variant="outlined"
                  value={formData["survey_organization.url"]}
                  onChange={handleChange}
                />
              </div>
              <div style={columnStyle}>
                <Typography variant="h6">Region</Typography>
                <TextField
                  size="small"
                  name="survey_organization.region"
                  variant="outlined"
                  value={formData["survey_organization.region"]}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          {/* first column */}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography
              color="primary"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
              onClick={toggleResProp}
            >
              {showResProp ? "-" : "+"} Additional Respondent Properties
            </Typography>
            {showResProp && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 1</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute1"
                      variant="outlined"
                      value={formData["survey_respondent.attribute1"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 2</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute2"
                      variant="outlined"
                      value={formData["survey_respondent.attribute2"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 3</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute3"
                      variant="outlined"
                      value={formData["survey_respondent.attribute3"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 4</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute4"
                      variant="outlined"
                      value={formData["survey_respondent.attribute4"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 5</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute5"
                      variant="outlined"
                      value={formData["survey_respondent.attribute5"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 6</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute6"
                      variant="outlined"
                      value={formData["survey_respondent.attribute6"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 7</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute7"
                      variant="outlined"
                      value={formData["survey_respondent.attribute7"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 8</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute8"
                      variant="outlined"
                      value={formData["survey_respondent.attribute8"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 9</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute9"
                      variant="outlined"
                      value={formData["survey_respondent.attribute9"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 10</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute10"
                      variant="outlined"
                      value={formData["survey_respondent.attribute10"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 11</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute11"
                      variant="outlined"
                      value={formData["survey_respondent.attribute11"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 12</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute12"
                      variant="outlined"
                      value={formData["survey_respondent.attribute12"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 13</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute13"
                      variant="outlined"
                      value={formData["survey_respondent.attribute13"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 14</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute14"
                      variant="outlined"
                      value={formData["survey_respondent.attribute14"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 15</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute15"
                      variant="outlined"
                      value={formData["survey_respondent.attribute15"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 16</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute16"
                      variant="outlined"
                      value={formData["survey_respondent.attribute16"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 17</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute17"
                      variant="outlined"
                      value={formData["survey_respondent.attribute17"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 18</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute18"
                      variant="outlined"
                      value={formData["survey_respondent.attribute18"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 19</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute19"
                      variant="outlined"
                      value={formData["survey_respondent.attribute19"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 20</Typography>
                    <TextField
                      size="small"
                      name="survey_respondent.attribute20"
                      variant="outlined"
                      value={formData["survey_respondent.attribute20"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Second column */}
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <Typography
              color="primary"
              style={{ fontWeight: "bold", textDecoration: "underline" }}
              onClick={toggleOrgProp}
            >
              {showOrgProp ? "-" : "+"} Additional Organization Properties
            </Typography>
            {showOrgProp && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 1</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute1"
                      variant="outlined"
                      value={formData["survey_organization.attribute1"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 2</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute2"
                      variant="outlined"
                      value={formData["survey_organization.attribute2"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 3</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute3"
                      variant="outlined"
                      value={formData["survey_organization.attribute3"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 4</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute4"
                      variant="outlined"
                      value={formData["survey_organization.attribute4"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 5</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute5"
                      variant="outlined"
                      value={formData["survey_organization.attribute5"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 6</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute6"
                      variant="outlined"
                      value={formData["survey_organization.attribute6"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 7</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute7"
                      variant="outlined"
                      value={formData["survey_organization.attribute7"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 8</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute8"
                      variant="outlined"
                      value={formData["survey_organization.attribute8"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 9</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute9"
                      variant="outlined"
                      value={formData["survey_organization.attribute9"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 10</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute10"
                      variant="outlined"
                      value={formData["survey_organization.attribute10"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 11</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute11"
                      variant="outlined"
                      value={formData["survey_organization.attribute11"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 12</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute12"
                      variant="outlined"
                      value={formData["survey_organization.attribute12"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 13</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute13"
                      variant="outlined"
                      value={formData["survey_organization.attribute13"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 14</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute14"
                      variant="outlined"
                      value={formData["survey_organization.attribute14"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 15</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute15"
                      variant="outlined"
                      value={formData["survey_organization.attribute15"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 16</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute16"
                      variant="outlined"
                      value={formData["survey_organization.attribute16"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 17</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute17"
                      variant="outlined"
                      value={formData["survey_organization.attribute17"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 18</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute18"
                      variant="outlined"
                      value={formData["survey_organization.attribute18"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div style={rowStyle}>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 19</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute19"
                      variant="outlined"
                      value={formData["survey_organization.attribute19"]}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={columnStyle}>
                    <Typography variant="h6">Attribute 20</Typography>
                    <TextField
                      size="small"
                      name="survey_organization.attribute20"
                      variant="outlined"
                      value={formData["survey_organization.attribute20"]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Divider color={theme.palette.blue.main} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>
            <Button variant="outlined" onClick={handleClosePanel}>
              Cancel
            </Button>
          </div>

          {(isSuperUser || isBuilderAdminUser) && (
            <div style={{ display: "flex", gap: "20px" }}>
              <Button onClick={handleSave}>Save</Button>
            </div>
          )}
        </div>

        {errorMsg !== "" && (
          <div style={{ textAlign: "right", color: theme.palette.error.main }}>
            {errorMsg}
          </div>
        )}
      </form>
    </div>
  );
};

export default PanelMember;
