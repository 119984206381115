import React, { useState } from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { htmlParser } from "../../../Utility/constants";

const InfoPopup = ({ infoOpen, setInfoOpen, validateFile }) => {
  const handleClose = () => setInfoOpen(false);

  const info1 = `<div>
<p style="text-align: center;">
  <strong>Data Import Instructions</strong>
</p>
<p>
  The data import process is done in two steps:
</p>
<p>
  <strong>Step 1. Validate the data import file:</strong><br>
  a. Select the file to import.<strong>The file should contain tab-separated data.</strong><br>
  b. The system validates the file. This step checks that:
</p>
<ul>
  <li>
    The header record in the file contains a SR_CUSTOM_ID field.
  </li>
  <li>
    Each survey field name in the header matches a field name in the survey.
  </li>
  <li>
    For checkbox fields, the header contains one item for each of the checkbox options. In this case each item name is of the format: [field name]-[value stored for the checkbox option] (e.g. "GEN-GENH", where "GEN" is the field name, and "GENH" is the value stored for that checkbox option.)
  </li>
  <li>
    Each of the SR_CUSTOM_ID values exists in the survey panel.
  </li>
</ul>
<p>
  c. If any errors occur when validating the file, those are listed. All errors will need to be resolved before being allowed to actually import the data.<br>
  d. After correcting any errors in the file, select the file again for it to be re-validated.
</p>
<p>
  <strong>Step 2. Import the data:</strong><br>
  Once the file is successfully validated, you will be presented with the option to actually import the data. When prompted:
</p>
<p>
  a. Select the file again.
</p>
<p>
  b. The import will automatically begin. A link will appear to view the progress of the import.
</p>
</div>`;

  const info2 = `<div>
<p style="text-align: center;">
  <strong style="text-align: center;">Data Import Instructions</strong>
</p>
<ul>
  <li>
    <p><strong>Import Options:</strong></p>
    <ul>
      <li>
        <p>"Add new filings or update existing filings with the data in the file" For any cases where a filing already exists in the system for a hospital in the import file, the answers in the file will be MERGED with the answers in the existing filing. This means that:</p>
        <ul>
          <li>
            <p>If there is an answer for a field in the import file, and there is an answer for that field in the existing filing, the answer from the file will be added to the existing filing.</p>
          </li>
          <li>
            <p>If there is an answer for a field in the import file, and there is already an answer for that field in the existing file, what is in the import file will <strong>overwrite</strong> the existing answer on the filing.</p>
          </li>
          <li>
            <p>If the answer for a field in the import file is the “no data” symbol (a period), that indicates there should not be an answer for that field, and so if the existing filing does have an answer for that field, that answer will be deleted.</p>
          </li>
          <li>
            <p>The data import file is not required to include all of the fields in the survey. As such, an existing answer in the existing filing will not be changed if that field is not included in the data import file.</p>
          </li>
        </ul>
      </li>
      <li>
        <p>"Delete all existing answers for each filing in the file before importing data" This option will delete all existing answers for the hospitals in the file before importing the data.</p>
      </li>
      
    </ul>
        <li>
        <p><strong>Check to disable validation rules execution:</strong><br>Selecting this option will import the data without running the survey’s validation rules against the data. This option is not selected by default since in most cases the user will want to validate the data.</p>
       </li>
    </li>
</ul>
</div>`;

  return (
    <Modal open={infoOpen} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "50%",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 24,
          p: 3,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box sx={{ position: "absolute", top: 8, right: 8 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography>
          {validateFile ? htmlParser(info1) : htmlParser(info2)}
        </Typography>
      </Box>
    </Modal>
  );
};

export default InfoPopup;
