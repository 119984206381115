import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Checkbox,
  Typography,
  Box,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
  Divider,
  FormControlLabel,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import { formatDate } from "../../Utility/constants";
import {
  showStatusPercentages,
  getSurveyFilingStatusByCustomId,
} from "../Survey/Survey.service";
import theme from "../../theme";
import { SURVEY_STATUSES, USER_ROLES } from "../../Utility/constants";

const SurveyInfo = ({ statusInfo, handleCloseSurveyInfo }) => {
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );

  const [formData, setFormData] = useState({
    surveyId: statusInfo.id,
    customId: "",
    stateIds: [],
    excludeStates: true,
    personId: personId,
  });

  const baseStats = [
    {
      status: SURVEY_STATUSES.SUBMITTED,
      count: 0,
      percentage: "0.0",
      totalCount: 0,
    },
    {
      status: SURVEY_STATUSES.COMPLETED,
      count: 0,
      percentage: "0.0",
      totalCount: 0,
    },
    {
      status: SURVEY_STATUSES.IN_PROGRESS,
      count: 0,
      percentage: "0.0",
      totalCount: 0,
    },
    {
      status: SURVEY_STATUSES.ERRORS,
      count: 0,
      percentage: "0.0",
      totalCount: 0,
    },
    {
      status: SURVEY_STATUSES.NOT_STARTED,
      count: 0,
      percentage: "0.0",
      totalCount: 0,
    },
    {
      status: SURVEY_STATUSES.NOT_YET_LOGGED_IN,
      count: 0,
      percentage: "0.0",
      totalCount: 0,
    },
  ];
  const [statsData, setStats] = useState([]);
  const [fillingMsg, setFillingMsg] = useState("");
  const [fillingErrorMsg, setFillingErrorMsg] = useState("");
  const [memberCount, setMemberCount] = useState(0);
  const states = useSelector((state) => state?.survey?.states);
  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );

  const handleClose = async (e) => {
    handleCloseSurveyInfo();
  };
  const handleCustomId = async (e) => {
    if (formData.customId.trim() !== "") {
      const res = await getSurveyFilingStatusByCustomId({
        surveyId: formData.surveyId,
        customId: formData.customId,
      });
      if (res?.data) {
        const successMsg = formData.customId + "'s status: " + res.data;
        setFillingMsg(successMsg);
        setFillingErrorMsg("");
      } else {
        setFillingErrorMsg("AHA ID not found");
        setFillingMsg("");
      }
    } else {
      setFillingErrorMsg("Please enter AHA ID");
      setFillingMsg("");
    }
  };

  const getFilteredRow = (data, entryStatus) => {
    return data.filter((entry) => entry.status === entryStatus);
  };
  const handleStatusPercentages = async (e) => {
    setMemberCount(0);
    const states =
      formData.stateIds.length > 0 ? formData.stateIds.join(",") : "";

    const payload = {
      surveyId: formData.surveyId,
      stateIds: states,
      excludeStates: formData.excludeStates,
      personId: personId,
    };
    const res = await showStatusPercentages(payload);
    if (res?.data?.length > 0) {
      const data = res.data;
      setMemberCount(res.data[0].totalCount);
      const resultSubmitted = getFilteredRow(data, SURVEY_STATUSES.SUBMITTED);
      const resultCompleted = getFilteredRow(data, SURVEY_STATUSES.COMPLETED);
      const resultInProgress = getFilteredRow(
        data,
        SURVEY_STATUSES.IN_PROGRESS
      );
      const resultErrors = getFilteredRow(data, SURVEY_STATUSES.ERRORS);
      const resultLoggedIn = getFilteredRow(data, SURVEY_STATUSES.NOT_STARTED);
      const resultNotLoggedIn = getFilteredRow(
        data,
        SURVEY_STATUSES.NOT_YET_LOGGED_IN
      );
      const updatedStats = [
        {
          status: SURVEY_STATUSES.SUBMITTED,
          count: resultSubmitted[0]?.count || 0,
          percentage: resultSubmitted[0]?.percentage || "0.0",
          totalCount: resultSubmitted[0]?.totalCount || 0,
        },
        {
          status: SURVEY_STATUSES.COMPLETED,
          count: resultCompleted[0]?.count || 0,
          percentage: resultCompleted[0]?.percentage || "0.0",
          totalCount: resultCompleted[0]?.totalCount || 0,
        },
        {
          status: SURVEY_STATUSES.IN_PROGRESS,
          count: resultInProgress[0]?.count || 0,
          percentage: resultInProgress[0]?.percentage || "0.0",
          totalCount: resultInProgress[0]?.totalCount || 0,
        },
        {
          status: SURVEY_STATUSES.ERRORS,
          count: resultErrors[0]?.count || 0,
          percentage: resultErrors[0]?.percentage || "0.0",
          totalCount: resultErrors[0]?.totalCount || 0,
        },
        {
          status: SURVEY_STATUSES.NOT_STARTED,
          count: resultLoggedIn[0]?.count || 0,
          percentage: resultLoggedIn[0]?.percentage || "0.0",
          totalCount: resultLoggedIn[0]?.totalCount || 0,
        },
        {
          status: SURVEY_STATUSES.NOT_YET_LOGGED_IN,
          count: resultNotLoggedIn[0]?.count || 0,
          percentage: resultNotLoggedIn[0]?.percentage || "0.0",
          totalCount: resultNotLoggedIn[0]?.totalCount || 0,
        },
      ];

      setStats(updatedStats);
    } else {
      setStats(baseStats);
    }
  };
  useEffect(() => {
    handleStatusPercentages();
  }, []);
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const isStakeholderUser =
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME
      ? true
      : false;
  return (
    <>
      {!!statusInfo && (
        <div className="panelModal">
          <div className="panelDiv">
            <Typography variant="label">{statusInfo.surveyName}</Typography>
          </div>
          <div className="panelForm">
            <Box sx={{ flexGrow: 1 }} marginBottom={2}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography color="primary">
                    <u>Status</u>
                  </Typography>
                  <Typography color="brown">{statusInfo.status}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="primary">
                    <u>Open Date</u>
                  </Typography>
                  <Typography color="brown">
                    {formatDate(statusInfo.createdDate)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Typography color="primary">
                    <u>Expiration Date</u>
                  </Typography>
                  <Typography color="brown">
                    {statusInfo.deactivateDate
                      ? formatDate(statusInfo.deactivateDate)
                      : ""}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography color="primary">
                    <u>Author</u>
                  </Typography>
                  <Typography color="brown">{statusInfo.createdBy}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Divider color={theme.palette.blue.main} />
            {!isStakeholderUser && (
              <Box sx={{ flexGrow: 1 }} marginTop={2}>
                <Grid container spacing={3}>
                  <Grid item xs={2}>
                    <Typography paddingTop={1}>
                      <b>View Statistics For</b>
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControl fullWidth>
                      <InputLabel className="inputLabel">
                        Select State
                      </InputLabel>
                      <Select
                        size="small"
                        multiple
                        variant="outlined"
                        value={formData["stateIds"]}
                        onChange={handleChange}
                        name="stateIds"
                        label="Select State"
                      >
                        {states.map((state) => (
                          <MenuItem key={state.id} value={state.id}>
                            {state.longName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="excludeStates"
                          checked={formData.excludeStates}
                          onChange={handleChange}
                        />
                      }
                      label="Exclude States"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      onClick={() => handleStatusPercentages()}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            )}
            {statsData.length > 0 && (
              <Box sx={{ flexGrow: 1 }} marginTop={5} marginBottom={2}>
                <Grid container spacing={1}>
                  <Grid item xs={1}>
                    <Typography color="primary">
                      <u>{statsData[0].status}</u>
                    </Typography>
                    <Typography color="brown">
                      {statsData[0].count} ({statsData[0].percentage}%)
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <Typography color="primary">
                      <u>{statsData[1].status}</u>
                    </Typography>
                    <Typography color="brown">
                      {statsData[1].count} ({statsData[1].percentage}%)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">
                      <u>{statsData[2].status}</u>
                    </Typography>
                    <Typography color="brown">
                      {statsData[2].count} ({statsData[2].percentage}%)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">
                      <u>{statsData[3].status}</u>
                    </Typography>
                    <Typography color="brown">
                      {" "}
                      {statsData[3].count} ({statsData[3].percentage}%)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">
                      <u>{statsData[4].status}</u>
                    </Typography>
                    <Typography color="brown">
                      {statsData[4].count} ({statsData[4].percentage}%)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">
                      <u>{statsData[5]?.status}</u>
                    </Typography>
                    <Typography color="brown">
                      {statsData[5]?.count} ({statsData[5]?.percentage}%)
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography color="primary">
                      <u>Panel Members</u>
                    </Typography>
                    <Typography color="brown">
                      {memberCount ? memberCount : statsData[0]?.totalCount}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}

            <Box sx={{ flexGrow: 1 }} marginTop={2}>
              <Grid container spacing={3}>
                <Grid item xs={1}>
                  <Typography paddingTop={1}>
                    <b>AHA ID</b>
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    size="small"
                    name="customId"
                    variant="outlined"
                    inputProps={{
                      maxLength: 20,
                    }}
                    value={formData["customId"]}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button
                    fullWidth
                    variant="contained"
                    onClick={() => handleCustomId()}
                  >
                    Submit
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Typography color="primary" variant="h5">
                    {fillingMsg}
                  </Typography>
                  <Typography color="error" variant="h5">
                    {fillingErrorMsg}
                  </Typography>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ flexGrow: 1 }} marginTop={5}>
              <Grid container spacing={3} style={{ textAlign: "right" }}>
                <Grid item xs={6}>
                  <Button variant="contained" onClick={() => handleClose()}>
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
        </div>
      )}
      ;
    </>
  );
};
export default SurveyInfo;
