import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  TextField,
  Card,
  CardContent,
  Button,
  LinearProgress,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import {
  createSurveyFiling,
  getSimplePanelMemberAssignments,
  getSurveyFilingIdsByCustomIds,
  validateCustomIds,
  getDataFilters,
} from "../Survey/Survey.service";
import TextArea from "../../components/Common/TextArea";
import {
  setBatchUpdateCurrentView,
  setSelectedListPmas,
  setSelectedStates,
  setSelectedSurveyFilingIds,
  setSelectedTextPmas,
  setSelectedDataFilters,
} from "../Survey/Survey.slice";
import { useNavigate } from "react-router-dom";

const ChoosePMA = () => {
  const navigate = useNavigate();
  const [assignments, setAssignments] = useState([]);
  const dispatch = useDispatch();
  const [selectedFilters, setSelectedFilters] = useState("none");
  const [filterList, setFilterList] = useState([]);

  const [stateId, setStateId] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const surveyId = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyId
  );
  const surveyName = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyName
  );
  const surveyPanelId = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyPanelId
  );
  const states = useSelector((state) => state?.survey?.states);
  const selectedStates = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedStates
  );
  const selectedListPmas = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedListPmas
  );
  const selectedTextPmas = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedTextPmas
  );
  const selectedDataFilters = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedDataFilters
  );

  const [customIdsList, setCustomIdsList] = useState("");
  const [panelList, setPanelList] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [loadingBatchUpdate, setLoadingBatchUpdate] = useState(false);

  useEffect(() => {
    setStateId(selectedStates);
    setPanelList(selectedListPmas);
    setCustomIdsList(selectedTextPmas);
    setSelectedFilters(selectedDataFilters);
  }, [selectedListPmas, selectedStates, selectedTextPmas, selectedDataFilters]);

  const handleFilterChange = (event) => {
    setSelectedFilters(event.target.value);
  };
  const handleStateChange = (event) => {
    setStateId(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handlePanelListChange = (event, value) => {
    setPanelList([...value]);
  };

  const handleTextAreaChange = (value) => {
    setCustomIdsList(value);
    setErrors([]);
    setSubmitDisabled(false);
  };

  const createNewSurveyFiling = async (surveyId, srId) => {
    try {
      const response = await createSurveyFiling({
        surveyId: surveyId,
        surveyRespondentId: srId,
      });
      const newSurveyFilingId = await response?.data?.id;
      return newSurveyFilingId;
    } catch (error) {
      console.log("Error creating Survey filing", error);
      return null;
    }
  };

  const getfilteredData = async (memberList) => {
    setLoadingBatchUpdate(true);
    let surveyFilingIds = [];
    surveyFilingIds = await Promise.all(
      memberList.map(
        async ({
          surveyFilingId,
          surveyRespondent: {
            id: surveyRespondentId,
            surveyOrganization: { customId },
          },
        }) => {
          if (surveyFilingId === 0) {
            const newSurveyFilingId = await createNewSurveyFiling(
              surveyId,
              surveyRespondentId
            );
            return {
              customId,
              surveyFilingId: newSurveyFilingId,
              surveyRespondentId,
            };
          } else {
            return {
              customId,
              surveyFilingId,
              surveyRespondentId,
            };
          }
        }
      )
    );
    surveyFilingIds = surveyFilingIds.filter(
      (item, index, self) =>
        index === self.findIndex((t) => t.customId === item.customId)
    );

    if (surveyFilingIds.length > 0) {
      dispatch(setSelectedSurveyFilingIds(surveyFilingIds));
      dispatch(setBatchUpdateCurrentView(2));
    }

    setLoadingBatchUpdate(false);
    dispatch(setSelectedListPmas(panelList));
    dispatch(setSelectedStates(stateId));
    dispatch(setSelectedTextPmas(customIdsList));
    dispatch(setSelectedDataFilters(selectedFilters));
  };
  const handleSubmit = async () => {
    setSubmitDisabled(true);
    const idsArray = customIdsList.split("\n").filter((id) => id.trim() !== "");
    const response = await validateCustomIds({
      surveyPanelId: surveyPanelId,
      customIds: idsArray,
    });

    if (response?.data?.length > 0) {
      setErrors(response.data);
    } else {
      setLoadingBatchUpdate(false);
      let surveyFilingIds = [];

      if (panelList?.length > 0) {
        getfilteredData(panelList);
      } else if (selectedFilters !== "none") {
        if (panelList?.length > 0) {
          getfilteredData(panelList);
        } else {
          getfilteredData(assignments);
        }
      }

      if (idsArray?.length > 0) {
        setLoadingBatchUpdate(true);
        const response = await getSurveyFilingIdsByCustomIds({
          surveyId: surveyId,
          customIds: idsArray,
        });

        const newSurveyFilingIds = await Promise.all(
          response.data.map(
            async ({ surveyFilingId, surveyRespondentId, customId }) => {
              if (surveyFilingId === 0) {
                const newSurveyFilingId = await createNewSurveyFiling(
                  surveyId,
                  surveyRespondentId
                );
                return {
                  customId,
                  surveyFilingId: newSurveyFilingId,
                  surveyRespondentId,
                };
              } else {
                return {
                  customId,
                  surveyFilingId,
                  surveyRespondentId,
                };
              }
            }
          )
        );

        surveyFilingIds = surveyFilingIds.concat(newSurveyFilingIds);

        surveyFilingIds = surveyFilingIds.filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.customId === item.customId)
        );

        if (surveyFilingIds.length > 0) {
          dispatch(setSelectedSurveyFilingIds(surveyFilingIds));
          dispatch(setBatchUpdateCurrentView(2));
        }
      }

      setLoadingBatchUpdate(true);
      dispatch(setSelectedListPmas(panelList));
      dispatch(setSelectedStates(stateId));
      dispatch(setSelectedTextPmas(customIdsList));
    }
  };

  useEffect(() => {
    const fetchAssignments = async () => {
      setShowMessage(false);
      let payload = {
        surveyId: surveyId,
        searchString: searchValue,
        filterCriteria: {
          state_id: stateId.join(),
        },
      };
      let dataFilters = {};
      if (selectedFilters !== "none") {
        setShowMessage(true);
        dataFilters = { dataFilterId: selectedFilters };
      }

      payload = { ...payload, ...dataFilters };

      try {
        const response = await getSimplePanelMemberAssignments(payload);
        setAssignments(response.data);
        setShowMessage(false);
      } catch (error) {
        console.error("Error fetching assignments:", error);
      }
    };

    if (
      (searchValue && searchValue?.length > 0 && searchValue?.trim() !== "") ||
      selectedFilters !== "none" ||
      stateId.length > 0
    ) {
      fetchAssignments();
    } else {
      setAssignments([]);
    }
  }, [searchValue, stateId, selectedFilters]);

  useEffect(() => {
    if (
      panelList?.length === 0 &&
      customIdsList === "" &&
      selectedFilters === "none"
    ) {
      setSubmitDisabled(true);
    } else {
      if (assignments.length !== 0) setSubmitDisabled(false);
    }
  }, [panelList, customIdsList, selectedFilters, assignments]);

  useEffect(() => {
    const fetchDataFilters = async () => {
      setFilterList([]);
      try {
        const response = await getDataFilters(surveyId);
        setFilterList(response?.data || []);
      } catch (error) {
        console.error("Error fetching Data Filters:", error);
      }
    };

    if (surveyId) {
      fetchDataFilters();
    }
  }, [surveyId]);
  return (
    <>
      <Typography color="primary" variant="label">
        Batch Update - {surveyName}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0",
        }}
      >
        <div style={{ width: "39%" }}>
          <Card>
            <CardContent>
              <div>
                <Typography variant="label">Choose State</Typography>
              </div>
              <div>
                <FormControl sx={{ width: "300px", marginTop: "15px" }}>
                  <Select
                    multiple
                    size="small"
                    variant="outlined"
                    onChange={handleStateChange}
                    value={stateId}
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.longName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </CardContent>
          </Card>
        </div>
        <div style={{ width: "59%" }}>
          <Card>
            <CardContent>
              <Typography variant="label" paragraph>
                Please select any number of panel members from the list with
                Ctrl key
              </Typography>
              <Grid container>
                <Grid item xs={showMessage ? 6 : 12}>
                  <FormControl>
                    <InputLabel className="inputLabel">
                      Select data filter
                    </InputLabel>
                    <Select
                      size="small"
                      sx={{ width: "340px" }}
                      value={selectedFilters}
                      onChange={handleFilterChange}
                      label="Select data filter"
                    >
                      <MenuItem value="none">
                        <em>None</em>
                      </MenuItem>
                      {filterList.map((filter) => (
                        <MenuItem key={filter.id} value={filter.id}>
                          {filter.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Autocomplete
                    value={panelList}
                    size="small"
                    filterOptions={(x) => x}
                    onInputChange={handleSearchChange}
                    onChange={handlePanelListChange}
                    sx={{ width: "340px", marginTop: "15px" }}
                    multiple
                    id="autocomplete-input"
                    options={assignments}
                    getOptionLabel={(option) =>
                      `${option.surveyRespondent.surveyOrganization.customId}  ${option.surveyRespondent.surveyOrganization.name} `
                    }
                    filterSelectedOptions
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                  />
                </Grid>
                {showMessage && (
                  <Grid item xs={6}>
                    <Typography
                      variant="label"
                      style={{ fontWeight: "bold", fontSize: "13px" }}
                    >
                      Applying data filter… this may take up to several minutes
                      as the data filter rule is run against each filing.
                      Selecting one or a couple states from the state list will
                      speed things up.
                    </Typography>
                    <Box>
                      <LinearProgress color="primary" />
                    </Box>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>
      <Card>
        <CardContent>
          <Typography variant="label">
            AHA IDs can be entered here, one AHA ID per line:
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "15px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                rowGap: "20px",
              }}
            >
              <TextArea
                rows={10}
                value={customIdsList}
                onChange={handleTextAreaChange}
                sx={{ maxWidth: "400px" }}
              />
              <div>
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  disabled={submitDisabled}
                >
                  Submit
                </Button>
                <Button
                  variant="outlined"
                  sx={{ marginLeft: "15px" }}
                  onClick={() => navigate("/")}
                >
                  Back
                </Button>
                {loadingBatchUpdate && " Retrieving results..."}
              </div>
            </div>

            {errors.length > 0 && (
              <div
                style={{
                  margin: "0 auto",
                }}
              >
                {errors.map((error, index) => (
                  <Typography color="error">{error}</Typography>
                ))}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </>
  );
};

export default ChoosePMA;
