import parse from "html-react-parser";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const LOCAL_STORAGE_KEYS = {
  TOKEN_KEY: "authToken",
};

const options = {
  replace: (domNode) => {
    const extractText = (node) => {
      if (node.type === "text") {
        return node.data || "";
      } else if (node.children) {
        return node.children.map(extractText).join("");
      } else {
        return "";
      }
    };

    if (domNode.attribs && domNode.attribs.class === "remove") {
      return extractText(domNode);
    }
  },
};

export const htmlParser = (htmlString) => {
  return parse(htmlString ? `${htmlString}` : "", options);
};

export const formatDate = (date) => {
  return dayjs.utc(date).local().format("MM-DD-YYYY hh:mm A");
};
export const formatShortDateDash = (date) => {
  return dayjs.utc(date).local().format("YYYY-MM-DD");
};
export const formatShortDate = (date) => {
  return dayjs.utc(date).local().format("MM/DD/YYYY");
};

export const dateInPast = function (firstDate) {
  const parseDate = dayjs.utc(firstDate).local().format("YYYY-MM-DD");
  const newDate = new Date(parseDate);
  const today = new Date();
  if (newDate.setHours(0, 0, 0, 0) <= today.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};
export const compareTwoDates = function (firstDate, secondDate) {
  const parseFirstDate = dayjs.utc(firstDate).local().format("YYYY-MM-DD");
  const newFirstDate = new Date(parseFirstDate);
  const parseSecondDate = dayjs.utc(secondDate).local().format("YYYY-MM-DD");
  const newSecondDate = new Date(parseSecondDate);
  if (newFirstDate.setHours(0, 0, 0, 0) <= newSecondDate.setHours(0, 0, 0, 0)) {
    return true;
  }

  return false;
};

export const replaceCommaChar = (string) => {
  const commaWithUtc = string ? string.replace(/,/g, "") : "";
  return string ? '"' + commaWithUtc + '"' : "";
};

export const getTextFromHTML = (htmlString) => {
  if (htmlString) {
    htmlString = htmlString.replace(/<\/(li|p|td|th|tr|div)>/g, " </$1>");
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    let textContent = tempDiv.textContent || tempDiv.innerText || "";
    textContent = textContent.replace(/\s+/g, " ").trim();
    return textContent;
  } else return "";
};

export const truncateText = (text, limit) => {
  if (text.length > limit) {
    return text.slice(0, limit) + " ...";
  } else {
    return text;
  }
};
export const BASE_URL = process.env.REACT_APP_BASE_URL + "/api/v1";

export const APP_URL = process.env.REACT_APP_BASE_URL;

export const SURVEY_STATUSES = {
  SUBMITTED: "Submitted",
  COMPLETED: "Completed",
  IN_PROGRESS: "In Progress",
  ERRORS: "Errors",
  NOT_STARTED: "Not Started",
  NOT_YET_LOGGED_IN: "Not Yet Logged In",
};

export const USER_ROLES = {
  SURVEY_ADMIN_ROLENAME: "Survey Respondent",
  SURVEY_SUPPORT_ROLENAME: "Survey Response Support Staff",
  BUILDER_SUPER_USER_ROLENAME: "Builder Super User",
  BUILDER_ADMIN_ROLENAME: "Builder Admin",
  BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME: "Stakeholder Admin Level 1",
  BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME: "Stakeholder Admin Level 2",
  BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME: "Stakeholder Admin Level 3",
  BUILDER_AHA_GROUP_USER_ROLENAME: "AHA Group User",
  LICENSEE_ROLENAME: "Licensee",
  MASTER_ROLENAME: "Master",
  CONTACTPOINT_ROLENAME: "Contact Point",
};

export const CLEAN_FORM_DATA = (data) => {
  return Object.fromEntries(
    Object.entries(data)
      .map(([key, value]) => {
        if (
          typeof value === "string" &&
          value.replace(/(&nbsp;|\s|<br\s*\/?>)/g, "").trim() === ""
        ) {
          value = "";
        }
        return [key, value];
      })
      .filter(([key, value]) => value !== null && value !== "")
  );
};
