import React, { useState, useEffect } from "react";
import {
  FormControlLabel,
  FormControl,
  Typography,
  Grid,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  LinearProgress,
  Box,
  IconButton,
} from "@mui/material";
import Button from "../../../components/Common/Button";
import { useLocation } from "react-router";
import {
  setImportCurrentView,
  setSelectedBatchId,
} from "../../Survey/Survey.slice";
import { useDispatch, useSelector } from "react-redux";
import {
  validateUploadFile,
  uploadFilingData,
  surveyKeyFile,
} from "../../Survey/Survey.service";
import BatchProgress from "./BatchProgress";
import { USER_ROLES, htmlParser } from "../../../Utility/constants";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoPopup from "./InfoPopup";

const BatchUpload = () => {
  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const isStakeholderUser =
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME
      ? true
      : false;
  const envTypes = ["Production"]; // , "Staging" Hide option
  const [checked, setChecked] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [totalFilings, setTotalFilings] = useState([]);
  const [totalAnswers, setTotalAnswers] = useState([]);
  const [validateFile, setValidateFile] = useState(true);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingKeyFile, setLoadingKeyFile] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(2);
  const [selectedType, setSelectedType] = useState(envTypes[0]);
  const [exportMsg, setExportMsg] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const location = useLocation();
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;
  const surveyPanelId = location?.state?.surveyPanelId;
  const dispatch = useDispatch();
  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const downloadKeyFile = async () => {
    setLoadingKeyFile(true);
    try {
      const payload = {
        surveyId: surveyId,
        surveyPanelId: surveyPanelId,
      };
      const response = await surveyKeyFile(payload);
      if (response?.status === 200) {
        const keyfile = response.data;
        let headerRowLine = [];
        headerRowLine.push(keyfile + "\n");

        var hiddenElement = document.createElement("a");
        hiddenElement.href =
          "data:text/csv;charset=utf-8," + encodeURIComponent(headerRowLine);

        hiddenElement.target = "_blank";
        hiddenElement.download = "key_" + surveyName + ".csv";
        hiddenElement.click();
        setLoadingKeyFile(false);
      } else {
        console.log(response);
        setLoadingKeyFile(true);
      }
    } catch (error) {
      console.error("Error downloading:", error);
    }
  };
  const handleFileImport = async (event) => {
    setLoadingFile(true);
    setExportMsg(null);
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await uploadFilingData(
        formData,
        surveyId,
        personId,
        selectedOptions,
        !checked
      );
      if (response?.status === 200) {
        setLoadingFile(false);
        setExportMsg("UPLOAD HAS BEEN STARTED!");
        dispatch(setSelectedBatchId(response?.data?.id));
      } else {
        console.log(response);
      }
    } catch (error) {
      console.error("Error exporting:", error);
    }
  };
  const handleFileUpload = async (event) => {
    setLoadingFile(true);
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await validateUploadFile(formData, surveyId);
      if (response?.status === 200) {
        const responseData = response?.data;
        if (responseData.fileErrors.length > 0) {
          setFileErrors(responseData.fileErrors);
          setTotalFilings(responseData.totalFilings);
          setTotalAnswers(responseData.totalAnswers);
          setShowErrors(true);
          setLoadingFile(false);
        } else {
          setValidateFile(false);
          setLoadingFile(false);
        }
      } else {
        console.log("error received");
        setLoadingFile(false);
      }
    } catch (error) {
      console.error("Error exporting:", error);
    }
  };
  useEffect(() => {
    if (selectedType === envTypes[0]) {
      setSelectedOptions(2);
    } else if (selectedType === envTypes[1]) {
      setSelectedOptions(5);
    }
  }, [selectedType]);

  return (
    <div>
      <Typography
        variant="label"
        color="primary"
        sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        Import Data for Survey: {htmlParser(surveyName)}
        <IconButton onClick={() => setInfoOpen(true)}>
          <ErrorOutlineIcon ml={2} color="primary" />
        </IconButton>
        <InfoPopup
          infoOpen={infoOpen}
          setInfoOpen={setInfoOpen}
          validateFile={validateFile}
        />
      </Typography>

      {validateFile && !showErrors && (
        <>
          <Typography
            variant="body1"
            color="primary"
            sx={{ cursor: "pointer", marginBottom: "30px", marginTop: "20px" }}
            onClick={() => downloadKeyFile()}
          >
            Download Key File (Single File Format)
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              component="label"
              style={{ marginRight: "20px" }}
            >
              Select File
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={(e) => handleFileUpload(e)}
                disabled={loadingFile}
              />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(setImportCurrentView(1))}
            >
              Back
            </Button>
          </div>

          {loadingFile && (
            <>
              <Typography style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Validating file, please wait...
              </Typography>
              <Box sx={{ width: "15%" }}>
                <LinearProgress />
              </Box>
            </>
          )}
          {loadingKeyFile && " Downloading Key File..."}
        </>
      )}
      {!validateFile && (
        <>
          <Typography variant="body1">File successfully validated</Typography>
          <Grid container sx={{ marginBottom: "20px", marginTop: "20px" }}>
            {!isStakeholderUser && (
              <Grid item xs={2}>
                <FormControl variant="outlined" sx={{ minWidth: "210px" }}>
                  <InputLabel className="inputLabel">
                    Import Data Into *
                  </InputLabel>
                  <Select
                    value={selectedType}
                    size="small"
                    label="Import Data Into *"
                    name="importData"
                    onChange={(e) => {
                      setSelectedType(e.target.value);
                    }}
                  >
                    {envTypes.map((name, index) => (
                      <MenuItem key={index} value={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={4}>
              <FormControl variant="outlined" sx={{ minWidth: "400px" }}>
                <InputLabel className="inputLabel">Import Options *</InputLabel>
                {selectedType === envTypes[0] && (
                  <Select
                    value={selectedOptions}
                    size="small"
                    label="Import Options *"
                    name="importOptions"
                    onChange={(e) => {
                      setSelectedOptions(e.target.value);
                    }}
                  >
                    <MenuItem key="2" value="1">
                      Delete all existing answers for each filing in the file
                      before importing data
                    </MenuItem>
                    <MenuItem key="3" value="2">
                      Add new filings or update existing filings with the data
                      in the file
                    </MenuItem>
                  </Select>
                )}
                {selectedType === envTypes[1] && (
                  <Select
                    value={selectedOptions}
                    size="small"
                    label="Import Options *"
                    name="importOptions"
                    onChange={(e) => {
                      setSelectedOptions(e.target.value);
                    }}
                  >
                    <MenuItem key="1" value="3">
                      Delete all existing data for this survey before importing
                      file
                    </MenuItem>
                    <MenuItem key="2" value="4">
                      Delete all existing answers for each filing in the file
                      before importing data
                    </MenuItem>
                    <MenuItem key="3" value="5">
                      Add new filings or update existing filings with the data
                      in the file
                    </MenuItem>
                  </Select>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <FormControlLabel
            control={
              <Checkbox checked={checked} onChange={handleCheckboxChange} />
            }
            label="Check to disable validation rules execution"
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "30px",
            }}
          >
            <Button
              variant="contained"
              component="label"
              style={{ marginRight: "20px" }}
            >
              Upload
              <input
                type="file"
                hidden
                accept=".csv"
                onChange={(e) => handleFileImport(e)}
                disabled={loadingFile}
              />
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => dispatch(setImportCurrentView(1))}
            >
              Back
            </Button>
          </div>
          {loadingFile && " Uploading file..."}
        </>
      )}
      {showErrors && (
        <BatchProgress
          fileErrors={fileErrors}
          totalFilings={totalFilings}
          totalAnswers={totalAnswers}
        />
      )}
      {exportMsg}
      {exportMsg && (
        <Typography
          variant="body1"
          color="primary"
          sx={{ cursor: "pointer", marginTop: "10px" }}
          onClick={() => {
            dispatch(setImportCurrentView(8));
          }}
        >
          View Batch Import Progress
        </Typography>
      )}
    </div>
  );
};

export default BatchUpload;
