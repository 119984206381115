import React, { useState, useMemo, useEffect } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useSelector, useDispatch } from "react-redux";
import { createSurveyFiling } from "../../Survey/Survey.service";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Tooltip from "../../../components/Common/Tooltip";
import theme from "../../../theme";
import { useLocation } from "react-router-dom";
import { Typography, Modal } from "@mui/material";
import { setFilterFormData } from "../../Survey/Survey.slice";
import {
  setPersonDisplaySettings,
  findSimplePanelMemberAssignmentsByBatchId,
  getPersonDisplaySettings,
} from "../../Survey/Survey.service";
import FilterForm from "../../PanelManagement/FilterForm";
import {
  initialFormData,
  filterEmptyFormData,
} from "../../PanelManagement/FilterForm";
import Button from "../../../components/Common/Button";
import { htmlParser } from "../../../Utility/constants";

const PanelAssignment = ({ searchPayload, selectedBatch }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const surveyName = location?.state?.surveyName;
  const [surveys, setSurveys] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const surveyPanelId = location?.state?.surveyPanelId;
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const [openModal, setOpenModal] = useState(false);
  const filterFormData = useSelector((state) => state?.survey?.filterFormData);
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const surveyId = location?.state?.surveyId;
  const [columnVisibility, setColumnVisibility] = useState({});

  const handleOpenModal = async () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const initialColumns = {
    surveyFilingId: true,
    shortName: true,
    sr_custom_id: true,
    custom_id: true,
    surveyFilingStatus: true,
    view: true,
    edit: true,
    opde: true,
  };
  const createNewSurveyFiling = async (surveyId, srId) => {
    try {
      const response = await createSurveyFiling({
        surveyId: surveyId,
        surveyRespondentId: srId,
      });
      const newSurveyFilingId = await response?.data?.id;
      return newSurveyFilingId;
    } catch (error) {
      console.log("Error creating Survey filing", error);
      return null;
    }
  };

  const openTaker = async (page, surveyFilingId, srId) => {
    let baseUrl;
    if (page === "opde" && orgId && srId) {
      if (surveyFilingId === 0) {
        const newSurveyFilingId = await createNewSurveyFiling(surveyId, srId);
        if (newSurveyFilingId) {
          baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/${page}/${newSurveyFilingId}/${orgId}`;
        }
      } else
        baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/${page}/${surveyFilingId}/${orgId}`;
    } else
      baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/${page}/${surveyFilingId}`;
    window.open(baseUrl, "_blank");
  };
  useEffect(() => {
    const getDisplaySettings = async () => {
      try {
        const res = await getPersonDisplaySettings({
          personId: personId,
          page: "DataImport",
        });
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const fetchedColumnObj = res.data
            .map((item) => {
              if (item?.type === "column") {
                return item?.name;
              } else return null;
            })
            .reduce((obj, columnName) => {
              if (columnName) {
                obj[columnName] = true;
              }
              return obj;
            }, {});

          for (const key in initialColumns) {
            if (!fetchedColumnObj.hasOwnProperty(key)) {
              fetchedColumnObj[key] = false;
            }
          }

          const fetchedFilterObj = await res.data.reduce((obj, item) => {
            if (item.type === "filter") {
              if (
                [
                  "survey_filing.status_id",
                  "survey_respondent.role_id",
                  "survey_organization.state_id",
                  "survey_respondent.state_id",
                ].includes(item.name)
              ) {
                obj[item.name] = item.value.split(",").map((v) => parseInt(v));
              } else {
                obj[item.name] = item.value;
              }
            }
            return obj;
          }, {});
          if (
            JSON.stringify(fetchedFilterObj) ===
              JSON.stringify(initialFormData) ||
            JSON.stringify(fetchedFilterObj) === JSON.stringify({})
          ) {
            dispatch(setFilterFormData(filterEmptyFormData(initialFormData)));
          } else dispatch(setFilterFormData(fetchedFilterObj));
        }
        setColumnVisibility(initialColumns);
      } catch (error) {
        console.error("Error fetching person display settings:", error);
      }
    };
    if (personId) {
      getDisplaySettings();
    }
  }, [personId]);
  useEffect(() => {
    const setColumns = Object.entries(columnVisibility)
      .filter(([key, value]) => value === true)
      .map(([key, value]) => key);

    const setDisplaySettings = async () => {
      try {
        await setPersonDisplaySettings({
          personId: personId,
          page: "DataImport",
          columns: setColumns,
          filterCriteria: processFilters(),
          createdBy: userName,
        });
      } catch (error) {
        console.error("Error setting person column settings:", error);
      }
    };
    if (filterFormData) {
      setDisplaySettings();
    }
  }, [filterFormData]);
  const processFilters = () => {
    const selectedFormData = {};

    Object.keys(filterFormData).forEach((key) => {
      if (Array.isArray(filterFormData[key])) {
        if (filterFormData[key].length > 0) {
          selectedFormData[key] = filterFormData[key].join(",");
        }
      } else if (filterFormData[key] !== "" && filterFormData[key] !== false) {
        selectedFormData[key] = filterFormData[key];
      }
    });
    return selectedFormData;
  };
  const fetchSimplePanelMemberAssignments = async () => {
    try {
      const payload = {
        surveyId: searchPayload.surveyId,
        surveyPanelId: surveyPanelId,
        batchId: selectedBatch,
        stateIds: searchPayload.stateIds,
        customIds: searchPayload.customIds,
        filterCriteria: processFilters(),
        pageNumber: pagination.pageIndex + 1,
        numberPerPage: pagination.pageSize,
      };
      const res = await findSimplePanelMemberAssignmentsByBatchId(payload);
      if (res?.data) {
        setSurveys(res?.data?.panelMemberAssignments);
        setRowCount(res?.data?.totalNumber);
      } else setSurveys([]);
    } catch (error) {
      console.error("Error fetching panel members:", error);
    }
  };
  useEffect(() => {
    if (filterFormData) {
      fetchSimplePanelMemberAssignments();
    }
  }, [filterFormData, pagination.pageIndex, pagination.pageSize]);
  const data = useMemo(() => {
    setColumnVisibility(initialColumns);
    return surveys?.map((member) => {
      return {
        surveyFilingId: member?.surveyFilingId,
        shortName:
          member?.surveyRespondent?.surveyOrganization?.stateCode?.shortName,
        sr_custom_id: member?.srCustomId,
        custom_id: member?.surveyRespondent?.surveyOrganization?.customId,
        surveyFilingStatus: member?.surveyFilingStatus,
        srId: member?.surveyRespondent?.id,
      };
    });
  }, [surveys]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "surveyFilingId",
        header: "Filing ID",
        size: 30,
      },
      {
        accessorKey: "shortName",
        header: "State",
        size: 30,
      },
      {
        accessorKey: "sr_custom_id",
        header: "Id",
        size: 30,
      },
      {
        accessorKey: "custom_id",
        header: "Custom Id",
        size: 30,
      },
      {
        accessorKey: "surveyFilingStatus",
        header: "Filing Status",
        size: 50,
      },
      {
        accessorKey: "view",
        header: "View",
        size: 50,
        Cell: ({ row }) => (
          <>
            {!!row?.original?.surveyFilingId && (
              <Tooltip title="View data" arrow>
                <DescriptionOutlinedIcon
                  sx={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    color: theme.palette.yellow.main,
                    background: theme.palette.yellow.light,
                    borderRadius: "10px",
                    padding: "10px",
                    marginRight: "10px",
                  }}
                  onClick={() =>
                    openTaker("view", row?.original?.surveyFilingId)
                  }
                />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        accessorKey: "edit",
        header: "Edit",
        size: 50,
        Cell: ({ row }) => (
          <>
            {!!row?.original?.surveyFilingId && (
              <Tooltip title="Edit data" arrow>
                <EditOutlinedIcon
                  sx={{
                    cursor: "pointer",
                    width: 20,
                    height: 20,
                    color: theme.palette.green.main,
                    background: theme.palette.green.light,
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                  onClick={() =>
                    openTaker("edit", row?.original?.surveyFilingId)
                  }
                />
              </Tooltip>
            )}
          </>
        ),
      },
      {
        accessorKey: "opde",
        header: "Opde",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Opde" arrow>
            <NoteAltOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 20,
                height: 20,
                color: theme.palette.orange.main,
                background: theme.palette.orange.light,
                borderRadius: "10px",
                padding: "10px",
                marginRight: "10px",
              }}
              onClick={() =>
                openTaker(
                  "opde",
                  row?.original?.surveyFilingId,
                  row?.original?.srId
                )
              }
            />
          </Tooltip>
        ),
      },
    ],
    []
  );
  const table = useMaterialReactTable({
    manualPagination: true,
    columns,
    data,
    state: { pagination, columnVisibility },
    onPaginationChange: setPagination,
    rowCount,
    enableSorting: false,
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    visibleInShowHideMenu: false,
    initialState: { density: "compact" },
  });

  return (
    <>
      <Typography
        variant="label"
        color="primary"
        sx={{ marginTop: "20px", marginBottom: "20px" }}
      >
        Batch Result Page {` - Batch Id: ${selectedBatch}`}
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        Functions: view, edit survey filings added through data import
      </Typography>
      <Typography variant="label" color="primary">
        SURVEY: {htmlParser(surveyName)}
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "20px",
        }}
      >
        <Button startIcon={<TuneOutlinedIcon />} onClick={handleOpenModal}>
          Set Filter (find) Criteria
        </Button>
      </div>
      <div style={{ marginTop: "10px" }}>
        <MaterialReactTable table={table} />
      </div>
      <Modal open={openModal} onClose={handleCloseModal}>
        <FilterForm handleClose={handleCloseModal} />
      </Modal>
    </>
  );
};

export default PanelAssignment;
