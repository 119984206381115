import { React, useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  InputLabel,
  Select,
  TextField,
  Checkbox,
  Radio,
  RadioGroup,
  FormHelperText,
  MenuItem,
  Button,
  Box,
} from "@mui/material";
import DatePicker from "../../../../components/Common/DatePicker";
import NumericField from "../../../../components/Common/NumericField";
import { formatShortDateDash } from "../../../../Utility/constants";
import {
  updateField,
  deleteField,
  deleteFieldValue,
  createField,
  updateHeading,
} from "../../../Survey/Survey.service";
import { namePositionOptions } from "./AddQuesConstants";
import {
  setTriggerMatrixGrp,
  setSavedComField,
  setSavedField,
} from "../../../Survey/Survey.slice";

import useCustomResponseTable from "./useCustomResponseTable";
import SecResponseOptions from "./SecResponseOptions";

const HeadingFieldAttributes = ({
  attrRow,
  handleCloseEditFieldModal,
  handleColumnFieldChangeWrapper,
  selectedField,
}) => {
  const dispatch = useDispatch();

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const savedComField = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedComField
  );
  const [fieldValuesData, setFieldValuesData] = useState(
    attrRow.row.field.fieldValues
  );

  const [attrInfo, setAttrInfo] = useState({
    id: attrRow.row.field.id,
    historyFlag: attrRow.row.field.historyFlag,
    fillHistory: attrRow.row.field.fillHistory,
    name: attrRow.row.field.name,
    namePosition: attrRow.row.field.namePosition || "",
    fieldFlow: attrRow.row.field.fieldFlow,
    horizontalAlign: attrRow.row.field.horizontalAlign,
    userLabel: attrRow.row.field.userLabel,
    sequence: attrRow.row.field.sequence,
    columnNumber: attrRow.row.field.columnNumber,
    isRequired: attrRow.row.field.isRequired,
    fieldType: attrRow.row.field.fieldType.name,
    minDate: attrRow.row.field.minDate,
    maxDate: attrRow.row.field.maxDate,
    maxLength: attrRow.row.field.maxLength,
    isMultiselect: attrRow.row.field.isMultiselect,
    maxNumeric: attrRow.row.field.maxNumeric,
    minNumeric: attrRow.row.field.minNumeric,
    size: attrRow.row.field.size,
    maxDigits: attrRow.row.field.maxDigits,
    numPrecision: attrRow.row.field.numPrecision,
    isFloat: attrRow.row.field.isFloat,
    autoCommas: attrRow.row.field.autoCommas,
  });

  const handleResChange = (id, field, value) => {
    setFieldValuesData((prevData) =>
      prevData.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleResDelete = async (id) => {
    if (savedComField?.[id]) {
      try {
        const response = await deleteField(savedComField[id]?.id);
        const resData = response?.data;
        if (resData) {
          const { [id]: _, ...updatedComs } = savedComField;
          dispatch(setSavedComField(updatedComs));
        }
      } catch (error) {
        console.error("Error deleting field:", error);
      }
    }
    setFieldValuesData((prevData) => {
      const newData = prevData.filter((row) => row.id !== id);
      const updatedData = newData.map((item, index) => ({
        ...item,
        sequence: index,
      }));

      return updatedData;
    });

    try {
      const response = await deleteFieldValue(id);
      const updatedQues = response?.data;
      if (
        updatedQues &&
        Object.keys(updatedQues).length > 0 &&
        updatedQues.id
      ) {
        dispatch(setSavedField(updatedQues));
      }
    } catch (error) {
      console.error("Error deleting field value:", error);
    }
  };
  const table = useCustomResponseTable(
    fieldValuesData,
    setFieldValuesData,
    handleResChange,
    handleResDelete,
    attrRow.row.field.fieldType.name
  );

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setAttrInfo((prevData) => ({
        ...prevData,
        [name]: checked,
      }));
    } else {
      setAttrInfo((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleFormFieldChangeWrapper = (name) => (val) => {
    setAttrInfo((prevData) => ({
      ...prevData,
      [name]: val,
    }));
  };

  const handleSave = async () => {
    dispatch(setTriggerMatrixGrp(false));
    let fielddata = attrInfo;
    fielddata.fieldType = { name: attrInfo.fieldType };
    fielddata.fieldValues = fieldValuesData;

    let headingPayload = {
      id: attrRow.row.id,
      dateCreated: attrRow.row.dateCreated,
      status: attrRow.row.status,
      name: attrRow.row.name,
      value: attrRow.row.value,
      widthPct: attrRow.row.widthPct,
      sequence: attrRow.row.sequence,
      exclusiveAnswer: attrRow.row.exclusiveAnswer,
      field: fielddata,
      modifiedBy: userName,
    };
    try {
      const response = await updateHeading(headingPayload);
      if (response?.data && !response?.data?.detail) {
        dispatch(setTriggerMatrixGrp(true));
        handleColumnFieldChangeWrapper();
      }
    } catch (error) {
      console.log("Error updating updateHeaderInfo", error);
      return null;
    }
  };

  const handleAddRes = () => {
    const newSeq =
      fieldValuesData?.length > 0
        ? Math.max(...fieldValuesData.map((row) => row.sequence)) + 1
        : 0;
    const newRow = {
      // id: newSeq + 1,
      sequence: newSeq,
      name: `VALUE${newSeq + 1}`,
      titlePosition: "Right",
      allowableValue: `VALUE${newSeq + 1}`,
      exclusiveAnswer: false,
      includeCom: false,
      comFieldHeading: "",
      comFieldPlace: "Left",
      comFieldLabel: "",
    };
    setFieldValuesData((prevData) => [...prevData, newRow]);
  };

  return (
    <div className="panelModal">
      <div className="panelDiv">
        <Typography variant="label">
          Secondary Field attributes information
        </Typography>
      </div>
      <div
        style={{
          width: "91vw",
          maxHeight: "90vh",
          overflowY: "auto",
          padding: "15px",
        }}
      >
        <Typography variant="label" paragraph>
          <b>ANSWER LABEL & HEADING</b>
        </Typography>

        <Grid
          item
          xs={12}
          container
          sx={{ mb: 1 }}
          spacing={1}
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="userLabel"
                value={attrInfo["userLabel"]}
                onChange={handleChange}
                label="User Label"
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <TextField
                size="small"
                name="name"
                variant="outlined"
                value={attrInfo["name"]}
                label="Heading"
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel className="inputLabel">Position</InputLabel>
              <Select
                size="small"
                name="namePosition"
                fullWidth
                value={attrInfo["namePosition"]}
                onChange={handleChange}
                label="Position"
              >
                {namePositionOptions.map((option, index) => (
                  <MenuItem key={`ftype${index}`} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {(attrInfo.fieldType === "Radio Field" ||
          attrInfo.fieldType === "Combobox Field" ||
          attrInfo.fieldType === "CheckBox Field") && (
          <>
            <SecResponseOptions table={table} handleAddRes={handleAddRes} />
          </>
        )}

        <Typography variant="label" sx={{ fontWeight: "bold" }} paragraph>
          ANSWER DISPLAY FORMAT
        </Typography>

        {attrInfo.fieldType === "Numeric Field" && (
          <>
            <Grid item xs={12} container spacing={2} alignItems="flex-start">
              <Grid item xs={2}>
                <NumericField
                  height="small"
                  name="size"
                  value={attrInfo["size"]}
                  onChange={handleFormFieldChangeWrapper("size")}
                  label="Display size (# of digits)"
                />
              </Grid>
              <Grid item xs={2}>
                <NumericField
                  height="small"
                  name="maxDigits"
                  variant="outlined"
                  value={attrInfo["maxDigits"]}
                  onChange={handleFormFieldChangeWrapper("maxDigits")}
                  label="Max # digits"
                />
              </Grid>
              <Grid item xs={2}>
                <NumericField
                  height="small"
                  name="numPrecision"
                  variant="outlined"
                  value={attrInfo["numPrecision"]}
                  onChange={handleFormFieldChangeWrapper("numPrecision")}
                  label="Precision (# of decimals)"
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    aria-label="allow-decimals"
                    name="isFloat"
                    value={attrInfo["isFloat"]}
                    onChange={handleChange}
                  >
                    <FormHelperText
                      sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
                    >
                      Allow Decimals?
                    </FormHelperText>
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <Box display="flex" alignItems="center">
                <FormControl component="fieldset" sx={{ flex: "0 0 auto" }}>
                  <RadioGroup
                    row
                    aria-label="autofill-commas"
                    name="autoCommas"
                    value={attrInfo["autoCommas"]}
                    onChange={handleChange}
                  >
                    <FormHelperText
                      sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
                    >
                      Autofill Commas?
                    </FormHelperText>
                    <FormControlLabel
                      value={true}
                      control={<Radio size="small" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio size="small" />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                <FormHelperText
                  sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
                >
                  (Commas are for display purposes only and will not be present
                  in the data export)
                </FormHelperText>
              </Box>
            </Grid>
          </>
        )}
        {attrInfo.fieldType === "Simpletext Field" && (
          <Grid item xs={3}>
            <TextField
              size="small"
              name="size"
              variant="outlined"
              value={attrInfo["size"]}
              onChange={handleChange}
              label="Display size (# of characters)"
            />
          </Grid>
        )}

        <Grid item xs={12} container spacing={0} alignItems="flex-start">
          <Grid item xs={4}>
            <RadioGroup
              row
              aria-label="field-flow"
              name="fieldFlow"
              value={attrInfo["fieldFlow"]}
              onChange={handleChange}
            >
              <FormHelperText
                sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
              >
                Insert New Line
              </FormHelperText>

              <FormControlLabel
                value="NL"
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value="SL"
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              row
              aria-label="horizontal-align"
              name="horizontalAlign"
              value={attrInfo["horizontalAlign"]}
              onChange={handleChange}
            >
              <FormHelperText
                sx={{ margin: 0, pr: 1, pt: 1, fontSize: "13px" }}
              >
                Alignment
              </FormHelperText>
              <FormControlLabel
                value="left"
                control={<Radio size="small" />}
                label="Left"
              />
              <FormControlLabel
                value="center"
                control={<Radio size="small" />}
                label="Center"
              />
              <FormControlLabel
                value="right"
                control={<Radio size="small" />}
                label="Right"
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Typography variant="label" sx={{ fontWeight: "bold" }}>
          ADDITIONAL ANSWER OPTIONS
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={attrInfo["historyFlag"] === true}
                name="historyFlag"
                onChange={handleChange}
              />
            }
            label="Show History"
          />
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={attrInfo["fillHistory"]}
                name="fillHistory"
                onChange={handleChange}
              />
            }
            label="Allow Fill With Last Year's Data"
          />
        </Grid>
        <Typography variant="label" sx={{ fontWeight: "bold" }}>
          INPUT VALIDATION
        </Typography>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={attrInfo["isRequired"] === true}
                name="isRequired"
                onChange={handleChange}
              />
            }
            label="Is Required"
          />
        </Grid>

        {attrInfo.fieldType === "Simpletext Field" && (
          <Grid item xs={3}>
            <TextField
              size="small"
              name="maxLength"
              variant="outlined"
              value={attrInfo["maxLength"]}
              onChange={handleChange}
              label="Max # chars"
            />
          </Grid>
        )}
        {(attrInfo.fieldType === "State Field" ||
          attrInfo.fieldType === "Combobox Field") && (
          <Grid item xs={6}>
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={attrInfo["isMultiselect"] === true}
                  name="isMultiselect"
                  onChange={handleChange}
                />
              }
              label="Allow Multiple Selection"
            />
          </Grid>
        )}
        {attrInfo.fieldType === "Numeric Field" && (
          <>
            <Grid
              item
              xs={12}
              container
              spacing={1}
              alignItems="flex-start"
              sx={{ mt: 1 }}
            >
              <Grid item xs={2}>
                <NumericField
                  height="small"
                  name="minNumeric"
                  variant="outlined"
                  value={attrInfo["minNumeric"]}
                  onChange={handleFormFieldChangeWrapper("minNumeric")}
                  label="Min Value"
                />
              </Grid>

              <Grid item xs={2}>
                <NumericField
                  height="small"
                  name="maxNumeric"
                  variant="outlined"
                  value={attrInfo["maxNumeric"]}
                  onChange={handleFormFieldChangeWrapper("maxNumeric")}
                  label="Max Value"
                />
              </Grid>
            </Grid>
          </>
        )}
        {attrInfo.fieldType === "Date Field" && (
          <>
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              <Grid item xs={3}>
                <DatePicker
                  name="minDate"
                  height="small"
                  value={attrInfo["minDate"]}
                  onChange={handleFormFieldChangeWrapper("minDate")}
                  label="Min Date"
                />
              </Grid>
              <Grid item xs={3}>
                <DatePicker
                  name="maxDate"
                  height="small"
                  value={attrInfo["maxDate"]}
                  onChange={handleFormFieldChangeWrapper("maxDate")}
                  label="Max Date"
                />
              </Grid>
            </Grid>
          </>
        )}
      </div>
      <div
        style={{
          display: "flex",
          gap: "15px",
          marginLeft: "44%",
          marginBottom: "1%",
        }}
      >
        <Button variant="outlined" onClick={handleCloseEditFieldModal}>
          Cancel
        </Button>
        <Button variant="contained" onClick={() => handleSave()}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default HeadingFieldAttributes;
