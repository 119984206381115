import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  FormHelperText,
} from "@mui/material";
import NumericField from "../../../../components/Common/NumericField";

const AnswerDisplayFormat = ({
  selectedField,
  fieldFormData,
  handleFieldChangeWrapper,
  handleFieldChange,
}) => (
  <Grid item xs={6}>
    <Card>
      <CardContent>
        <Box>
          <Typography variant="label" sx={{ fontWeight: "bold" }}>
            ANSWER DISPLAY FORMAT
          </Typography>
        </Box>
        <Grid
          item
          xs={12}
          container
          sx={{ mt: 0 }}
          spacing={1}
          alignItems="flex-start"
        >
          {selectedField === "Numeric Field" && (
            <>
              <Grid item xs={12} container spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                  <NumericField
                    height="small"
                    name="size"
                    value={fieldFormData["size"]}
                    onChange={handleFieldChangeWrapper("size")}
                    label="Display size (# of digits)"
                  />
                </Grid>
                <Grid item xs={6}>
                  <NumericField
                    height="small"
                    name="maxDigits"
                    variant="outlined"
                    value={fieldFormData["maxDigits"]}
                    onChange={handleFieldChangeWrapper("maxDigits")}
                    label="Max # digits"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container spacing={2} alignItems="flex-start">
                <Grid item xs={6}>
                  <NumericField
                    height="small"
                    name="numPrecision"
                    variant="outlined"
                    value={fieldFormData["numPrecision"]}
                    onChange={handleFieldChangeWrapper("numPrecision")}
                    label="Precision (# of decimals)"
                  />
                </Grid>
                <Grid item xs={2}>
                  Allow Decimals?
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset">
                    <RadioGroup
                      row
                      aria-label="allow-decimals"
                      name="isFloat"
                      value={fieldFormData["isFloat"]}
                      onChange={(e) =>
                        handleFieldChange(e?.target?.name, e?.target?.value)
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid item xs={2}>
                Autofill Commas?
              </Grid>
              <Grid item xs={10}>
                <Box display="flex" alignItems="center">
                  <FormControl component="fieldset" sx={{ flex: "0 0 auto" }}>
                    <RadioGroup
                      row
                      aria-label="autofill-commas"
                      name="autoCommas"
                      value={fieldFormData["autoCommas"]}
                      onChange={(e) =>
                        handleFieldChange(e?.target?.name, e?.target?.value)
                      }
                    >
                      <FormControlLabel
                        value={true}
                        control={<Radio size="small" />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value={false}
                        control={<Radio size="small" />}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                  <Typography variant="body1">
                    (Commas are for display purposes only and will not be
                    present in the data export)
                  </Typography>
                </Box>
              </Grid>
            </>
          )}
          {selectedField === "Simpletext Field" && (
            <Grid item xs={6}>
              <NumericField
                height="small"
                name="size"
                variant="outlined"
                value={fieldFormData["size"]}
                onChange={handleFieldChangeWrapper("size")}
                label="Display size (# of characters)"
              />
            </Grid>
          )}
          {selectedField === "Textarea Field" && (
            <Grid item xs={12} container spacing={2} alignItems="flex-start">
              <Grid item xs={6}>
                <NumericField
                  height="small"
                  name="size"
                  variant="outlined"
                  value={fieldFormData["size"]}
                  onChange={handleFieldChangeWrapper("size")}
                  label="Width (# of characters)"
                />
              </Grid>
              <Grid item xs={6}>
                <NumericField
                  height="small"
                  name="numLines"
                  variant="outlined"
                  value={fieldFormData["numLines"]}
                  onChange={handleFieldChangeWrapper("numLines")}
                  label="Height (# of lines)"
                />
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} container spacing={0} alignItems="flex-start">
            <Grid item xs={5}>
              <RadioGroup
                row
                aria-label="field-flow"
                name="fieldFlow"
                value={fieldFormData["fieldFlow"]}
                onChange={(e) =>
                  handleFieldChange(e?.target?.name, e?.target?.value)
                }
              >
                <FormHelperText sx={{ margin: 0, pr: 1, pt: 1 }}>
                  Insert New Line
                </FormHelperText>
                <FormControlLabel
                  value="NL"
                  control={<Radio size="small" />}
                  label="Yes"
                />
                <FormControlLabel
                  value="SL"
                  control={<Radio size="small" />}
                  label="No"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={7}>
              <RadioGroup
                row
                aria-label="horizontal-align"
                name="horizontalAlign"
                value={fieldFormData["horizontalAlign"]}
                onChange={(e) =>
                  handleFieldChange(e?.target?.name, e?.target?.value)
                }
              >
                <FormHelperText sx={{ margin: 0, pr: 1, pt: 1 }}>
                  Alignment
                </FormHelperText>
                <FormControlLabel
                  value="left"
                  control={<Radio size="small" />}
                  label="Left"
                />
                <FormControlLabel
                  value="center"
                  control={<Radio size="small" />}
                  label="Center"
                />
                <FormControlLabel
                  value="right"
                  control={<Radio size="small" />}
                  label="Right"
                />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Grid>
);

export default AnswerDisplayFormat;
