import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import TextField from "../../../../components/Common/TextField";
import { namePositionOptions } from "./AddQuesConstants";

const AnswerLabelHeading = ({
  fieldFormData,
  handleFieldChangeWrapper,
  handleFieldChange,
}) => {
  return (
    <Grid item xs={6}>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="label">
              <b>USER LABEL & HEADING</b>
            </Typography>
          </Box>
          <Grid
            item
            xs={12}
            container
            sx={{ mt: 0 }}
            spacing={1}
            alignItems="flex-start"
          >
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  height="small"
                  name="userLabel"
                  value={fieldFormData["userLabel"]}
                  onChange={handleFieldChangeWrapper("userLabel")}
                  label="User Label"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <TextField
                  height="small"
                  name="name"
                  value={fieldFormData["name"]}
                  onChange={handleFieldChangeWrapper("name")}
                  label="Heading"
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel className="inputLabel">Position</InputLabel>
                <Select
                  size="small"
                  name="namePosition"
                  fullWidth
                  value={fieldFormData["namePosition"]}
                  onChange={(e) =>
                    handleFieldChange(e?.target?.name, e?.target?.value)
                  }
                  label="Position"
                >
                  {namePositionOptions.map((option, index) => (
                    <MenuItem key={`ftype${index}`} value={option.value}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AnswerLabelHeading;
