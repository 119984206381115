import React, { useState, useEffect } from "react";
import Button from "../../components/Common/Button";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  completedBatches,
  surveyAnswers,
  getDataFilters,
} from "../Survey/Survey.service";
import {
  USER_ROLES,
  formatShortDate,
  formatShortDateDash,
  compareTwoDates,
  htmlParser,
} from "../../Utility/constants";
import { fetchPanelsByOrgId } from "../../pages/Survey/Survey.slice";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import DatePicker from "../../components/Common/DatePicker";
import theme from "../../theme";

const DataExport = () => {
  const [loading, setLoading] = useState(false);
  const [stateIds, setStateIds] = useState([]);
  const [regionIds, setRegionIds] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState("none");
  const [includeAllFields, setIncludeAllFields] = useState(false);
  const [selectedFilingStatus, setSelectedFilingStatus] = useState([]);
  const [beginingDate, setBeginingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [batchResult, setbatchResult] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [enteredThroughBatch, setEnteredThroughBatch] = useState(null);
  const [downloadLoopData, setDownloadLoopData] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const location = useLocation();
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;
  const surveyPanels = useSelector((state) => state.survey.surveyPanels);
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.username
  );

  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );
  const surveyFilingStatuses = useSelector(
    (state) => state?.survey?.surveyFilingStatuses
  );

  const states = useSelector((state) => state?.survey?.states);

  const regions = useSelector((state) => state?.survey?.regions);

  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const handleStateChange = (event) => {
    setStateIds(event.target.value);
  };

  const handleRegionChange = (event) => {
    setRegionIds(event.target.value);
  };
  const handleFilterChange = (event) => {
    setSelectedFilters(event.target.value);
  };

  const handleFilterRadioChange = (event) => {
    setIncludeAllFields(event.target.value === "true");
  };
  const handleFilingChange = (e) => {
    setSelectedFilingStatus(e.target.value);
  };
  const handleBeginingDate = (date) => {
    setBeginingDate(date);
  };
  const handleEndingDate = (date) => {
    setEndingDate(date);
  };
  const handleEnteredThroughChange = (event) => {
    setEnteredThroughBatch(event.target.value === "true");
  };
  const handleLoopDataChange = (event) => {
    setDownloadLoopData(event.target.value === "true");
  };

  useEffect(() => {
    dispatch(fetchPanelsByOrgId(orgId));
  }, [dispatch]);

  useEffect(() => {
    const findCompletedBatches = async () => {
      try {
        const res = await completedBatches(surveyId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const responseData = res?.data;
          setbatchResult(responseData);
        }
      } catch (error) {
        console.error("Error fetching batch Information:", error);
      }
    };
    if (personId) {
      findCompletedBatches();
    }
  }, [personId]);

  const handleExport = async () => {
    setErrMsg("");
    setMsg("");
    let isValidDate = true;
    if (beginingDate && endingDate) {
      isValidDate = compareTwoDates(beginingDate, endingDate);
    }
    if (isValidDate) {
      setLoading(true);
      const filterValues = {
        include_all_fields: includeAllFields,
        state_id: stateIds.join(),
        region_id: regionIds.join(),
        start_date: formatShortDateDash(beginingDate),
        end_date: formatShortDateDash(endingDate),
        status_id: selectedFilingStatus.join(),
        batch_id: selectedBatch,
        entered_through_batch: enteredThroughBatch,
        download_loop_data: downloadLoopData,
      };

      const filterCriteria = Object.fromEntries(
        Object.entries(filterValues).filter(
          ([key, value]) => value !== null && value !== ""
        )
      );

      let payload = {
        surveyId: surveyId,
        requestedUserEmail: userName,
        dataFilterId: selectedFilters === "none" ? null : selectedFilters,
        filterCriteria: filterCriteria,
      };

      if (
        roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME ||
        roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME ||
        roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME
      ) {
        payload.surveyPanelId = surveyPanels[0]?.id;
      }
      try {
        const response = await surveyAnswers(payload);
        if (response?.status === 200) {
          setLoading(false);
          setMsg(
            "Export complete. Please check your email for a link to the file. You may need to check your spam folder if you do not see the email in your inbox."
          );
        } else {
          setMsg(
            "An error occurred while generating the download. Please try again. If the issue persists, please contact system support."
          );
          setLoading(false);
        }
      } catch (error) {
        setMsg(
          "An error occurred while generating the download. Please try again. If the issue persists, please contact system support."
        );
        setLoading(false);
        console.error("Error exporting:", error);
      }
    } else {
      setErrMsg("Ending Date should be greater than Beginning Date");
    }
  };

  useEffect(() => {
    const fetchDataFilters = async () => {
      setLoadingList(true);
      setFilterList([]);
      try {
        const response = await getDataFilters(surveyId);
        setFilterList(response?.data || []);
        setLoadingList(false);
      } catch (error) {
        console.error("Error fetching Data Filters:", error);
        setLoadingList(false);
      }
    };

    if (surveyId) {
      fetchDataFilters();
    }
  }, [surveyId]);

  return loading ? (
    <>
      <Typography style={{ fontWeight: "bold" }}>
        Generating export file… this may take several minutes if a data filter
        is a being used.
      </Typography>
    </>
  ) : (
    <div>
      <Typography variant="label" color="primary" sx={{ marginBottom: "20px" }}>
        {htmlParser(surveyName)}
      </Typography>
      <Typography variant="body1">
        A link to download the export file will be emailed to you at the email
        address you use to sign into the system. Please check your spam folder
        if you do not see the email in your inbox.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        The definition and position of the answer fields always occupy the first
        record in the data file.
      </Typography>
      <Box component="fieldset">
        <legend sx={{ color: theme.palette.pink.main }}>
          <b>Data Export</b>
        </legend>
        {loadingList && "Loading data filters..."}

        <Grid container rowSpacing={1}>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a filter</Typography>
            </Grid>
            <Grid item xs={9} container>
              <FormControl>
                <Select
                  size="small"
                  sx={{ width: "220px", marginRight: "50px" }}
                  value={selectedFilters}
                  onChange={handleFilterChange}
                >
                  <MenuItem value="none">
                    <em>None (All data)</em>
                  </MenuItem>
                  {filterList.map((filter) => (
                    <MenuItem key={filter.id} value={filter.id}>
                      {filter.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <RadioGroup
                row
                value={includeAllFields}
                onChange={handleFilterRadioChange}
              >
                <FormControlLabel
                  value={true}
                  control={<Radio size="small" />}
                  label="Include all fields"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio size="small" />}
                  label="Use only the filter's selected fields"
                />
              </RadioGroup>
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a state</Typography>
            </Grid>
            <FormControl sx={{ width: "220px" }}>
              <Select
                size="small"
                multiple
                variant="outlined"
                value={stateIds}
                onChange={handleStateChange}
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.longName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a region</Typography>
            </Grid>
            <FormControl sx={{ width: "220px" }}>
              <Select
                size="small"
                multiple
                variant="outlined"
                value={regionIds}
                onChange={handleRegionChange}
              >
                {regions.map((region) => (
                  <MenuItem key={region.id} value={region.id}>
                    {region.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Beginning Date</Typography>
            </Grid>
            <Grid item xs={9} container alignItems="center">
              <DatePicker
                value={beginingDate}
                onChange={handleBeginingDate}
                height="small"
                sx={{ width: "220px", marginRight: "50px" }}
              />
              <Typography variant="body1" sx={{ marginRight: "50px" }}>
                Ending Date
              </Typography>
              <DatePicker
                value={endingDate}
                onChange={handleEndingDate}
                height="small"
                sx={{ width: "220px" }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a survey status</Typography>
            </Grid>
            <FormControl sx={{ width: "220px" }}>
              <Select
                multiple
                size="small"
                variant="outlined"
                onChange={handleFilingChange}
                value={selectedFilingStatus}
              >
                {surveyFilingStatuses.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Enter a batch ID</Typography>
            </Grid>
            <FormControl sx={{ width: "220px" }}>
              <Select
                size="small"
                value={selectedBatch}
                onChange={(e) => setSelectedBatch(e.target.value)}
              >
                {batchResult.map((data, index) => (
                  <MenuItem key={index} value={data.id}>
                    Batch ID:{data.id} {formatShortDate(data.dateCreated)} (
                    {data.recordCount} records)
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">
                View surveys entered online/batch
              </Typography>
            </Grid>
            <RadioGroup
              row
              value={enteredThroughBatch}
              onChange={handleEnteredThroughChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Entered through batch"
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="Entered online"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Download Loop Data</Typography>
            </Grid>
            <RadioGroup
              row
              value={downloadLoopData}
              onChange={handleLoopDataChange}
            >
              <FormControlLabel
                value={true}
                control={<Radio size="small" />}
                label="Yes"
              />
              <FormControlLabel
                value={false}
                control={<Radio size="small" />}
                label="No"
              />
            </RadioGroup>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              onClick={handleExport}
              disabled={loading}
              sx={{ marginRight: "20px" }}
            >
              EXPORT
            </Button>
            <Button
              variant="outlined"
              component="label"
              onClick={() => {
                navigate("/");
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Box>
      {msg && (
        <Typography
          color="primary"
          variant="body1"
          sx={{ fontWeight: "bold", marginTop: "15px" }}
        >
          {msg}
        </Typography>
      )}
      {errMsg && (
        <Typography
          color="error"
          variant="body1"
          sx={{ fontWeight: "bold", marginTop: "15px" }}
        >
          {errMsg}
        </Typography>
      )}
    </div>
  );
};

export default DataExport;
