import React from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "../pages/Homepage/Homepage";
import ProcessSurvey from "../pages/PanelManagement/ProcessSurvey";
import PanelManagement from "../pages/PanelManagement/PanelManagement";
import ResponseReport from "../pages/PanelManagement/ResponseReport";
import SurveyBuilder from "../pages/ModifySurvey/SurveyBuilder";
import Admin from "../pages/Admin/Admin";
const PageRoutes = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Homepage />} />
      <Route exact path="/process-survey" element={<ProcessSurvey />} />
      <Route exact path="/panel-mgr" element={<PanelManagement />} />
      <Route exact path="/response-report" element={<ResponseReport />} />
      <Route exact path="/survey-builder" element={<SurveyBuilder />} />
      <Route exact path="/admin" element={<Admin />} />
    </Routes>
  );
};

export default PageRoutes;
