import React, { useEffect, useState } from "react";
import Tooltip from "../../../components/Common/Tooltip";
import { useDispatch, useSelector } from "react-redux";

import { Button, Modal } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  setSelectedSec,
  setSelectedPage,
  setSurveyBuilderCurrentView,
  setSelectedQues,
  setSavedQuesGrp,
  setSavedField,
  setSavedQues,
  setEditQuesGrpId,
  setEditQuesId,
  setEditFieldId,
  setSelectedField,
  setTriggerMatrixGrp,
} from "../../Survey/Survey.slice";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";
import {
  createSurveyFiling,
  deactivateSurvey,
  deleteSurveyFiling,
  getRespondentByUsername,
  getSurveyFiling,
  updateSurveyOrganization,
} from "../../Survey/Survey.service";
import { triggerSidebarRefresh } from "../../Survey/Survey.slice";
import ActivateSurvey from "./ActivateSurvey";
import { useNavigate } from "react-router-dom";
import AddQuesModal from "./AddQues/AddQuesModal";
import StateSelectModal from "../../Homepage/StateSelectModal";

const SurveyActionButtons = ({ page, selectedPageId }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [openSuveyModal, setOpenSuveyModal] = useState(false);
  const [openAddQuesModal, setOpenAddQuesModal] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [surveyRespondent, setSurveyRespondent] = useState(null);
  const [surveyFilingId, setSurveyFilingId] = useState(null);
  const [openStateModal, setOpenStateModal] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [ruleExists, setRuleExists] = useState(false);
  const [ruleId, setRuleId] = useState(null);

  const surveyActiveStat = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.active
  );
  const surveyId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.id
  );
  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );
  const previewSurveyId = useSelector(
    (state) => state?.survey?.homeSurveyData?.previewSurveyId
  );
  const surveyOwnerOrgId = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData?.surveyData?.survey?.ownerOrganization
        ?.id
  );
  const stateCodeId = useSelector(
    (state) => state?.survey?.loggedInUserData?.stateCode?.id
  );

  const selectedSurveySecs = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.sections
  );

  const buttonStyle = {
    marginRight: "20px",
    marginBottom: "15px",
  };

  useEffect(() => {
    if (selectedSurveySecs && selectedPageId) {
      const findDisplayRule = (pageId) => {
        for (const section of selectedSurveySecs) {
          for (const page of section.pages) {
            if (page.page.id === pageId && "displayRule" in page.page) {
              return page.page.displayRule.id;
            }
          }
        }
        return null;
      };

      const ruleId = findDisplayRule(selectedPageId);
      setRuleExists(!!ruleId);
      setRuleId(ruleId);
    }
  }, [selectedPageId, selectedSurveySecs]);

  const newSurveyVR = (level) => {
    dispatch(setSelectedQues({}));

    dispatch(
      setSurveyBuilderCurrentView({
        viewIndex: 11,
        source: level,
      })
    );
  };
  const activateDeactiveSurveyAction = () => {
    if (surveyActiveStat) {
      setConfirmTitle(
        "Are you sure you want to deactivate the <b>" +
          surveyName +
          "</b> survey?"
      );
      setConfirmOpen(true);
    } else {
      setOpenSuveyModal(true);
    }
  };
  const callDeactivateSurvey = async () => {
    try {
      const response = await deactivateSurvey(surveyId);

      if (response?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey deactivated successfully");
        setAlertSeverity("success");
        setConfirmTitle(null);
        setConfirmOpen(false);
        dispatch(triggerSidebarRefresh());
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error captured for deactivating survey");
        setAlertSeverity("error");
        setConfirmTitle(null);
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error captured for deactivating survey", error);
      return null;
    }
  };

  const handleCloseModal = () => {
    setOpenSuveyModal(false);
  };

  const handleAddQuesSubmit = (selectedGroupType) => {
    dispatch(setSelectedField(selectedGroupType));
    dispatch(setSavedQuesGrp(null));
    dispatch(setSavedQues(null));
    dispatch(setSavedField(null));
    dispatch(setEditQuesGrpId(null));
    dispatch(setEditQuesId(null));
    dispatch(setEditFieldId(null));
    dispatch(setTriggerMatrixGrp(false));
    navigate("/add-question");
    setOpenAddQuesModal(false);
  };

  const handleCloseStateModal = () => {
    setOpenStateModal(false);
  };

  useEffect(() => {
    const updateSurveyOrg = async () => {
      if (surveyRespondent) {
        const surveyOrgPayload = JSON.parse(
          JSON.stringify(surveyRespondent?.surveyOrganization)
        );
        surveyOrgPayload.stateCode = { id: selectedState };
        await updateSurveyOrganization(surveyOrgPayload);
        setIsButtonEnabled(true);
      }
    };

    updateSurveyOrg();
  }, [selectedState]);

  const handleContinue = () => {
    const baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/edit/${surveyFilingId}`;
    window.open(baseUrl, "_blank");
    handleCloseStateModal();
  };
  const createNewSurveyFiling = async (surveyId, srId) => {
    try {
      const response = await createSurveyFiling({
        surveyId: surveyId,
        surveyRespondentId: srId,
      });
      const newSurveyFilingId = await response?.data?.id;
      return newSurveyFilingId;
    } catch (error) {
      console.log("Error creating Survey filing", error);
      return null;
    }
  };

  const openPreview = async (surveyId, ownerOrgId) => {
    console.log(surveyId);
    setPreviewLoading(true);
    if (ownerOrgId == "8002") {
      setIsButtonEnabled(false);
      setOpenStateModal(true);
    }
    setSurveyRespondent(null);
    setSelectedState(null);

    try {
      const response = await getRespondentByUsername();
      const surveyRespondent = response?.data[response?.data.length - 1];
      setSurveyRespondent(surveyRespondent);
      const surveyRespondentId = response?.data[response?.data.length - 1]?.id;

      const surveyFiling = await getSurveyFiling({
        surveyId,
        surveyRespondentId,
      });

      if (Array.isArray(surveyFiling?.data) && surveyFiling?.data?.length > 0) {
        for (const filing of surveyFiling?.data) {
          const surveyFilingId = filing?.id;
          await deleteSurveyFiling(surveyFilingId);
        }
      }

      const newSurveyFilingId = await createNewSurveyFiling(
        surveyId,
        surveyRespondentId
      );

      setSurveyFilingId(newSurveyFilingId);
      const surveyOrgState = await response?.data[response?.data.length - 1]
        ?.surveyOrganization?.stateCode?.id;
      if (ownerOrgId == "8002") {
        setSelectedState(surveyOrgState);
        setIsButtonEnabled(true);
      } else {
        setSelectedState(stateCodeId ? stateCodeId : 1);

        const baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/edit/${newSurveyFilingId}`;
        window.open(baseUrl, "_blank");
      }
    } catch (error) {
      console.error("Preview error:", error);
    } finally {
      setPreviewLoading(false);
    }
  };

  return (
    <>
      {page === "Survey" && (
        <div>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() =>
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 7,
                  source: "editSurveyButton",
                })
              )
            }
          >
            Edit survey
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => {
              dispatch(setSelectedSec({}));
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 5,
                  source: "addSectionButton",
                })
              );
            }}
          >
            Add Section
          </Button>
          <Tooltip title="Add Survey Validation Rules">
            <Button
              variant="outlined"
              size="small"
              component="label"
              style={buttonStyle}
              onClick={() => newSurveyVR("SurveyLevel")}
            >
              Add Survey Validation Rule (Vr)
            </Button>
          </Tooltip>
          <Tooltip title="Validation Rules View">
            <Button
              size="small"
              variant="outlined"
              component="label"
              style={{
                ...buttonStyle,
                textTransform: "none",
              }}
              onClick={() =>
                dispatch(
                  setSurveyBuilderCurrentView({
                    viewIndex: 8,
                    source: "viewSurveyVrsButton",
                  })
                )
              }
            >
              VIEW SURVEY VRs
            </Button>
          </Tooltip>
          <Button
            disabled={previewLoading}
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => {
              openPreview(previewSurveyId, surveyOwnerOrgId);
            }}
          >
            Preview Survey
          </Button>
          {surveyActiveStat !== null && surveyActiveStat !== undefined && (
            <Button
              variant="outlined"
              size="small"
              component="label"
              style={buttonStyle}
              onClick={() => activateDeactiveSurveyAction()}
            >
              {surveyActiveStat ? "Deactivate Survey" : "Activate Survey"}
            </Button>
          )}
        </div>
      )}
      {page === "Section" && (
        <div>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() =>
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 5,
                  source: "editSectionButton",
                })
              )
            }
          >
            Edit section
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => {
              dispatch(setSelectedPage({}));
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 6,
                  source: "addPageButton",
                })
              );
            }}
          >
            Add page
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => newSurveyVR("SectionLevel")}
          >
            ADD SECTION VALIDATION RULE (VR)
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={{
              ...buttonStyle,
              textTransform: "none",
            }}
            onClick={() =>
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 9,
                  source: "viewSectionVrsButton",
                })
              )
            }
          >
            VIEW SECTION VRs
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => {
              openPreview(previewSurveyId);
            }}
          >
            Preview Survey
          </Button>
        </div>
      )}
      {page === "Page" && (
        <div>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() =>
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 6,
                  source: "editPageButton",
                })
              )
            }
          >
            Edit page
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => setOpenAddQuesModal(true)}
          >
            Add Question Group
          </Button>
          <Tooltip title="Add Page Validation Rules">
            <Button
              variant="outlined"
              size="small"
              component="label"
              style={buttonStyle}
              onClick={() => newSurveyVR("PageLevel")}
            >
              Add Page Validation Rule (Vr)
            </Button>
          </Tooltip>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={{
              ...buttonStyle,
              textTransform: "none",
            }}
            onClick={() =>
              dispatch(
                setSurveyBuilderCurrentView({
                  viewIndex: 10,
                  source: "viewPageVrsButton",
                })
              )
            }
          >
            VIEW PAGE VRs
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() =>
              newSurveyVR(
                ruleId ? `addPageLevelDR__${ruleId}` : "addPageLevelDR"
              )
            }
          >
            {ruleExists ? "EDIT HIDE PAGE RULE" : "ADD HIDE PAGE RULE"}
          </Button>
          <Button
            variant="outlined"
            size="small"
            component="label"
            style={buttonStyle}
            onClick={() => {
              openPreview(previewSurveyId);
            }}
          >
            Preview Survey
          </Button>
        </div>
      )}
      <ConfirmDialog
        title={confirmTitle}
        pageName="ActiveSurvey"
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={callDeactivateSurvey}
      ></ConfirmDialog>
      <Modal open={openSuveyModal}>
        <ActivateSurvey handleClose={handleCloseModal} />
      </Modal>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
      <AddQuesModal
        open={openAddQuesModal}
        onClose={() => setOpenAddQuesModal(false)}
        onSubmit={handleAddQuesSubmit}
      />
      <StateSelectModal
        open={openStateModal}
        handleClose={handleCloseStateModal}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
        handleContinue={handleContinue}
        isButtonEnabled={isButtonEnabled}
        setIsButtonEnabled={setIsButtonEnabled}
      />
    </>
  );
};

export default SurveyActionButtons;
