import React from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
} from "@mui/material";
import DatePicker from "../../../../components/Common/DatePicker";
import NumericField from "../../../../components/Common/NumericField";

const InputValidation = ({
  selectedField,
  fieldFormData,
  handleFieldChangeWrapper,
  handleFieldChange,
}) => (
  <>
    {selectedField !== "radiogrp" &&
      selectedField !== "checkboxgrp" &&
      selectedField !== "commongrp" && (
        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Box>
                <Typography variant="label" sx={{ fontWeight: "bold" }}>
                  INPUT VALIDATION
                </Typography>
              </Box>
              <FormGroup>
                <Grid container spacing={1} sx={{ marginTop: "8px" }}>
                  <Grid
                    item
                    xs={12}
                    container
                    spacing={1}
                    alignItems="flex-start"
                  >
                    <Grid item xs={selectedField === "Date Field" ? 12 : 6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={fieldFormData["isRequired"] === true}
                            name="isRequired"
                            onChange={(e) =>
                              handleFieldChange(
                                e?.target?.name,
                                e?.target?.checked
                              )
                            }
                          />
                        }
                        label="Is Required"
                      />
                    </Grid>
                    {(selectedField === "Simpletext Field" ||
                      selectedField === "Textarea Field") && (
                      <Grid item xs={6}>
                        <NumericField
                          size="small"
                          name="maxLength"
                          variant="outlined"
                          value={fieldFormData["maxLength"]}
                          onChange={handleFieldChangeWrapper("maxLength")}
                          label="Max # chars"
                        />
                      </Grid>
                    )}
                    {(selectedField === "State Field" ||
                      selectedField === "Combobox Field") && (
                      <Grid item xs={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              checked={fieldFormData["isMultiselect"] === true}
                              name="isMultiselect"
                              onChange={(e) =>
                                handleFieldChange(
                                  e?.target?.name,
                                  e?.target?.checked
                                )
                              }
                            />
                          }
                          label="Allow Multiple Selection"
                        />
                      </Grid>
                    )}
                    {selectedField === "Numeric Field" && (
                      <Grid
                        item
                        xs={12}
                        container
                        spacing={1}
                        alignItems="flex-start"
                      >
                        <Grid item xs={6}>
                          <NumericField
                            height="small"
                            name="minNumeric"
                            variant="outlined"
                            value={fieldFormData["minNumeric"]}
                            onChange={handleFieldChangeWrapper("minNumeric")}
                            label="Min Value"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <NumericField
                            height="small"
                            name="maxNumeric"
                            variant="outlined"
                            value={fieldFormData["maxNumeric"]}
                            onChange={handleFieldChangeWrapper("maxNumeric")}
                            label="Max Value"
                          />
                        </Grid>
                      </Grid>
                    )}
                    {selectedField === "Date Field" && (
                      <>
                        <Grid item xs={6}>
                          <DatePicker
                            name="minDate"
                            height="small"
                            value={fieldFormData["minDate"]}
                            onChange={handleFieldChangeWrapper("minDate")}
                            label="Min Date"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <DatePicker
                            name="maxDate"
                            height="small"
                            value={fieldFormData["maxDate"]}
                            onChange={handleFieldChangeWrapper("maxDate")}
                            label="Max Date"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </Grid>
              </FormGroup>
            </CardContent>
          </Card>
        </Grid>
      )}
  </>
);

export default InputValidation;
