import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InfoIcon from "@mui/icons-material/Info";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import HomeIcon from "@mui/icons-material/Home";
import ExplorerTree from "./ExplorerTree";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import Button from "../Common/Button";
import { useLocation } from "react-router-dom";
import ProcessSurveySidebar from "./ProcessSurveySidebar";
import PanelMgtSidebar from "./PanelMgtSidebar";
import SurveyBuilderSidebar from "./SurveyBuilderSidebar";
import AdminSidebar from "./AdminSidebar";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

function CustomSeparator() {
  function getSectionNumber(sequence) {
    if (sequence >= 0 && sequence <= 25) {
      return String.fromCharCode(65 + sequence);
    }
    return null;
  }

  //   const sectionNumber = getSectionNumber(SectionData?.sequence);
  //   const pageNumber = PageData?.sequence + 1;

  const breadcrumbs = [
    <Link
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      key="1"
      href="/"
      onClick={handleClick}
    >
      {`Section -1`}
    </Link>,
    <Link
      style={{
        textDecoration: "none",
        color: "inherit",
      }}
      key="2"
      href="/"
      onClick={handleClick}
    >
      {`Page - 1`}
    </Link>,
    // <Typography key="3" color="text.primary">
    //   Q1
    // </Typography>,
  ];

  return (
    <Stack spacing={2}>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {/* {sectionNumber && pageNumber && breadcrumbs} */}
      </Breadcrumbs>
    </Stack>
  );
}

const drawerWidth = 350;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function Sidebar({ open }) {
  const location = useLocation();
  const renderSidebar = () => {
    if (
      location.pathname === "/process-survey" ||
      location.pathname === "/response-report"
    ) {
      return <ProcessSurveySidebar />;
    } else if (location.pathname === "/panel-mgr") {
      return <PanelMgtSidebar />;
    } else if (location.pathname === "/survey-builder") {
      return <SurveyBuilderSidebar />;
    } else if (location.pathname === "/admin") {
      return <AdminSidebar />;
    } else {
      return <ExplorerTree />;
    }
  };

  return (
    <Box sx={{ display: "flex", position: "unset" }}>
      <CssBaseline />
      <Drawer
        sx={{ position: "unset !important" }}
        variant="permanent"
        open={open}
      >
        <Box
          sx={{
            backgroundColor: "rgba(251, 251, 251, 1)",
            padding: "20px 20px ",
            height: "90vh",
            overflowY: "auto",
            overflowX: "hidden",
            // margin: "12px 0 0 12px",
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0, 48, 135, 1) white",
            "&::-webkit-scrollbar": {
              width: 3,
              backgroundColor: "white",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0, 48, 135, 1)",
              borderRadius: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "transparent",
            },
          }}
        >
          {renderSidebar()}
        </Box>
      </Drawer>
    </Box>
  );
}
