import React from "react";
import { Typography, Button } from "@mui/material";
import { MaterialReactTable } from "material-react-table";

const SecResponseOptions = ({ table, handleAddRes }) => (
  <>
    <Typography variant="label" sx={{ fontWeight: "bold" }} paragraph>
      RESPONSES
    </Typography>
    <MaterialReactTable table={table} />
    <Button
      sx={{ mt: "1%", mb: "1%" }}
      variant="contained"
      onClick={handleAddRes}
    >
      Add Response
    </Button>
  </>
);

export default SecResponseOptions;
