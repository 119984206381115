import React from "react";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import "../Layout.scss";
import { Grid } from "@mui/material";

const NoSidebarLayout = ({ children }) => {
  return (
    <div className="app-layout">
      <Navbar />
      <div className="main-layout">
        <main style={{ width: "100%", overflow: "auto" }}>
          <Grid>{children}</Grid>
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default NoSidebarLayout;
