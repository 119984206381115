import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import {
  Box,
  Toolbar,
  IconButton,
  Menu,
  Badge,
  MenuItem,
  Typography,
  Button,
} from "@mui/material";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import MoreIcon from "@mui/icons-material/MoreVert";
import Logo from "../../assets/aha-header-logo.png";
import HeaderHome from "../../assets/header-home.svg";
import HeaderHelp from "../../assets/header-help.svg";
import HeaderSettings from "../../assets/header-settings.svg";
import "./Navbar.scss";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import { USER_ROLES, APP_URL } from "../../Utility/constants";
import {
  setSelectedPanelId,
  setSelectedOrgs,
} from "../../pages/Survey/Survey.slice";

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

export default function Navbar({ toggleSidebar }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const headerAlignStyles = {
    display: "flex",
    alignItems: "center",
    padding: "2px",
    fontSize: "0.90rem",
  };
  const headerBtnStyles = {
    my: 2,
    display: "flex",
    textTransform: "none",
  };
  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );
  const [anchorElReport, setAnchorElReport] = React.useState(null);
  const open = Boolean(anchorElReport);
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const isSuperUser =
    roleName === USER_ROLES.BUILDER_SUPER_USER_ROLENAME ? true : false;

  const isBuilderAdminUser =
    roleName === USER_ROLES.BUILDER_ADMIN_ROLENAME ? true : false;

  const isStakeholderUser =
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME ||
    roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME
      ? true
      : false;

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleClose = () => {
    setAnchorElReport(null);
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    handleMenuClose();
    handleMobileMenuClose();
    window.location.href = `${APP_URL}/logout`;
  };

  const handleAccount = () => {
    handleMenuClose();
    handleMobileMenuClose();
  };

  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
    toggleSidebar(!sidebarOpen);
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleAccount}>
        <IconButton size="large" color="inherit">
          <Badge>
            <AccountCircle />
          </Badge>
        </IconButton>
        <p>Account</p>
      </MenuItem>
      <MenuItem onClick={handleLogout}>
        <IconButton size="large" color="inherit">
          <Badge>
            <LogoutIcon />
          </Badge>
        </IconButton>
        <p>Logout</p>
      </MenuItem>
    </Menu>
  );

  const homeClicked = () => {
    dispatch(setSelectedPanelId(null));
    dispatch(setSelectedOrgs({}));
    navigate("/");
  };
  const settingsClicked = () => {
    navigate("/admin");
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          backgroundColor: "#FBFBFB",
          height: "100px",
          boxShadow:
            "0px 1px 2px -1px rgba(0,0,0,0.2), 0px 0px 2px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
        }}
        position="fixed"
      >
        <Toolbar>
          <Toolbar>
            <Box
              component="img"
              className="header-logo"
              alt="Your logo."
              src={Logo}
            />
          </Toolbar>

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "right",
            }}
          >
            <Menu
              id="basic-menu"
              anchorEl={anchorElReport}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem>Print Survey</MenuItem>
              <MenuItem>Print Glossary</MenuItem>
            </Menu>
            <Button
              color="headerMenu"
              sx={headerBtnStyles}
              onClick={() => homeClicked()}
            >
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <Box
                    sx={{ height: "25px" }}
                    component="img"
                    alt="Home icon"
                    src={HeaderHome}
                  />
                </span>
                <Typography sx={headerAlignStyles} variant="headerMenu">
                  Home
                </Typography>
              </div>
            </Button>
            {(isSuperUser || isStakeholderUser || isBuilderAdminUser) && (
              <Button
                color="headerMenu"
                sx={headerBtnStyles}
                onClick={() => settingsClicked()}
              >
                <div style={headerAlignStyles}>
                  <span className="headerIcon" style={headerAlignStyles}>
                    <Box
                      sx={{ height: "25px" }}
                      component="img"
                      alt="Home icon"
                      src={HeaderSettings}
                    />
                  </span>
                  <Typography sx={headerAlignStyles} variant="headerMenu">
                    Admin
                  </Typography>
                </div>
              </Button>
            )}
            <Button color="headerMenu" sx={headerBtnStyles}>
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <Box
                    sx={{ height: "25px" }}
                    component="img"
                    alt="Home icon"
                    src={HeaderHelp}
                  />
                </span>
                <Typography sx={headerAlignStyles} variant="headerMenu">
                  Help
                </Typography>
              </div>
            </Button>
          </Box>

          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="headerMenu"
              sx={{ borderRadius: "13%", border: "1px solid #eeeeee" }}
            >
              {/* <AccountCircle /> */}
              <div style={headerAlignStyles}>
                <span className="headerIcon" style={headerAlignStyles}>
                  <PersonIcon
                    sx={{
                      width: 35,
                      height: 35,
                      color: "white",
                      background: "#B6C3DB",
                    }}
                  />
                </span>
                <Box
                  sx={{
                    display: { xs: "none", md: "flex" },
                    flexDirection: "column",
                    justifyContent: "left",
                  }}
                >
                  <Typography sx={{ color: "#171B1E", fontWeight: "bold" }}>
                    {userName}
                  </Typography>
                </Box>
              </div>
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="headerMenu"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
  );
}
