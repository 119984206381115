import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Modal,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "../../../components/Common/Button";
import theme from "../../../theme";
import SurveyHelpText from "../SurveyHelpText";
import {
  inactiveSurveysByOrg,
  createSurvey,
  updateSurvey,
  parentSurveyList,
  surveyLogoFilename,
  uploadSurveyLogo,
} from "../../Survey/Survey.service";
import {
  fetchPanelsByOrgId,
  setSelectedSurveyId,
  setSelectedSurveyName,
  setSurveyBuilderCurrentView,
  triggerSidebarRefresh,
} from "../../Survey/Survey.slice";
import RichEditor from "../../../components/Common/RichEditor";
import { CLEAN_FORM_DATA } from "../../../Utility/constants";

export const initNewSurveyData = {
  name: "",
  description: "",
  surveyPanel: null,
  helpText: "",
  loginText: "",
  email: "",
  thankYouMsg: "",
  submitMsg: "",
  historicalSurveyId: "",
  parentSurveyId: "",
};

const NewSurvey = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [historicalSurveys, setHistoricalSurveys] = useState([]);
  const [parentSurveys, setParentSurveys] = useState([]);
  const [jumpSection, setJumpSection] = useState(null);
  const [openSurveyHelpModal, setOpenSurveyHelpModal] = useState(false);
  const [nameError, setNameError] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");

  const surveyPanels = useSelector((state) => state.survey.surveyPanels);
  const [showProperties, setShowProperties] = useState(false);
  const selectedSurveyData = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData
  );

  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );

  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const [formData, setFormData] = useState(initNewSurveyData);
  const [updateFormData, setUpdateFormData] = useState(
    selectedSurveyData?.survey
  );
  const [uploadingLogo, setUploadingLogo] = useState(false);
  const [logoText, setLogoText] = useState(null);
  const [imgError, setImgError] = useState(null);
  const [updateLogoName, setUpdateLogoName] = useState(false);

  useEffect(() => {
    dispatch(fetchPanelsByOrgId(orgId));
  }, [orgId, dispatch]);

  useEffect(() => {
    const getSurveyLogoFilename = async () => {
      try {
        const res = await surveyLogoFilename(selectedSurveyData?.survey?.id);
        if (res.data) {
          setLogoText(res.data);
        } else setLogoText("No logo selected");
      } catch (error) {
        console.error("Error fetching survey logo:", error);
      }
    };
    if (selectedSurveyData?.survey?.id) {
      getSurveyLogoFilename();
    }
  }, [selectedSurveyData?.survey?.id, updateLogoName]);

  const handleLogoUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      setImgError("Please upload a valid image file.");
      return;
    }
    setUploadingLogo(true);

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await uploadSurveyLogo(
        selectedSurveyData?.survey?.id,
        formData
      );
      if (response?.status === 200) {
        setUpdateLogoName((prev) => !prev);
        setImgError(null);
      } else {
        setImgError("Error uploading logo");
      }
    } catch (error) {
      console.error("Error uploading logo:", error);
    } finally {
      setUploadingLogo(false);
      event.target.value = "";
    }
  };

  useEffect(() => {
    if (Object.keys(selectedSurveyData).length > 0) {
      const updatedFormData = {
        name: selectedSurveyData.survey.name,
        description: selectedSurveyData.survey.description,
        surveyPanel: {
          id: selectedSurveyData.survey.surveyPanel
            ? selectedSurveyData.survey.surveyPanel.id
            : null,
        },
        helpText: selectedSurveyData.survey.helpText,
        loginText: selectedSurveyData.survey.loginText,
        email: selectedSurveyData.survey.email,
        thankYouMsg: selectedSurveyData.survey.thankYouMsg,
        submitMsg: selectedSurveyData.survey.submitMsg,
        historicalSurveyId: selectedSurveyData.survey.historicalSurveyId,
        parentSurveyId: selectedSurveyData.survey.parentSurveyId,
      };
      setFormData(updatedFormData);
    }
  }, [selectedSurveyData]);

  useEffect(() => {
    const getHistoricalSurveys = async () => {
      try {
        const res = await inactiveSurveysByOrg(orgId);
        setHistoricalSurveys(res.data);
      } catch (error) {
        console.error("Error fetching surveys:", error);
      }
    };
    const getParentSurveys = async () => {
      try {
        const res = await parentSurveyList();
        setParentSurveys(res.data);
      } catch (error) {
        console.error("Error fetching parent survey", error);
      }
    };
    if (orgId) {
      getHistoricalSurveys();
      getParentSurveys();
    }
  }, [orgId]);

  const toggleProps = () => {
    setShowProperties(!showProperties);
  };
  const handleCloseSurveyHelpModal = () => {
    setOpenSurveyHelpModal(false);
  };
  const handleOpenSurveyHelpModal = (content = "") => {
    setJumpSection(content);
    setOpenSurveyHelpModal(true);
  };

  const goBackPage = () => {
    if (selectedSurveyData && selectedSurveyData?.survey?.id) {
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 1, source: "" }));
      dispatch(triggerSidebarRefresh());
    } else {
      navigate("/");
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "surveyPanel") {
      if (value === "") {
        setFormData((prevData) => ({
          ...prevData,
          [name]: null,
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          [name]: { id: value },
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (Object.keys(selectedSurveyData).length > 0) {
      if (name === "surveyPanel") {
        if (value === "") {
          setUpdateFormData((prevData) => ({
            ...prevData,
            [name]: null,
          }));
        } else {
          setUpdateFormData((prevData) => ({
            ...prevData,
            [name]: { id: value },
          }));
        }
      } else {
        setUpdateFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };
  const handleSave = () => {
    setNameError(null);
    const name = formData["name"];
    if (!name || name?.replace(/(&nbsp;|\s|<br\s*\/?>)/g, "").trim() === "") {
      setNameError("Please enter name");
    } else {
      if (selectedSurveyData && selectedSurveyData?.survey?.id) {
        updateSurveyRecord(selectedSurveyData?.survey?.id);
      } else {
        createSurveyRecord();
      }
    }
  };

  const updateSurveyRecord = async (id) => {
    const cleanData = CLEAN_FORM_DATA(updateFormData);
    try {
      const payload = { ...cleanData, modifiedBy: userName };
      const response = await updateSurvey(payload);
      if (response?.data) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey updated successfully.");
        setAlertSeverity("success");

        dispatch(setSelectedSurveyId(selectedSurveyData?.survey?.id));
        setTimeout(() => {
          dispatch(setSurveyBuilderCurrentView({ viewIndex: 1, source: "" }));
          dispatch(triggerSidebarRefresh());
        }, 2000);
      }
    } catch (error) {
      console.log("Error- create survey", error);
    }
  };
  const createSurveyRecord = async () => {
    const cleanData = CLEAN_FORM_DATA(formData);
    try {
      const payload = {
        ...cleanData,
        createdBy: userName,
        createdByPersonId: personId,
      };
      const response = await createSurvey(payload);
      if (
        response?.status === 200 &&
        response?.data &&
        response?.data?.survey
      ) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Survey created successfully.");
        setAlertSeverity("success");

        dispatch(setSelectedSurveyId(response.data.survey.id));
        dispatch(setSelectedSurveyName(response.data.survey.name));
        setTimeout(() => {
          navigate("/survey-builder");
        }, 2000);
      } else if (response?.data?.status === 400) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error- create survey", error);
    }
  };

  return (
    <Box component="fieldset">
      <legend sx={{ color: theme.palette.pink.main }}>
        <b>NEW SURVEY TITLE & ATTRIBUTES</b>
      </legend>
      {errorMsg !== "" && (
        <div style={{ color: theme.palette.error.main }}>{errorMsg}</div>
      )}
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <Typography variant="caption" color="error">
            * Required
          </Typography>
          <Typography variant="body1">Name *</Typography>
        </Grid>
        <Grid item xs={10}>
          <RichEditor
            name="name"
            value={formData["name"]}
            onChange={handleChange}
          />

          {nameError && (
            <Typography variant="body1" color="error">
              {nameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">Description</Typography>
        </Grid>
        <Grid item xs={10}>
          <RichEditor
            name="description"
            value={formData["description"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">Panel</Typography>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ minWidth: 290 }}>
            <InputLabel className="inputLabel">Survey Panel</InputLabel>
            <Select
              size="small"
              variant="outlined"
              value={formData["surveyPanel"] ? formData["surveyPanel"]?.id : ""}
              name="surveyPanel"
              onChange={handleChange}
              label="Select Panel"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {surveyPanels.map((surveyPanel) => (
                <MenuItem key={surveyPanel.id} value={surveyPanel.id}>
                  {surveyPanel.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Typography
            variant="body1"
            className="blueLinkText"
            onClick={() => toggleProps()}
          >
            {showProperties ? "-" : "+"} additional survey properties
          </Typography>
        </Grid>
        {showProperties && (
          <>
            <Grid item xs={2}>
              <Typography variant="body1">Help</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="helpText"
                value={formData["helpText"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Login Page Text
                <IconButton
                  onClick={() => handleOpenSurveyHelpModal("logintext")}
                >
                  <ErrorOutlineIcon color="primary"></ErrorOutlineIcon>
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="loginText"
                value={formData["loginText"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Submit Text
                <IconButton onClick={() => handleOpenSurveyHelpModal("")}>
                  <ErrorOutlineIcon color="primary"></ErrorOutlineIcon>
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="submitMsg"
                value={formData["submitMsg"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Thank You Text
                <IconButton
                  onClick={() => handleOpenSurveyHelpModal("thankyoumsg")}
                >
                  <ErrorOutlineIcon color="primary"></ErrorOutlineIcon>
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="thankYouMsg"
                value={formData["thankYouMsg"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Historical Reference</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl sx={{ minWidth: 300, maxWidth: 350 }}>
                <InputLabel className="inputLabel">
                  Select Historical Reference
                </InputLabel>
                <Select
                  size="small"
                  variant="outlined"
                  value={
                    formData["historicalSurveyId"]
                      ? formData["historicalSurveyId"]
                      : ""
                  }
                  name="historicalSurveyId"
                  onChange={handleChange}
                  label="Select Historical Reference"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {historicalSurveys.map((survey) => (
                    <MenuItem key={survey.id} value={survey.id}>
                      {survey.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Parent Survey</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl sx={{ minWidth: 300, maxWidth: 350 }}>
                <InputLabel className="inputLabel">
                  Select Parent Survey
                </InputLabel>
                <Select
                  size="small"
                  variant="outlined"
                  value={
                    formData["parentSurveyId"] ? formData["parentSurveyId"] : ""
                  }
                  name="parentSurveyId"
                  onChange={handleChange}
                  label="Select Parent Survey"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {parentSurveys.map((survey) => (
                    <MenuItem key={survey.id} value={survey.id}>
                      {survey.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Administrator Email{" "}
                <IconButton
                  onClick={() => handleOpenSurveyHelpModal("emailadd")}
                >
                  <ErrorOutlineIcon color="primary"></ErrorOutlineIcon>
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                size="small"
                name="email"
                variant="outlined"
                value={formData["email"]}
                style={{ width: 500 }}
                onChange={handleChange}
              />
            </Grid>
            {selectedSurveyData?.survey?.id && (
              <>
                <Grid item xs={2}>
                  <Typography variant="body1">Logo</Typography>
                </Grid>
                <Grid item xs={10}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      component="label"
                      style={{ marginRight: "20px" }}
                      disabled={uploadingLogo}
                    >
                      {uploadingLogo ? "Uploading" : "Upload"}
                      <input
                        type="file"
                        hidden
                        accept="image/*"
                        onChange={(e) => handleLogoUpload(e)}
                      />
                    </Button>
                    <Typography variant="body1">{logoText}</Typography>
                  </div>
                </Grid>

                {imgError && (
                  <>
                    <Grid item xs={2}></Grid>
                    <Grid item xs={10}>
                      <Typography variant="body1" color="error">
                        {imgError}
                      </Typography>
                    </Grid>
                  </>
                )}
              </>
            )}
          </>
        )}
        <Grid item xs={10}></Grid>
        <Grid item xs={2}>
          <Button title="Home" onClick={() => goBackPage()}>
            Cancel
          </Button>{" "}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Modal open={openSurveyHelpModal} onClose={handleCloseSurveyHelpModal}>
        <>
          <SurveyHelpText
            jumpSection={jumpSection}
            handleClose={handleCloseSurveyHelpModal}
          />
        </>
      </Modal>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default NewSurvey;
