import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPanelsByOrgId,
  setSelectedPanelId,
} from "../../pages/Survey/Survey.slice";
import { useLocation } from "react-router-dom";

const ProcessSurveySidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const surveyPanels = useSelector((state) => state.survey.surveyPanels);
  const surveyPanelId = location?.state?.surveyPanelId;
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const [defaultPanelId, setDefaultPanelId] = useState("");

  useEffect(() => {
    dispatch(fetchPanelsByOrgId(orgId));
  }, [dispatch, orgId]);
  // -------------------------------
  // Default the selected panel to the first panel in the list
  // 1. If the survey panel id of the selected survey is not belonging to the list
  // 2. survey panel id of the selected survey itself is empty

  useEffect(() => {
    const setPanel = (isPanelExist) => {
      let data = "";
      if (surveyPanelId !== undefined && isPanelExist) {
        data = surveyPanelId;
      } else {
        data = surveyPanels[0].id;
      }

      setDefaultPanelId(data);
    };
    if (surveyPanels?.length > 0) {
      let isPanelExist = false;
      if (surveyPanelId) {
        isPanelExist = surveyPanels.some((panel) => panel.id === surveyPanelId);
      }
      setPanel(isPanelExist);
    }
  }, [surveyPanels]);

  useEffect(() => {
    if (defaultPanelId) {
      dispatch(setSelectedPanelId(defaultPanelId));
    }
  }, [dispatch, defaultPanelId]);

  const handlePanelSelect = (event) => {
    const panelId = event.target.value;
    setDefaultPanelId(panelId);
  };

  return (
    <div style={{ whiteSpace: "normal" }}>
      <Typography color="primary" variant="label">
        Current list of survey panel(s) for AHA Annual Survey Organization
      </Typography>
      <Box sx={{ margin: "20px 0" }}>
        <FormControl fullWidth>
          <Select value={defaultPanelId} onChange={handlePanelSelect}>
            {surveyPanels?.map((panel) => (
              <MenuItem key={panel?.id} value={panel?.id}>
                {panel.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography color="primary">
        To work with another panel, please select from the list above.
      </Typography>
    </div>
  );
};

export default ProcessSurveySidebar;
