import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Divider,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import theme from "../../theme";
import {
  createOrganization,
  updateOrganization,
} from "../Survey/Survey.service";
import { CLEAN_FORM_DATA } from "../../Utility/constants";

export const initNewData = {
  id: "",
  orgTypeId: "",
  name: "",
  address1: "",
  address2: "",
  city: "",
  county: "",
  zipCode: "",
  zipCodeExt: "",
  phoneNumber: "",
  fax: "",
  attribute: "none",
  stateId: "",
  attributeValue: "",
  urbanFlag: "",
};
const AddEditOrganization = ({
  handleCloseOrg,
  selOrgData,
  orgTypeList,
  handleRefreshOrg,
}) => {
  const [formData, setFormData] = useState(initNewData);

  const [errorMsg, setErrorMsg] = useState("");
  const [requiredErrorMsg, setRequiredErrorMsg] = useState("");
  const states = useSelector((state) => state?.survey?.states);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const createdBy = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );

  const attributes = [
    "survey_respondent.attribute1",
    "survey_respondent.attribute2",
    "survey_respondent.attribute3",
    "survey_respondent.attribute4",
    "survey_respondent.attribute5",
    "survey_respondent.attribute6",
    "survey_respondent.attribute7",
    "survey_respondent.attribute8",
    "survey_respondent.attribute9",
    "survey_respondent.attribute10",
    "survey_respondent.attribute11",
    "survey_respondent.attribute12",
    "survey_respondent.attribute13",
    "survey_respondent.attribute14",
    "survey_respondent.attribute15",
    "survey_respondent.attribute16",
    "survey_respondent.attribute17",
    "survey_respondent.attribute18",
    "survey_respondent.attribute19",
    "survey_respondent.attribute20",
    "survey_organization.attribute1",
    "survey_organization.attribute2",
    "survey_organization.attribute3",
    "survey_organization.attribute4",
    "survey_organization.attribute5",
    "survey_organization.attribute6",
    "survey_organization.attribute7",
    "survey_organization.attribute8",
    "survey_organization.attribute9",
    "survey_organization.attribute10",
    "survey_organization.attribute11",
    "survey_organization.attribute12",
    "survey_organization.attribute13",
    "survey_organization.attribute14",
    "survey_organization.attribute15",
    "survey_organization.attribute16",
    "survey_organization.attribute17",
    "survey_organization.attribute18",
    "survey_organization.attribute19",
    "survey_organization.attribute20",
  ];

  useEffect(() => {
    if (Object.keys(selOrgData).length > 0) {
      const updatedFormData = {
        id: selOrgData.id,
        orgTypeId: selOrgData.orgType ? selOrgData.orgType.id : null,
        attribute: selOrgData.attribute ? selOrgData.attribute : "none",
        name: selOrgData.name || "",
        address1: selOrgData.address1 || "",
        address2: selOrgData.address2 || "",
        city: selOrgData.city || "",
        county: selOrgData.county || "",
        zipCode: selOrgData.zipCode || "",
        zipCodeExt: selOrgData.zipCodeExt || "",
        phoneNumber: selOrgData.phoneNumber || "",
        fax: selOrgData.fax || "",
        stateId: selOrgData.stateCode ? selOrgData.stateCode.id : null,
        attributeValue: selOrgData.attributeValue || "",
        urbanFlag:
          selOrgData?.urbanFlag || selOrgData?.urbanFlag === false
            ? selOrgData?.urbanFlag
            : null,
      };
      setFormData(updatedFormData);
    }
  }, [selOrgData]);

  const handleChange = (e) => {
    const { name, value, type } = e.target;
    if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === "urban" ? true : false,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSave = async (e) => {
    setErrorMsg("");
    setRequiredErrorMsg("");
    const name = formData["name"];
    const orgTypeId = formData["orgTypeId"];
    const stateId = formData["stateId"];
    if (
      name.trim() === "" ||
      orgTypeId === "" ||
      stateId === "" ||
      stateId === null
    ) {
      setRequiredErrorMsg("Please enter required fields");
    } else {
      const isUpdateUserCall = Object.keys(selOrgData).length > 0;
      if (isUpdateUserCall) {
        updateOrgSave(selOrgData.id);
      } else {
        createOrgSave();
      }
    }
  };
  const updateOrgSave = async (id) => {
    const cleanData = CLEAN_FORM_DATA(formData);
    if (cleanData.attribute === "none") {
      cleanData.attribute = null;
    }
    cleanData.attributeValue = cleanData.attributeValue
      ? cleanData.attributeValue
      : null;

    const payload = {
      ...cleanData,
      modifiedBy: createdBy,
      ownerOrgId: orgId,
      id: id,
    };
    try {
      const response = await updateOrganization(payload);
      if (response?.data?.id) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Organization updated successfully.");
        setAlertSeverity("success");

        setTimeout(() => {
          handleRefreshOrg();
        }, 2000);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving organization", error);
    }
  };
  const createOrgSave = async () => {
    const cleanData = CLEAN_FORM_DATA(formData);
    if (cleanData.attribute === "none") {
      cleanData.attribute = null;
    }
    cleanData.attributeValue = cleanData.attributeValue
      ? cleanData.attributeValue
      : null;

    const payload = {
      ...cleanData,
      createdBy: createdBy,
      ownerOrgId: orgId,
    };
    try {
      const response = await createOrganization(payload);
      if (response?.data?.id) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Organization created successfully.");
        setAlertSeverity("success");
        setTimeout(() => {
          handleRefreshOrg();
        }, 2000);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving organization", error);
    }
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Create/Update Organization</Typography>
        </div>
        <div className="panelForm">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <label>Please edit the organization information</label>
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              * Name, Type, State are required.
            </Typography>
            {requiredErrorMsg !== "" && (
              <div
                style={{ textAlign: "right", color: theme.palette.error.main }}
              >
                {requiredErrorMsg}
              </div>
            )}
            {errorMsg !== "" && (
              <div
                style={{ textAlign: "right", color: theme.palette.error.main }}
              >
                {errorMsg}
              </div>
            )}
            <Divider color={theme.palette.blue.main} />
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={2}>
                <Typography variant="body1">Name *</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="name"
                  variant="outlined"
                  value={formData["name"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Type *</Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  sx={{ width: "240px" }}
                  size="small"
                  variant="outlined"
                  value={formData["orgTypeId"]}
                  onChange={handleChange}
                  name="orgTypeId"
                >
                  {orgTypeList.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Address 1</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="address1"
                  variant="outlined"
                  value={formData["address1"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Address 2</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="address2"
                  variant="outlined"
                  value={formData["address2"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">City</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="city"
                  variant="outlined"
                  value={formData["city"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">County</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="county"
                  variant="outlined"
                  value={formData["county"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">State *</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl sx={{ width: "240px" }}>
                  <Select
                    size="small"
                    variant="outlined"
                    value={formData["stateId"]}
                    onChange={handleChange}
                    name="stateId"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.longName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Zip</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="zipCode"
                  variant="outlined"
                  value={formData["zipCode"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Zip Ext.</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="zipCodeExt"
                  variant="outlined"
                  value={formData["zipCodeExt"]}
                  onChange={handleChange}
                  inputProps={{
                    maxLength: 4,
                  }}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Telephone Number</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="phoneNumber"
                  variant="outlined"
                  value={formData["phoneNumber"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Fax Number</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="fax"
                  variant="outlined"
                  value={formData["fax"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Attribute</Typography>
              </Grid>
              <Grid item xs={3}>
                <Select
                  sx={{ width: "240px" }}
                  size="small"
                  variant="outlined"
                  value={formData["attribute"]}
                  onChange={handleChange}
                  name="attribute"
                >
                  <MenuItem value={"none"}>None</MenuItem>
                  {attributes.map((attr, index) => (
                    <MenuItem key={index} value={attr}>
                      {attr}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Location</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl component="fieldset" sx={{ width: "220px" }}>
                  <RadioGroup
                    row
                    name="urbanFlag"
                    value={
                      formData["urbanFlag"]
                        ? "urban"
                        : formData["urbanFlag"] === false
                        ? "rural"
                        : null
                    }
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="urban"
                      control={<Radio />}
                      label="Urban"
                    />
                    <FormControlLabel
                      value="rural"
                      control={<Radio />}
                      label="Rural"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Attribute Value</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="attributeValue"
                  variant="outlined"
                  value={formData["attributeValue"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Divider color={theme.palette.blue.main} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Button variant="outlined" onClick={handleCloseOrg}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button variant="outlined" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AddEditOrganization;
