import React, { useState } from "react";
import {
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Button,
  FormControl,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import RichEditor from "../../../components/Common/RichEditor";
import { updateQuestionGroup } from "../../Survey/Survey.service";
const QuickEditQuesGrp = ({
  handleCloseEditGrpModal,
  openQuestionGrp,
  handleRefreshQuestionGrp,
}) => {
  const [quesFormData, setQuesFormData] = useState(openQuestionGrp);
  const placements = ["No Title", "Above Title", "Below Title"];
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setQuesFormData((prevData) => ({
        ...prevData,
        [name]: checked ? true : false,
      }));
    } else {
      setQuesFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const handleSaveQuesGrp = async (e) => {
    const name = quesFormData["name"];
    try {
      let questionGroupObject = {
        id: quesFormData["id"],
        groupLabel: quesFormData["groupLabel"],
        name: quesFormData["name"],
        description: quesFormData["description"],
        descriptionPosition: quesFormData["descriptionPosition"],
        helpText: quesFormData["helpText"],
        sequence: quesFormData["sequence"] || 0,
        shortName: quesFormData["shortName"],
        tableWidthPct: quesFormData["tableWidthPct"]
          ? Number(quesFormData["tableWidthPct"])
          : null,
        questionWidthPct: quesFormData["questionWidthPct"]
          ? Number(quesFormData["questionWidthPct"])
          : null,
        hideGroup: quesFormData["hideGroup"],
        modifiedBy: quesFormData["modifiedBy"],
        page: quesFormData["page"],
      };

      const response = await updateQuestionGroup(questionGroupObject);
      if (response?.status === 200) {
        handleRefreshQuestionGrp();
      }
    } catch (error) {
      console.error("Error updating question data", error);
    }
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Text Edit</Typography>
        </div>
        <div className="panelForm">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body1">Group Label</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="groupLabel"
                value={quesFormData["groupLabel"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Text</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="name"
                value={quesFormData["name"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Description</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="description"
                value={quesFormData["description"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Description Placement</Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControl sx={{ width: "300px" }}>
                <Select
                  size="small"
                  variant="outlined"
                  onChange={handleChange}
                  name="descriptionPosition"
                  value={quesFormData["descriptionPosition"]}
                >
                  {placements.map((name, index) => (
                    <MenuItem key={name} value={name}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Help</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="helpText"
                value={quesFormData["helpText"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">
                Short Name (used in Batch Update)
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                size="small"
                name="shortName"
                variant="outlined"
                value={quesFormData["shortName"]}
                style={{ width: 300 }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Table Width %</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                type="number"
                size="small"
                name="tableWidthPct"
                variant="outlined"
                value={quesFormData["tableWidthPct"]}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Question Width %</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                type="number"
                size="small"
                name="questionWidthPct"
                variant="outlined"
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                }}
                value={quesFormData["questionWidthPct"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Hidden </Typography>
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={quesFormData["hideGroup"]}
                    name="hideGroup"
                    onChange={handleChange}
                  />
                }
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <Button
                variant="outlined"
                onClick={() => handleCloseEditGrpModal()}
              >
                Cancel
              </Button>{" "}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveQuesGrp()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default QuickEditQuesGrp;
