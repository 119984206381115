import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, Typography, Button } from "@mui/material";
import RichEditor from "../../../../components/Common/RichEditor";
import { updateQuestion } from "../../../Survey/Survey.service";
import { setTriggerMatrixGrp } from "../../../Survey/Survey.slice";
const QuickQuestionEdit = ({
  handleCloseEditModal,
  openQuestion,
  handleRefreshQuestionWrapper,
  qGroupId,
}) => {
  const dispatch = useDispatch();
  const [quesFormData, setQuesFormData] = useState({
    id: openQuestion?.row.id,
    label: openQuestion?.row.label || null,
    name: openQuestion?.row.name || null,
    helpText: openQuestion?.row.helpText || null,
    sequence: openQuestion?.row.sequence,
    createdBy: openQuestion?.row.createdBy,
    dateCreated: openQuestion?.row.dateCreated,
  });

  const [nameError, setNameError] = useState(null);
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const handleChange = (e) => {
    const { name, value } = e.target;
    setQuesFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSaveQues = async (e) => {
    setNameError(null);
    const name = quesFormData["name"];
    if (!name || name?.replace(/(&nbsp;|\s|<br\s*\/?>)/g, "").trim() === "") {
      setNameError("Please enter text");
    } else {
      dispatch(setTriggerMatrixGrp(false));
      try {
        let questionData = {
          id: quesFormData.id,
          createdBy: quesFormData.createdBy,
          dateCreated: quesFormData.dateCreated,
          dateModified: quesFormData.dateModified,
          sequence: quesFormData.sequence,
          modifiedBy: userName,
          name: quesFormData.name,
          label: quesFormData.label,
          helpText: quesFormData.helpText,
          questionGroup: qGroupId,
        };

        const response = await updateQuestion(questionData);
        if (response?.status === 200) {
          dispatch(setTriggerMatrixGrp(true));
          handleRefreshQuestionWrapper(response.data, openQuestion.index);
        }
      } catch (error) {
        console.error("Error updating question data", error);
      }
    }
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Text Edit</Typography>
        </div>
        <div className="panelForm">
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="body1">Label</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="label"
                value={quesFormData["label"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Text *</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="name"
                value={quesFormData["name"]}
                onChange={handleChange}
              />
              {nameError && (
                <Typography variant="body1" color="error">
                  {nameError}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body1">Help</Typography>
            </Grid>
            <Grid item xs={10}>
              <RichEditor
                name="helpText"
                value={quesFormData["helpText"]}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <Button variant="outlined" onClick={() => handleCloseEditModal()}>
                Cancel
              </Button>{" "}
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSaveQues()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default QuickQuestionEdit;
