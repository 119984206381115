import { useMaterialReactTable } from "material-react-table";
import { useMemo } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Tooltip from "../../../../components/Common/Tooltip";
import TextField from "../../../../components/Common/TextField";
import {
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import theme from "../../../../theme";

const useCustomResponseTable = (
  data,
  setData,
  handleResChange,
  handleResDelete,
  selectedField
) => {
  const columns = useMemo(() => {
    let baseColumns = [
      {
        accessorKey: "delete",
        header: "Delete",
        size: 60,
        Cell: ({ row }) => (
          <Tooltip title="Delete response" arrow>
            <CloseOutlinedIcon
              sx={{
                cursor: "pointer",
                color: theme.palette.error.main,
                background: theme.palette.error.light,
                borderRadius: "5px",
                padding: "5px",
              }}
              onClick={() => handleResDelete(row.original.id)}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "name",
        header: "Response Text*",
        Cell: ({ row }) => (
          <TextField
            height="small"
            value={row.original.name}
            onChange={(val) => {
              handleResChange(row.original.id, "name", val);
            }}
          />
        ),
      },
      {
        accessorKey: "titlePosition",
        header: "Placement",
        Cell: ({ row }) => (
          <FormControl>
            <Select
              size="small"
              name="namePosition"
              value={row.original.titlePosition}
              onChange={(e) =>
                handleResChange(
                  row.original.id,
                  "titlePosition",
                  e?.target?.value
                )
              }
            >
              <MenuItem value="Above">Above</MenuItem>
              <MenuItem value="Below">Below</MenuItem>
              <MenuItem value="Left">Left</MenuItem>
              <MenuItem value="Right">Right</MenuItem>
            </Select>
          </FormControl>
        ),
      },
      {
        accessorKey: "allowableValue",
        header: "Stored Value",
        Cell: ({ row }) => (
          <TextField
            height="small"
            value={row.original.allowableValue}
            onChange={(val) => {
              handleResChange(row.original.id, "allowableValue", val);
            }}
          />
        ),
      },
      {
        accessorKey: "exclusiveAnswer",
        header: "Exclusive Answer",
        size: 120,
        Cell: ({ row }) => (
          <FormControlLabel
            sx={{ ml: 2 }}
            control={
              <Checkbox
                checked={row.original.exclusiveAnswer === true}
                onChange={(e) => {
                  handleResChange(
                    row.original.id,
                    "exclusiveAnswer",
                    e?.target?.checked
                  );
                }}
              />
            }
          />
        ),
      },
    ];

    if (selectedField === "Radio Field" || selectedField === "Combobox Field") {
      baseColumns = baseColumns.filter(
        (column) => column.accessorKey !== "exclusiveAnswer"
      );
    }

    return baseColumns;
  }, [handleResChange, handleResDelete, selectedField]);

  return useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    enableColumnActions: false,
    initialState: { density: "compact" },
    muiTableHeadProps: {
      sx: {
        opacity: "unset",
      },
    },
    muiTableBodyProps: {
      sx: {
        opacity: "unset",
      },
    },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );

          if (draggingRow.index !== hoveredRow.index) {
            const updatedData = data.map((item, index) => ({
              ...item,
              sequence: index,
            }));

            setData(updatedData);
          }
        }
      },
    }),
  });
};

export default useCustomResponseTable;
