import React from "react";
import sanitizeHtml from "sanitize-html";
import Button from "../../components/Common/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { htmlParser } from "../../Utility/constants";

const ConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm, pageName } = props;
  const yesLabel =
    pageName === "Home" || pageName === "Helper" || pageName === "ModifySurvey"
      ? "OK"
      : pageName === "DelSurvey" || pageName === "ActiveSurvey"
      ? "CONFIRM"
      : "YES";
  const noLabel =
    pageName === "Home" ||
    pageName === "Helper" ||
    pageName === "DelSurvey" ||
    pageName === "ModifySurvey" ||
    pageName === "ActiveSurvey"
      ? "CANCEL"
      : "NO";
  return (
    <Dialog
      trasparent={true}
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{htmlParser(title)}</DialogTitle>

      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={() => setOpen(false)}>
          {noLabel}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
        >
          {yesLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
