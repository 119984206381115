import React, { useEffect, useState } from "react";
import {
  getFieldsForSelectList,
  getQuesGroupsForSelectList,
} from "../Survey/Survey.service";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Select, MenuItem, FormControl } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setBatchUpdateCurrentView,
  setSelectedQuesFields,
  setSelectedQuesGroups,
  setUpdateFilingStatus,
} from "../Survey/Survey.slice";
import { getTextFromHTML } from "../../Utility/constants";

const ChooseFields = () => {
  const [selectedFields, setSelectedFields] = useState([]);
  const [selectedGrps, setSelectedGrps] = useState([]);
  const [fields, setFields] = useState([]);
  const [quesGroups, setQuesGroups] = useState([]);
  const [filingStatus, setFilingStatus] = useState("yes");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const dispatch = useDispatch();
  const surveyId = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyId
  );
  const selectedQuesFields = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedFields
  );
  const selectedQuesGroups = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedQuesGroups
  );
  const updateFilingStatus = useSelector(
    (state) => state?.survey?.batchUpdateData?.updateFilingStatus
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fieldsRes = await getFieldsForSelectList(surveyId);
        if (fieldsRes.data) {
          setFields(fieldsRes.data);
        }

        const quesGroupsRes = await getQuesGroupsForSelectList(surveyId);
        if (quesGroupsRes.data) {
          setQuesGroups(quesGroupsRes.data);
        }
      } catch (error) {
        console.error("Error fetching lists data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedFields?.length === 0 && selectedGrps?.length === 0) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [selectedFields, selectedGrps]);

  useEffect(() => {
    setSelectedFields(selectedQuesFields);
    setSelectedGrps(selectedQuesGroups);
    setFilingStatus(updateFilingStatus);
  }, [selectedQuesFields, selectedQuesGroups]);

  const handleFieldChange = (event) => {
    setSelectedFields(event.target.value);
  };

  const handleQuesGroupChange = (event) => {
    setSelectedGrps(event.target.value);
  };

  const handleFilingStatusChange = (event) => {
    setFilingStatus(event.target.checked ? "yes" : "no");
  };

  const handleSubmit = () => {
    if (selectedFields.length === 0 && selectedGrps.length === 0) {
      return;
    }
    dispatch(setSelectedQuesFields(selectedFields));
    dispatch(setSelectedQuesGroups(selectedGrps));
    dispatch(setUpdateFilingStatus(filingStatus));
    dispatch(setBatchUpdateCurrentView(3));
  };

  return (
    <>
      <Typography color="primary" variant="label">
        Please select the survey data fields or predefined set of fields and
        press Submit
      </Typography>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0",
        }}
      >
        <div style={{ width: "49%" }}>
          <Card>
            <CardContent>
              <div>
                <Typography variant="label">
                  Please choose the fields for batch update
                </Typography>
              </div>
              <FormControl>
                <Select
                  multiple
                  size="small"
                  sx={{ width: "220px", marginTop: "15px" }}
                  labelId="filter-select-label"
                  id="filter-selectfield"
                  value={selectedFields}
                  onChange={handleFieldChange}
                >
                  {Object.entries(fields).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </div>
        <div style={{ width: "49%" }}>
          <Card>
            <CardContent>
              <div>
                <Typography variant="label">
                  Please choose the group of fields you would like to update
                </Typography>
              </div>
              <FormControl>
                <Select
                  multiple
                  size="small"
                  sx={{ width: "400px", marginTop: "15px" }}
                  labelId="filter-select-label"
                  id="filter-selectgroup"
                  value={selectedGrps}
                  onChange={handleQuesGroupChange}
                >
                  {Object.entries(quesGroups).map(([key, value]) => (
                    <MenuItem key={key} value={key}>
                      {getTextFromHTML(value)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </div>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography variant="h6" marginRight="15px">
          Keep the filing status unchanged?
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={filingStatus === "yes"}
              onChange={handleFilingStatusChange}
              name="filingStatus"
            />
          }
          label="Yes"
        />
      </div>
      <Button
        variant="contained"
        sx={{ margin: "15px 15px 0 0" }}
        onClick={handleSubmit}
        disabled={submitDisabled}
      >
        Submit
      </Button>
      <Button
        variant="outlined"
        sx={{ marginTop: "15px" }}
        onClick={() => dispatch(setBatchUpdateCurrentView(1))}
      >
        Back
      </Button>
    </>
  );
};

export default ChooseFields;
