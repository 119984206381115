import React from "react";
import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import RichEditor from "../../../../components/Common/RichEditor";

const QuestionCard = ({ quesFormData, handleQuesChange, showFieldOptions }) => {
  return (
    <Box>
      <Card>
        <CardContent>
          <Box>
            <Typography variant="label" sx={{ fontWeight: "bold" }}>
              QUESTION
            </Typography>
          </Box>
          <Grid container spacing={1} sx={{ marginTop: "0" }}>
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="body1">Label</Typography>
                <RichEditor
                  name="label"
                  value={quesFormData["label"]}
                  onChange={handleQuesChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="body1">Text *</Typography>
                <RichEditor
                  name="name"
                  value={quesFormData["name"]}
                  onChange={handleQuesChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1} alignItems="flex-start">
              <Grid item xs={12}>
                <Typography variant="body1">Help </Typography>
                <RichEditor
                  name="helpText"
                  value={quesFormData["helpText"]}
                  onChange={handleQuesChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};

export default QuestionCard;
