import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getTextFromHTML } from "../../../Utility/constants";
import theme from "../../../theme";
import {
  moveQuestionGroup,
  previewRuleLevelAdjustments,
} from "../../Survey/Survey.service";

const AdjustmentsList = ({ ruleAdjustmentData }) => {
  const listItems = ruleAdjustmentData.map((row, index) => (
    <li key={index}>{row}</li>
  ));
  return <ul>{listItems}</ul>;
};

const QuickMoveGroup = ({
  handleCloseMoveGrpModal,
  handleRefreshMoveGrpModal,
  moveGroupId,
  moveGroupName,
}) => {
  const [sections, setSections] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [openRuleAdjustmentList, setOpenRuleAdjustmentList] = useState(false);
  const [ruleAdjustmentData, setRuleAdjustmentData] = useState([]);

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedIndex(value);
  };
  const selectedSurveySections = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData.sections
  );
  useEffect(() => {
    const fetchSections = () => {
      let sectionData = [];
      selectedSurveySections.forEach((item, index) => {
        const sectionGrp = {
          id: item?.section?.id,
          name: getTextFromHTML(item?.section?.name),
          isParent: true,
          index: index,
        };
        // add section row
        sectionData.push(sectionGrp);

        const currentPages = item?.pages;
        currentPages.forEach((item, subindex) => {
          const pageGrp = {
            id: item?.page?.id,
            name: getTextFromHTML(item?.page?.name),
            isParent: false,
            index: subindex,
          };
          // add page row
          sectionData.push(pageGrp);
        });
      });
      setSections(sectionData);

      //set first page{id} default selected
      const firstPage = sectionData[1].id;
      setSelectedIndex(firstPage);
    };
    if (selectedSurveySections) {
      fetchSections();
    }
  }, [selectedSurveySections]);

  const handleMoveGroup = async (e) => {
    setErrorMsg("");
    try {
      const response = await moveQuestionGroup(moveGroupId, selectedIndex);
      if (response?.data && !response?.data?.detail) {
        handleRefreshMoveGrpModal();
      } else if (response?.data?.status === 400) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error moving question group", error);
    }
  };
  const handlePreviewRuleLevelAdjustments = async (e) => {
    setOpenRuleAdjustmentList(false);
    setRuleAdjustmentData([]);
    setErrorMsg("");
    try {
      const response = await previewRuleLevelAdjustments(
        moveGroupId,
        selectedIndex
      );
      if (response?.data && !response?.data?.detail) {
        if (response.data.length === 0) {
          handleMoveGroup();
        } else {
          setRuleAdjustmentData(response.data);
          setOpenRuleAdjustmentList(true);
        }
      } else if (response?.data?.status === 400) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error RuleLevelAdjustments", error);
    }
  };

  const handleCloseRuleAdjustmentList = () => {
    setOpenRuleAdjustmentList(false);
    setRuleAdjustmentData([]);
    handleCloseMoveGrpModal();
  };

  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Move Group to Another Page</Typography>
        </div>
        <div className="panelForm">
          <Grid container spacing={2}>
            <Grid item xs={11} sx={{ textAlign: "center" }}>
              {moveGroupName && !openRuleAdjustmentList && (
                <Typography variant="label" paragraph>
                  {moveGroupName}
                </Typography>
              )}
              {openRuleAdjustmentList && (
                <Typography variant="label" paragraph>
                  The following adjustments to rules will be made: Click
                  Continue to proceed.
                </Typography>
              )}
            </Grid>
          </Grid>

          <Divider
            color={theme.palette.blue.main}
            sx={{ marginBottom: "20px" }}
          />
          {!openRuleAdjustmentList && (
            <Grid container spacing={2}>
              <Grid item xs={5} xl={4} sx={{ textAlign: "center" }}>
                <Typography variant="body1">
                  Please select the page to which to move this group
                </Typography>
              </Grid>
              <Grid item xs={7}>
                {sections && (
                  <FormControl fullWidth>
                    <Select
                      variant="outlined"
                      name="orgTypeId"
                      size="small"
                      onChange={handleChange}
                      value={selectedIndex}
                    >
                      {sections.map((option, index) => {
                        if (option.isParent)
                          return (
                            <ListSubheader
                              key={index}
                              sx={{ textDecoration: "underline" }}
                            >
                              Section {option.name}
                            </ListSubheader>
                          );
                        else
                          return (
                            <MenuItem
                              key={option.id}
                              sx={{ fontSize: "15px" }}
                              value={option.id}
                            >
                              Page{"-"}
                              {option.index + 1}
                              {" : "} {option.name}
                            </MenuItem>
                          );
                      })}
                    </Select>
                  </FormControl>
                )}
                {errorMsg !== "" && (
                  <div style={{ color: theme.palette.error.main }}>
                    {errorMsg}
                  </div>
                )}
              </Grid>
            </Grid>
          )}
          {ruleAdjustmentData?.length > 0 && (
            <Grid container>
              <Grid item xs={12}>
                {errorMsg !== "" && (
                  <div style={{ color: theme.palette.error.main }}>
                    {errorMsg}
                  </div>
                )}
                <AdjustmentsList ruleAdjustmentData={ruleAdjustmentData} />
              </Grid>
            </Grid>
          )}
          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              {!openRuleAdjustmentList && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => handleCloseMoveGrpModal()}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handlePreviewRuleLevelAdjustments()}
                  >
                    Save
                  </Button>
                </>
              )}
              {openRuleAdjustmentList && (
                <>
                  <Button
                    variant="outlined"
                    onClick={() => handleCloseRuleAdjustmentList()}
                  >
                    Cancel
                  </Button>{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleMoveGroup()}
                  >
                    Continue
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default QuickMoveGroup;
