import React from "react";
import BatchUpload from "./BatchUpload";
import BatchHome from "./BatchHome";
import BatchProgress from "./BatchProgress";
import PastBatchResults from "./PastBatchResults";
import BatchSearch from "./BatchSearch";
import BatchSummary from "./BatchSummary";
import ImportProgress from "./ImportProgress";
import UploadBatchProgress from "./UploadBatchProgress";
import { useSelector } from "react-redux";

const renderView = (currentView) => {
  switch (currentView) {
    case 1:
      return <BatchHome />;
    case 2:
      return <BatchUpload />;
    case 3:
      return <BatchProgress />;
    case 4:
      return <PastBatchResults />;
    case 5:
      return <BatchSearch />;
    case 6:
      return <BatchSummary />;
    case 7:
      return <ImportProgress />;
    case 8:
      return <UploadBatchProgress />;
    default:
      return null;
  }
};
const Import = () => {
  const currentView = useSelector((state) => state?.survey?.importCurrentView);

  return <>{renderView(currentView)}</>;
};

export default Import;
