import React, { useEffect, useState } from "react";
import RenderRadioGrp from "../../../../components/QuestionTypes/RenderRadioGrp";
import RenderCheckboxGrp from "../../../../components/QuestionTypes/RenderCheckboxGrp";
import RenderCommonGrp from "../../../../components/QuestionTypes/RenderCommonGrp";
import RenderQuestion from "../../../../components/QuestionTypes/RenderQuestion";
import {
  Card,
  CardContent,
  Grid,
  StyledEngineProvider,
  Typography,
} from "@mui/material";
import { htmlParser } from "../../../../Utility/constants";
import HelpTextPopper from "../../../../components/Common/Popper";
import { fullQuestionGroupById } from "../../../Survey/Survey.service";
import { useDispatch, useSelector } from "react-redux";

const QuesPreview = ({ secondViewButtons }) => {
  const dispatch = useDispatch();
  const [quesGrp, setQuesGrp] = useState(null);

  const savedField = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedField
  );
  const savedComField = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedComField
  );
  const savedQuesGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.savedQuesGrp
  );

  const triggerMatrixGrp = useSelector(
    (state) => state?.survey?.surveyBuilderData.triggerMatrixGrp
  );
  useEffect(() => {
    if (savedQuesGrp) {
      const fetchQuesGroup = async () => {
        try {
          const response = await fullQuestionGroupById(savedQuesGrp?.id);
          const resData = response?.data;
          if (resData && Object.keys(resData).length > 0 && resData.id) {
            setQuesGrp(resData);
          }
        } catch (error) {
          console.error("Error fetching quesGrp:", error);
        }
      };
      if (
        savedField != null ||
        Object.keys(savedComField).length > 0 ||
        triggerMatrixGrp
      ) {
        fetchQuesGroup();
      }
    }
  }, [savedField, savedComField, triggerMatrixGrp]);

  return (
    <>
      <Card sx={{ mt: 1 }}>
        <CardContent>
          <Typography variant="label" sx={{ fontWeight: "bold" }}>
            QUESTION PREVIEW
          </Typography>
        </CardContent>
        <form>
          {quesGrp && (
            <>
              {quesGrp.groupType.name === "radiogrp" && (
                <RenderRadioGrp questionsGrp={quesGrp} disableSave={true} />
              )}
              {quesGrp.groupType.name === "checkboxgrp" && (
                <RenderCheckboxGrp questionsGrp={quesGrp} disableSave={true} />
              )}
              {quesGrp.groupType.name === "commongrp" && (
                <RenderCommonGrp questionsGrp={quesGrp} disableSave={true} />
              )}
              {quesGrp.groupType.name !== "radiogrp" &&
                quesGrp.groupType.name !== "checkboxgrp" &&
                quesGrp.groupType.name !== "commongrp" &&
                quesGrp.questions &&
                quesGrp.questions.length > 0 &&
                quesGrp.questions.length === 1 &&
                quesGrp.questions.map((question) => {
                  return (
                    <RenderQuestion
                      question={question}
                      multiple={false}
                      disableSave={true}
                    />
                  );
                })}
              {quesGrp.groupType.name !== "radiogrp" &&
                quesGrp.groupType.name !== "checkboxgrp" &&
                quesGrp.groupType.name !== "commongrp" &&
                quesGrp.questions?.length > 1 && (
                  <>
                    <StyledEngineProvider injectFirst>
                      <Card className="card">
                        <CardContent className="card-header">
                          <Grid container direction={"column"}>
                            <Grid
                              item
                              alignSelf="start"
                              className="question-container"
                            >
                              <Typography variant="question">
                                {htmlParser(
                                  quesGrp.descriptionPosition !== null ||
                                    quesGrp.descriptionPosition !== "No Title"
                                    ? (quesGrp.label || "") +
                                        " " +
                                        (quesGrp.name || "")
                                    : (quesGrp.label || "") +
                                        " " +
                                        (quesGrp.description || "")
                                )}
                                {quesGrp?.helpText && (
                                  <HelpTextPopper
                                    question={quesGrp}
                                  ></HelpTextPopper>
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <div>
                            <Typography variant="h6">
                              {htmlParser(
                                quesGrp.descriptionPosition !== null ||
                                  quesGrp.descriptionPosition !== "No Title"
                                  ? quesGrp.description || ""
                                  : ""
                              )}
                            </Typography>
                          </div>
                        </CardContent>
                        <CardContent
                          className="card-content"
                          sx={{ backgroundColor: "#fff !important" }}
                        >
                          {quesGrp.questions.map((question) => {
                            return (
                              <RenderQuestion
                                question={question}
                                questionsInPage={quesGrp}
                                multiple={true}
                                disableSave={true}
                              />
                            );
                          })}
                        </CardContent>
                      </Card>
                    </StyledEngineProvider>
                  </>
                )}
            </>
          )}
        </form>
      </Card>
      <Grid
        item
        container
        xs={12}
        justifyContent="flex-end"
        sx={{ marginTop: "15px" }}
      >
        {secondViewButtons}
      </Grid>
    </>
  );
};

export default QuesPreview;
