import React from "react";
import PageRoutes from "../../../Routes/Routes";
import Footer from "../../Footer/Footer";
import Navbar from "../../Navbar/Navbar";
import Sidebar from "../../Sidebar/Sidebar";
import "../Layout.scss";

const AppLayout = () => {
  return (
    <div className="app-layout">
      <Navbar />
      <div className="main-layout">
        <Sidebar open={true} />
        <main style={{ width: "100%", overflow: "auto" }}>
          <PageRoutes />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default AppLayout;
