import * as apiService from "../../Utility/api.service";
import { BASE_URL } from "../../Utility/constants";

// authorization
export const getIsAPILoggedIn = async (payload) =>
  apiService.getData(`${BASE_URL}/authorization/isBuilderLoggedIn`);

// builder
export const getLoginData = async (payload) =>
  apiService.getData(`${BASE_URL}/builder/ssoBuilderLogin`);

export const getStates = async () =>
  apiService.getData(`${BASE_URL}/builder/states`);

export const parentSurveyList = async () =>
  apiService.getData(`${BASE_URL}/builder/parentSurveyList`);

export const getPersonDisplaySettings = async (payload) =>
  apiService.getData(
    `${BASE_URL}/builder/personDisplaySettings/${payload.personId}/${payload.page}`
  );

export const setPersonDisplaySettings = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/personDisplaySettings`, payload);

export const getSurveyByOrg = async (orgId) =>
  apiService.getData(`${BASE_URL}/builder/surveysByOrg/${orgId}`);

export const getSurveyByPersonId = async (personId) =>
  apiService.getData(`${BASE_URL}/builder/surveysByPersonId/${personId}`);

export const getOtherSurveysByOrgIdPersonId = async (payload) =>
  apiService.getData(
    `${BASE_URL}/builder/otherSurveysByOrganizationIdPersonId/${payload.orgId}/${payload.personId}`
  );

export const getSurveyFilingCount = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/surveyFilingCount/${surveyId}`);

export const responseReportRecords = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/responseReportRecords`, payload);

export const responseReportPDF = async (payload) =>
  apiService.postPdfData(`${BASE_URL}/builder/responseReportPDF`, payload);

export const copyBuilderSurvey = async (payload) =>
  apiService.getData(
    `${BASE_URL}/builder/copySurvey/${payload.surveyId}/${payload.organizationId}/${payload.personId}`
  );

export const deleteSelectedSurvey = async (surveyId) =>
  apiService.postData(`${BASE_URL}/builder/deleteSurvey/${surveyId}`, {});

export const deleteSurveyData = async (surveyId) =>
  apiService.postData(`${BASE_URL}/builder/deleteSurveyData/${surveyId}`, {});

export const inactiveSurveysByOrg = async (orgId) =>
  apiService.getData(`${BASE_URL}/builder/inactiveSurveysByOrg/${orgId}`);

export const createSurvey = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createSurvey`, payload);

export const updateSurvey = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateSurvey`, payload);

export const createPage = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createPage`, payload);

export const updatePage = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updatePage`, payload);

export const createSection = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createSection`, payload);

export const updateSection = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateSection`, payload);

export const getFieldsForSelectList = async (surveyId) =>
  apiService.getData(
    `${BASE_URL}/builder/survey/fieldsForSelectList/${surveyId}`
  );

export const getQuesGroupsForSelectList = async (surveyId) =>
  apiService.getData(
    `${BASE_URL}/builder/survey/questionGroupsForSelectList/${surveyId}`
  );

export const updateGroupShortName = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateGroupShortName`, payload);

export const updateSectionOrder = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateSectionOrder`, payload);

export const updatePageOrder = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updatePageOrder`, payload);

export const updateQuestionGroupOrder = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateQuestionGroupOrder`, payload);

export const updateQuestionOrder = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateQuestionOrder`, payload);

export const deleteSection = async (secId) =>
  apiService.postData(`${BASE_URL}/builder/deleteSection/${secId}`);

export const deletePage = async (pageId) =>
  apiService.postData(`${BASE_URL}/builder/deletePage/${pageId}`);

export const rulesBySurvey = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/rulesBySurvey`, payload);

export const updateRuleOrder = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateRuleOrder`, payload);

export const deleteRule = async (ruleId) =>
  apiService.postData(`${BASE_URL}/builder/deleteRule/${ruleId}`);

export const rulesBySection = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/rulesBySection`, payload);

export const rulesByPage = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/rulesByPage`, payload);

export const getRule = async (ruleId) =>
  apiService.getData(`${BASE_URL}/builder/rule/${ruleId}`);

export const deleteQuestionGroup = async (groupId) =>
  apiService.postData(`${BASE_URL}/builder/deleteQuestionGroup/${groupId}`);

export const deleteQuestion = async (questionId) =>
  apiService.postData(`${BASE_URL}/builder/deleteQuestion/${questionId}`);

export const getDataFilters = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/dataFilters/${surveyId}`);

export const saveRule = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/saveRule`, payload);

export const ruleFieldLabels = async (ruleId) =>
  apiService.getData(`${BASE_URL}/builder/ruleFieldLabels/${ruleId}`);

export const nextComplexRule = async (ruleId) =>
  apiService.getData(`${BASE_URL}/builder/nextComplexRule/${ruleId}`);

export const updateFieldUserLabel = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateFieldUserLabel`, payload);

export const ruleModeExceptions = async (ruleId) =>
  apiService.getData(`${BASE_URL}/builder/ruleModeExceptions/${ruleId}`);

export const ruleEditorSurveys = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/ruleEditorSurveys/${surveyId}`);

export const deactivateSurvey = async (surveyId) =>
  apiService.postData(`${BASE_URL}/builder/deactivateSurvey/${surveyId}`);

export const activateSurvey = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/activateSurvey`, payload);

export const createStandardQuestionGroup = async (payload) =>
  apiService.postData(
    `${BASE_URL}/builder/createStandardQuestionGroup`,
    payload
  );

export const updateQuestion = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateQuestion`, payload);

export const updateHeading = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateHeading`, payload);

export const createHeading = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createHeading`, payload);

export const deleteHeading = async (headingId) =>
  apiService.postData(`${BASE_URL}/builder/deleteHeading/${headingId}`);

export const updateQuestionGroup = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateQuestionGroup`, payload);

export const createMatrixQuestionGroup = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createMatrixQuestionGroup`, payload);

export const createField = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createField`, payload);

export const updateField = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateField`, payload);

export const deleteField = async (fieldId) =>
  apiService.postData(`${BASE_URL}/builder/deleteField/${fieldId}`);

export const deleteFieldValue = async (fieldVal) =>
  apiService.postData(`${BASE_URL}/builder/deleteFieldValue/${fieldVal}`);

export const userListByOrgId = async (organizationId, personId) =>
  apiService.getData(
    `${BASE_URL}/builder/persons/${organizationId}/${personId}`
  );

export const moveQuestionGroup = async (groupId, pageId) =>
  apiService.postData(
    `${BASE_URL}/builder/moveQuestionGroup/${groupId}/${pageId}`
  );

export const previewRuleLevelAdjustments = async (groupId, pageId) =>
  apiService.postData(
    `${BASE_URL}/builder/previewRuleLevelAdjustments/${groupId}/${pageId}`
  );
export const deleteDataFilter = async (dataFilterId) =>
  apiService.postData(`${BASE_URL}/builder/deleteDataFilter/${dataFilterId}`);

export const createDataFilter = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createDataFilter`, payload);

export const updateDataFilter = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateDataFilter`, payload);

export const fieldUserLabels = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/survey/fieldUserLabels/${surveyId}`);

export const dataFilterFields = async (dataFilterId) =>
  apiService.getData(`${BASE_URL}/builder/dataFilterFields/${dataFilterId}`);

export const saveDataFilterFields = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/saveDataFilterFields`, payload);

export const saveDataFilterRule = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/saveDataFilterRule`, payload);

export const importFieldValues = async (file, fieldId, replaceExistingValues) =>
  apiService.postFormData(
    `${BASE_URL}/builder/importFieldValues/${fieldId}/${replaceExistingValues}`,
    file
  );
export const createQuestion = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createQuestion`, payload);

export const createStandardMatrixField = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/createStandardMatrixField`, payload);

export const fullQuestionGroupById = async (groupId) =>
  apiService.getData(`${BASE_URL}/builder/fullQuestionGroupById/${groupId}`);

export const fullCommonGroupByIdForBuilder = async (groupId) =>
  apiService.getData(
    `${BASE_URL}/builder/fullCommonGroupByIdForBuilder/${groupId}`
  );

export const fullQuestionById = async (questionId) =>
  apiService.getData(`${BASE_URL}/builder/fullQuestionById/${questionId}`);

export const updateFieldOrder = async (payload) =>
  apiService.postData(`${BASE_URL}/builder/updateFieldOrder`, payload);

export const commonGroupFieldLabels = async (questionGroupId) =>
  apiService.getData(
    `${BASE_URL}/builder/commonGroupFieldLabels/${questionGroupId}`
  );

export const updateCommonGroupFieldLabels = async (payload) =>
  apiService.postData(
    `${BASE_URL}/builder/updateCommonGroupFieldLabels`,
    payload
  );

export const uploadSurveyLogo = async (surveyId, file) =>
  apiService.postFormData(
    `${BASE_URL}/builder/uploadSurveyLogo/${surveyId}`,
    file
  );

export const surveyLogoFilename = async (surveyId) =>
  apiService.getData(`${BASE_URL}/builder/surveyLogoFilename/${surveyId}`);

export const copyQuestionGroup = async (groupId, pageId, personId) =>
  apiService.postData(
    `${BASE_URL}/builder/copyQuestionGroup/${groupId}/${pageId}/${personId}`
  );

//panel
export const orgAndChildOrgsByParentOrgId = async (organizationId) =>
  apiService.getData(
    `${BASE_URL}/panel/orgAndChildOrgsByParentOrgId/${organizationId}`
  );
export const createPersonRoleList = async (personId) =>
  apiService.getData(`${BASE_URL}/panel/createPersonRoleList/${personId}`);

export const createOrganizationOrgTypeList = async () =>
  apiService.getData(`${BASE_URL}/panel/createOrganizationOrgTypeList`);

export const updatePerson = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/updatePerson`, payload);

export const createPerson = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/createPerson`, payload);

export const deletePerson = async (personId) =>
  apiService.postData(`${BASE_URL}/panel/deletePerson/${personId}`);

export const updateOrganization = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/updateOrganization`, payload);

export const createOrganization = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/createOrganization`, payload);

export const deleteOrganization = async (organizationId) =>
  apiService.postData(`${BASE_URL}/panel/deleteOrganization/${organizationId}`);

export const findActivePanelsByOrgId = async (organizationId) =>
  apiService.getData(`${BASE_URL}/panel/findActivePanels/${organizationId}`);

export const organizationsByParentOrganizationId = async (organizationId) =>
  apiService.getData(
    `${BASE_URL}/panel/organizationsByParentOrganizationId/${organizationId}`
  );

export const getPanelMembers = async (payload) =>
  apiService.postData(
    `${BASE_URL}/panel/panelMemberAssignmentsByPage`,
    payload
  );

export const getRegions = async () =>
  apiService.getData(`${BASE_URL}/panel/regions`);

export const getSurveyNames = async (surveyPanelId) =>
  apiService.getData(`${BASE_URL}/panel/surveyNamesByPanelId/${surveyPanelId}`);

export const getSurveyFilingStatuses = async () =>
  apiService.getData(`${BASE_URL}/panel/surveyFilingStatuses`);

export const savePanelMemberAssignment = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/panelMemberAssignment`, payload);

export const updateSurveyRespondent = async (payload) =>
  apiService.putData(`${BASE_URL}/panel/surveyRespondent`, payload);

export const updateSurveyOrganization = async (payload) =>
  apiService.putData(`${BASE_URL}/panel/surveyOrganization`, payload);

export const deletePanelMember = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/deletePanelMemberAssignment`, payload);

export const getRespondentByUsername = async () =>
  apiService.getData(`${BASE_URL}/panel/respondentByUsername/preview`);

export const getSurveyFiling = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/lookupFiling`, payload);

export const deleteSurveyHelpers = async (selectedPanelId) =>
  apiService.postData(
    `${BASE_URL}/panel/deleteSurveyHelpers/${selectedPanelId}`,
    {}
  );

export const findSimplePanelMemberAssignmentsByBatchId = async (payload) =>
  apiService.postData(
    `${BASE_URL}/panel/findSimplePanelMemberAssignmentsByBatchId`,
    payload
  );

export const updateSubpanels = async (surveyPanelId) =>
  apiService.postData(`${BASE_URL}/panel/updateSubpanels/${surveyPanelId}`, {});

export const getSimplePanelMemberAssignments = async (payload) =>
  apiService.postData(
    `${BASE_URL}/panel/simplePanelMemberAssignments`,
    payload
  );

export const validateCustomIds = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/validateCustomIds`, payload);

export const getSurveyFilingIdsByCustomIds = async (payload) =>
  apiService.postData(
    `${BASE_URL}/panel/findSurveyFilingIdsByCustomIds`,
    payload
  );

export const getPanelMemberAssignment = async (surveyRespondentId, surveyId) =>
  apiService.getData(
    `${BASE_URL}/panel/panelMemberAssignment/${surveyRespondentId}/${surveyId}`
  );

export const createPanel = async (payload) =>
  apiService.postData(`${BASE_URL}/panel/createPanel`, payload);

// survey-processing
export const surveyAnswers = async (payload) =>
  apiService.postData(`${BASE_URL}/survey-processing/surveyAnswers`, payload);

export const surveyPanel = async (payload) =>
  apiService.postData(`${BASE_URL}/survey-processing/surveyPanel`, payload);

// rule
export const runAllRulesForFilings = async (payload) =>
  apiService.postData(`${BASE_URL}/rule/runAllRulesForFilings`, payload);

export const surveyKeyFile = async (payload) =>
  apiService.postData(`${BASE_URL}/survey-processing/surveyKeyFile`, payload);

export const validateUploadFile = async (file, surveyId) =>
  apiService.postFormData(
    `${BASE_URL}/survey-processing/validateUploadFile/${surveyId}`,
    file
  );
export const surveyComments = async (payload) =>
  apiService.postData(`${BASE_URL}/survey-processing/surveyComments`, payload);

export const uploadFilingData = async (
  file,
  surveyId,
  personId,
  uploadMode,
  executeValidations
) =>
  apiService.postFormData(
    `${BASE_URL}/survey-processing/uploadFilingData/${surveyId}/${personId}/${uploadMode}/${executeValidations}`,
    file
  );

export const inProgressBatches = async (surveyId) =>
  apiService.getData(
    `${BASE_URL}/survey-processing/inProgressBatches/${surveyId}`
  );

export const deleteFilingsInBatch = async (batchId) =>
  apiService.postData(
    `${BASE_URL}/survey-processing/deleteFilingsInBatch/${batchId}`
  );

export const completedBatches = async (surveyId) =>
  apiService.getData(
    `${BASE_URL}/survey-processing/completedBatches/${surveyId}`
  );
export const batchExceptions = async (batchId) =>
  apiService.getData(
    `${BASE_URL}/survey-processing/batchExceptions/${batchId}`
  );
export const findBatchById = async (batchId) =>
  apiService.getData(`${BASE_URL}/survey-processing/findBatchById/${batchId}`);

// taker
export const forceUpdateSurveyFilingStatus = async (payload) =>
  apiService.postData(
    `${BASE_URL}/taker/forceUpdateSurveyFilingStatus`,
    payload
  );
export const showStatusPercentages = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/statusPercentages`, payload);

export const getSurveyFilingStatusByCustomId = async (payload) =>
  apiService.getData(
    `${BASE_URL}/taker/surveyFilingStatusByCustomId/${payload.surveyId}/${payload.customId}`
  );

export const createSurveyFiling = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/createSurveyFiling`, payload);

export const deleteSurveyFiling = async (surveyFilingId) =>
  apiService.postData(`${BASE_URL}/taker/deleteSurveyFiling/${surveyFilingId}`);

export const updateSurveyFilingStatus = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/updateSurveyFilingStatus`, payload);

export const getSurveyFilingBySurveyFilingId = async (surveyFilingId) =>
  apiService.getData(`${BASE_URL}/taker/surveyFiling/${surveyFilingId}`);

export const displayBatchUpdatePage = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/displayBatchUpdatePage`, payload);

export const saveAnswers = async (payload) =>
  apiService.postData(`${BASE_URL}/taker/save`, payload);

export const getPopulatedSurvey = async (surveyId) =>
  apiService.getData(`${BASE_URL}/taker/populatedSurveyForBuilder/${surveyId}`);

export const getCommentTypes = async () =>
  apiService.getData(`${BASE_URL}/taker/commentTypes`);
