import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSimplePanelMemberAssignments,
  runAllRulesForFilings,
} from "../Survey/Survey.service";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Tooltip from "../../components/Common/Tooltip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import theme from "../../theme";
import {
  setBatchUpdateCurrentView,
  setSelectedSurveyFilingIds,
  setUpdatedSurveyFilingIds,
  setValidationData,
} from "../Survey/Survey.slice";

const RunBatchUpdateVals = () => {
  const dispatch = useDispatch();
  const [filteredPanelList, setFilteredPanelList] = useState([]);
  const [invalidCustomId, setInvalidCustomId] = useState([]);
  const [selectedCustomIds, setSelectedCustomIds] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const surveyId = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyId
  );
  const updatedSurveyFilingIds = useSelector(
    (state) => state?.survey?.batchUpdateData?.updatedSurveyFilingIds
  );
  const validationDatas = useSelector(
    (state) => state?.survey?.batchUpdateData?.validationData
  );
  const surveyName = useSelector(
    (state) => state?.survey?.homeSurveyData?.selectedSurveyName
  );

  const updateFilingStatus = useSelector(
    (state) => state?.survey?.batchUpdateData?.updateFilingStatus
  );

  const handleBackToQuesPage = () => {
    console.log(updatedSurveyFilingIds);

    if (updatedSurveyFilingIds.length > 0) {
      const lastUpdatedSurveyFilingId =
        updatedSurveyFilingIds[updatedSurveyFilingIds.length - 1];
      dispatch(setSelectedSurveyFilingIds([lastUpdatedSurveyFilingId]));

      const newUpdatedSurveyFilingIds = updatedSurveyFilingIds.slice(0, -1);
      dispatch(setUpdatedSurveyFilingIds(newUpdatedSurveyFilingIds));

      dispatch(setBatchUpdateCurrentView(3));
    }
  };

  const handleBackToValPage = () => {
    dispatch(setBatchUpdateCurrentView(3));
  };

  useEffect(() => {
    const customId = updatedSurveyFilingIds.map((filId) => filId?.customId);
    setSelectedCustomIds(customId);
  }, [updatedSurveyFilingIds]);

  useEffect(() => {
    const fetchAssignments = async () => {
      try {
        if (selectedCustomIds?.length > 0) {
          const response = await getSimplePanelMemberAssignments({
            surveyId: surveyId,
            filterCriteria: {
              custom_id: selectedCustomIds.join(","),
            },
          });
          setFilteredPanelList(response.data);
        }
      } catch (error) {
        console.error("Error fetching assignments:", error);
      }
    };

    fetchAssignments();
  }, [selectedCustomIds]);

  useEffect(() => {
    const getCustomId = () => {
      const invalidCustomIds = [];
      for (const updatedSurveyFilingId of updatedSurveyFilingIds) {
        for (const validationData of validationDatas) {
          if (updatedSurveyFilingId.surveyFilingId === validationData.id) {
            invalidCustomIds.push(updatedSurveyFilingId.customId);
          }
        }
      }
      return invalidCustomIds;
    };
    setInvalidCustomId(getCustomId());
  }, [validationDatas, updatedSurveyFilingIds]);

  const handleValidationClick = async () => {
    setShowTable(true);
    setLoading(true);
    try {
      const response = await await runAllRulesForFilings({
        surveyId: surveyId,
        panelMemberAssignments: filteredPanelList,
        updateStatuses: updateFilingStatus === "yes" ? false : true,
      });
      dispatch(setValidationData(response.data));
    } catch (error) {
      console.error("Error fetching validation data:", error);
    } finally {
      setLoading(false);
    }
  };

  const openTaker = async (page, surveyFilingId) => {
    let baseUrl;
    if (page === "opde" && orgId) {
      baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/${page}/${surveyFilingId}/${orgId}`;
    } else
      baseUrl = `${process.env.REACT_APP_REACT_TAKER_URL_REDIRECT}/${page}/${surveyFilingId}`;

    if (updateFilingStatus) {
      baseUrl += `?updateStatuses=${
        updateFilingStatus === "yes" ? false : true
      }`;
    }
    window.open(baseUrl, "_blank");
  };

  const data = useMemo(() => {
    return filteredPanelList?.map((member) => {
      return {
        sr_custom_id: member?.id,
        name: member?.surveyRespondent?.name,
        custom_id: member?.surveyRespondent?.surveyOrganization?.customId,
        orgName: member?.surveyRespondent?.surveyOrganization?.name,
        status: member?.surveyFilingStatus,
        surveyFilingId: member?.surveyFilingId,
        srId: member?.surveyRespondent?.id,
      };
    });
  }, [filteredPanelList]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "sr_custom_id",
        header: "Id",
        size: 150,
        enableHiding: false,
      },
      {
        accessorKey: "name",
        header: "Name",
        size: 150,
      },
      {
        accessorKey: "custom_id",
        header: "Custom Id",
        size: 150,
      },
      {
        accessorKey: "orgName",
        header: "Org. Name",
        size: 150,
      },
      {
        accessorKey: "status",
        header: "Status",
        size: 150,
      },
      {
        accessorKey: "view",
        header: "View",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="View data" arrow>
            <DescriptionOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 40,
                height: 40,
                color: theme.palette.yellow.main,
                background: theme.palette.yellow.light,
                borderRadius: "10px",
                padding: "10px",
                marginRight: "10px",
                boxSizing: "border-box",
              }}
              onClick={() => openTaker("view", row?.original?.surveyFilingId)}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "edit",
        header: "Edit",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Edit data" arrow>
            <EditOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 40,
                height: 40,
                color: theme.palette.green.main,
                background: theme.palette.green.light,
                borderRadius: "10px",
                padding: "10px",
                boxSizing: "border-box",
              }}
              onClick={() => openTaker("edit", row?.original?.surveyFilingId)}
            />
          </Tooltip>
        ),
      },
      {
        accessorKey: "opde",
        header: "Opde",
        size: 50,
        Cell: ({ row }) => (
          <Tooltip title="Opde" arrow>
            <NoteAltOutlinedIcon
              sx={{
                cursor: "pointer",
                width: 40,
                height: 40,
                color: theme.palette.orange.main,
                background: theme.palette.orange.light,
                borderRadius: "10px",
                padding: "10px",
                marginRight: "10px",
                boxSizing: "border-box",
              }}
              onClick={() => openTaker("opde", row?.original?.surveyFilingId)}
            />
          </Tooltip>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    initialState: { density: "compact" },
    enableColumnFilters: false,
    enableFullScreenToggle: false,
    enableDensityToggle: false,
  });

  return (
    <>
      {!showTable ? (
        <>
          <Typography color="primary" variant="label">
            Batch Update complete for:
          </Typography>
          <div>
            {updatedSurveyFilingIds.map((item, index) => (
              <Typography key={index} variant="body1">
                {item.customId}
              </Typography>
            ))}
          </div>
          <Typography
            variant="body1"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleValidationClick}
          >
            Click to run all validations for this batch
          </Typography>
          <Button
            variant="outlined"
            sx={{ marginTop: "15px" }}
            onClick={handleBackToQuesPage}
          >
            Back
          </Button>
        </>
      ) : loading ? (
        <>
          <Box
            display="flex"
            sx={{ height: "100vh", width: "100%" }}
            bgcolor="#d9e0ed"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <div style={{ marginBottom: "15px" }}>
            <Typography
              variant="label"
              color="primary"
              sx={{ marginTop: "20px", marginBottom: "15px" }}
            >
              Batch Update Page
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: "20px" }}>
              Functions: view, edit survey filings updated through batch update
            </Typography>
            {invalidCustomId.length > 0 && (
              <div
                style={{
                  marginBottom: "15px",
                }}
              >
                <Typography
                  color="primary"
                  variant="label"
                  style={{
                    marginBottom: "15px",
                  }}
                >
                  The following filings have errors:
                </Typography>

                {invalidCustomId.map((customId, index) => (
                  <Typography key={index} variant="body1">
                    {customId}
                  </Typography>
                ))}
              </div>
            )}
            <Typography variant="label" color="primary">
              {`SURVEY: ${surveyName}`}
            </Typography>
          </div>
          <MaterialReactTable table={table} />
          <Button
            variant="outlined"
            sx={{ marginTop: "15px" }}
            onClick={handleBackToValPage}
          >
            Back
          </Button>
        </>
      )}
    </>
  );
};

export default RunBatchUpdateVals;
