import React, { useEffect, useState } from "react";

import {
  Grid,
  Typography,
  Button,
  Select,
  MenuItem,
  ListSubheader,
  FormControl,
  Divider,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getTextFromHTML } from "../../../Utility/constants";
import theme from "../../../theme";
import { copyQuestionGroup } from "../../Survey/Survey.service";

const QuickCopyGroup = ({
  handleCloseCopyGrpModal,
  handleRefreshCopyGrpModal,
  copyGroupId,
  copyGroupName,
}) => {
  const [sections, setSections] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );

  const handleChange = (e) => {
    const { value } = e.target;
    setSelectedIndex(value);
  };
  const selectedSurveySections = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData.sections
  );
  useEffect(() => {
    const fetchSections = () => {
      let sectionData = [];
      selectedSurveySections.forEach((item, index) => {
        const sectionGrp = {
          id: item?.section?.id,
          name: getTextFromHTML(item?.section?.name),
          isParent: true,
          index: index,
        };
        // add section row
        sectionData.push(sectionGrp);

        const currentPages = item?.pages;
        currentPages.forEach((item, subindex) => {
          const pageGrp = {
            id: item?.page?.id,
            name: getTextFromHTML(item?.page?.name),
            isParent: false,
            index: subindex,
          };
          // add page row
          sectionData.push(pageGrp);
        });
      });
      setSections(sectionData);

      //set first page{id} default selected
      const firstPage = sectionData[1].id;
      setSelectedIndex(firstPage);
    };
    if (selectedSurveySections) {
      fetchSections();
    }
  }, [selectedSurveySections]);

  const handleCopyGroup = async (e) => {
    setErrorMsg("");
    try {
      const response = await copyQuestionGroup(
        copyGroupId,
        selectedIndex,
        personId
      );
      if (response?.data && !response?.data?.detail) {
        handleRefreshCopyGrpModal();
      } else if (response?.data?.status === 400) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error moving question group", error);
    }
  };

  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Copy Group</Typography>
        </div>
        <div className="panelForm">
          <Grid container spacing={2}>
            <Grid item xs={11} sx={{ textAlign: "center" }}>
              <Typography variant="label" paragraph>
                {copyGroupName}
              </Typography>
            </Grid>
          </Grid>

          <Divider
            color={theme.palette.blue.main}
            sx={{ marginBottom: "20px" }}
          />
          <Grid container spacing={2}>
            <Grid item xs={5} xl={4} sx={{ textAlign: "center" }}>
              <Typography variant="body1">
                Please select the page to which to copy this group
              </Typography>
            </Grid>
            <Grid item xs={7}>
              {sections && (
                <FormControl fullWidth>
                  <Select
                    variant="outlined"
                    name="orgTypeId"
                    size="small"
                    onChange={handleChange}
                    value={selectedIndex}
                  >
                    {sections.map((option, index) => {
                      if (option.isParent)
                        return (
                          <ListSubheader
                            key={index}
                            sx={{ textDecoration: "underline" }}
                          >
                            Section {option.name}
                          </ListSubheader>
                        );
                      else
                        return (
                          <MenuItem
                            key={option.id}
                            sx={{ fontSize: "15px" }}
                            value={option.id}
                          >
                            Page{"-"}
                            {option.index + 1}
                            {" : "} {option.name}
                          </MenuItem>
                        );
                    })}
                  </Select>
                </FormControl>
              )}
              {errorMsg !== "" && (
                <div style={{ color: theme.palette.error.main }}>
                  {errorMsg}
                </div>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={5}></Grid>
            <Grid item xs={5}>
              <>
                <Button
                  variant="outlined"
                  onClick={() => handleCloseCopyGrpModal()}
                >
                  Cancel
                </Button>{" "}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleCopyGroup()}
                >
                  Save
                </Button>
              </>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

export default QuickCopyGroup;
