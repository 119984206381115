import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Modal,
  Stack,
  Chip,
  Grid,
  Divider,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  userListByOrgId,
  deletePerson,
  createPersonRoleList,
} from "../Survey/Survey.service";
import { setSelectedOrgs } from "../Survey/Survey.slice";
import { formatDate } from "../../Utility/constants";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "../../components/Common/Tooltip";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import AddEditUser from "./AddEditUser";
import { USER_ROLES } from "../../Utility/constants";

const Admin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedOrgs = useSelector((state) => state?.survey?.selectedOrgs);
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );

  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );
  const isSuperUser =
    roleName === USER_ROLES.BUILDER_SUPER_USER_ROLENAME ? true : false;

  const isBuilderAdminUser =
    roleName === USER_ROLES.BUILDER_ADMIN_ROLENAME ? true : false;

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmUserId, setConfirmUserId] = useState(null);

  const [showLoading, setShowLoading] = useState(true);
  const [personList, setPersonList] = useState([]);
  const [noRecords, setNoRecords] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [selUserData, setSelUserData] = useState({});
  const [userRoleList, setUserRoleList] = useState([]);

  useEffect(() => {
    const getRoleList = async () => {
      try {
        const response = await createPersonRoleList(personId);
        if (response.data) {
          setUserRoleList(response.data);
        }
      } catch (error) {
        console.log("Error getting person role list", error);
      }
    };
    if (personId) {
      getRoleList();
    }
  }, [personId]);
  useEffect(() => {
    if (selectedOrgs && selectedOrgs?.id) {
      setShowLoading(true);
      getOrgPersons();
    }
  }, [selectedOrgs]);

  const getOrgPersons = async () => {
    setNoRecords(null);
    setPersonList([]);
    try {
      const res = await userListByOrgId(selectedOrgs?.id, personId);
      if (Array.isArray(res?.data) && res?.data?.length > 0) {
        setPersonList(res.data);
        setShowLoading(false);
        setNoRecords(null);
      } else {
        setNoRecords("No users to display");
        setShowLoading(false);
      }
    } catch (error) {
      console.error("Error fetching person list:", error);
      setShowLoading(false);
    }
  };
  const openDeletePersonData = async (id, name) => {
    setConfirmTitle(`Deleting ${name} - Press OK to proceed.`);

    setConfirmUserId(id);
    setConfirmOpen(true);
  };
  const handlePersonDelete = async () => {
    try {
      const response = await deletePerson(confirmUserId);

      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("record deleted successfully");
        setAlertSeverity("success");
        setShowLoading(true);
        getOrgPersons();
        setConfirmTitle(null);
        confirmUserId(null);
        setConfirmOpen(false);
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting record");
        setAlertSeverity("error");
        setConfirmTitle(null);
        confirmUserId(null);
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error deleting person data.", error);
      return null;
    }
  };

  const data = useMemo(() => {
    return personList?.map((person) => {
      return {
        id: person?.id,
        name: person?.name,
        username: person?.username,
        role: person?.role?.name,
        dateCreated: person?.dateCreated
          ? formatDate(person?.dateCreated)
          : null,
        dateModified: person?.dateModified
          ? formatDate(person?.dateModified)
          : null,
        modifiedBy: person?.modifiedBy,
        createdBy: person?.createdBy,
      };
    });
  }, [personList]);

  const orgClicked = () => {
    dispatch(setSelectedOrgs({}));
    navigate("/organization");
  };

  const handleOpenUserModal = async () => {
    setSelUserData({});
    setOpenUserModal(true);
  };

  const handleCloseUserModal = () => {
    setOpenUserModal(false);
    setSelUserData({});
    setSelectedUserId(null);
  };
  const handleRefreshUserModal = () => {
    handleCloseUserModal();
    setShowLoading(true);
    getOrgPersons();
  };

  useEffect(() => {
    if (selectedUserId) {
      const result = personList?.find((item) => item.id === selectedUserId);
      setSelUserData(result);
      setOpenUserModal(true);
    }
  }, [selectedUserId]);

  return (
    <>
      <Grid container rowSpacing={1} sx={{ marginBottom: "15px" }}>
        <Grid item xs={12} container>
          <Grid item xs={8} xl={9}>
            <Typography color="primary" variant="h6">
              <strong> User Home Page :</strong> Functions: Create or Edit User
            </Typography>
            <Typography color="primary" variant="h6">
              <strong> Organization :</strong>{" "}
              {selectedOrgs && selectedOrgs?.name ? selectedOrgs?.name : ""}
            </Typography>
          </Grid>

          <Grid item xs={4} xl={3}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Chip
                label="Create new user"
                onClick={handleOpenUserModal}
                className="chipStyle"
              />
              {(isSuperUser || isBuilderAdminUser) && (
                <Chip
                  label="Organizations"
                  className="chipStyle"
                  onClick={() => {
                    orgClicked();
                  }}
                  variant="outlined"
                />
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        color={theme.palette.lightBlue.main}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Box>
        {showLoading && "Loading ..."}

        {noRecords && (
          <Typography color="error" variant="h6" paragraph>
            {noRecords}
          </Typography>
        )}
        {personList.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      color: theme.palette.blue.main,
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell>Created Date</TableCell>
                  <TableCell>Created By</TableCell>
                  <TableCell>Modified Date</TableCell>
                  <TableCell>Modified By</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row">
                      <span
                        className="blueLinkText"
                        onClick={() => setSelectedUserId(row.id)}
                      >
                        {row.name}
                      </span>
                    </TableCell>
                    <TableCell>{row.username}</TableCell>
                    <TableCell>{row.role}</TableCell>
                    <TableCell>{row.dateCreated}</TableCell>
                    <TableCell>{row.createdBy}</TableCell>
                    <TableCell>{row.dateModified}</TableCell>
                    <TableCell>{row.modifiedBy}</TableCell>
                    <TableCell>
                      <Tooltip title="Delete this user" arrow>
                        <DeleteOutlinedIcon
                          sx={{
                            cursor: "pointer",
                            width: 15,
                            height: 15,
                            color: theme.palette.blue.main,
                          }}
                          onClick={() => {
                            openDeletePersonData(row.id, row.name);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handlePersonDelete}
        pageName="Home"
      ></ConfirmDialog>
      <Modal open={openUserModal} onClose={handleCloseUserModal}>
        <AddEditUser
          handleCloseUser={handleCloseUserModal}
          userRoleList={userRoleList}
          selUserData={selUserData}
          handleRefreshUser={handleRefreshUserModal}
        />
      </Modal>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Admin;
