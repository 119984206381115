import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Divider,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import theme from "../../theme";
import { createPerson, updatePerson } from "../Survey/Survey.service";
import { CLEAN_FORM_DATA } from "../../Utility/constants";

export const initNewUserData = {
  id: "",
  firstName: "",
  lastName: "",
  username: "",
  middleInitial: "",
  title: "",
  roleId: "",
  address1: "",
  address2: "",
  city: "",
  county: "",
  stateId: "",
  zipCode: "",
  zipCodeExt: "",
  telephone: "",
};
const AddEditUser = ({
  handleCloseUser,
  selUserData,
  userRoleList,
  handleRefreshUser,
}) => {
  const [formData, setFormData] = useState(initNewUserData);

  const [errorMsg, setErrorMsg] = useState("");
  const [requiredErrorMsg, setRequiredErrorMsg] = useState("");
  const states = useSelector((state) => state?.survey?.states);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const createdBy = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const selectedOrgs = useSelector((state) => state?.survey?.selectedOrgs);
  const orgName = useSelector(
    (state) =>
      state?.survey?.loggedInUserData?.authorizedUser?.organization?.name
  );
  useEffect(() => {
    if (Object.keys(selUserData).length > 0) {
      const updatedFormData = {
        id: selUserData.id,
        firstName: selUserData.firstName || "",
        lastName: selUserData.lastName || "",
        username: selUserData.username || "",
        middleInitial: selUserData.middleInitial || "",
        title: selUserData.title || "",
        roleId: selUserData.role ? selUserData.role.id : null,
        address1: selUserData.address1 || "",
        address2: selUserData.address2 || "",
        city: selUserData.city || "",
        county: selUserData.county || "",
        stateId: selUserData.stateCode ? selUserData.stateCode.id : null,
        zipCode: selUserData.zipCode || "",
        zipCodeExt: selUserData.zipCodeExt || "",
        telephone: selUserData.telephone || "",
      };
      setFormData(updatedFormData);
    }
  }, [selUserData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSave = async (e) => {
    setErrorMsg("");
    setRequiredErrorMsg("");
    const firstName = formData["firstName"];
    const lastName = formData["lastName"];
    const username = formData["username"];
    const stateId = formData["stateId"];
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      username.trim() === "" ||
      stateId === "" ||
      stateId === null
    ) {
      setRequiredErrorMsg("Please enter required fields");
    } else {
      const isUpdateUserCall = Object.keys(selUserData).length > 0;
      if (isUpdateUserCall) {
        updateUserSave(selUserData.id);
      } else {
        createUserSave();
      }
    }
  };
  const updateUserSave = async (id) => {
    const cleanData = CLEAN_FORM_DATA(formData);
    const payload = {
      ...cleanData,
      modifiedBy: createdBy,
      organizationId: selectedOrgs?.id,
      id: id,
    };
    try {
      const response = await updatePerson(payload);
      if (response?.data?.id) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("User updated successfully.");
        setAlertSeverity("success");

        setTimeout(() => {
          handleRefreshUser();
        }, 2000);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving user", error);
    }
  };
  const createUserSave = async () => {
    const cleanData = CLEAN_FORM_DATA(formData);
    const payload = {
      ...cleanData,
      createdBy: createdBy,
      organizationId: selectedOrgs?.id,
    };
    try {
      const response = await createPerson(payload);
      if (response?.data?.id) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("User created successfully.");
        setAlertSeverity("success");
        setTimeout(() => {
          handleRefreshUser();
        }, 2000);
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setErrorMsg(response?.data?.detail);
      }
    } catch (error) {
      console.log("Error saving user", error);
    }
  };
  return (
    <>
      <div className="panelModal">
        <div className="panelDiv">
          <Typography variant="label">Create/Update User</Typography>
        </div>
        <div className="panelForm">
          <form
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <label>Please edit the user information</label>
            <Typography color="primary" style={{ fontWeight: "bold" }}>
              * First Name, Last Name, User Name, State, Role are required.
            </Typography>
            {requiredErrorMsg !== "" && (
              <div
                style={{ textAlign: "right", color: theme.palette.error.main }}
              >
                {requiredErrorMsg}
              </div>
            )}
            {errorMsg !== "" && (
              <div
                style={{ textAlign: "right", color: theme.palette.error.main }}
              >
                {errorMsg}
              </div>
            )}
            <Divider color={theme.palette.blue.main} />
            <Grid
              container
              rowSpacing={2}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              <Grid item xs={2}>
                <Typography variant="body1">Organization</Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1">{orgName}</Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography variant="body1">First Name *</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="firstName"
                  variant="outlined"
                  value={formData["firstName"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Last Name *</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="lastName"
                  variant="outlined"
                  value={formData["lastName"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">User Name *</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="username"
                  variant="outlined"
                  value={formData["username"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Middle Initial</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="middleInitial"
                  variant="outlined"
                  value={formData["middleInitial"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Title</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="title"
                  variant="outlined"
                  value={formData["title"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Role *</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl sx={{ width: "240px" }}>
                  <Select
                    size="small"
                    variant="outlined"
                    value={formData["roleId"]}
                    onChange={handleChange}
                    name="roleId"
                  >
                    {userRoleList.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Address 1</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="address1"
                  variant="outlined"
                  value={formData["address1"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Address 2</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="address2"
                  variant="outlined"
                  value={formData["address2"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">City</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="city"
                  variant="outlined"
                  value={formData["city"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">County</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="county"
                  variant="outlined"
                  value={formData["county"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">State *</Typography>
              </Grid>
              <Grid item xs={3}>
                <FormControl sx={{ width: "240px" }}>
                  <Select
                    size="small"
                    variant="outlined"
                    value={formData["stateId"]}
                    onChange={handleChange}
                    name="stateId"
                  >
                    {states.map((state) => (
                      <MenuItem key={state.id} value={state.id}>
                        {state.longName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Zip</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="zipCode"
                  variant="outlined"
                  value={formData["zipCode"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
              <Grid item xs={2}>
                <Typography variant="body1">Zip Ext.</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="zipCodeExt"
                  variant="outlined"
                  value={formData["zipCodeExt"]}
                  inputProps={{
                    maxLength: 4,
                  }}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2} sx={{ textAlign: "right" }}>
                <Typography variant="body1">Telephone Number</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  name="telephone"
                  variant="outlined"
                  value={formData["telephone"]}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
            <Divider color={theme.palette.blue.main} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div>
                <Button variant="outlined" onClick={handleCloseUser}>
                  Cancel
                </Button>
              </div>
              <div>
                <Button variant="outlined" onClick={handleSave}>
                  Save
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default AddEditUser;
