import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Button from "../../../components/Common/Button";
import theme from "../../../theme";
import { createSection, updateSection } from "../../Survey/Survey.service";
import {
  fetchPanelsByOrgId,
  setSelectedSurveyId,
  setSelectedSurveyName,
  setSurveyBuilderCurrentView,
  triggerSidebarRefresh,
} from "../../Survey/Survey.slice";
import RichEditor from "../../../components/Common/RichEditor";
import { CLEAN_FORM_DATA } from "../../../Utility/constants";

export const initNewSurveyData = {
  name: "",
  description: "",
  helpText: "",
  allowHistoryFill: null,
};

const SectionForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [seq, setSeq] = useState(null);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [nameError, setNameError] = useState(null);
  const selectedSec = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec
  );
  const selectedSurveyData = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData
  );

  const currentViewSource = useSelector(
    (state) =>
      state?.survey?.surveyBuilderData?.surveyBuilderCurrentView?.source
  );
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );
  const [formData, setFormData] = useState(initNewSurveyData);
  const [updateFormData, setUpdateFormData] = useState({});

  useEffect(() => {
    const setSequence = () => {
      const sec = selectedSurveyData?.sections;

      if (sec?.length > 0) {
        const largestSequenceSection = sec.reduce(
          (max, current) =>
            current.section.sequence > max.section.sequence ? current : max,
          sec[0]
        );
        setSeq(largestSequenceSection.section.sequence + 1);
      } else setSeq(0);
    };
    setSequence();
  }, [selectedSurveyData]);

  useEffect(() => {
    setUpdateFormData(selectedSec?.section);
  }, [selectedSec]);

  useEffect(() => {
    if (Object.keys(selectedSec)?.length > 0) {
      const updatedFormData = {
        name: selectedSec?.section?.name,
        description: selectedSec?.section?.description,
        helpText: selectedSec?.section?.helpText,
        allowHistoryFill: selectedSec?.section?.allowHistoryFill,
      };
      setFormData(updatedFormData);
    }
  }, [selectedSec]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "allowHistoryFill") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value === "true",
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
    if (Object.keys(selectedSec).length > 0) {
      if (name === "allowHistoryFill") {
        setUpdateFormData((prevData) => ({
          ...prevData,
          [name]: value === "true",
        }));
      } else {
        setUpdateFormData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      }
    }
  };
  const handleSave = () => {
    setNameError(null);
    const name = formData["name"];
    if (!name || name?.replace(/(&nbsp;|\s|<br\s*\/?>)/g, "").trim() === "") {
      setNameError("Please enter name");
    } else {
      if (selectedSec && selectedSec?.section?.id) {
        updateSectionRecord(selectedSec?.section?.id);
      } else {
        createSectionRecord();
      }
    }
  };

  const updateSectionRecord = async (id) => {
    const cleanData = CLEAN_FORM_DATA(updateFormData);
    try {
      const payload = {
        ...cleanData,
        modifiedBy: userName,
      };
      const response = await updateSection(payload);
      if (response?.data) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Section updated successfully.");
        setAlertSeverity("success");
        //   dispatch(setSelectedSurveyId(id));
        //   dispatch(setSelectedSurveyName(selectedSec?.survey?.name));

        setTimeout(() => {
          handleCancel();
          dispatch(triggerSidebarRefresh());
        }, 2000);
      }
    } catch (error) {
      console.log("Error updating section", error);
    }
  };

  const createSectionRecord = async () => {
    const cleanData = CLEAN_FORM_DATA(formData);
    try {
      const payload = {
        ...cleanData,
        createdBy: userName,
        survey: selectedSurveyData?.survey,
        sequence: seq,
      };
      const response = await createSection(payload);
      if (response?.data) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Section created successfully.");
        setAlertSeverity("success");

        //   dispatch(setSelectedSurveyId(response.data.survey.id));
        //   dispatch(setSelectedSurveyName(response.data.survey.name));
        setTimeout(() => {
          handleCancel();
          dispatch(triggerSidebarRefresh());
        }, 2000);
      }
    } catch (error) {
      console.log("Error creating section", error);
    }
  };

  const handleCancel = () => {
    if (
      currentViewSource === "addSectionButton" ||
      currentViewSource === "editSectionIcon"
    ) {
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 1, source: "" }));
    } else if (currentViewSource === "editSectionButton") {
      dispatch(setSurveyBuilderCurrentView({ viewIndex: 2, source: "" }));
    }
  };

  return (
    <Box component="fieldset">
      <legend sx={{ color: theme.palette.pink.main }}>
        <b>SECTION TITLE & ATTRIBUTES</b>
      </legend>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={2}>
          <Typography variant="body1">Name *</Typography>
        </Grid>
        <Grid item xs={5}>
          <RichEditor
            name="name"
            value={formData["name"]}
            onChange={handleChange}
          />
          {nameError && (
            <Typography variant="body1" color="error">
              {nameError}
            </Typography>
          )}
        </Grid>
        <Grid item xs={5}>
          <Typography variant="subtitle1" color="error">
            * Required
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">Description</Typography>
        </Grid>
        <Grid item xs={10}>
          <RichEditor
            name="description"
            value={formData["description"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">Help</Typography>
        </Grid>
        <Grid item xs={10}>
          <RichEditor
            name="helpText"
            value={formData["helpText"]}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1">
            Allow to fill with last year's data
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <RadioGroup
            row
            name="allowHistoryFill"
            value={formData["allowHistoryFill"]}
            onChange={handleChange}
          >
            <FormControlLabel value={true} control={<Radio />} label="Yes" />
            <FormControlLabel value={false} control={<Radio />} label="No" />
          </RadioGroup>
        </Grid>
        <Grid item xs={2}></Grid>
        <Grid item xs={10}>
          <Button onClick={handleCancel} sx={{ marginRight: "10px" }}>
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </Box>
  );
};

export default SectionForm;
