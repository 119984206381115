import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

import { setSurveyBuilderCurrentView } from "../../Survey/Survey.slice";
import SurveyActionButtons from "../SurveyBuilder/SurveyActionButtons";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";
import {
  deleteRule,
  updateRuleOrder,
  rulesBySection,
} from "../../Survey/Survey.service";
import theme from "../../../theme";
import Tooltip from "../../../components/Common/Tooltip";
import { htmlParser } from "../../../Utility/constants";

const SectionVrs = () => {
  const dispatch = useDispatch();
  const selectedSecName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec?.section?.name
  );
  const selectedSecId = useSelector(
    (state) => state?.survey?.surveyBuilderData?.selectedSec?.section?.id
  );

  const [loadingVrs, setLoadingVrs] = useState(false);
  const [vrData, setVrData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 0 });
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmRuleId, setConfirmRuleId] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  useEffect(() => {
    if (selectedSecId) {
      fetchSectionRules();
    }
  }, [selectedSecId]);
  const fetchSectionRules = async () => {
    setLoadingVrs(true);
    try {
      const response = await rulesBySection({ id: selectedSecId });
      setVrData(response.data);
      setPagination({ pageIndex: 0, pageSize: response?.data.length });
      setLoadingVrs(false);
    } catch (error) {
      console.error("Error fetching Section VRs:", error);
      setLoadingVrs(false);
    }
  };

  const openDeleteRule = async (id) => {
    setConfirmTitle("Deleting rule " + id + ". Press OK to proceed.");
    setConfirmRuleId(id);
    setConfirmOpen(true);
  };

  const data = useMemo(() => {
    return vrData?.map((vr) => {
      return {
        id: vr?.id,
        userText: vr?.userText,
        requiredAction: vr?.requiredAction,
        errorText: vr?.errorText,
      };
    });
  }, [vrData]);
  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Operations",
        size: 30,
        maxSize: 30,
        Cell: ({ row }) => (
          <>
            <Tooltip title="Edit this rule" arrow>
              <NoteAltOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.green.main,
                  background: theme.palette.green.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "10px",
                }}
                onClick={() =>
                  dispatch(
                    setSurveyBuilderCurrentView({
                      viewIndex: 11,
                      source: "SectionLevel__" + row?.original?.id,
                    })
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Delete this rule" arrow>
              <CloseOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.error.main,
                  background: theme.palette.error.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  openDeleteRule(row?.original?.id);
                }}
              />
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "expression",
        header: "Rules",
        Cell: ({ row }) => (
          <>
            <Typography variant="string" sx={{ whiteSpace: "pre-line" }}>
              {row?.original?.userText} [{row?.original?.requiredAction}:{" "}
              {row?.original?.errorText}.] - Ref. # {row?.original?.id}
            </Typography>
          </>
        ),
      },
    ],
    []
  );

  const updateSectionVrOrder = async (newReOrder) => {
    try {
      await updateRuleOrder(newReOrder);
    } catch (error) {
      console.error("Error updating section order", error);
    }
  };

  const handleDeleteRule = async () => {
    try {
      const response = await deleteRule(confirmRuleId);
      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Rule deleted successfully");
        setAlertSeverity("success");
        setVrData(vrData.filter((vr) => vr.id !== confirmRuleId));
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error occured deleting section rule");
        setAlertSeverity("error");
      }
      setConfirmTitle("");
      setConfirmRuleId("");
      setConfirmOpen(false);
    } catch (error) {
      console.log("Error occured deleting section rule", error);
      return null;
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    state: { pagination },
    enableColumnActions: false,
    initialState: { density: "compact" },
    layoutMode: "grid",
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );

          if (draggingRow.index !== hoveredRow.index) {
            setVrData([...data]);
            const newReOrder = {
              idSequenceList: data.map((item, index) => ({
                id: item.id,
                sequence: index,
              })),
            };
            updateSectionVrOrder(newReOrder);
          }
        }
      },
    }),
  });
  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label" color="primary">
          {htmlParser(selectedSecName)}
        </Typography>
      </div>
      <SurveyActionButtons page="Section" />
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label" color="primary">
          VALIDATION RULES IN SECTION. AVAILABLE OPERATIONS: REORDER, DELETE AND
          EDIT RULE
        </Typography>
      </div>
      {loadingVrs && "Loading Rules..."}
      {!loadingVrs && <MaterialReactTable table={table} />}
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleDeleteRule}
        pageName="ModifySurvey"
      ></ConfirmDialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default SectionVrs;
