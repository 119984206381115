import React, { useEffect, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Button from "../../components/Common/Button";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import { setFilterFormData } from "../Survey/Survey.slice";
import theme from "../../theme";

export const initialFormData = {
  "survey_filing.status_id": [],
  "survey_respondent.role_id": [1],
  "survey_respondent.sr_Custom_Id": "",
  "survey_respondent.first_name": "",
  "survey_respondent.middle_initial": "",
  "survey_respondent.last_name": "",
  "survey_respondent.title": "",
  "survey_respondent.username": "",
  "survey_respondent.address1": "",
  "survey_respondent.address2": "",
  "survey_respondent.city": "",
  "survey_respondent.county": "",
  "survey_respondent.state_id": [],
  "survey_respondent.zip_code": "",
  "survey_respondent.zip_code_ext": "",
  "survey_respondent.phone_number": "",
  "survey_respondent.fax_number": "",
  "survey_respondent.email": "",

  "survey_respondent.attribute1": "",
  "survey_respondent.attribute2": "",
  "survey_respondent.attribute3": "",
  "survey_respondent.attribute4": "",
  "survey_respondent.attribute5": "",
  "survey_respondent.attribute6": "",
  "survey_respondent.attribute7": "",
  "survey_respondent.attribute8": "",
  "survey_respondent.attribute9": "",
  "survey_respondent.attribute10": "",
  "survey_respondent.attribute11": "",
  "survey_respondent.attribute12": "",
  "survey_respondent.attribute13": "",
  "survey_respondent.attribute14": "",
  "survey_respondent.attribute15": "",
  "survey_respondent.attribute16": "",
  "survey_respondent.attribute17": "",
  "survey_respondent.attribute18": "",
  "survey_respondent.attribute19": "",
  "survey_respondent.attribute20": "",

  "survey_organization.custom_id": "",
  "survey_organization.name": "",
  "survey_organization.address1": "",
  "survey_organization.address2": "",
  "survey_organization.city": "",
  "survey_organization.county": "",
  "survey_organization.state_id": [],
  "survey_organization.zip_code": "",
  "survey_organization.zip_code_ext": "",
  "survey_organization.phone_number": "",
  "survey_organization.fax_number": "",
  "survey_organization.urban_flag": "",
  "survey_organization.url": "",
  "survey_organization.region": "",

  "survey_organization.attribute1": "",
  "survey_organization.attribute2": "",
  "survey_organization.attribute3": "",
  "survey_organization.attribute4": "",
  "survey_organization.attribute5": "",
  "survey_organization.attribute6": "",
  "survey_organization.attribute7": "",
  "survey_organization.attribute8": "",
  "survey_organization.attribute9": "",
  "survey_organization.attribute10": "",
  "survey_organization.attribute11": "",
  "survey_organization.attribute12": "",
  "survey_organization.attribute13": "",
  "survey_organization.attribute14": "",
  "survey_organization.attribute15": "",
  "survey_organization.attribute16": "",
  "survey_organization.attribute17": "",
  "survey_organization.attribute18": "",
  "survey_organization.attribute19": "",
  "survey_organization.attribute20": "",
};

export const filterEmptyFormData = (formData) => {
  const filteredFormData = {};
  for (const key in formData) {
    if (
      (Array.isArray(formData[key]) &&
        formData[key].length !== 0 &&
        formData[key].some((item) => item !== "")) ||
      (!Array.isArray(formData[key]) && formData[key] !== "")
    ) {
      filteredFormData[key] = formData[key];
    }
  }
  return filteredFormData;
};

const FilterForm = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [showResProp, setSResProp] = useState(false);
  const [showOrgProp, setShowOrgProp] = useState(false);
  const filterFormData = useSelector((state) => state?.survey?.filterFormData);
  const [formData, setFormData] = useState(initialFormData);
  const surveyFilingStatuses = useSelector(
    (state) => state?.survey?.surveyFilingStatuses
  );
  const states = useSelector((state) => state?.survey?.states);

  const toggleResProp = () => {
    setSResProp(!showResProp);
  };
  const toggleOrgProp = () => {
    setShowOrgProp(!showOrgProp);
  };

  useEffect(() => {
    const updatedFormData = { ...filterFormData };
    for (const key in initialFormData) {
      if (!(key in updatedFormData)) {
        updatedFormData[key] = initialFormData[key];
      }
    }

    if (Object.keys(filterFormData).length !== 0) {
      setFormData(updatedFormData);
    }
  }, [filterFormData]);

  const handleFormClear = () => {
    setFormData(initialFormData);
    dispatch(setFilterFormData(filterEmptyFormData(initialFormData)));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: checked ? [1, 2] : [1],
      }));
    } else if (type === "radio") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: parseInt(value),
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await dispatch(setFilterFormData(filterEmptyFormData(formData)));
    handleClose();
  };

  const columnStyle = {
    display: "flex",
    flexDirection: "column",
  };

  const rowStyle = {
    display: "flex",
    flexDirection: "row",
    gap: "20px",
  };

  const divSpacing = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };
  const flexDiv = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "20px",
  };
  return (
    <div className="panelModal">
      <div className="panelDiv">
        <Typography variant="label">
          Survey Respondents / Survey Organization Filter
        </Typography>
      </div>
      <div className="panelForm">
        <form style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography color="primary" style={{ fontWeight: "bold" }}>
            Note : Texts entered are case-insensitive. Please use wildcard
            character (*) as needed.
          </Typography>
          <div style={divSpacing}>
            <div>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div style={{ display: "flex", gap: "15px" }}>
              <Button onClick={handleFormClear}>Clear</Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </div>
          <Divider color={theme.palette.blue.main} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <div className="attributeDiv">
              <div
                style={{ display: "flex", flexDirection: "row", gap: "20px" }}
              >
                <div style={columnStyle}>
                  <Typography variant="h6">Data Filters</Typography>
                  <TextField variant="outlined" size="small" />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Survey Filing Status</Typography>
                  <FormControl sx={{ width: "220px" }}>
                    <Select
                      size="small"
                      multiple
                      variant="outlined"
                      value={formData["survey_filing.status_id"]}
                      onChange={handleChange}
                      name="survey_filing.status_id"
                    >
                      {surveyFilingStatuses.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                          {status.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </div>
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  name="survey_respondent.role_id"
                  checked={
                    formData["survey_respondent.role_id"].includes(1) &&
                    formData["survey_respondent.role_id"].includes(2)
                  }
                  onChange={handleChange}
                />
              }
              label="Include survey respondent support staff"
            />
          </div>
          <div style={flexDiv}>
            {/* First column */}

            <div className="attributeDiv">
              <Typography
                color="primary"
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Survey Respondent Information
              </Typography>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Id</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.sr_Custom_Id"
                    variant="outlined"
                    value={formData["survey_respondent.sr_Custom_Id"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">First Name</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.first_name"
                    variant="outlined"
                    value={formData["survey_respondent.first_name"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Middle Name</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.middle_initial"
                    variant="outlined"
                    value={formData["survey_respondent.middle_initial"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Last Name</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.last_name"
                    variant="outlined"
                    value={formData["survey_respondent.last_name"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Title</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.title"
                    variant="outlined"
                    value={formData["survey_respondent.title"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Username (6 chars min.)</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.username"
                    variant="outlined"
                    value={formData["survey_respondent.username"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Address 1</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.address1"
                    variant="outlined"
                    value={formData["survey_respondent.address1"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Address 2</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.address2"
                    variant="outlined"
                    value={formData["survey_respondent.address2"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">City</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.city"
                    variant="outlined"
                    value={formData["survey_respondent.city"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">County</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.county"
                    variant="outlined"
                    value={formData["survey_respondent.county"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">State</Typography>
                  <FormControl sx={{ width: "220px" }}>
                    <Select
                      size="small"
                      multiple
                      variant="outlined"
                      value={formData["survey_respondent.state_id"]}
                      onChange={handleChange}
                      name="survey_respondent.state_id"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.longName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Zip</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.zip_code"
                    variant="outlined"
                    value={formData["survey_respondent.zip_code"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Zip Ext.</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.zip_code_ext"
                    variant="outlined"
                    value={formData["survey_respondent.zip_code_ext"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Telephone Number</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.phone_number"
                    variant="outlined"
                    value={formData["survey_respondent.phone_number"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Fax Number</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.fax_number"
                    variant="outlined"
                    value={formData["survey_respondent.fax_number"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Email Address</Typography>
                  <TextField
                    size="small"
                    name="survey_respondent.email"
                    variant="outlined"
                    value={formData["survey_respondent.email"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
            {/* Second column */}
            <div className="attributeDiv">
              <Typography
                color="primary"
                style={{ fontWeight: "bold", textDecoration: "underline" }}
              >
                Survey Organization Information
              </Typography>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Custom Id</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.custom_id"
                    variant="outlined"
                    value={formData["survey_organization.custom_id"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Name</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.name"
                    variant="outlined"
                    value={formData["survey_organization.name"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Address 1</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.address1"
                    variant="outlined"
                    value={formData["survey_organization.address1"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Address 2</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.address2"
                    variant="outlined"
                    value={formData["survey_organization.address2"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">City</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.city"
                    variant="outlined"
                    value={formData["survey_organization.city"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">County</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.county"
                    variant="outlined"
                    value={formData["survey_organization.county"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">State</Typography>
                  <FormControl sx={{ width: "220px" }}>
                    <Select
                      size="small"
                      multiple
                      variant="outlined"
                      value={formData["survey_organization.state_id"]}
                      onChange={handleChange}
                      name="survey_organization.state_id"
                    >
                      {states.map((state) => (
                        <MenuItem key={state.id} value={state.id}>
                          {state.longName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Zip</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.zip_code"
                    variant="outlined"
                    value={formData["survey_organization.zip_code"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Zip Ext.</Typography>

                  <TextField
                    size="small"
                    name="survey_organization.zip_code_ext"
                    variant="outlined"
                    value={formData["survey_organization.zip_code_ext"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Telephone Number</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.phone_number"
                    variant="outlined"
                    value={formData["survey_organization.phone_number"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Fax Number</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.fax_number"
                    variant="outlined"
                    value={formData["survey_organization.fax_number"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Location</Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <FormControl component="fieldset" sx={{ width: "220px" }}>
                      <RadioGroup
                        row
                        name="survey_organization.urban_flag"
                        value={formData["survey_organization.urban_flag"]}
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Urban"
                        />
                        <FormControlLabel
                          value={0}
                          control={<Radio />}
                          label="Rural"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div style={rowStyle}>
                <div style={columnStyle}>
                  <Typography variant="h6">Website URL</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.url"
                    variant="outlined"
                    value={formData["survey_organization.url"]}
                    onChange={handleChange}
                  />
                </div>
                <div style={columnStyle}>
                  <Typography variant="h6">Region</Typography>
                  <TextField
                    size="small"
                    name="survey_organization.region"
                    variant="outlined"
                    value={formData["survey_organization.region"]}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div style={flexDiv}>
            {/* first column */}
            <div className="attributeDiv">
              <Typography
                color="primary"
                className="linkText"
                onClick={toggleResProp}
              >
                {showResProp ? "-" : "+"} Additional Respondent Properties
              </Typography>
              {showResProp && (
                <div className="attributeDiv">
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 1</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute1"
                        variant="outlined"
                        value={formData["survey_respondent.attribute1"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 2</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute2"
                        variant="outlined"
                        value={formData["survey_respondent.attribute2"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 3</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute3"
                        variant="outlined"
                        value={formData["survey_respondent.attribute3"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 4</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute4"
                        variant="outlined"
                        value={formData["survey_respondent.attribute4"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 5</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute5"
                        variant="outlined"
                        value={formData["survey_respondent.attribute5"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 6</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute6"
                        variant="outlined"
                        value={formData["survey_respondent.attribute6"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 7</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute7"
                        variant="outlined"
                        value={formData["survey_respondent.attribute7"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 8</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute8"
                        variant="outlined"
                        value={formData["survey_respondent.attribute8"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 9</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute9"
                        variant="outlined"
                        value={formData["survey_respondent.attribute9"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 10</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute10"
                        variant="outlined"
                        value={formData["survey_respondent.attribute10"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 11</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute11"
                        variant="outlined"
                        value={formData["survey_respondent.attribute11"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 12</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute12"
                        variant="outlined"
                        value={formData["survey_respondent.attribute12"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 13</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute13"
                        variant="outlined"
                        value={formData["survey_respondent.attribute13"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 14</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute14"
                        variant="outlined"
                        value={formData["survey_respondent.attribute14"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 15</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute15"
                        variant="outlined"
                        value={formData["survey_respondent.attribute15"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 16</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute16"
                        variant="outlined"
                        value={formData["survey_respondent.attribute16"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 17</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute17"
                        variant="outlined"
                        value={formData["survey_respondent.attribute17"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 18</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute18"
                        variant="outlined"
                        value={formData["survey_respondent.attribute18"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 19</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute19"
                        variant="outlined"
                        value={formData["survey_respondent.attribute19"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 20</Typography>
                      <TextField
                        size="small"
                        name="survey_respondent.attribute20"
                        variant="outlined"
                        value={formData["survey_respondent.attribute20"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            {/* Second column */}
            <div className="attributeDiv">
              <Typography
                color="primary"
                className="linkText"
                onClick={toggleOrgProp}
              >
                {showOrgProp ? "-" : "+"} Additional Organization Properties
              </Typography>
              {showOrgProp && (
                <div className="attributeDiv">
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 1</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute1"
                        variant="outlined"
                        value={formData["survey_organization.attribute1"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 2</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute2"
                        variant="outlined"
                        value={formData["survey_organization.attribute2"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 3</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute3"
                        variant="outlined"
                        value={formData["survey_organization.attribute3"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 4</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute4"
                        variant="outlined"
                        value={formData["survey_organization.attribute4"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 5</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute5"
                        variant="outlined"
                        value={formData["survey_organization.attribute5"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 6</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute6"
                        variant="outlined"
                        value={formData["survey_organization.attribute6"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 7</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute7"
                        variant="outlined"
                        value={formData["survey_organization.attribute7"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 8</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute8"
                        variant="outlined"
                        value={formData["survey_organization.attribute8"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 9</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute9"
                        variant="outlined"
                        value={formData["survey_organization.attribute9"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 10</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute10"
                        variant="outlined"
                        value={formData["survey_organization.attribute10"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 11</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute11"
                        variant="outlined"
                        value={formData["survey_organization.attribute11"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 12</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute12"
                        variant="outlined"
                        value={formData["survey_organization.attribute12"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 13</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute13"
                        variant="outlined"
                        value={formData["survey_organization.attribute13"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 14</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute14"
                        variant="outlined"
                        value={formData["survey_organization.attribute14"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 15</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute15"
                        variant="outlined"
                        value={formData["survey_organization.attribute15"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 16</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute16"
                        variant="outlined"
                        value={formData["survey_organization.attribute16"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 17</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute17"
                        variant="outlined"
                        value={formData["survey_organization.attribute17"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 18</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute18"
                        variant="outlined"
                        value={formData["survey_organization.attribute18"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div style={rowStyle}>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 19</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute19"
                        variant="outlined"
                        value={formData["survey_organization.attribute19"]}
                        onChange={handleChange}
                      />
                    </div>
                    <div style={columnStyle}>
                      <Typography variant="h6">Attribute 20</Typography>
                      <TextField
                        size="small"
                        name="survey_organization.attribute20"
                        variant="outlined"
                        value={formData["survey_organization.attribute20"]}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Divider color={theme.palette.blue.main} />
          <div style={divSpacing}>
            <div>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </div>
            <div style={{ display: "flex", gap: "20px" }}>
              <Button onClick={handleFormClear}>Clear</Button>
              <Button onClick={handleSubmit}>Submit</Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FilterForm;
