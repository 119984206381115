import React, { useEffect, useState, useMemo } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Snackbar,
  Chip,
  Grid,
  Stack,
  Divider,
  Modal,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  organizationsByParentOrganizationId,
  deleteOrganization,
  createOrganizationOrgTypeList,
} from "../Survey/Survey.service";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import Tooltip from "../../components/Common/Tooltip";
import ConfirmDialog from "../../components/Common/ConfirmDialog";
import { setSelectedOrgs } from "../Survey/Survey.slice";
import AddEditOrganization from "./AddEditOrganization";

const Organization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const orgName = useSelector(
    (state) =>
      state?.survey?.loggedInUserData?.authorizedUser?.organization?.name
  );
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmUserId, setConfirmUserId] = useState(null);

  const [showLoading, setShowLoading] = useState(true);
  const [orgList, setOrgList] = useState([]);
  const [noRecords, setNoRecords] = useState(null);
  const [selectedOrgId, setSelectedOrgId] = useState(null);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [selOrgData, setSelOrgData] = useState({});
  const [orgTypeList, setOrgTypeList] = useState([]);

  useEffect(() => {
    if (orgId) {
      setShowLoading(true);
      getOrgList();
      getOrgTypeList();
    }
  }, [orgId]);
  const getOrgTypeList = async () => {
    try {
      const response = await createOrganizationOrgTypeList();
      if (response.data) {
        setOrgTypeList(response.data);
      }
    } catch (error) {
      console.log("Error getting organization type list", error);
    }
  };

  const getOrgList = async () => {
    setOrgList([]);
    setNoRecords(null);
    try {
      const res = await organizationsByParentOrganizationId(orgId);
      if (Array.isArray(res?.data) && res?.data?.length > 0) {
        setOrgList(res.data);
        setShowLoading(false);
        setNoRecords(null);
      } else {
        setOrgList([]);
        setNoRecords("No organizations to display");
      }
    } catch (error) {
      console.error("Error fetching organizations list:", error);
      setShowLoading(false);
    }
  };
  const openDeleteOrgData = async (id, name) => {
    setConfirmTitle(`Deleting ${name} - Press OK to proceed.`);

    setConfirmUserId(id);
    setConfirmOpen(true);
  };
  const handleOrgDelete = async () => {
    try {
      const response = await deleteOrganization(confirmUserId);

      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Organization deleted successfully");
        setAlertSeverity("success");
        setShowLoading(true);
        getOrgList();
        setConfirmTitle(null);
        confirmUserId(null);
        setConfirmOpen(false);
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting organization");
        setAlertSeverity("error");
        setConfirmTitle(null);
        confirmUserId(null);
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error deleting organization data.", error);
      return null;
    }
  };

  const handleOpenOrgModal = async () => {
    setSelOrgData({});
    setOpenOrgModal(true);
  };

  const handleCloseOrgModal = () => {
    setOpenOrgModal(false);
    setSelOrgData({});
    setSelectedOrgId(null);
  };
  const handleRefreshOrgModal = () => {
    handleCloseOrgModal();
    setShowLoading(true);
    getOrgList();
  };

  const data = useMemo(() => {
    return orgList?.map((data) => {
      return {
        id: data?.id,
        name: data?.name,
        address1: data?.address1 || "",
        address2: data?.address2 || "",
        city: data?.city || "",
        county: data?.county || "",
        stateCode: data?.stateCode ? data?.stateCode?.shortName : "",
        zipCode: data?.zipCode || "",
        zipCodeExt: data?.zipCodeExt || "",
        phoneNumber: data?.phoneNumber || "",
        fax: data?.fax || "",
        attribute: data?.attribute || "",
        attributeValue: data?.attributeValue || "",
        urbanFlag:
          data?.urbanFlag !== undefined
            ? data?.urbanFlag
              ? "Urban"
              : "Rural"
            : "",
      };
    });
  }, [orgList]);
  const usersClicked = () => {
    dispatch(setSelectedOrgs({}));
    navigate("/admin");
  };
  useEffect(() => {
    if (selectedOrgId) {
      const result = orgList?.find((item) => item.id === selectedOrgId);
      setSelOrgData(result);
      setOpenOrgModal(true);
    }
  }, [selectedOrgId]);

  return (
    <>
      <Grid container rowSpacing={1} sx={{ marginBottom: "15px" }}>
        <Grid item xs={12} container>
          <Grid item xs={8} xl={9}>
            <Typography color="primary" variant="h6">
              <strong> Organization Home Page :</strong> Functions: Create or
              Edit Organization
            </Typography>
            <Typography color="primary" variant="h6">
              <strong> Organization :</strong> {orgName}
            </Typography>
          </Grid>

          <Grid item xs={4} xl={3}>
            <Stack direction="row" spacing={1} sx={{ float: "right" }}>
              <Chip
                label="Create new organization"
                className="chipStyle"
                onClick={handleOpenOrgModal}
              />
              <Chip
                label="Users"
                className="chipStyle"
                onClick={() => {
                  usersClicked();
                }}
                variant="outlined"
              />
            </Stack>
          </Grid>
        </Grid>
      </Grid>
      <Divider
        color={theme.palette.lightBlue.main}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      />
      <Box>
        {showLoading && "Loading ..."}
        {noRecords && (
          <Typography color="error" variant="h6" paragraph>
            {noRecords}
          </Typography>
        )}
        {orgList.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      color: theme.palette.blue.main,
                      fontWeight: "bold",
                      fontSize: "13px",
                    },
                  }}
                >
                  <TableCell>Name</TableCell>
                  <TableCell>Address1</TableCell>
                  <TableCell>Address2</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>County</TableCell>
                  <TableCell>State</TableCell>
                  <TableCell>Zip</TableCell>
                  <TableCell>Zip Ext.</TableCell>
                  <TableCell>Telephone</TableCell>
                  <TableCell>Fax</TableCell>
                  <TableCell>Attribute</TableCell>
                  <TableCell>Attribute Value</TableCell>
                  <TableCell>Location</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                sx={{
                  "& td": {
                    fontSize: "13px",
                  },
                }}
              >
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                    }}
                  >
                    <TableCell scope="row">
                      <span
                        className="blueLinkText"
                        onClick={() => setSelectedOrgId(row.id)}
                      >
                        {row.name}
                      </span>
                    </TableCell>
                    <TableCell>{row.address1}</TableCell>
                    <TableCell>{row.address2}</TableCell>
                    <TableCell>{row.city}</TableCell>
                    <TableCell>{row.county}</TableCell>
                    <TableCell>{row.stateCode}</TableCell>
                    <TableCell>{row.zipCode}</TableCell>

                    <TableCell>{row.zipCodeExt}</TableCell>
                    <TableCell>{row.phoneNumber}</TableCell>
                    <TableCell>{row.fax}</TableCell>
                    <TableCell>{row.attribute}</TableCell>
                    <TableCell>{row.attributeValue}</TableCell>
                    <TableCell>{row.urbanFlag}</TableCell>

                    <TableCell>
                      <Tooltip title="Delete this organization" arrow>
                        <DeleteOutlinedIcon
                          sx={{
                            cursor: "pointer",
                            width: 15,
                            height: 15,
                            color: theme.palette.blue.main,
                          }}
                          onClick={() => {
                            openDeleteOrgData(row.id, row.name);
                          }}
                        />
                      </Tooltip>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleOrgDelete}
        pageName="Home"
      ></ConfirmDialog>
      <Modal open={openOrgModal} onClose={handleCloseOrgModal}>
        <AddEditOrganization
          handleCloseOrg={handleCloseOrgModal}
          orgTypeList={orgTypeList}
          selOrgData={selOrgData}
          handleRefreshOrg={handleRefreshOrgModal}
        />
      </Modal>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Organization;
