import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    text: {
      primary: "#232323",
    },
    primary: {
      main: "#003087",
      contrastText: "#fff",
    },
    secondary: {
      main: "#ff9800",
    },
    info: {
      main: "#fed141",
    },
    success: {
      main: "#008755",
    },
    warning: {
      main: "#e09f3e",
    },
    error: {
      main: "#d50032",
      light: "#d500324d",
    },
    headerMenu: {
      main: "#717579",
    },
    primaryText: {
      main: "#171B1E",
    },
    sidebarMenuActive: {
      main: "#1b1ba7",
    },
    blue: {
      main: "#003087",
      light: "#0030874d",
    },
    lightBlue: {
      main: "#99CCFF",
      light: "#99CCFF4d",
    },
    darkBlue: {
      main: "#0039E6",
      light: "#0039E64d",
    },
    yellow: {
      main: "#ffcc00",
      light: "#ffcc004d",
    },
    green: {
      main: "#4caf50",
      light: "#4caf504d",
    },
    orange: {
      main: "#ff9900",
      light: "#ff99004d",
    },
    purple: {
      main: "#914e86",
      light: "#914e864d",
    },
    gray: {
      main: "#7f8c89",
      light: "#7f8c894d",
    },
    brown: {
      main: "#8B4513",
      light: "#8B45134d",
    },
    pink: {
      main: "#FFC0CB",
      light: "#FFC0CB4d",
    },
    lightPink: {
      main: "#FFB3B3",
      light: "#FFB3B34d",
    },
  },
  typography: {
    fontFamily: "Arial",
    question: {
      fontWeight: "700",
      fontSize: "20px",
      lineHeight: "23px",
    },
    label: {
      fontWeight: "600",
      fontSize: "16px",
      lineHeight: "24px",
    },
    headerMenu: {
      fontWeight: "600",
      fontSize: "18px",
      lineHeight: "24px",
    },
    d1: {
      fontWeight: "900",
      fontSize: "54px",
      lineHeight: 1.2,
    },
    d2: {
      fontWeight: "700",
      fontSize: "40px",
      lineHeight: 1.3,
    },
    h1: {
      fontWeight: "700",
      fontSize: "44px",
      lineHeight: 1.3,
    },
    h2: {
      fontWeight: "700",
      fontSize: "36px",
      lineHeight: 1.3,
    },
    h3: {
      fontWeight: "400",
      fontSize: "28px",
      lineHeight: 1.3,
    },
    h4: {
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: 1.3,
    },
    h5: {
      fontWeight: "400",
      fontSize: "20px",
      lineHeight: 1.3,
    },
    h6: {
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "24px",
    },
  },
});

export default theme;
