import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Button,
} from "@mui/material";

function AddQuesModal({ open, onClose, onSubmit }) {
  const [selectedGroupType, setSelectedGroupType] = useState("");
  const [error, setError] = useState("");

  const handleRadioChange = (event) => {
    setSelectedGroupType(event.target.value);
    setError("");
  };

  const handleSubmit = () => {
    if (!selectedGroupType) {
      setError("Please select an option");
    } else {
      onSubmit(selectedGroupType);
      onClose();
    }
  };

  useEffect(() => {
    if (open) {
      setSelectedGroupType("");
      setError("");
    }
  }, [open]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          boxShadow: 24,
          px: 5,
          py: 3,
          borderRadius: 1,
        }}
      >
        <FormControl component="fieldset" fullWidth>
          <Typography sx={{ fontWeight: "bold" }} gutterBottom>
            Please select a group type:
          </Typography>
          <RadioGroup value={selectedGroupType} onChange={handleRadioChange}>
            <FormControlLabel
              value="noAnswer"
              control={<Radio />}
              label="Standard group"
            />
            <FormControlLabel
              value="checkboxgrp"
              control={<Radio />}
              label="Checkbox matrix group"
            />
            <FormControlLabel
              value="radiogrp"
              control={<Radio />}
              label="Radio matrix group"
            />
            <FormControlLabel
              value="commongrp"
              control={<Radio />}
              label="Common matrix group"
            />
          </RadioGroup>
          {error && (
            <Typography color="error" variant="body2" mt={1}>
              {error}
            </Typography>
          )}
        </FormControl>
        <Box mt={2} display="flex" justifyContent="flex-start">
          <Button onClick={onClose} variant="outlined" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary" variant="contained">
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default AddQuesModal;
