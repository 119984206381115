import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Button from "../../components/Common/Button";
import DatePicker from "../../components/Common/DatePicker";
import {
  getCommentTypes,
  getRegions,
  surveyComments,
} from "../Survey/Survey.service";
import {
  USER_ROLES,
  formatShortDateDash,
  compareTwoDates,
  htmlParser,
} from "../../Utility/constants";
import { fetchPanelsByOrgId } from "../../pages/Survey/Survey.slice";
import theme from "../../theme";

const CommentsExport = () => {
  const location = useLocation();
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;
  const surveyPanels = useSelector((state) => state.survey.surveyPanels);
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.username
  );

  const roleName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.role?.name
  );
  const surveyFilingStatuses = useSelector(
    (state) => state?.survey?.surveyFilingStatuses
  );

  const states = useSelector((state) => state?.survey?.states);

  const [loading, setLoading] = useState(false);
  const [stateIds, setStateIds] = useState([]);

  const [commentsResult, setCommentsResult] = useState([]);
  const [selectedComment, setSelectedComment] = useState([]);
  const [totalComments, setTotalComments] = useState(null);

  const [regionResult, setRegionResult] = useState([]);
  const [regionIds, setRegionIds] = useState([]);

  const [downloadType, setDownloadType] = useState("csv");
  const [selectedFilingStatus, setSelectedFilingStatus] = useState([]);
  const [beginingDate, setBeginingDate] = useState(null);
  const [endingDate, setEndingDate] = useState(null);
  const [ahaID, setahaID] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("");

  const handleStateChange = (event) => {
    setStateIds(event.target.value);
  };
  const handleCommentChange = (event) => {
    setSelectedComment(event.target.value);
  };
  const handleRegionChange = (event) => {
    setRegionIds(event.target.value);
  };

  const handleDownloadTypeChange = (event) => {
    setDownloadType(event.target.value);
  };
  const handleFilingChange = (e) => {
    setSelectedFilingStatus(e.target.value);
  };
  const handleBeginingDate = (date) => {
    setBeginingDate(date);
  };
  const handleEndingDate = (date) => {
    setEndingDate(date);
  };
  const handleAhaIdChange = (event) => {
    setahaID(event.target.value);
  };

  useEffect(() => {
    const fetchCommentTypes = async () => {
      try {
        const res = await getCommentTypes();
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          let allCommentIds = [];

          res.data.forEach((element) => {
            allCommentIds.push(element.id);
          });
          setCommentsResult(res?.data);
          setSelectedComment(allCommentIds);
          setTotalComments(res?.data?.length);
        }
      } catch (error) {
        console.error("Error fetching comment types", error);
      }
    };
    const fetchRegions = async () => {
      try {
        const res = await getRegions();
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          setRegionResult(res.data);
        }
      } catch (error) {
        console.error("Error fetching regions", error);
      }
    };

    fetchCommentTypes();
    fetchRegions();
  }, []);
  useEffect(() => {
    dispatch(fetchPanelsByOrgId(orgId));
  }, [dispatch]);

  const handleExport = async () => {
    setErrMsg("");
    setMsg("");
    let isValidDate = true;
    if (beginingDate && endingDate) {
      isValidDate = compareTwoDates(beginingDate, endingDate);
    }
    if (isValidDate) {
      setLoading(true);
      const filterValues = {
        comment_type_id:
          selectedComment.length === totalComments
            ? ""
            : selectedComment.join(),
        state_id: stateIds.join(),
        region_id: regionIds.join(),
        start_date: beginingDate ? formatShortDateDash(beginingDate) : "",
        end_date: endingDate ? formatShortDateDash(endingDate) : "",
        status_id: selectedFilingStatus.join(),
        aha_id: ahaID,
      };
      const filterCriteria = Object.fromEntries(
        Object.entries(filterValues).filter(
          ([key, value]) => value !== null && value !== ""
        )
      );
      let payload = {
        surveyId: surveyId,
        requestedUserEmail: userName,
        download_type: downloadType,
        filterCriteria: filterCriteria,
      };
      if (
        roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_1_ROLENAME ||
        roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_2_ROLENAME ||
        roleName === USER_ROLES.BUILDER_STAKEHOLDER_LEVEL_3_ROLENAME
      )
        payload.push({ surveyPanelId: surveyPanels[0]?.id });
      try {
        const response = await surveyComments(payload);
        if (response?.status === 200) {
          setLoading(false);
          setMsg(
            "Export complete. Please check your email for a link to the file. You may need to check your spam folder if you do not see the email in your inbox."
          );
        } else {
          setMsg(
            "An error occurred while generating the download. Please try again. If the issue persists, please contact system support."
          );
          setLoading(false);
        }
      } catch (error) {
        setMsg(
          "An error occurred while generating the download. Please try again. If the issue persists, please contact system support."
        );
        setLoading(false);
        console.error("Error exporting:", error);
      }
    } else {
      setErrMsg("Ending Date should be greater than Beginning Date");
    }
  };
  return loading ? (
    <Typography style={{ fontWeight: "bold" }}>
      Generating export file… it may take a few moments.
    </Typography>
  ) : (
    <div>
      <Typography variant="label" color="primary" sx={{ marginBottom: "20px" }}>
        {htmlParser(surveyName)}
      </Typography>
      <Typography variant="body1">
        Please note: Depending upon the type of connection you have and the
        amount of data entered for the survey, it may take a few moments to
        generate and download. We appreciate your patience.
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: "20px" }}>
        After the data generation period, you will be prompted to save the file
        in your own computer. The file name given should end with a .csv
        extension.
      </Typography>
      <Box component="fieldset">
        <legend sx={{ color: theme.palette.pink.main }}>
          <b>Survey Comments Export</b>
        </legend>
        <Grid container rowSpacing={1}>
          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Comment Type</Typography>
            </Grid>
            <Grid item xs={9} container>
              <FormControl>
                <Select
                  size="small"
                  multiple
                  sx={{ width: "300px", marginRight: "50px" }}
                  value={selectedComment}
                  onChange={handleCommentChange}
                >
                  {commentsResult.map((comment) => (
                    <MenuItem key={comment.id} value={comment.id}>
                      {comment.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a state</Typography>
            </Grid>
            <FormControl sx={{ width: "300px" }}>
              <Select
                size="small"
                multiple
                variant="outlined"
                value={stateIds}
                onChange={handleStateChange}
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.longName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a region</Typography>
            </Grid>
            <Grid item xs={9}>
              <FormControl>
                <Select
                  size="small"
                  multiple
                  sx={{ width: "300px" }}
                  value={regionIds}
                  onChange={handleRegionChange}
                >
                  {regionResult.map((region) => (
                    <MenuItem key={region.id} value={region.id}>
                      {region.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Beginning Date</Typography>
            </Grid>
            <Grid item xs={9} container alignItems="center">
              <DatePicker
                value={beginingDate}
                onChange={handleBeginingDate}
                height="small"
                sx={{ width: "300px", marginRight: "50px" }}
              />
              <Typography variant="body1" sx={{ margin: "0 20px 0 50px" }}>
                Ending Date
              </Typography>
              <DatePicker
                value={endingDate}
                onChange={handleEndingDate}
                height="small"
                sx={{ width: "300px" }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Select a survey status</Typography>
            </Grid>
            <FormControl sx={{ width: "300px" }}>
              <Select
                size="small"
                multiple
                variant="outlined"
                onChange={handleFilingChange}
                value={selectedFilingStatus}
              >
                {surveyFilingStatuses.map((status) => (
                  <MenuItem key={status.id} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">AHA ID</Typography>
            </Grid>
            <Grid item xs={3}>
              <TextField
                sx={{ width: "300px" }}
                size="small"
                name="customId"
                variant="outlined"
                value={ahaID}
                onChange={handleAhaIdChange}
              />
            </Grid>
            <Grid item xs={9}></Grid>
          </Grid>

          <Grid item xs={12} container alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body1">Type Of Download</Typography>
            </Grid>
            <RadioGroup
              row
              value={downloadType}
              onChange={handleDownloadTypeChange}
            >
              <FormControlLabel
                value="csv"
                control={<Radio size="small" value="csv" />}
                label="CSV"
              />
              <FormControlLabel
                value="selected"
                control={<Radio size="small" value="pdf" />}
                label="PDF"
              />
            </RadioGroup>
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                onClick={handleExport}
                disabled={loading}
                sx={{ marginRight: "20px" }}
              >
                EXPORT
              </Button>
              <Button
                variant="outlined"
                component="label"
                onClick={() => {
                  navigate("/");
                }}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      {msg && (
        <Typography
          color="primary"
          variant="body1"
          sx={{ fontWeight: "bold", marginTop: "15px" }}
        >
          {msg}
        </Typography>
      )}
      {errMsg && (
        <Typography
          color="error"
          variant="body1"
          sx={{ fontWeight: "bold", marginTop: "15px" }}
        >
          {errMsg}
        </Typography>
      )}
    </div>
  );
};

export default CommentsExport;
