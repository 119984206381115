import { Typography } from "@mui/material";
import React from "react";
import { htmlParser } from "../../Utility/constants";
import { useLocation } from "react-router-dom";
import Button from "../../components/Common/Button";

const PanelImport = () => {
  const location = useLocation();
  const surveyName = location?.state?.surveyName;

  return (
    <div>
      <Typography
        variant="label"
        color="primary"
        sx={{ marginBottom: "20px", display: "flex", alignItems: "center" }}
      >
        Import Panel for Survey: {htmlParser(surveyName)}
      </Typography>
      <Typography
        variant="body1"
        color="primary"
        sx={{ cursor: "pointer", marginBottom: "30px", marginTop: "20px" }}
      >
        Download Key File (Single File Format)
      </Typography>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "30px",
          marginTop: "20px",
        }}
      >
        <Button
          variant="contained"
          component="label"
          style={{ marginRight: "20px" }}
        >
          Select File
          <input type="file" hidden accept=".csv" />
        </Button>
        <Button variant="outlined" color="primary">
          Back
        </Button>
      </div>
    </div>
  );
};

export default PanelImport;
