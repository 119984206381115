import React, { useState, useEffect } from "react";
import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import Button from "../Common/Button";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import { useLocation } from "react-router-dom";
import {
  fetchPanelsByOrgId,
  setFilterFormData,
  setSelectedPanelId,
} from "../../pages/Survey/Survey.slice";
import { createPanel } from "../../pages/Survey/Survey.service";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
}));
const PanelMgtSidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const surveyPanels = useSelector((state) => state.survey.surveyPanels);
  const surveyPanelId = location?.state?.surveyPanelId;
  const [newPanelName, setNewPanelName] = useState("");
  const [isFolderDialogOpen, setPanelDialogOpen] = useState(false);
  const orgId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.organization?.id
  );
  const [isPanelNameError, setIsPanelNameError] = useState(false);
  const [defaultPanelId, setDefaultPanelId] = useState("");
  const createdBy = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const handleCreatePanel = async () => {
    setIsPanelNameError(false);
    if (!newPanelName.trim()) {
      setIsPanelNameError(true);
      return;
    }
    setPanelDialogOpen(false);

    const payload = {
      organizationId: orgId,
      name: newPanelName,
      createdBy: createdBy,
    };
    
    try {
      const response = await createPanel(payload);
      if (response?.status === 200) {
        dispatch(fetchPanelsByOrgId(orgId));
      }
    } catch (error) {
      console.log("Error creating Panel", error);
      return null;
    }
  };

  useEffect(() => {
    dispatch(fetchPanelsByOrgId(orgId));
  }, [dispatch, orgId]);

  // -------------------------------
  // Default the selected panel to the first panel in the list
  // 1. If the survey panel id of the selected survey is not belonging to the list
  // 2. survey panel id of the selected survey itself is empty

  useEffect(() => {
    const setPanel = (isPanelExist) => {
      let data = "";
      if (surveyPanelId !== undefined && isPanelExist) {
        data = surveyPanelId;
      } else {
        data = surveyPanels[0].id;
      }

      setDefaultPanelId(data);
    };
    if (surveyPanels?.length > 0) {
      let isPanelExist = false;
      if (surveyPanelId) {
        isPanelExist = surveyPanels.some((panel) => panel.id === surveyPanelId);
      }
      setPanel(isPanelExist);
    }
  }, [surveyPanels]);

  useEffect(() => {
    if (defaultPanelId) {
      dispatch(setSelectedPanelId(defaultPanelId));
    }
  }, [dispatch, defaultPanelId]);

  const handlePanelSelect = (event) => {
    const panelId = event.target.value;
    setDefaultPanelId(panelId);
    dispatch(setFilterFormData({}));
  };

  return (
    <div style={{ whiteSpace: "normal" }}>
      <div style={{ display: "flex", marginBottom: "10px" }}>
        <Button
          startIcon={<CreateNewFolderIcon />}
          onClick={() => {
            setPanelDialogOpen(true);
            setIsPanelNameError(false);
            setNewPanelName("");
          }}
        >
          Create Panel
        </Button>
      </div>

      <BootstrapDialog
        open={isFolderDialogOpen}
        onClose={() => setPanelDialogOpen(false)}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          <Typography variant="body1" fontWeight="bold">
            Create New Panel
          </Typography>
          <IconButton
            onClick={() => setPanelDialogOpen(false)}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <TextField
            value={newPanelName}
            onChange={(e) => setNewPanelName(e.target.value)}
            fullWidth
          />
          <Typography color="error" variant="label"></Typography>

          {isPanelNameError && (
            <Typography color="error" variant="body2" mt={1}>
              {isPanelNameError ? "Panel name is required" : ""}
            </Typography>
          )}
          <div style={{ paddingTop: "20px" }}>
            <Button onClick={handleCreatePanel}>Create</Button>
          </div>
        </DialogContent>
      </BootstrapDialog>
      <Typography color="primary" variant="label">
        Current list of survey panel(s) for AHA Annual Survey Organization
      </Typography>
      <Box sx={{ margin: "20px 0" }}>
        <FormControl fullWidth>
          <Select value={defaultPanelId} onChange={handlePanelSelect}>
            {surveyPanels?.map((panel) => (
              <MenuItem key={panel?.id} value={panel?.id}>
                {panel.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Typography color="primary">
        To work with another panel, please select from the list above.
      </Typography>
    </div>
  );
};

export default PanelMgtSidebar;
