import React, { useState, useEffect, useRef } from "react";
import { Grid, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/Common/Button";
import { setImportCurrentView } from "../../Survey/Survey.slice";
import { useLocation } from "react-router";
import { batchExceptions, findBatchById } from "../../Survey/Survey.service";
import { htmlParser } from "../../../Utility/constants";
import theme from "../../../theme";

const ErrorList = ({ errors }) => {
  const listItems = errors.map((error, index) => (
    <li key={index}>
      {"Record "}
      {error?.id} : {error?.exceptionType?.description}
    </li>
  ));
  return <ul>{listItems}</ul>;
};

const UploadBatchProgress = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const surveyName = location?.state?.surveyName;
  const selectedBatchId = useSelector((state) => state.survey.selectedBatchId);
  const [fileErrors, setFileErrors] = useState([]);
  const [summaryData, setSummaryData] = useState({});
  const [importComplete, setImportComplete] = useState(false);
  useEffect(() => {
    const findBatchExceptions = async () => {
      try {
        const res = await batchExceptions(summaryData.batchId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const responseData = res?.data;
          setFileErrors(responseData);
        }
      } catch (error) {
        console.error("Error fetching batch Exceptions:", error);
      }
    };
    if (summaryData && summaryData?.exceptionsRecordCount > 0) {
      findBatchExceptions();
    }
  }, [summaryData]);

  const getBatchData = async () => {
    try {
      const res = await findBatchById(selectedBatchId);
      if (res?.status === 200 && res?.data) {
        const responseData = res?.data;
        const batchData = {
          batchId: responseData["id"],
          currentlyProcessing: responseData["currentlyProcessing"],
          recordsProcessed: responseData["recordsProcessed"],
          validationErrorsRecordCount:
            responseData["validationErrorsRecordCount"],
          exceptionsRecordCount: responseData["exceptionsRecordCount"],
          endTime: responseData["endTime"],
        };
        setSummaryData(batchData);
      }
    } catch (error) {
      console.error("Error fetching batch data:", error);
    }
  };
  const endTimeRef = useRef(summaryData.endTime);

  useEffect(() => {
    endTimeRef.current = summaryData.endTime;
  }, [summaryData]);

  useEffect(() => {
    //Implementing the setInterval method
    const interval = setInterval(() => {
      if (endTimeRef.current) {
        clearInterval(interval);
        setImportComplete(true);
        return;
      }
      setSummaryData({});
      setFileErrors([]);
      getBatchData();
    }, 9000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [selectedBatchId]);
  return (
    <>
      <Typography variant="label" color="primary" sx={{ marginBottom: "30px" }}>
        Survey: {htmlParser(surveyName)}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={5}>
          <Typography
            variant="label"
            color="primary"
            sx={{ marginBottom: "20px" }}
          >
            Batch Progress Page
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => dispatch(setImportCurrentView(7))}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      {summaryData?.batchId && (
        <Grid container spacing={2} sx={{ marginTop: "20px" }}>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <b>BATCH ID</b>
            </Typography>
            <Typography variant="body1" color="primary">
              {summaryData.batchId}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <b> FILINGS UPLOADED</b>
            </Typography>
            <Typography variant="body1" color="primary">
              {summaryData.recordsProcessed}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle2">
              <b>CURRENTLY PROCESSING</b>
            </Typography>
            <Typography variant="body1" color="primary">
              {summaryData.currentlyProcessing
                ? summaryData.currentlyProcessing
                : "0"}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1">
              <b>Batch Exceptions</b>
            </Typography>
            <Typography variant="body1" color="primary">
              {summaryData.exceptionsRecordCount}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="subtitle2">
              <b>RECORDS WITH VALIDATION ERRORS</b>
            </Typography>
            <Typography variant="body1" color="primary">
              {summaryData.validationErrorsRecordCount}
            </Typography>
          </Grid>
        </Grid>
      )}
      {importComplete && (
        <>
          <Typography color="primary">IMPORT COMPLETE!</Typography>
          <Button
            color="primary"
            onClick={() => dispatch(setImportCurrentView(4))}
          >
            VIEW RESULTS
          </Button>
        </>
      )}
      {fileErrors && fileErrors?.length > 0 && (
        <>
          <Typography
            variant="subtitle2"
            color={theme.palette.error.main}
            sx={{ textDecoration: "underline", paddingTop: "10px" }}
          >
            BATCH Exceptions
          </Typography>
          <div>
            The following exceptions were found. Please correct the errors.
          </div>

          <Grid item xs={true}>
            {<ErrorList errors={fileErrors} />}
          </Grid>
        </>
      )}
      {!summaryData?.batchId && (
        <Typography variant="subtitle2" color="info">
          Refreshing Batch Summary...
        </Typography>
      )}
    </>
  );
};

export default UploadBatchProgress;
