import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Button from "../../components/Common/Button";
import theme from "../../theme";
const RuleHelpText = ({ handleClose }) => {
  const [jumpSection, setJumpSection] = useState(null);
  const handleClosePage = async (e) => {
    handleClose();
  };

  useEffect(() => {
    if (jumpSection) {
      document.getElementById(jumpSection).scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  }, [jumpSection]);
  const addMargin = {
    marginTop: "16px",
    marginBottom: "5px",
  };
  const leftMargin = {
    marginLeft: "15px",
  };
  return (
    <div className="panelModal">
      <div className="panelForm">
        <Typography variant="h5" color="primary" id="TopInstructions">
          Instructions for Composing Data Validation Rules and Page Display
          Rules
        </Typography>
        <Divider color={theme.palette.blue.main} />
        <ul>
          <li>
            <span
              onClick={() => setJumpSection("groupGeneral")}
              className="pointerLink"
            >
              General Instructions
            </span>
          </li>
          <li>
            <span
              className="pointerLink"
              onClick={() => setJumpSection("groupFrequentQ")}
            >
              Frequently Asked Questions
            </span>
          </li>
          <li>
            <span
              className="pointerLink"
              onClick={() => setJumpSection("groupLogical")}
            >
              Logical Operators
            </span>
          </li>
          <li>
            <span
              className="pointerLink"
              onClick={() => setJumpSection("groupMaths")}
            >
              Mathematical Operators
            </span>
          </li>
          <li>
            <span
              className="pointerLink"
              onClick={() => setJumpSection("groupFunctions")}
            >
              Functions
            </span>
          </li>
          <li>
            <span
              className="pointerLink"
              onClick={() => setJumpSection("groupVariables")}
            >
              Variables
            </span>
          </li>
        </ul>
        <Typography
          variant="h5"
          color="primary"
          style={addMargin}
          id="groupGeneral"
        >
          <span className="pointerLink">General Instructions</span>
        </Typography>
        <Typography variant="body2" paragraph color="primary">
          <span className="pointerLink">
            This editor allows you to create data validation and data processing
            rules.
          </span>
        </Typography>
        <Typography variant="body2" color="primary" id="groupFrequentQ">
          <span className="pointerLink">Frequently Asked Questions</span>
        </Typography>
        <Typography variant="body2" color="primary" sx={addMargin}>
          <u className="pointerLink">How do I get started?</u>
        </Typography>
        <Typography variant="body2" sx={leftMargin} color="primary" paragraph>
          <span className="pointerLink">
            It is highly recommended that you read through these instructions
            first. This rule creator was designed to be as flexible as possible
            to allow for both simple and complex rules. As a result, it has a
            "free form" aspect to it that might leave you wondering how to start
            composing rules. Reading through these instructions should
            familiarize you with the the different tools you can use in creating
            your rules. If you still have questions, please call the support
            number for assistance.
          </span>
        </Typography>
        <Typography variant="body2" color="primary" sx={addMargin}>
          <u className="pointerLink">What are data validation rules?</u>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Data validation rules are like edit checks. They allow you to check
            a user's answers as they are entered. If the conditions of a rule
            are met, a message will be displayed to the user. You can then
            either require the user to correct their answer, or simply allow
            them to confirm their answer by either clicking on a confimation box
            or typing in an explanation.
          </span>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Example: You are asking for the number of days a business was open
            during their last fiscal year, and you want to require them to
            correct their answer if it is greater than 365 (or 366 in the case
            of a leap year). You could then create a validation rule for this.
          </span>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Example: You are asking for financial information and want the user
            to confirm their data if expenses exceed revenue.
          </span>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Example: You list out the most popular fast food restaurants and ask
            the user to select those that they would like to have but that are
            not nearby. When the user saves their answers you want to tally up
            the number of restaurants they selected, and if it's greater than 5,
            display the message, "Ever consider moving?" and have the user
            correct or confirm their answer. You can do this with a validation
            rule.
          </span>
        </Typography>
        <Typography variant="body2" color="primary" sx={addMargin}>
          <u className="pointerLink">What are data processing rules?</u>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Data processing "rules" are pieces of logic that manipulate user
            data behind the scenes and do not display any messages to the user.
          </span>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Example: If the user selected 'X' for question 1, you want to
            automatically set their answer for question 2 to 'Y'. You can do
            this with a data processing rule.
          </span>
        </Typography>
        <Typography variant="body2" paragraph color="primary" sx={addMargin}>
          <u className="pointerLink">
            What are the differences between data validation rules and data
            processing rules?
          </u>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            The main difference is simply that a validation rule should always
            evaluate to "true" or "false". If the expression evaluates to "true"
            based on the user's answers, an error message will be displayed to
            the user. Data processing rules do not display anything to the user
            and simply modify their data as specified by the rule.
          </span>
        </Typography>
        <Typography variant="body2" color="primary" sx={addMargin}>
          <u className="pointerLink">
            What does it mean if I try to save a rule and get a parser error?
          </u>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            The parser is what checks your rule to make sure its syntax is
            correct. If you get an error, it means that there is an error in
            your syntax. Check that:
          </span>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul>
            <li>
              You are passing the correct number of arguments to any functions.
            </li>
            <li>
              The data types of those arguments are what the functions are
              expecting.
            </li>
            <li>
              Make certain you have a closing parenthases for every open
              parenthases.
            </li>
          </ul>
        </Typography>
        <Typography variant="body2" paragraph color="primary" sx={addMargin}>
          <u className="pointerLink">What does the '~' stand for?</u>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            The tilda ('~') is automatically used in front of variable names to
            indicate to the validation rule engine that what follows is a
            variable. Because of the special significance of the tilda to the
            validation rule engine, it may not be used in any literal values.
          </span>
        </Typography>
        <Typography variant="body2" paragraph color="primary" sx={addMargin}>
          <u className="pointerLink">
            Can I use functions as arguments in other functions?
          </u>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            Yes. Just make sure the data type (string, number, boolean) returned
            by the nested function matches the expected data type of the
            argument the function is replacing.
          </span>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupLogical"
        >
          <b className="pointerLink">Logical Operators</b>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <span className="pointerLink">
            The following logical operators are available when composing
            validation or data processing rules:
          </span>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <ul>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupEqual")}
              >
                =(equals)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupNotEqual")}
              >
                != (not equal to)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupGreaterThan")}
              >
                {">"}(greater than)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupGreaterThanEqual")}
              >
                {">"}=(greater than or equal to)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupLessThan")}
              >
                {"<"}(less than)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupLessThanEqual")}
              >
                {"<"}=(less than or equal to)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupIsNull")}
              >
                = null (is null)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupIsNotNull")}
              >
                != null (is not null)
              </span>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupEqual"
        >
          <b className="pointerLink" style={leftMargin}>
            =(equals)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The = operator is used to test if two values are
              identical.
            </li>
            <li>
              Required syntax:
              <i>field value or literal = field value or literal</i>
            </li>
            <li>
              Example 1: ~fv1 = "apple", where ~fv1 is "apple" --
              <i>returns true</i>
            </li>
            <li>
              Example 2: ~fv1 = "apple", where ~fv1 is "Apple" --
              <i>returns false (string comparisons are case sensitive)</i>
            </li>
            <li>
              Example 3: ~fv1 = "apple", where ~fv1 is "crabapple" --
              <i>returns false (~fv1 is not exactly equal to "apple")</i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupNotEqual"
        >
          <b className="pointerLink" style={leftMargin}>
            !=(not equal to)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The != operator is used to test if two values are not
              identical.
            </li>
            <li>
              Required syntax:
              <i>field value or literal != field value or literal</i>
            </li>
            <li>
              Example 1: ~fv1 != "apple", where ~fv1 is "apple" --
              <i>returns false (the strings are identical)</i>
            </li>
            <li>
              Example 2: ~fv1 != "apple", where ~fv1 is "Apple" --
              <i>returns true (string comparisons are case sensitive)</i>
            </li>
            <li>
              Example 3: ~fv1 != "apple", where ~fv1 is "crabapple" --
              <i>returns true (~fv1 is not exactly equal to "apple")</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupGreaterThan"
        >
          <b className="pointerLink" style={leftMargin}>
            {">"}(greater than)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The {">"} operator allows you to test if one value is
              greater than another.
            </li>
            <li>
              Required syntax:
              <i>field value or literal {">"} field value or literal</i>
            </li>
            <li>
              Example 1: 3 {">"} 2 -- <i>returns true</i>
            </li>
            <li>
              Example 2: "1000" {">"} "2", --
              <i>
                returns false, since the numbers are being treated as strings,
                and "1" comes before "2" in the alphanumeric alphabet.
              </i>
            </li>
            <li>
              Example 3: ~fv1 {">"} ~fv2, where ~fv1 = 4 and ~fv2 = 2 --
              <i>returns true</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupGreaterThanEqual"
        >
          <b className="pointerLink" style={leftMargin}>
            {">"}=(greater than or equal to)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The {">"}= operator allows you to test if one value
              is greater than or equal to another.
            </li>
            <li>
              Required syntax:
              <i> field value or literal {">"}= field value or literal</i>
            </li>
            <li>
              Example 1: 3 {">"}=2 -- <i>returns true</i>
            </li>
            <li>
              Example 2: 3 {">"}=3, --
              <i>returns true</i>
            </li>
            <li>
              Example 3: 3 {">"}=4, --
              <i>returns false</i>
            </li>
            <li>
              Example 4: "1000" {">"}= "2", --
              <i>
                returns false, since the numbers are being treated as strings,
                and "1" comes before "2" in the alphanumeric alphabet.
              </i>
            </li>
            <li>
              <span className="pointerLink">
                Example 5: ~fv1 {">"}= ~fv2, where ~fv1 = 4 and ~fv2 = 2 --
                <i>returns true</i>
              </span>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupLessThan"
        >
          <b className="pointerLink" style={leftMargin}>
            {"<"}(less than)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The {"<"} operator allows you to test if one value is
              less than another.
            </li>
            <li>
              Required syntax:
              <i>field value or literal {"<"} field value or literal</i>
            </li>
            <li>
              Example 1: 2 {"<"} 3 -- <i>returns true</i>
            </li>
            <li>
              Example 2: 3 {"<"} 3 -- <i>returns false</i>
            </li>
            <li>
              Example 3: "1000" {"<"} "2", --
              <i>
                returns true, since the numbers are being treated as strings,
                and "1" comes before "2" in the alphanumeric alphabet.
              </i>
            </li>
            <li>
              Example 4: ~fv1 {"<"} ~fv2, where ~fv1 = 2 and ~fv2 = 4 --
              <i>returns true</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupLessThanEqual"
        >
          <b className="pointerLink" style={leftMargin}>
            {"<"}=(less than or equal to)
          </b>
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <ul style={{ listStyleType: "none" }} className="pointerLink">
            <li>
              Description: The {"<"}= operator allows you to test if one value
              is less than or equal to another.
            </li>
            <li>
              Required syntax: field value or literal {"<"}= field value or
              literal
            </li>
            <li>
              Example 1: 1 {"<"}= 2 -- <i>returns true</i>
            </li>
            <li>
              Example 2: 2 {"<"}= 2 -- <i>returns true</i>
            </li>
            <li>
              Example 3: 3 {"<"}= 2 -- <i>returns false</i>
            </li>
            <li>
              Example 4: "1000" {"<"}= "2", --
              <i>
                returns true, since the numbers are being treated as strings,
                and "1" comes before "2" in the alphanumeric alphabet.
              </i>
            </li>
            <li>
              Example 5: ~fv1 {"<"}= ~fv2, where ~fv1 = 2 and ~fv2 = 4 --
              <i>returns true</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupIsNull"
        >
          <b className="pointerLink" style={leftMargin}>
            = null (is null)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The = null operator allows you to test if a field
              value is null (has no answer).
            </li>
            <li>
              Required syntax: field value <i>= null</i>
            </li>
            <li>
              Example 1: ~fv1 = null, where field 1 has no answer in it --
              <i>returns true</i>
            </li>
            <li>
              Example 2: ~fv1 = null, where field 1 has a value of "apple" --
              <i>returns false</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupIsNotNull"
        >
          <b className="pointerLink" style={leftMargin}>
            != null (is not null)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The != null operator allows you to test if a field
              value is not null (has an answer).
            </li>
            <li>
              Required syntax: <i>field value != null</i>
            </li>
            <li>
              Example 1: ~fv1 != null, where field 1 has no answer in it --
              <i>returns false</i>
            </li>
            <li>
              Example 2: ~fv1 != null, where field 1 has a value of "apple" --
              <i>returns true</i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupMaths"
        >
          <b className="pointerLink">Mathematical Operators</b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          The following mathematical operators are available when composing
          validation or data processing rules:
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <ul>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupAddition")}
              >
                + (addition)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupSubtraction")}
              >
                - (subtraction)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupMultiplication")}
              >
                * (multiplication)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupDivision")}
              >
                / (division)
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupAssignment")}
              >
                := (assignment operator)
              </span>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupAddition"
        >
          <b className="pointerLink" style={leftMargin}>
            + (addition)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The addition operator allows you to add two numbers
              together.
            </li>
            <li>
              Required syntax: <i>number + number</i>
            </li>
            <li>
              Example 1: 1 + 2 -- <i>returns the number 3</i>
            </li>
            <li>
              Example 2: ~fv1 + 1, where ~fv1 = 4 -- <i>returns the number 5</i>
            </li>
            <li>
              Example 3: ~fv1 + ~fv2, where ~fv1 = 4 and ~fv2 = 3 --
              <i>returns the number 7</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupSubtraction"
        >
          <b className="pointerLink" style={leftMargin}>
            - (subtraction)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The subtraction operator allows you to subtract one
              number from another.
            </li>
            <li>
              Required syntax: <i>number - number</i>
            </li>
            <li>
              Example 1: 3 - 2 -- <i>returns the number 1</i>
            </li>
            <li>
              Example 2: ~fv1 - 1, where ~fv1 = 4 -- <i>returns the number 3</i>
            </li>
            <li>
              Example 3: ~fv1 - ~fv2, where ~fv1 = 4 and ~fv2 = 2 --
              <i>returns the number 2</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupMultiplication"
        >
          <b className="pointerLink" style={leftMargin}>
            * (multiplication)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The multiplication operator allows you to multiply
              two numbers together.
            </li>
            <li>
              Required syntax: <i>number * number</i>
            </li>
            <li>
              Example 1: 3 * 2 -- <i>returns the number 6</i>
            </li>
            <li>
              Example 2: ~fv1 * 2, where ~fv1 = 4 -- <i>returns the number 8</i>
            </li>
            <li>
              Example 3: ~fv1 * ~fv2, where ~fv1 = 4 and ~fv2 = 2 --
              <i>returns the number 8</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupDivision"
        >
          <b className="pointerLink" style={leftMargin}>
            / (division)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The division operator allows you to divide one number
              by another.
            </li>
            <li>
              Required syntax: <i>number / number</i>
            </li>
            <li>
              Example 1: 6 / 2 -- <i>returns the number 3</i>
            </li>
            <li>
              Example 2: ~fv1 / 2, where ~fv1 = 8 -- <i>returns the number 4</i>
            </li>
            <li>
              Example 3: ~fv1 / ~fv2, where ~fv1 = 3 and ~fv2 = 2 --
              <i>returns the number 1.5</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupAssignment"
        >
          <b className="pointerLink" style={leftMargin}>
            := (assignment operator)
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The assignment operator is used to set the value of
              fields and variables.
            </li>
            <li>
              Required syntax: field value or variable :=
              <i>value to be assigned</i>
            </li>
            <li>
              Example 1: ~fv1 :=
              <i>"apple" -- sets the value of field 1 to "apple".</i>
            </li>
            <li>
              Example 2: ~fv1 := 4 --
              <i>sets the value of field 1 to the number 4.</i>
            </li>
            <li>
              Example 3: ~v1 := 0 --
              <i>sets the value of variable 1 to the number 0.</i>
            </li>
            <li>
              Example 4: ~v1 := ~v1 + 1 --
              <i>increments the value of variable 1 by one.</i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupFunctions"
        >
          <b className="pointerLink">Functions</b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          The following functions are available when composing validation or
          data processing rules:
        </Typography>
        <Typography variant="body2" paragraph sx={leftMargin} color="primary">
          <ul>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupContains")}
              >
                contains
              </span>
            </li>
            <li
              className="pointerLink"
              onClick={() => setJumpSection("groupIfthenelse")}
            >
              ifthenelse
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupLength")}
              >
                length
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupLower")}
              >
                lower
              </span>
            </li>
            <li
              className="pointerLink"
              onClick={() => setJumpSection("groupMod")}
            >
              mod
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupNotcontains")}
              >
                notcontains
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupTodecimal")}
              >
                todecimal
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupTointeger")}
              >
                tointeger
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupTostring")}
              >
                tostring
              </span>
            </li>
            <li>
              <span
                className="pointerLink"
                onClick={() => setJumpSection("groupUpper")}
              >
                upper
              </span>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupContains"
        >
          <b className="pointerLink" style={leftMargin}>
            contains
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The "contains" function searches one string for
              another string (a substring).
            </li>
            <li>
              Required syntax:
              <i>
                contains ( "string1" , "substring to search for in string1" )
              </i>
            </li>
            <li>
              Example 1: contains ( "cranapple" , "apple" ) --
              <i>
                searches for the string "apple" in "cranapple", it is found, so
                expression evaluates to "true" and message would be displayed to
                user
              </i>
            </li>
            <li>
              Example 2: contains ( ~fv1 , "apple" ) --
              <i>
                searches for the string "apple" in what the user answered in
                field 1 (~fv stands for field value 1)
              </i>
            </li>
            <li>
              Example 3: contains ( ~fv1 , ~fv2 ) --
              <i>
                searches for user's answer from field 2 in user's answer from
                field 1
              </i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupIfthenelse"
        >
          <b className="pointerLink" style={leftMargin}>
            ifthenelse
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: ifthenelse allows you to perform one action if a
              condition is met, otherwise perform another action. Note: it is
              not required to perform an <i>else</i> action. You can substitute
              the word null for the else action.
            </li>
            <li>
              Required syntax:
              <i>
                ifthenelse ( condtion that will evaluate to either true or false
                , action to perform if true, action to perform if false )
              </i>
            </li>
            <li>
              or, if you do not want to do anything if the condition evaluates
              to false:
              <i>
                ifthenelse ( condtion that will evaluate to either true or false
                , action to perform if true, null )
              </i>
            </li>
            <li>
              Example 1: ifthenelse ( 1 = 1, ~fv1 := 0 , ~fv1 := 1 ) --
              <i>
                tests to see if 1=1. This of course evaluates to true and so
                field value 1 is set to one. If 1=1 had evaluated to false
                (which is impossible, of course) field value 1 would have
                instead be set to zero.
              </i>
            </li>
            <li>
              Example 2: ifthenelse ( ~fv1 {">"} 365 , ~fv1 := 365 , null ) --
              <i>
                if field value 1 is greater than 365, it is automatically
                changed to 365. Otherwise, if field value 1 is already 365 or
                less, nothing is done.
              </i>
            </li>
            <li>
              Example 3: ifthenelse ( contains ( ~fv1 , "apple" ) , ~fv2 := 1 ,
              ~fv2 := 0 ) --
              <i>
                if field value 1 contains the string "apple", set field value 2
                to one, otherwise set field value 2 to zero
              </i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupLength"
        >
          <b className="pointerLink" style={leftMargin}>
            length
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The length function returns the number of characters
              in a field value or literal.
            </li>
            <li>
              Required syntax: length <i>( field value or literal )</i>
            </li>
            <li>
              Example 1: length ( "apple" ) --
              <i>returns the number 5, since "apple" is five characters long</i>
            </li>
            <li>
              Example 2: length ( ~fv1 ) --
              <i>
                returns the number of characters in field value 1. If the user
                answered "John Smith" in field 1, the length function would
                return the number 10, since "John Smith" contains ten characters
                (the space is included in the count)
              </i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupLower"
        >
          <b className="pointerLink" style={leftMargin}>
            lower
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The lower function converts a field value or literal
              to all lowercase letters.
            </li>
            <li>
              Required syntax: <i>lower (field value or literal)</i>
            </li>
            <li>
              Example 1: lower ( "YES" ) -- <i>returns the string "yes"</i>
            </li>
            <li>
              Example 2: lower ( "yEs" ) -- <i>returns the string "yes"</i>
            </li>
            <li>
              Example 3: lower ( ~fv1 ) --
              <i>
                converts field value 1 to all lowercase. If field value 1 was
                "John Smith", it would return "john smith".
              </i>
            </li>
          </ul>
        </Typography>

        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupMod"
        >
          <b className="pointerLink" style={leftMargin}>
            mod
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The mod function returns the remainder when one
              number is divided by another.
            </li>
            <li>
              Required syntax: <i>mod ( dividend , divisor )</i>
            </li>
            <li>
              Example 1: mod ( 10 , 2 ) --
              <i>returns the number 0, since 10/2 = 5 (with no remainder).</i>
            </li>
            <li>
              Example 2: mod ( 9 , 2 ) --
              <i>
                returns the number 1, since 9/2 results in a remainder of one.
              </i>
            </li>
            <li>
              Example 3: mod ( ~fv2 , 10 ) --
              <i>returns the remainder when field value 2 is divided by ten.</i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupNotcontains"
        >
          <b className="pointerLink" style={leftMargin}>
            notcontains
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The notcontains function searches one string for
              another string (a substring), and evaluates to true if the
              substring is not found.
            </li>
            <li>
              Required syntax:
              <i>
                notcontains ( "string1" , "substring to search for in string1" )
              </i>
            </li>
            <li>
              Example 1: contains ( "cranapple" , "apple" ) --
              <i>
                searches for the string "apple" in "cranapple", it is found, so
                expression evaluates to "false" and message would not be
                displayed to user
              </i>
            </li>
            <li>
              Example 2: contains ( "orange" , "apple" ) --
              <i>
                searches for the string "apple" in "orange", it is not found, so
                expression evaluates to "true" and message would be displayed to
                user
              </i>
            </li>
            <li>
              Example 3: contains ( ~fv1 , ~fv2 ) --
              <i>
                searches for user's answer from field 2 in user's answer from
                field 1
              </i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupTodecimal"
        >
          <b className="pointerLink" style={leftMargin}>
            todecimal
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The todecimal function converts a numeric string to a
              decimal. Since all answers are stored as strings, this function
              can be used to convert those numeric strings to actual numbers
              before performing any mathematical operations or number
              comparisons. Please note that an error will be returned if the
              string cannot be converted to a decimal.
            </li>
            <li>
              Required syntax:
              <i>todecimal ( field value or numeric literal )</i>
            </li>
            <li>
              Example 1: todecimal ( "123.45" ) --
              <i>returns the number 123.45</i>
            </li>
            <li>
              Example 2: todecimal ( "123.456" ) --
              <i>returns the number 123.456</i>
            </li>
            <li>
              Example 3: todecimal ( ~fv2 ), where ~fv2 = "10.2" --
              <i>returns 10.2</i>
            </li>
            <li>
              Example 4: todecimal ( ~fv2 ), where ~fv2 = "apple" --
              <i>
                returns an error, since "apple" cannot be converted to a number
              </i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupTointeger"
        >
          <b className="pointerLink" style={leftMargin}>
            tointeger
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The tointeger function converts a numeric string to
              an integer. Since all answers are stored as strings, this function
              can be used to convert those numeric strings to actual numbers
              before performing any mathematical operations or number
              comparisons. Please note that an error will be returned if the
              string cannot be converted to an integer.
            </li>
            <li>
              Required syntax:
              <i>tointeger ( field value or numeric literal )</i>
            </li>
            <li>
              Example 1: tointeger ( "123" ) -- <i>returns the number 123</i>
            </li>
            <li>
              Example 2: tointeger ( ~fv2 ), where ~fv2 = "10" --
              <i>returns the number 10</i>
            </li>
            <li>
              Example 3: tointeger ( "123.45" ) --
              <i>
                returns an error, since "123.45" cannot be converted to an
                integer.
              </i>
            </li>
            <li>
              Example 4: tointeger ( ~fv2 ), where ~fv2 = "10.2" --
              <i>
                returns an error, since "10.2" cannot be converted to an
                integer.
              </i>
            </li>
            <li>
              Example 5: tointeger ( ~fv2 ), where ~fv2 = "apple" --
              <i>
                returns an error, since "apple" cannot be converted to an
                integer.
              </i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupTostring"
        >
          <b className="pointerLink" style={leftMargin}>
            tostring
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The tostring function converts any field value or
              number to a string.
            </li>
            <li>
              Required syntax: <i>tostring ( field value or number )</i>
            </li>
            <li>
              Example 1: <i>tostring ( 123 ) -- returns the string "123"</i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupUpper"
        >
          <b className="pointerLink" style={leftMargin}>
            upper
          </b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          <ul style={{ listStyleType: "none" }}>
            <li>
              Description: The upper function converts a field value or literal
              to all uppercase letters.
            </li>
            <li>
              Required syntax: <i>upper (field value or literal)</i>
            </li>
            <li>
              Example 1: lower ( "yes" ) -- <i>returns the string "YES"</i>
            </li>
            <li>
              Example 2: lower ( "yEs" ) -- <i>returns the string "YES"</i>
            </li>
            <li>
              Example 3: lower ( ~fv1 ) --
              <i>
                converts field value 1 to all lowercase. If field value 1 was
                "John Smith", it would return "JOHN SMITH".
              </i>
            </li>
          </ul>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          color="primary"
          sx={addMargin}
          id="groupVariables"
        >
          <b className="pointerLink">Variables</b>
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          Ten variables (v1-v10) are available for your use while executing the
          validation rules for any particular page, section or survey.
        </Typography>
        <Typography
          variant="body2"
          paragraph
          sx={leftMargin}
          color="primary"
          className="pointerLink"
        >
          Variables can be used in a variety of ways. For instance, you may want
          to use a variable as a counter. If you had a series of checkbox
          questions on a particular page, you may want to check each question to
          see if at least one checkbox is checked. If it is, you'd increment the
          variable by one. Then, after checking all of the questions in such a
          fashion, you could display an error message to the user if the number
          questions answered (stored in the variable) is less than a certain
          number.
        </Typography>

        <Grid container rowSpacing={1} alignItems="right">
          <Grid item xs={12} container>
            <Grid item xs={5}></Grid>
            <Grid item xs={2}>
              <Button variant="contained" onClick={() => handleClosePage()}>
                Close
              </Button>{" "}
              <Button
                variant="contained"
                onClick={() => setJumpSection("TopInstructions")}
              >
                Move Up
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default RuleHelpText;
