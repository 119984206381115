import React, { useState, useEffect } from "react";
import { Typography, Grid, Box, IconButton, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import CancelIcon from "@mui/icons-material/Cancel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import {
  setImportCurrentView,
  setImportPastBatchResult,
  setSelectedBatchId,
  setSelectedPanelMemberData,
} from "../../Survey/Survey.slice";
import theme from "../../../theme";
import Button from "../../../components/Common/Button";
import {
  deleteFilingsInBatch,
  batchExceptions,
  getPanelMemberAssignment,
} from "../../Survey/Survey.service";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";
import { formatDate, htmlParser } from "../../../Utility/constants";
import PanelAssignment from "./PanelAssignment";
import PanelMember from "../../PanelManagement/PanelMember";

const BatchSummary = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;
  const summaryData = useSelector(
    (state) => state.survey.importPastBatchResult
  );

  const [confirmHelperTitle, setConfirmHelperTitle] = useState(null);
  const [confirmHelperOpen, setConfirmHelperOpen] = useState(false);
  const [fileErrors, setFileErrors] = useState([]);
  const [showSurvey, setShowSurvey] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});
  const [surveyRespondentId, setSurveyRespondentId] = useState(null);
  const [openPanelModal, setOpenPanelModal] = useState(false);
  const [refreshExceptions, setRefreshExceptions] = useState(false);

  useEffect(() => {
    return () => {
      dispatch(setSelectedBatchId(null));
    };
  }, []);

  useEffect(() => {
    const getPanelMemberInfo = async () => {
      try {
        const response = await getPanelMemberAssignment(
          surveyRespondentId,
          surveyId
        );
        if (response?.status === 200) {
          dispatch(setSelectedPanelMemberData(response?.data));
          setOpenPanelModal(true);
        }
      } catch (error) {
        console.log("Error deleting batch", error);
        return null;
      }
    };
    if (surveyRespondentId) {
      setRefreshExceptions(false);
      getPanelMemberInfo();
    }
  }, [surveyRespondentId]);

  const ErrorList = ({ errors }) => {
    const listItems = errors.map((error, index) => (
      <li key={index}>
        {"Record "}
        {error?.id} : {error?.exceptionType?.description}
        {error?.surveyRespondentId && (
          <>
            {" - "}
            <Typography
              variant="body2"
              component="span"
              sx={{
                cursor: "pointer",
                color: theme.palette.primary.main,
                textDecoration: "underline",
              }}
              onClick={() => setSurveyRespondentId(error?.surveyRespondentId)}
            >
              Edit Panel Member
            </Typography>
          </>
        )}
      </li>
    ));
    return <ul>{listItems}</ul>;
  };
  useEffect(() => {
    const findBatchExceptions = async () => {
      try {
        const res = await batchExceptions(summaryData.batchId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const responseData = res?.data;
          setFileErrors(responseData);
        }
      } catch (error) {
        console.error("Error fetching batch Exceptions:", error);
      }
    };
    if (summaryData || refreshExceptions) {
      findBatchExceptions();
    }
  }, [summaryData, refreshExceptions]);

  const handleClosePanelModal = () => {
    setOpenPanelModal(false);
    setSurveyRespondentId(null);
    setRefreshExceptions(true);
  };

  const handleDeleteBatch = async () => {
    try {
      const response = await deleteFilingsInBatch(summaryData.batchId);
      if (response?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg(response.data);
        setAlertSeverity("success");

        setConfirmHelperOpen(false);
        setTimeout(() => {
          dispatch(setImportCurrentView(4));
        }, 1000);
      }
    } catch (error) {
      console.log("Error deleting batch", error);
      return null;
    }
  };

  const openDeleteFiling = async () => {
    setConfirmHelperTitle("Deleting batch... Please press YES to proceed.");
    setConfirmHelperOpen(true);
  };
  const viewFilings = async () => {
    const payload = {
      surveyId: surveyId,
      batchId: summaryData.batchId,
      stateIds: null,
      customIds: "",
    };
    setSearchPayload(payload);
    setShowSurvey(true);
  };
  const toggleList = () => {
    setSearchPayload({});
    setShowSurvey(false);
  };
  return (
    <>
      {!showSurvey && (
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Typography
              variant="label"
              color="primary"
              sx={{ marginBottom: "50px" }}
            >
              {htmlParser(surveyName)}
            </Typography>
          </Grid>

          <Grid item xs={2} xl={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => viewFilings()}
            >
              VIEW FILINGS
            </Button>
          </Grid>
          <Grid item xs={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                dispatch(setImportCurrentView(4));
                dispatch(setImportPastBatchResult(null));
                dispatch(setSelectedBatchId(null));
              }}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      )}

      {summaryData && !showSurvey && (
        <>
          <Box sx={{ marginBottom: "15px" }}>
            <Typography variant="body1">
              <b>Batch Id:</b> {summaryData.batchId}
            </Typography>
            <Typography variant="body1">
              <b>File Name:</b> {summaryData.fileName}
            </Typography>
            <Typography variant="body1">
              <b>Start Date:</b> {formatDate(summaryData.startDate)}
            </Typography>
            <Typography variant="body1">
              <b>Records Uploaded:</b> {summaryData.recordsUploaded}
            </Typography>
            <Typography variant="body1">
              <b>Record Exceptions:</b> {summaryData.recordExceptions}
            </Typography>
            <Typography variant="body1">
              <b>Record Validation Errors: </b>
              {summaryData.recordValidationErrors}
            </Typography>
            <Typography variant="body1">
              <b>Uploaded By:</b> {summaryData.uploadedBy}
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", alignItems: "center", marginBottom: "20px" }}
          >
            <Typography
              variant="body1"
              color={theme.palette.error.main}
              sx={{ marginRight: "50px" }}
            >
              Delete all Filings in batch {summaryData.batchId}
            </Typography>
            <IconButton onClick={openDeleteFiling}>
              <CancelIcon
                sx={{
                  color: theme.palette.error.main,
                }}
              />
            </IconButton>
          </Box>
          <Box sx={{ marginBottom: "10px" }}>
            {fileErrors && fileErrors?.length > 0 && (
              <>
                <Typography
                  variant="subtitle1"
                  color={theme.palette.error.main}
                  sx={{ textDecoration: "underline", paddingTop: "5px" }}
                >
                  Batch Exceptions
                </Typography>
                <div>
                  The following exceptions were found. Please correct the
                  errors.
                </div>

                <Grid item xs={true}>
                  {<ErrorList errors={fileErrors} />}
                </Grid>
              </>
            )}
            {!fileErrors && (
              <Typography variant="body1">
                There are no batch exceptions for this batch
              </Typography>
            )}
          </Box>
        </>
      )}

      <Modal open={openPanelModal} onClose={handleClosePanelModal}>
        <div className="panelModal">
          <div className="panelDiv">
            <Typography variant="label">Panel Member</Typography>
          </div>
          <PanelMember handleClosePanel={handleClosePanelModal} />
        </div>
      </Modal>
      {showSurvey && (
        <>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginLeft: "40%" }}
            onClick={() => toggleList()}
          >
            Back
          </Button>
          <div>
            <PanelAssignment
              selectedBatch={summaryData.batchId}
              searchPayload={searchPayload}
            />
          </div>
        </>
      )}

      <ConfirmDialog
        title={confirmHelperTitle}
        open={confirmHelperOpen}
        setOpen={setConfirmHelperOpen}
        onConfirm={handleDeleteBatch}
      ></ConfirmDialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default BatchSummary;
