import React, { useEffect } from "react";
import { Typography } from "@mui/material";
import Button from "../../components/Common/Button";
const SurveyHelpText = ({ handleClose, jumpSection }) => {
  const addMargin = {
    marginTop: "16px",
    marginBottom: "5px",
  };

  useEffect(() => {
    if (jumpSection) {
      document.getElementById(jumpSection).scrollIntoView({
        block: "start",
        inline: "nearest",
        behavior: "smooth",
      });
    }
  }, [jumpSection]);

  const handleClosePage = async (e) => {
    handleClose();
  };
  return (
    <div className="panelModal">
      <div className="panelForm">
        <Typography className="linkRegularText" id="groupTitle">
          GROUP TITLE
        </Typography>
        <Typography variant="body1">
          The group title is used to identify a group of questions. This field
          is required.
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          GROUP DESCRIPTION
        </Typography>
        <Typography variant="body1">
          The group description is displayed directly above any question or
          answer column headings. It spans across the entire length of the page,
          and is not bolded.
        </Typography>
        <Typography variant="body1">This field is not required.</Typography>
        <Typography className="linkRegularText" style={addMargin}>
          GROUP HELP TEXT
        </Typography>
        <Typography variant="body1">
          The group help text opens in a separate window when the user clicks on
          a small green question mark displayed next to the group title.
        </Typography>
        <Typography variant="subtitle2" color="primary" style={addMargin}>
          QUESTION COLUMN HEADING
        </Typography>
        <Typography variant="body1">
          The question column heading is displayed in bold above the first
          question in a group of questions.
        </Typography>
        <Typography variant="body1">
          The question column heading is optional.
        </Typography>
        <Typography variant="subtitle2" color="primary" style={addMargin}>
          ANSWER COLUMN HEADING
        </Typography>
        <Typography variant="body1">
          The answer column heading is displayed in bold above the first answer
          field in a group of questions.
        </Typography>
        <Typography variant="body1">
          The answer column heading is optional.
        </Typography>
        <Typography variant="subtitle2" color="primary" style={addMargin}>
          QUESTION COLUMN WIDTH %
        </Typography>
        <Typography variant="body1">
          Percent of the total page width used for the question column. If this
          field is left blank the question column width percent defaults to 50%
        </Typography>
        <Typography variant="subtitle2" color="primary" style={addMargin}>
          ANSWER COLUMN WIDTH %
        </Typography>
        <Typography variant="body1">
          Percent of the total page width used for the answer column. If this
          field is left blank the answer column width percent defaults to 50%.
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          BORDER SIZE
        </Typography>
        <Typography variant="body1">
          Enter a number in this field if you would like a border displayed
          around the group of questions. The greater the number, the thicker the
          border.
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          RESPONSE OPTION
        </Typography>
        <Typography variant="body1">
          If you select 'Multiple Sessions', the user will be able to create a
          login and password that will enable them to save their work and return
          at a later time.
        </Typography>
        <Typography variant="body1">
          If you select 'Single Session', the user will not be given the option
          of creating a login and password. They will need to complete their
          survey within a single session.
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          ALLOW RESPONDENTS TO SET UP HELPER USERS?
        </Typography>
        <Typography variant="body1">
          If you select 'Yes', the respondent will be able to create logins and
          passwords for additional users to help in filling out the survey.
          These additional users will not be able to take surveys of their own.
          They will simply be able to access the survey of the respondent who
          created them.
        </Typography>
        <Typography variant="body1">
          If you select 'No', the respondent will not be given the option of
          creating additional users to help in filling out the survey.
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          ALLOW RESPONDENTS TO VIEW THE SURVEY RESULTS?
        </Typography>
        <Typography variant="body1">
          If you select 'Yes', the respondent will be able to view the current
          results of the survey.
        </Typography>
        <Typography
          className="linkRegularText"
          style={addMargin}
          id="logintext"
        >
          TEXT TO DISPLAY ON LOGIN PAGE
        </Typography>
        <Typography variant="body1">
          This text will display on the righthand side of the login page
        </Typography>
        <Typography
          className="linkRegularText"
          style={addMargin}
          id="thankyoumsg"
        >
          THANK YOU MESSAGE
        </Typography>
        <Typography variant="body1">
          This text will display on the page displayed when a respondent
          completes the survey.
        </Typography>
        <Typography className="linkRegularText" style={addMargin} id="emailadd">
          EMAIL ADDRESS
        </Typography>
        <Typography variant="body1">
          This email address will display on the page displayed when a
          respondent completes the survey. The email address will be dislayed
          under any 'Thank You Message'
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          WEB ADDRESS
        </Typography>
        <Typography variant="body1">
          A link to this web address will display on the page displayed when a
          respondent completes the survey. The web address will be dislayed
          under any 'Thank You Message' and email address
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          NUMBER OF BLANK LINES IN SPACER
        </Typography>
        <Typography variant="body1">
          The number of blank lines (carriage returns) that this PDF spacer will
          include. Anything below this PDF spacer will be pushed down the page
          by that many lines. A PDF spacer is useful in causing page breaks
          where desired in the PDF file of the survey.
        </Typography>
        <Typography className="linkRegularText" style={addMargin}>
          SURVEY COLOR
        </Typography>
        <Typography variant="body1">
          Specify a color ("red", "blue", etc.) or an html hexadecimal color
          code to use for this survey when survey respondents will have more
          than one survey to choose from after logging in (for such things as
          quarterly surveys). The link to this survey will be displayed in the
          color specified here.
        </Typography>

        <Button
          variant="contained"
          sx={{ marginLeft: "45%" }}
          onClick={() => handleClosePage()}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default SurveyHelpText;
