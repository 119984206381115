import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormControlLabel,
  RadioGroup,
  StyledEngineProvider,
} from "@mui/material";
import RadioButton from "../Common/RadioButton";
import { htmlParser } from "../../Utility/constants";
import HelpTextPopper from "../Common/Popper";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswerOptions } from "../../pages/Survey/Survey.slice";
import CopyrightTwoToneIcon from "@mui/icons-material/CopyrightTwoTone";
import NumericInputQuestion from "./NumericInputQuestion";
import TextInputQuestion from "./TextInputQuestion";
import DatePicker from "../Common/DatePicker";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import TextAreaQuestion from "./TextAreaQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import DropdownQuestion from "./DropdownQuestion";
import PhoneField from "../Common/PhoneField";
import StatesQuestion from "./StatesQuestion";

const TextFieldConfig = {
  questionNo: "Question-1",
  questionType: "Text",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  optionDirection: "row",
};

const TextAreaConfig = {
  questionNo: "Question-2",
  questionType: "Text Area",
  value: "text area input",
  size: 500,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  row: 10,
};

const NumericFieldConfig = {
  questionNo: "Question-3",
  questionType: "Numeric",
  value: "",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  allowDecimal: false,
  precision: 4,
  maxDigits: 5,
  min: 20,
  max: 1000000,
};

const DateFieldConfig = {
  questionNo: "Question-4",
  questionType: "Date",
  size: 250,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RadioConfig = {
  questionNo: "Question-5",
  questionType: "Radio",
  optionDirection: "row",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const MultiChoiceConfig = {
  questionNo: "Question-6",
  questionType: "Checkbox",
  optionDirection: "column",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const DropdownConfig = {
  questionNo: "Question-7",
  questionType: "Dropdown",
  size: 10,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RenderRadioGrp = ({
  questionsGrp,
  pageFilingId,
  getCorrectedData,
  pageId,
  secId,
  openCommentModal,
  reloadSec,
  hideComment = false,
  disableSave = false,
  fetchBatchUpdatePage = () => {},
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const headings = questionsGrp.headings.map((heading) => heading.name);
  const questions = questionsGrp.questions.map((question) => question);
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [tableHeader, setTableHeader] = useState([]);

  const [additionalFieldVal, setAdditionalFieldVal] = useState({});
  const tableRef = useRef(null);
  const selectedSurveyFilingIds = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedSurveyFilingIds
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const updateFilingStatus = useSelector(
    (state) => state?.survey?.batchUpdateData?.updateFilingStatus
  );

  const {
    customId: currentCustomId,
    surveyFilingId: currentSurveyFilingId,
    surveyRespondentId: currentRespondentId,
  } = selectedSurveyFilingIds?.[0] || {};

  const saveFieldResponse = async (value, field) => {
    if (otherProps?.correctionObj?.page === "correction") {
      getCorrectedData(value, field);
    } else {
      const payload = {
        answers: [
          {
            id: field?.answer?.id ? field?.answer?.id : null,
            value: value,
            loopIndex: 1,
            fieldId: field.id,
            surveyFilingId: currentSurveyFilingId,
            surveyRespondentId: currentRespondentId,
            createdBy: "string",
          },
        ],
        surveyFilingId: currentSurveyFilingId,
        pageFilingId: pageFilingId,
        runValidations: false,
        modifiedBy: userName,
        modifiedBySurveyRespondentId: currentRespondentId,
        updateStatuses: updateFilingStatus === "yes" ? false : true,
      };

      if (!disableSave) {
        await dispatch(saveAnswerOptions(payload));
      }

      if (field?.isInSamePageDispRule) {
        fetchBatchUpdatePage();
      }
    }
  };

  const handleRadioChange = (question, value, field) => {
    const updatedSelectedOptions = [...selectedOptions];

    // Find the corresponding question in the array
    const questionIndex = updatedSelectedOptions.findIndex(
      (item) => item.questionName === question
    );

    if (questionIndex !== -1) {
      updatedSelectedOptions[questionIndex].selectedAns = value;
    }

    setSelectedOptions(updatedSelectedOptions);
    saveFieldResponse(value, field);
  };

  const handleRadioDoubleClick = (question, field) => {
    // Create a copy of the selectedOptions array
    const updatedSelectedOptions = [...selectedOptions];

    const questionIndex = updatedSelectedOptions.findIndex(
      (item) => item.questionName === question
    );

    if (questionIndex !== -1) {
      updatedSelectedOptions[questionIndex].selectedAns = null;
    }
    // Update the state variable with the modified array
    setSelectedOptions(updatedSelectedOptions);
    saveFieldResponse(null, field);
  };

  const isChecked = (questionName, value, field) => {
    return selectedOptions[questionName] == value;
  };

  useEffect(() => {
    if (questions) {
      const options = questionsGrp.questions?.map((question) => {
        return {
          questionName: question.name,
          selectedAns: question?.fields[0]?.answer
            ? question?.fields[0]?.answer?.value
            : null,
        };
      });
      setSelectedOptions(options);
    }
  }, [questionsGrp]);

  const handleNumericChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const dateChanged = (value, field) => {
    saveFieldResponse(value, field);
  };

  const phoneNumChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const handleTextChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const textAreaChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const handleRadio = (value, field) => {
    saveFieldResponse(value, field);
  };

  const handleDropdownChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const stateFieldChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const checkboxOptionChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const fieldComponents = (question, field) => {
    const fieldType = field.fieldType.name;
    let fieldComponent;
    switch (fieldType) {
      case "Simpletext Field":
        fieldComponent = () => (
          <TextInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...TextFieldConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            question={field}
            onChange={(value) => {
              handleTextChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
            height="small"
          />
        );
        break;
      case "Textarea Field":
        fieldComponent = () => (
          <TextAreaQuestion
            disabledField={field?.disabledField}
            question={field}
            type={otherProps.type}
            fieldConfig={{
              ...TextAreaConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              textAreaChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
          />
        );
        break;
      case "Numeric Field":
        fieldComponent = () => (
          <NumericInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...NumericFieldConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleNumericChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
            height="small"
          />
        );
        break;
      case "Date Field":
        fieldComponent = () => (
          <DatePicker
            disabledField={field?.disabledField}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => dateChanged(value, field)}
          />
        );
        break;
      case "Radioyn Field":
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...RadioConfig,
              ...field,
            }}
            type={otherProps.type}
            qesType="y/n"
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleRadio(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
          />
        );
        break;
      case "Radio Field":
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...RadioConfig,
              ...field,
            }}
            type={otherProps.type}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleRadio(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
          />
        );
        break;
      case "CheckBox Field":
        fieldComponent = () => (
          <MultipleChoiceQuestion
            disabledField={field?.disabledField}
            fieldConfig={MultiChoiceConfig}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            type={otherProps.type}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => checkboxOptionChange(value, field)}
          />
        );
        break;
      case "State Field":
        fieldComponent = () => (
          <StatesQuestion
            disabledField={field?.disabledField}
            fieldConfig={DropdownConfig}
            type={otherProps.type}
            value={field?.answer ? field?.answer.value : ""}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => stateFieldChange(value, field)}
          />
        );
        break;
      case "Phone Field":
        fieldComponent = () => (
          <PhoneField
            question={field}
            disabledField={field?.disabledField}
            placeholder={"(XXX) XXX-XXXX"}
            type={otherProps.type}
            value={field?.answer ? field?.answer.value : null}
            size={field.size}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => phoneNumChange(value, field)}
          />
        );
        break;
      case "Combobox Field":
        fieldComponent = () => (
          <DropdownQuestion
            disabledField={field?.disabledField}
            fieldConfig={{
              ...DropdownConfig,
              ...field,
              value: additionalFieldVal[field.id] || "",
            }}
            type={otherProps.type}
            question={field}
            errorIndicator={getErrorRule(field)}
            onChange={(value) => {
              handleDropdownChange(value, field);
              setAdditionalFieldVal((prevValues) => ({
                ...prevValues,
                [field.id]: value,
              }));
            }}
            height="small"
          />
        );
        break;
      default:
        fieldComponent = () => (
          <Typography variant="h4">Under Development</Typography>
        );
        break;
    }

    const alignmentConfig = {
      "right-left": {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
      },
      "right-center": {
        flexDirection: "row-reverse",
        justifyContent: "center",
      },
      "right-right": {
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      },
      "left-left": { flexDirection: "row", justifyContent: "flex-start" },
      "left-center": { flexDirection: "row", justifyContent: "center" },
      "left-right": { flexDirection: "row", justifyContent: "flex-end" },
      "Above-left": { flexDirection: "column", alignContent: "flex-start" },
      "Above-center": { flexDirection: "column", alignContent: "center" },
      "Above-right": { flexDirection: "column", alignContent: "flex-end" },
      "Below-left": {
        flexDirection: "column-reverse",
        alignContent: "flex-start",
      },
      "Below-center": {
        flexDirection: "column-reverse",
        alignContent: "center",
      },
      "Below-right": {
        flexDirection: "column-reverse",
        alignContent: "flex-end",
      },
    };

    const getNamePosition = (namePos) => {
      if ((namePos && namePos === "No Title") || !namePos) return "left";
      else return namePos;
    };

    let alignmentKey = `${getNamePosition(field.namePosition)}-${
      field.horizontalAlign || "left"
    }`;

    let { flexDirection, justifyContent, alignContent } =
      alignmentConfig[alignmentKey] || {};

    if (field.fieldFlow === "NL") {
      flexDirection = "column";
    }
    return (
      <Grid
        item
        container
        justifyContent={justifyContent}
        flexDirection={flexDirection}
        alignContent={alignContent}
      >
        {field.name &&
          field.namePosition !== "No Title" &&
          field.namePosition && (
            <Grid item className="heading-container">
              <Typography variant="label">{htmlParser(field.name)}</Typography>
            </Grid>
          )}

        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          item
        >
          {fieldComponent(field)}
        </Grid>
      </Grid>
    );
  };

  const getErrorRule = (field) => {
    if (
      otherProps.correctionObj?.page === "correction" &&
      otherProps.correctionObj?.fieldIds.includes(field?.id)
    ) {
      return { displayIndicator: true };
    }
    return null;
  };

  const tableData = questions.map((question) => {
    const row = [];

    const firstRow = [];
    firstRow.push(
      <div style={{ display: "flex", alignItems: "center" }}>
        {isOpde && !hideComment && (
          <CopyrightTwoToneIcon
            style={{
              margin: "0 5px 3px 0",
              color: "#003087",
              cursor: "pointer",
            }}
            onClick={() => openCommentModal(secId, pageId, question.id)}
          />
        )}
        <Typography>
          {htmlParser(
            question.descriptionPosition !== null ||
              question.descriptionPosition !== "No Title"
              ? (question.label || "") + " " + (question.name || "")
              : (question.label || "") + " " + (question.description || "")
          )}
          {question?.helpText && (
            <HelpTextPopper question={question}></HelpTextPopper>
          )}
        </Typography>
      </div>
    );
    row.push(firstRow);

    question.fields.forEach((field, index) => {
      if (field.fieldType.name === "Radio Field" && index === 0) {
        field.fieldValues.forEach((fieldValue) =>
          row.push([
            <RadioGroup
              sx={{
                alignItems: "center",
              }}
              onChange={() =>
                handleRadioChange(
                  question.name,
                  fieldValue.allowableValue,
                  question.fields[0]
                )
              }
            >
              <FormControlLabel
                disabled={otherProps?.type === "preview" ? true : false}
                control={
                  <RadioButton
                    disabledField={question.fields[0].disabledField}
                    type={otherProps.type}
                    value={fieldValue.allowableValue}
                    checked={
                      selectedOptions.find(
                        (item) =>
                          item.questionName === question.name &&
                          item.selectedAns === fieldValue.allowableValue
                      ) !== undefined
                    }
                    flag={
                      question.fields[0]?.historicalAnswer?.value ===
                      fieldValue.allowableValue
                        ? true
                        : false
                    }
                  />
                }
                onDoubleClick={() =>
                  handleRadioDoubleClick(question.name, question.fields[0])
                }
                label={
                  <>
                    {getErrorRule(field) && (
                      <span style={{ color: "maroon" }}>{`<< `}</span>
                    )}
                  </>
                }
                style={{ marginLeft: "15px" }}
              />
            </RadioGroup>,
          ])
        );
      } else if (field.columnNumber !== null) {
        row[field.columnNumber]?.push(<>{fieldComponents(question, field)}</>);
      }
    });
    row.questionId = question.id;
    return row;
  });

  useEffect(() => {
    const tableHeader = (
      <table style={{ borderCollapse: "collapse", width: "100%" }}>
        <thead>
          <tr
            style={{
              borderBottom: "1px solid #ccc",
            }}
          >
            {headings.map((heading, index) => (
              <th
                key={index}
                style={{
                  textAlign: index === 0 ? "left" : "center",
                  width: index === 0 ? "40%" : `${60 / (headings.length - 1)}%`,
                }}
              >
                {htmlParser(heading)}
              </th>
            ))}
          </tr>
        </thead>
      </table>
    );
    setTableHeader(tableHeader);
  }, [headings.length, questionsGrp]);

  return (
    <StyledEngineProvider injectFirst>
      <Card className="card">
        <CardContent className="card-header">
          <Grid container direction={"column"}>
            <Grid
              item
              // alignSelf={TextFieldConfig.alignQuestion}
              className="question-container"
            >
              <Typography variant="question">
                {htmlParser(
                  questionsGrp.descriptionPosition !== null ||
                    questionsGrp.descriptionPosition !== "No Title"
                    ? (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.name || "")
                    : (questionsGrp.groupLabel || "") +
                        " " +
                        (questionsGrp.description || "")
                )}
                {questionsGrp?.helpText && (
                  <HelpTextPopper question={questionsGrp}></HelpTextPopper>
                )}
              </Typography>
            </Grid>
          </Grid>
          <div>
            <Typography variant="h6">
              {htmlParser(
                questionsGrp.descriptionPosition !== null ||
                  questionsGrp.descriptionPosition !== "No Title"
                  ? questionsGrp.description || ""
                  : ""
              )}
            </Typography>
          </div>
        </CardContent>
        <CardContent
          className="card-content"
          sx={{ backgroundColor: "#fff !important" }}
        >
          <Grid item>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
              <FormControl component="fieldset">
                {tableHeader}
                <Box
                  sx={{
                    overflowY: "scroll",
                    height:
                      tableRef?.current?.offsetHeight <= 600 ? "100%" : "600px",
                    scrollbarWidth: "thin",
                    scrollbarColor: "rgba(0, 48, 135, 1) white",
                    "&::-webkit-scrollbar": {
                      width: 3,
                      backgroundColor: "white",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0, 48, 135, 1)",
                      borderRadius: 4,
                    },
                    "&::-webkit-scrollbar-track": {
                      backgroundColor: "transparent",
                    },
                    "@media print": {
                      height: "100%",
                    },
                  }}
                >
                  <table style={{ borderCollapse: "collapse", width: "100%" }}>
                    <tbody ref={tableRef}>
                      {tableData.map((row, rowIndex) => (
                        <tr
                          key={rowIndex}
                          style={{
                            borderBottom: "1px solid #ccc",
                          }}
                          id={row.questionId}
                        >
                          {row.map((cell, cellIndex) => (
                            <td
                              key={cellIndex}
                              style={{
                                textAlign: cellIndex === 0 ? "left" : "center",
                                verticalAlign: "top",
                                padding: "2px",
                                width:
                                  cellIndex === 0
                                    ? "40%"
                                    : `${60 / (row.length - 1)}%`,
                              }}
                            >
                              {cell}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Box>
              </FormControl>
            </Box>
          </Grid>
        </CardContent>
      </Card>
    </StyledEngineProvider>
  );
};

export default RenderRadioGrp;
