import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Modal,
} from "@mui/material";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import ConfirmDialog from "../../components/Common/ConfirmDialog";
import Button from "../../components/Common/Button";
import Tooltip from "../../components/Common/Tooltip";
import theme from "../../theme";
import { htmlParser } from "../../Utility/constants";
import { getDataFilters, deleteDataFilter } from "../Survey/Survey.service";

import {
  setSelectedSurveyId,
  setSelectedSurveyName,
} from "../Survey/Survey.slice";
import AddEditDataFilter from "./AddEditDataFilter";
import AddDataQuery from "./AddDataQuery";

const DataFilters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterList, setFilterList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const location = useLocation();
  const surveyId = location?.state?.surveyId;
  const surveyName = location?.state?.surveyName;
  const [confirmTitle, setConfirmTitle] = useState(null);
  const [confirmId, setConfirmId] = useState(0);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [selectedFilterId, setSelectedFilterId] = useState(null);
  const [openDataFilterModal, setOpenDataFilterModal] = useState(false);
  const [selFilterData, setSelFilterData] = useState({});

  const [openDataQueryModal, setOpenDataQueryModal] = useState(false);
  const [dataFilterId, setDataFilterId] = useState(null);

  useEffect(() => {
    if (surveyId) {
      fetchDataFilters();
    }
  }, [surveyId]);

  const fetchDataFilters = async () => {
    setLoadingList(true);
    setFilterList([]);
    try {
      const response = await getDataFilters(surveyId);
      setFilterList(response?.data || []);
      setLoadingList(false);
    } catch (error) {
      console.error("Error fetching Data Filters:", error);
      setLoadingList(false);
    }
  };
  const data = useMemo(() => {
    return filterList?.map((filter) => {
      return {
        name: filter?.name,
        id: filter?.id,
        description: filter?.description || "",
        modifiedBy: filter?.modifiedBy,
        surveyId: filter?.survey?.id,
        surveyName: filter?.survey?.name,
        ruleId: filter?.rule?.id || "",
      };
    });
  }, [filterList]);

  const openDeleteFilter = async (id, name) => {
    const title = name || "";
    setConfirmTitle(
      "This will delete data filter for <b>" +
        title +
        "</b>, and cannot be undone."
    );
    setConfirmId(id);
    setConfirmDeleteOpen(true);
  };

  const handleOpenDataFilterModal = async () => {
    setSelFilterData({});
    setOpenDataFilterModal(true);
  };
  useEffect(() => {
    if (selectedFilterId) {
      const result = filterList?.find((item) => item.id === selectedFilterId);
      setSelFilterData(result);
      setOpenDataFilterModal(true);
    }
  }, [selectedFilterId]);

  const deleteDataFilterRecord = async () => {
    try {
      const response = await deleteDataFilter(confirmId);

      if (response?.status === 200) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Data filter deleted successfully");
        setAlertSeverity("success");
        setConfirmTitle(null);
        setConfirmId(0);
        setConfirmDeleteOpen(false);
        setFilterList(filterList.filter((vr) => vr.id !== confirmId));
      } else if (
        response?.response?.status === 400 ||
        response?.data?.status === 400
      ) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting data filter");
        setAlertSeverity("error");
        setConfirmTitle(null);
        setConfirmId(0);
        setConfirmDeleteOpen(false);
      }
    } catch (error) {
      console.log("Error deleting data filter.", error);
      return null;
    }
  };
  const handleCloseDataFilterModal = () => {
    setOpenDataFilterModal(false);
    setSelFilterData({});
    setSelectedFilterId(null);
  };
  const handleRefreshDataFilterModal = () => {
    handleCloseDataFilterModal();
    fetchDataFilters();
  };
  const handleCloseDataQueryModal = () => {
    setOpenDataQueryModal(false);
    setDataFilterId(null);
  };

  const showDataQueryModal = (id) => {
    setOpenDataQueryModal(true);
    setDataFilterId(id);
  };

  const redirectModifySurvey = (row) => {
    dispatch(setSelectedSurveyId(row.surveyId));
    dispatch(setSelectedSurveyName(row.surveyName));
    let payload = {};
    if (row.ruleId) {
      payload = { id: row.id, ruleId: row.ruleId };
    } else {
      payload = { id: row.id };
    }
    navigate(`/survey-builder`, {
      state: { showDataFilterRule: true, datafilterRecord: payload },
    });
  };
  return (
    <>
      <div>
        <Typography variant="label" color="primary" paragraph>
          {htmlParser(surveyName)}
        </Typography>
        <Grid container sx={{ marginBottom: "10px" }}>
          <Grid item xs={4}>
            <Typography variant="label" color="primary">
              Data Filters
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              size="small"
              component="label"
              onClick={() => handleOpenDataFilterModal()}
            >
              Add Data Filter
            </Button>
          </Grid>
        </Grid>
        {loadingList && "Loading Data Filters..."}
        {filterList.length > 0 && (
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead>
                <TableRow
                  sx={{
                    "& th": {
                      color: theme.palette.blue.main,
                      fontWeight: "bold",
                    },
                  }}
                >
                  <TableCell width="40%">Data Filters</TableCell>
                  <TableCell width="10%">Operations</TableCell>
                  <TableCell width="17%">Filing Criteria</TableCell>
                  <TableCell width="auto">Returned Fields</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row) => (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell scope="row" width="40%">
                      {row.name}
                      {row.description ? " - " + row.description : ""}
                    </TableCell>
                    <TableCell width="10%">
                      <>
                        <Tooltip title="Edit Filter" arrow>
                          <NoteAltOutlinedIcon
                            sx={{
                              cursor: "pointer",
                              color: theme.palette.green.main,
                              background: theme.palette.green.light,
                              borderRadius: "3px",
                              padding: "3px",
                              marginRight: "10px",
                            }}
                            onClick={() => setSelectedFilterId(row.id)}
                          />
                        </Tooltip>
                        <Tooltip title="Delete Filter" arrow>
                          <CloseOutlinedIcon
                            sx={{
                              cursor: "pointer",
                              color: theme.palette.error.main,
                              background: theme.palette.error.light,
                              borderRadius: "3px",
                              padding: "3px",
                            }}
                            onClick={() => {
                              openDeleteFilter(row.id, row.name);
                            }}
                          />
                        </Tooltip>
                      </>
                    </TableCell>
                    <TableCell width="17%">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => redirectModifySurvey(row)}
                      >
                        {row.ruleId ? "Edit " : "Add "} Filing Criteria
                      </Button>
                    </TableCell>
                    <TableCell width="auto">
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => showDataQueryModal(row.id)}
                      >
                        Edit Returned Fields
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </div>
      <ConfirmDialog
        title={confirmTitle}
        open={confirmDeleteOpen}
        setOpen={setConfirmDeleteOpen}
        onConfirm={deleteDataFilterRecord}
      ></ConfirmDialog>

      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
      <Modal open={openDataFilterModal} onClose={handleCloseDataFilterModal}>
        <AddEditDataFilter
          handleCloseDataFilter={handleCloseDataFilterModal}
          selFilterData={selFilterData}
          handleRefreshDataFilter={handleRefreshDataFilterModal}
        />
      </Modal>

      <Modal open={openDataQueryModal} onClose={handleCloseDataQueryModal}>
        <AddDataQuery
          handleCloseDataQuery={handleCloseDataQueryModal}
          dataFilterId={dataFilterId}
        />
      </Modal>
    </>
  );
};

export default DataFilters;
