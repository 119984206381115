import React, { useState, useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Grid, Typography, FormControl, TextField } from "@mui/material";
import Button from "../../../components/Common/Button";
import { useLocation } from "react-router";
import { setImportCurrentView } from "../../Survey/Survey.slice";
import { useDispatch, useSelector } from "react-redux";
import { formatShortDate, htmlParser } from "../../../Utility/constants";
import { completedBatches } from "../../Survey/Survey.service";
import PanelAssignment from "./PanelAssignment";

const BatchSearch = () => {
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchResult, setbatchResult] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [customIds, setCustomIds] = useState(null);
  const location = useLocation();
  const surveyName = location?.state?.surveyName;
  const surveyId = location?.state?.surveyId;
  const dispatch = useDispatch();
  const states = useSelector((state) => state?.survey?.states);
  const personId = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.id
  );
  const [searchPayload, setSearchPayload] = useState({});
  const [showSurvey, setShowSurvey] = useState(false);
  const [showMessage, setShowMessage] = useState(null);

  useEffect(() => {
    const getCompletedBatches = async () => {
      try {
        const res = await completedBatches(surveyId);
        if (Array.isArray(res?.data) && res?.data?.length > 0) {
          const responseData = res?.data;
          setbatchResult(responseData);
          setSelectedBatch(responseData[0].id);
        }
      } catch (error) {
        console.error("Error fetching batch Information:", error);
      }
    };
    if (personId) {
      getCompletedBatches();
    }
  }, [personId]);

  const handleBatchChange = (event) => {
    setSelectedBatch(event.target.value);
  };
  const handleStateChange = (event) => {
    const newSelectedValues = event.target.value;
    setSelectedValues(newSelectedValues);
  };
  const toggleList = () => {
    setSearchPayload({});
    setShowSurvey(false);
  };
  const handleSubmit = async () => {
    const payload = {
      surveyId: surveyId,
      batchId: selectedBatch,
      stateIds: selectedValues.length > 0 ? selectedValues.join(",") : null,
      customIds: customIds?.length > 0 ? customIds : "",
    };
    setSearchPayload(payload);
    setShowSurvey(true);
    setShowMessage(null);
  };
  return (
    <>
      {!showSurvey && (
        <>
          <Typography variant="label" color="primary">
            Survey: {htmlParser(surveyName)}
          </Typography>

          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Typography variant="body1">
                Survey Organization Custom ID(s) (multiple ids can be added with
                the ',' character):
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                variant="outlined"
                value={customIds}
                onChange={(e) => setCustomIds(e.target.value)}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2} sx={{ marginTop: "2px" }}>
            <Grid item xs={4} sx={{ textAlign: "right" }}>
              <Typography variant="body1">State:</Typography>
            </Grid>
            <Grid item xs={8}>
              <FormControl sx={{ width: "300px" }}>
                <Select
                  value={selectedValues}
                  variant="outlined"
                  multiple
                  onChange={handleStateChange}
                >
                  {states.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.longName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          {batchResult?.length > 0 && (
            <>
              <Grid container spacing={2} sx={{ marginTop: "2px" }}>
                <Grid item xs={4} sx={{ textAlign: "right" }}>
                  <Typography variant="body1">Batch ID:</Typography>
                </Grid>
                <Grid item xs={8}>
                  <FormControl sx={{ minWidth: 200 }}>
                    <Select value={selectedBatch} onChange={handleBatchChange}>
                      {batchResult.map((data, index) => (
                        <MenuItem key={index} value={data.id}>
                          Batch ID:{data.id} {formatShortDate(data.dateCreated)}{" "}
                          ({data.recordCount} records)
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
          )}

          <Grid container spacing={2} sx={{ marginTop: "2px" }}>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              <Button
                variant="contained"
                component="label"
                onClick={() => handleSubmit()}
              >
                Submit
              </Button>
              <Button
                sx={{ marginLeft: "10px" }}
                variant="outlined"
                color="primary"
                onClick={() => dispatch(setImportCurrentView(1))}
              >
                Back
              </Button>
            </Grid>
          </Grid>
        </>
      )}
      {showSurvey && (
        <>
          <Button
            variant="outlined"
            color="primary"
            sx={{ marginLeft: "40%" }}
            onClick={() => toggleList()}
          >
            Back
          </Button>
          <div>
            <PanelAssignment
              selectedBatch={selectedBatch}
              searchPayload={searchPayload}
            />
          </div>
        </>
      )}
      {showMessage && (
        <Grid container sx={{ marginTop: "10px" }}>
          <Grid item xs={5} sx={{ textAlign: "right" }}>
            <Typography color="primary" variant="lsbel">
              {showMessage}
            </Typography>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default BatchSearch;
