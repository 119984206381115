import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/material/styles";
import DatePicker from "../Common/DatePicker";
import "./QuestionTypes.scss";
import NumericInputQuestion from "./NumericInputQuestion";
import SingleChoiceQuestion from "./SingleChoiceQuestion";
import TextInputQuestion from "./TextInputQuestion";
import TextAreaQuestion from "./TextAreaQuestion";
import MultipleChoiceQuestion from "./MultipleChoiceQuestion";
import { htmlParser } from "../../Utility/constants";
import DropdownQuestion from "./DropdownQuestion";
import PhoneField from "../Common/PhoneField";
import HelpTextPopper from "../Common/Popper";
import StatesQuestion from "./StatesQuestion";
import { useDispatch, useSelector } from "react-redux";
import { saveAnswerOptions } from "../../pages/Survey/Survey.slice";
import StateDropdown from "../Common/StateDropdown";
import TotalCalcQes from "./TotalCalcQes";
import CopyrightTwoToneIcon from "@mui/icons-material/CopyrightTwoTone";

const TextFieldConfig = {
  questionNo: "Question-1",
  questionType: "Text",
  size: 25,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  optionDirection: "row",
};

const TextAreaConfig = {
  questionNo: "Question-2",
  questionType: "Text Area",
  value: "text area input",
  size: 500,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  maxLength: 5,
  row: 10,
};

const NumericFieldConfig = {
  questionNo: "Question-3",
  questionType: "Numeric",
  value: "",
  size: 250,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
  allowDecimal: false,
  precision: 4,
  maxDigits: 5,
  min: 20,
  max: 1000000,
};

const DateFieldConfig = {
  questionNo: "Question-4",
  questionType: "Date",
  size: 250,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const RadioConfig = {
  questionNo: "Question-5",
  questionType: "Radio",
  optionDirection: "row",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const MultiChoiceConfig = {
  questionNo: "Question-6",
  questionType: "Checkbox",
  optionDirection: "column",
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

const DropdownConfig = {
  questionNo: "Question-7",
  questionType: "Dropdown",
  size: 200,
  alignField: "left",
  heading: "Answer",
  alignHeading: "above",
  alignQuestion: "start",
};

// const StatesConfig = {
//   questionNo: "Question-8",
//   questionType: "States",
//   alignField: "center",
//   alignQuestion: "start",
// };

const RenderQuestion = ({
  renderFields,
  question,
  pageFilingId,
  fieldId,
  multiple,
  correctionObj,
  getCorrectedData,
  questionsInPage,
  pageId,
  secId,
  openCommentModal,
  reloadSec,
  hideComment = false,
  disableSave = false,
  fetchBatchUpdatePage = () => {},
  ...otherProps
}) => {
  const dispatch = useDispatch();
  const isOpde = useSelector((state) => state?.survey?.isOpde);
  const selectedSurveyFilingIds = useSelector(
    (state) => state?.survey?.batchUpdateData?.selectedSurveyFilingIds
  );

  const userName = useSelector(
    (state) => state?.survey?.loggedInUserData?.authorizedUser?.name
  );

  const updateFilingStatus = useSelector(
    (state) => state?.survey?.batchUpdateData?.updateFilingStatus
  );

  const {
    customId: currentCustomId,
    surveyFilingId: currentSurveyFilingId,
    surveyRespondentId: currentRespondentId,
  } = selectedSurveyFilingIds?.[0] || {};

  const saveFieldResponse = async (value, field) => {
    if (correctionObj?.page === "correction") {
      getCorrectedData(value, field);
    } else {
      const payload = {
        answers: [
          {
            id: field?.answer?.id ? field?.answer?.id : null,
            value: value,
            loopIndex: 1,
            fieldId: field.id,
            surveyFilingId: currentSurveyFilingId,
            surveyRespondentId: currentRespondentId,
            createdBy: "string",
          },
        ],
        surveyFilingId: currentSurveyFilingId,
        pageFilingId: pageFilingId,
        runValidations: false,
        modifiedBy: userName,
        modifiedBySurveyRespondentId: currentRespondentId,
        updateStatuses: updateFilingStatus === "yes" ? false : true,
      };

      if (!disableSave) {
        await dispatch(saveAnswerOptions(payload));
      }

      if (
        field?.isInSamePageDispRule ||
        questionsInPage?.groupType?.name === "autocalctotalgrp"
      ) {
        fetchBatchUpdatePage();
      }
    }
  };

  const saveTotalResponse = async (value, field) => {
    if (correctionObj?.page === "correction") {
      getCorrectedData(value, field);
    } else {
      const payload = {
        answers: [
          {
            id: field?.answer?.id ? field?.answer?.id : null,
            value: value,
            loopIndex: 1,
            fieldId: field.id,
            surveyFilingId: currentSurveyFilingId,
            surveyRespondentId: currentRespondentId,
            createdBy: "string",
          },
        ],
        surveyFilingId: currentSurveyFilingId,
        pageFilingId: pageFilingId,
        runValidations: false,
        modifiedBy: userName,
        modifiedBySurveyRespondentId: currentRespondentId,
        updateStatuses: updateFilingStatus === "yes" ? false : true,
      };

      await dispatch(saveAnswerOptions(payload));

      if (
        field?.isInSamePageDispRule ||
        questionsInPage.groupType.name === "autocalctotalgrp"
      ) {
        fetchBatchUpdatePage();
      }
    }
  };

  const numericFieldChange = async (value, field) => {
    let qesGrp = { ...questionsInPage };
    if (qesGrp?.groupType?.name === "autocalctotalgrp") {
      let totalValue = 0;
      let totalField = null;
      for (const obj of questionsInPage?.questions) {
        if (obj?.fields[0]?.fieldType?.name !== "Auto Calc Total Field") {
          if (obj?.fields[0]?.id === field?.id) {
            totalValue += Number(value || 0);
          } else {
            totalValue += Number(obj?.fields[0]?.answer?.value || 0);
          }
        } else if (
          obj?.fields[0]?.fieldType?.name === "Auto Calc Total Field"
        ) {
          totalField = obj?.fields[0];
        }
      }
      await saveTotalResponse(totalValue, totalField);
    }
    await saveFieldResponse(value, field);
  };

  const dateChanged = (value, field) => {
    saveFieldResponse(value, field);
  };

  const phoneNumChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const textFieldChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const textAreaChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const radioFieldChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const dropdownChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const stateFieldChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const checkboxOptionChange = (value, field) => {
    saveFieldResponse(value, field);
  };

  const getTotalVal = () => {
    if (questionsInPage?.questions?.length > 0) {
      let totalValue = 0;
      for (const obj of questionsInPage?.questions) {
        if (obj?.fields[0]?.fieldType?.name !== "Auto Calc Total Field")
          totalValue += Number(obj?.fields[0]?.answer?.value || 0);
      }
      return totalValue;
    }
    return "";
  };

  const fieldComponents = (
    fieldId
      ? question.fields.filter((field) => field.id === fieldId)
      : question.fields
  ).map((field, index) => {
    const fieldType = field.fieldType.name;
    let fieldComponent;

    const getErrorRule = () => {
      if (
        correctionObj?.page === "correction" &&
        correctionObj?.fieldIds.includes(field?.id)
      ) {
        return { displayIndicator: true };
      }
      return null;
    };
    switch (fieldType) {
      case "Simpletext Field":
        let textConfig = Object.assign({}, TextFieldConfig, field);
        textConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <TextInputQuestion
            disabledField={field?.disabledField}
            fieldConfig={textConfig}
            type={otherProps.type}
            errorIndicator={getErrorRule()}
            question={field}
            onChange={(value) => textFieldChange(value, field)}
          />
        );
        break;
      case "Textarea Field":
        let config = Object.assign(TextAreaConfig, field);
        config.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <TextAreaQuestion
            disabledField={field?.disabledField}
            type={otherProps.type}
            errorIndicator={getErrorRule()}
            fieldConfig={config}
            question={field}
            onChange={(value) => textAreaChange(value, field)}
          />
        );
        break;
      case "Numeric Field":
        let numConfig = Object.assign(NumericFieldConfig, field);
        numConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <NumericInputQuestion
            disabledField={field?.disabledField}
            type={otherProps.type}
            errorIndicator={getErrorRule()}
            fieldConfig={numConfig}
            question={field}
            onChange={(value) => numericFieldChange(value, field)}
          />
        );
        break;
      case "Auto Calc Total Field":
        let CalcConfig = Object.assign(NumericFieldConfig, field);
        CalcConfig.value = getTotalVal();
        fieldComponent = () => (
          <TotalCalcQes
            disabledField={true}
            type={otherProps.type}
            errorIndicator={getErrorRule()}
            fieldConfig={CalcConfig}
            question={field}
            total={getTotalVal()}
            // onChange={(value) => numericFieldChange(value, field)}
          />
        );
        break;
      case "Date Field":
        let dateConfig = Object.assign(DateFieldConfig, field);
        dateConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <DatePicker
            disabledField={field?.disabledField}
            type={otherProps.type}
            errorIndicator={getErrorRule()}
            value={dateConfig.value}
            question={field}
            onChange={(value) => dateChanged(value, field)}
          />
        );
        break;
      case "Radioyn Field":
        let radioConfig = Object.assign(RadioConfig, field);
        radioConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            fieldConfig={radioConfig}
            type={otherProps.type}
            qesType="y/n"
            value={radioConfig.value}
            question={field}
            onChange={(value) => radioFieldChange(value, field)}
          />
        );
        break;
      case "Radio Field":
        let radioFieldConfig = Object.assign(RadioConfig, field);
        radioFieldConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <SingleChoiceQuestion
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            type={otherProps.type}
            fieldConfig={radioFieldConfig}
            value={radioFieldConfig.value}
            question={field}
            onChange={(value) => radioFieldChange(value, field)}
          />
        );
        break;
      case "CheckBox Field":
        let checkConfig = Object.assign(MultiChoiceConfig, field);
        checkConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <MultipleChoiceQuestion
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            type={otherProps.type}
            fieldConfig={checkConfig}
            value={checkConfig.value}
            question={field}
            onChange={(value) => checkboxOptionChange(value, field)}
          />
        );
        break;
      case "State Select Field":
        let stateMultiConfig = Object.assign(DropdownConfig, field);
        stateMultiConfig.value = field?.answer ? field?.answer.value : "";
        fieldComponent = () => (
          <StatesQuestion
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            type={otherProps.type}
            fieldConfig={stateMultiConfig}
            value={stateMultiConfig.value}
            question={field}
            onChange={(value) => stateFieldChange(value, field)}
          />
        );
        break;
      case "State Field":
        let stateConfig = Object.assign(DropdownConfig, field);
        stateConfig.value = field?.answer ? field?.answer?.value : "";
        fieldComponent = () => (
          <StateDropdown
            question={field}
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            type={otherProps.type}
            size={stateConfig?.size}
            value={stateConfig?.value}
            label={field}
            options={field.fieldValues || []}
            onChange={(value) => dropdownChange(value, field)}
          />
        );
        break;
      case "Phone Field":
        fieldComponent = () => (
          <PhoneField
            question={field}
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            type={otherProps.type}
            placeholder={"(XXX) XXX-XXXX"}
            value={field?.answer ? field?.answer.value : null}
            size={field.size}
            onChange={(value) => phoneNumChange(value, field)}
          />
        );
        break;
      case "Combobox Field":
        let comboConfig = Object.assign(DropdownConfig, field);
        comboConfig.value = field?.answer ? field?.answer.value : "";
        comboConfig.historicalValue = field?.historicalAnswer?.value;
        fieldComponent = () => (
          <DropdownQuestion
            disabledField={field?.disabledField}
            errorIndicator={getErrorRule()}
            type={otherProps.type}
            fieldConfig={comboConfig}
            question={field}
            onChange={(value) => dropdownChange(value, field)}
          />
        );
        break;
      default:
        // throw new Error(`Unknown field type: ${fieldType}`);
        fieldComponent = () => (
          <Typography variant="h4">Under Development</Typography>
        );
        break;
    }
    const alignmentConfig = {
      "right-left": {
        flexDirection: "row-reverse",
        justifyContent: "flex-end",
      },
      "right-center": {
        flexDirection: "row-reverse",
        justifyContent: "center",
      },
      "right-right": {
        flexDirection: "row-reverse",
        justifyContent: "flex-start",
      },
      "left-left": { flexDirection: "row", justifyContent: "flex-start" },
      "left-center": { flexDirection: "row", justifyContent: "center" },
      "left-right": { flexDirection: "row", justifyContent: "flex-end" },
      "above-left": { flexDirection: "column", alignContent: "flex-start" },
      "above-center": { flexDirection: "column", alignContent: "center" },
      "above-right": { flexDirection: "column", alignContent: "flex-end" },
      "below-left": {
        flexDirection: "column-reverse",
        alignContent: "flex-start",
      },
      "below-center": {
        flexDirection: "column-reverse",
        alignContent: "center",
      },
      "below-right": {
        flexDirection: "column-reverse",
        alignContent: "flex-end",
      },
    };

    const getNamePosition = (namePos) => {
      if ((namePos && namePos === "No Title") || !namePos) return "left";
      else return namePos;
    };

    let alignmentKey = `${getNamePosition(field.namePosition)}-${
      field.horizontalAlign || "left"
    }`;
    // const alignmentKey = `Above-left`;
    let { flexDirection, justifyContent, alignContent } =
      alignmentConfig[alignmentKey] || {};

    if (field.fieldFlow === "NL") {
      flexDirection = "column";
    }
    // console.log(question);
    return (
      <>
        {renderFields && (
          <Grid
            item
            sx={{
              marginBottom: "15px",
            }}
          >
            <Typography variant="label" color="primary">
              {htmlParser(field.userLabel)}
            </Typography>
          </Grid>
        )}
        <Grid
          item
          container
          justifyContent={justifyContent}
          flexDirection={flexDirection}
          alignContent={alignContent}
          flexWrap="nowrap"
          key={index}
        >
          {field.name &&
            field.namePosition !== "No Title" &&
            field.namePosition && (
              <Grid item className="heading-container">
                <Typography variant="label">
                  {htmlParser(field.name)}
                </Typography>
              </Grid>
            )}
          <Grid sx={{ display: "flex", alignItems: "center" }} item>
            {fieldComponent(field)}
          </Grid>
        </Grid>
      </>
    );
  });

  const standAloneQes = (
    <StyledEngineProvider injectFirst>
      <Card className="card" id={question.id}>
        <CardContent className="card-header">
          {isOpde && !hideComment && (
            <CopyrightTwoToneIcon
              style={{
                margin: "0 5px -6px 0",
                color: "#003087",
                cursor: "pointer",
              }}
              onClick={() => openCommentModal(secId, pageId, question.id)}
            />
          )}
          <Grid item alignSelf="start" className="question-container">
            <Typography variant="question">
              {htmlParser(((question.label || "") + " " || "") + question.name)}
              {question.helpText && (
                <HelpTextPopper question={question}></HelpTextPopper>
              )}
            </Typography>
          </Grid>
          <div>
            <Typography variant="h6">
              {htmlParser(question.description || "")}
            </Typography>
          </div>
        </CardContent>
        <CardContent className="card-content">
          <Grid item container flexWrap="nowrap" flexDirection="column">
            {fieldComponents}
          </Grid>
        </CardContent>
      </Card>
    </StyledEngineProvider>
  );
  const multiQes = (
    <Grid container flexWrap="nowrap" flexDirection="row" id={question.id}>
      {question.name !== "&nbsp;" && question.name !== "" && (
        <Grid item mr={4}>
          <legend
            style={{
              width: "500px",
              fontSize: "16px",
              fontWeight: "bold",
              marginTop: "20px",
            }}
            gutterBottom
          >
            {isOpde && (
              <CopyrightTwoToneIcon
                style={{
                  margin: "0 5px -6px 0",
                  color: "#003087",
                  cursor: "pointer",
                }}
                onClick={() => openCommentModal(secId, pageId, question.id)}
              />
            )}
            {htmlParser(((question.label || "") + " " || "") + question.name)}
            {question.helpText && (
              <HelpTextPopper question={question}></HelpTextPopper>
            )}
          </legend>
        </Grid>
      )}

      <Grid item container flexWrap="nowrap" mb={1}>
        {fieldComponents}
      </Grid>
    </Grid>
  );

  return multiple ? <>{multiQes}</> : <>{standAloneQes}</>;
};

export default RenderQuestion;
