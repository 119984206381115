import { Button, Snackbar, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import Tooltip from "../../../components/Common/Tooltip";
import NoteAltOutlinedIcon from "@mui/icons-material/NoteAltOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import theme from "../../../theme";
import { deleteSection, updateSectionOrder } from "../../Survey/Survey.service";
import {
  setSelectedSec,
  setSurveyBuilderCurrentView,
  triggerSidebarRefresh,
} from "../../Survey/Survey.slice";
import ConfirmDialog from "../../../components/Common/ConfirmDialog";
import MuiAlert from "@mui/material/Alert";
import SurveyActionButtons from "./SurveyActionButtons";
import { getTextFromHTML, htmlParser } from "../../../Utility/constants";

const EditSurvey = () => {
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 0 });
  const [confirmTitle, setConfirmTitle] = useState("");
  const [confirmSecId, setConfirmSecId] = useState("");
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const dispatch = useDispatch();
  const surveyName = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.survey?.name
  );

  const surveySections = useSelector(
    (state) => state?.survey?.surveyBuilderData?.surveyData?.sections
  );
  useEffect(() => {
    if (surveySections) {
      const sectionsData = surveySections.map((section) => ({
        id: section?.section?.id,
        sectionName: getTextFromHTML(section?.section?.name),
      }));
      setData(sectionsData);
      setPagination({ pageIndex: 0, pageSize: sectionsData?.length });
    }
  }, [surveySections]);

  const handleSecDelete = async () => {
    try {
      const response = await deleteSection(confirmSecId);
      if (response?.status === 200 && response?.data === true) {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Section deleted successfully");
        setAlertSeverity("success");
        setConfirmTitle("");
        setConfirmSecId("");
        setConfirmOpen(false);
        dispatch(triggerSidebarRefresh());
      } else {
        setSuccessSnackbarOpen(true);
        setAlertMsg("Error deleting section");
        setAlertSeverity("error");
        setConfirmTitle("");
        setConfirmSecId("");
        setConfirmOpen(false);
      }
    } catch (error) {
      console.log("Error deleting survey data.", error);
      return null;
    }
  };

  const openDeleteSurveyData = async (secId, name) => {
    setConfirmTitle("Deleting " + name + " - Press OK to proceed.");
    setConfirmSecId(secId);
    setConfirmOpen(true);
  };

  const handleOperation = (source, secId, surveySecs) => {
    const selectedSec = surveySecs?.find((obj) => obj.section.id === secId);
    dispatch(setSelectedSec(selectedSec));
    dispatch(
      setSurveyBuilderCurrentView({
        viewIndex: 5,
        source: source,
      })
    );
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "operations",
        header: "Operations",
        size: 50,
        Cell: ({ row }) => (
          <>
            <Tooltip title="Section info edit" arrow>
              <NoteAltOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.green.main,
                  background: theme.palette.green.light,
                  borderRadius: "10px",
                  padding: "10px",
                  marginRight: "10px",
                }}
                onClick={() =>
                  handleOperation(
                    "editSectionIcon",
                    row?.original?.id,
                    surveySections
                  )
                }
              />
            </Tooltip>
            <Tooltip title="Delete a Section" arrow>
              <CloseOutlinedIcon
                sx={{
                  cursor: "pointer",
                  width: 40,
                  height: 40,
                  color: theme.palette.error.main,
                  background: theme.palette.error.light,
                  borderRadius: "10px",
                  padding: "10px",
                }}
                onClick={() => {
                  openDeleteSurveyData(
                    row?.original?.id,
                    row?.original?.sectionName
                  );
                }}
              />
            </Tooltip>
          </>
        ),
      },
      {
        accessorKey: "sectionName",
        header: "Section",
      },
    ],
    [surveySections]
  );

  const updateSecOrder = async (newSecOrder) => {
    try {
      await updateSectionOrder(newSecOrder);
    } catch (error) {
      console.error("Error updating section order", error);
    } finally {
      dispatch(triggerSidebarRefresh());
    }
  };

  const table = useMaterialReactTable({
    columns,
    data,
    enableRowOrdering: true,
    enableSorting: false,
    enableTopToolbar: false,
    enableBottomToolbar: false,
    state: { pagination },
    enableColumnActions: false,
    initialState: { density: "compact" },
    muiRowDragHandleProps: ({ table }) => ({
      onDragEnd: () => {
        const { draggingRow, hoveredRow } = table.getState();
        if (hoveredRow && draggingRow) {
          data.splice(
            hoveredRow.index,
            0,
            data.splice(draggingRow.index, 1)[0]
          );

          if (draggingRow.index !== hoveredRow.index) {
            setData([...data]);
            const newSecOrder = {
              idSequenceList: data.map((item, index) => ({
                id: item.id,
                sequence: index,
              })),
            };

            updateSecOrder(newSecOrder);
          }
        }
      },
    }),
  });
  const buttonStyle = {
    marginRight: "20px",
    marginBottom: "15px",
  };

  return (
    <>
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label" color="primary">
          {htmlParser(surveyName)}
        </Typography>
      </div>
      <SurveyActionButtons page="Survey" />
      <div style={{ marginBottom: "20px" }}>
        <Typography variant="label">
          Sections in Survey. Available operations: reorder, edit and delete
          section
        </Typography>
      </div>
      <MaterialReactTable table={table} />
      <ConfirmDialog
        title={confirmTitle}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        onConfirm={handleSecDelete}
        pageName="ModifySurvey"
      ></ConfirmDialog>
      <Snackbar
        open={successSnackbarOpen}
        autoHideDuration={2000}
        onClose={() => setSuccessSnackbarOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MuiAlert
          onClose={() => setSuccessSnackbarOpen(false)}
          severity={alertSeverity}
          variant="filled"
        >
          {alertMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default EditSurvey;
